import { TextField } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";

export default function CustField(props) {
  const styles = {
    field: {
      '& .MuiFormLabel-root': {
        fontSize: 12,
        mt: "-3px",
        padding: 0
      }
    },
    fieldBlur: {
      background: blueGrey,
    },
    inputProps: {
      style: {
        mt: 0,
        fontSize: 12,
        height: 22,
        padding: "3px",
        paddingLeft: "5px",
        '& input::placeholder': {
          fontSize: 12,
          padding: 0
        }
      }
    }
  };
  const [style] = React.useState([styles.field, props.sx]);

  return (
    <TextField
      variant="outlined"
      {...props}
      value={props.value ? props.value : ''}
      error={!props.value}
      required
      inputProps={styles.inputProps}
      sx={style}
      onBlur={(e) => props.onChange(e)}
      onChange={(e) => props.onChange(e)}
      size="small"
    />
  );
}

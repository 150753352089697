import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupsIcon from '@mui/icons-material/Groups';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SchemaIcon from '@mui/icons-material/Schema';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import {
    Alert, Autocomplete, Avatar, Button, Card, CardHeader, CircularProgress, Dialog, Divider, Fab, Grid, InputAdornment, ListItemIcon, ListItemText, MenuItem, Snackbar, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography
} from '@mui/material';
import Menu from '@mui/material/Menu';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    addProject, fetchAllOrgs, fetchAllOrgsOfUser, listProjects, registerOrganization, removeOrgFromUser, updateOrganization, updateProject
} from "../../apis/ClientDataService";
import OrganizationContext from "../../state/OrganizationContext";
import ProjectContext from "../../state/ProjectContext";
import { getUser } from '../../state/state';
import GrantAccess from './GrantAccess';

export default function SideBar() {

    const user = getUser();

    const [name, setName] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(false);
    const [userOrgs, setUserOrgs] = useState(null);
    const [userOrgsProjects, setUserOrgsProjects] = useState({})
    const [activeOrg, setActiveOrg] = useState({});
    const [activeProject, setActiveProject] = useState({});

    const [snackNotif, setSnackNotif] = useState("");

    const [expanded, setExpanded] = useState([]);

    const handleToggle = (event, nodeIds) => {
        // console.log(nodeIds);
        setExpanded(nodeIds);
    };

    const { globalOrganization, updateGlobalOrganization } = useContext(OrganizationContext);
    const { globalProject, updateGlobalProject } = useContext(ProjectContext);
    const navigate = useNavigate();

    const [isDialogOpen, setIsDialogOpen] = useState("");
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const filteredOrgs = userOrgs
        ? userOrgs.filter((org) =>
            org.name.toLowerCase().includes(searchText.toLowerCase())
        )
        : [];

    const handleAddOrganizationClick = () => {
        setIsDialogOpen("addOrganization");
        setName("");
    };

    const handleAddProjectClick = () => {
        setIsDialogOpen("addProject");
        setName("");
    };

    const handleGoToScenariosClick = (org, project) => {
        updateGlobalOrganization(org);
        updateGlobalProject(project);

        navigate("/scenarios");
    };

    const handleEditOrganizationClick = (org) => {
        setName(org.name);
        setIsDialogOpen("editOrganization");
    };

    const handleAddUserToOrganizationClick = (org) => {
        setName(org.name);
        setIsDialogOpen("addUser");
    };

    const handleEditProjectClick = (project) => {
        setName(project.name);
        setIsDialogOpen("editProject");
    };

    const handleViewOrganizationDetailsClick = () => {
        setIsDialogOpen("viewOrganizationDetails");
    };

    const handleViewProjectDetailsClick = () => {
        setIsDialogOpen("viewProjectDetails");
    };

    const handleRemoveOrganizationClick = () => {
        setIsDialogOpen("removeOrganization");
    };

    const handleRemoveProjectClick = () => {
        setIsDialogOpen("removeProject");
    };

    const handleDialogClose = () => {
        setIsDialogOpen("");
        setError(false);
    };

    const validateForm = () => {
        return name.trim() && name.length >= 1
    }

    useEffect(() => {
        setLoading(true);
        setError(null);
        fetchAllOrgs((organizations) => {
            // console.log(1,organizations)
            setOrgs(organizations);
            // if (organizations.length > 0) {
            //     setExpanded(organizations.map(org => `org${org.id}`));
            // }
        }, err => {
            setLoading(false);
            setError("Organizations could not be fetched");
            console.log(err);
        });

        if (user) {
            fetchAllOrgsOfUser(user.id, (organizations) => {
                // console.log(2,organizations)
                setUserOrgs(organizations);
                organizations.map(
                    (organization) => listProjects(organization.id, (projects) => {
                        // console.log(3,organization,projects)
                        setUserOrgsProjects(prevState => ({ ...prevState, [organization.id]: projects }));
                    },
                        err => {
                            setError("Projects could not be fetched!");
                            console.log(err);
                        }))
                setLoading(false);
            }, err => {
                setLoading(false);
                setError("User organizations could not be fetched!");
                console.log(err);
            });
        }
    }, []);

    const createOrganization = () => {
        setLoading(true);
        setError(null);
        registerOrganization(
            {
                name: name.trim(),
            },
            (organization) => {
                const updatedOrgs = [...orgs, organization];
                setOrgs(updatedOrgs);
                // console.log(4,organization)
                listProjects(organization.id, (projects) => {
                    // console.log(5,projects);
                    setUserOrgsProjects(prevState => ({ ...prevState, [organization.id]: projects }));
                },
                    err => {
                        setError("Projects could not be fetched");
                        console.log(err);
                    })

                if(userOrgs === null || userOrgs.length === 0){
                    updateGlobalOrganization(organization)
                }

                setUserOrgs([...userOrgs, organization]);
                setLoading(false);
                handleDialogClose();
                setSnackNotif("addOrganization");
                // If org is added let us expand that nodeId view of that organization
                setExpanded([...expanded, `org${organization.id}`]);
                // updateOrganization(org);
                // navigate(-2);
            },
            (err) => {
                setLoading(false);
                if (err["error"] === "DUPLICATE_NAME")
                    setError("Organization already exists!");
                else
                    setError(
                        "Unable to register organization. Please contact priceops!"
                    );
            }
        );
    };

    const createProject = (orgId) => {
        setLoading(true);
        setError(null);
        addProject(orgId, {
            name: name.trim(),
        }, (project) => {
            setUserOrgsProjects(prevState => ({ ...prevState, [orgId]: [...prevState[orgId], project] }));
            setLoading(false);
            // If project is added let us expand that nodeId view of its organization
            setExpanded([...expanded, `org${orgId}`]);
            handleDialogClose();
            setSnackNotif("addProject");
        },
            (err) => {
                setLoading(false);
                setError("Unable to add project, " + err.error_message);
            }
        );
    };

    const removeOrganizationFromUser = () => {
        setLoading(true);
        setError(null);
        removeOrgFromUser(activeOrg.id, user.id, () => {

            // If last org deleted set global org to null
            // If globalOrg deleted but other orgs there then set userOrgs[0] as global Org
            // Else if it is not a global org that has been removed then we dont care

            if(activeOrg.id === globalOrganization.id){
                if(userOrgs && userOrgs.length > 1){
                    for(let userOrg of userOrgs){
                        if(userOrg.id !== activeOrg.id){
                            updateGlobalOrganization(userOrg)
                            break
                        }
                    }
                }else{
                    updateGlobalOrganization(null)
                }
            }

            setUserOrgs(userOrgs.filter(org => org.id !== activeOrg.id));
            setUserOrgsProjects(prevState => {
                // using spread operator
                let { [activeOrg.id]: _, ...newState } = prevState; // destructure the key and the rest of the state
                return newState; // return the new state
            });

            setLoading(false);
            handleDialogClose();
            setSnackNotif("removeOrganization");
        },
            (err) => {
                setLoading(false);
                setError("Unable to remove organization, " + err.error_message);
            });
    };

    const editOrganization = () => {

        let updatedOrg = JSON.parse(JSON.stringify(activeOrg));
        updatedOrg.name = name.trim();
        //other fields to update for organization
        console.log(userOrgsProjects)
        if (JSON.stringify(updatedOrg) === JSON.stringify(activeOrg)) {
            setError("No changes have been made!");
        }
        else {
            setLoading(true);
            setError(null);
            updateOrganization(
                updatedOrg,
                (currentOrg) => {
                    setOrgs((prevOrgs) =>
                        prevOrgs.map((org) => (org.id === currentOrg.id ? currentOrg : org)));
                    setUserOrgs((prevUserOrgs) =>
                        prevUserOrgs.map((org) => (org.id === currentOrg.id ? currentOrg : org)));

                    // if updated org is the globalOrg then apply the changes to the globalOrg
                    if (globalOrganization.id === currentOrg.id) {
                        updateGlobalOrganization(currentOrg)
                    }

                    setLoading(false);

                    handleDialogClose();
                    setSnackNotif("editOrganization");
                },
                (err) => {
                    setLoading(false);
                    if (err["error"] === "DUPLICATE_NAME")
                        setError("Organization already exists!");
                    else
                        setError(
                            "Unable to update organization. Please contact priceops!"
                        );
                }
            );
        }
    }

    const editProject = () => {
        let updatedProject = JSON.parse(JSON.stringify(activeProject));
        updatedProject.name = name.trim();
        //other fields to update for project
        if (JSON.stringify(updatedProject) === JSON.stringify(activeProject)) {
            setError("No changes have been made!");
        }
        else {
            setLoading(true);
            setError(null);
            updateProject(activeOrg.id,
                updatedProject,
                (currentProject) => {
                    setUserOrgsProjects((prevState) => ({
                        ...prevState,
                        [activeOrg.id]: prevState[activeOrg.id].map((project) =>
                            project.id === currentProject.id ? currentProject : project
                        ),
                    }));

                    // if updated Project is the globalProject then apply the changes to the globalProject
                    if (globalProject.id === currentProject.id) {
                        updateGlobalProject(currentProject)
                    }
                    setLoading(false);
                    handleDialogClose();
                    setSnackNotif("editProject");
                },
                (err) => {
                    setLoading(false);
                    setError("Unable to update project, " + err.error_message);
                }
            );
        }
    }

    const onTagsChange = (event, selectedOrg) => {
        // console.log(selectedOrg);
        setSelectedOrg(selectedOrg);
    }

    // const handleSelect = (org) => {
    //     updateOrganization(org);
    //
    //     navigate("/organization/" + org.id);
    // }

    // const styles = {
    //     org: {
    //         boxShadow: 2,
    //         borderRadius: 1,
    //         p: "12px",
    //         mb: 2,
    //         position: "relative"
    //     }
    // }


    // TREEVIEW TREEITEM LABEL'S COMPONENT STRUCTURE UI
    const CustomTreeItemLabel = ({ type, entity, parent, labelText }) => {

        const [showIcons, setShowIcons] = useState(false);

        function MoreActions() {
            const [anchorEl, setAnchorEl] = useState(null);
            const open = Boolean(anchorEl);

            const handleMenuClose = (event) => {
                event.stopPropagation();
                setShowIcons(false);
                setAnchorEl(null);
            }

            return (
                <>
                    <Tooltip title="More Actions">
                        <MoreHorizIcon
                            id="more-actions"
                            aria-controls={open ? 'more-actions-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                            }}
                            sx={{
                                fontSize: 20,
                                ml: 1,
                                cursor: 'pointer',
                                '&:hover': { bgcolor: 'white', borderRadius: 1 },
                            }}
                        />
                    </Tooltip>
                    <Menu
                        id="more-actions-menu"
                        aria-labelledby="more-actions"
                        anchorEl={anchorEl}
                        open={open}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onClose={(event) => {
                            handleMenuClose(event);
                        }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        sx={{
                            '& .MuiMenuItem-root': {
                                '&:hover': {
                                    borderLeft: '2px solid',
                                    borderLeftColor: 'primary.main',
                                    backgroundColor: 'primary.background'
                                },
                                borderLeft: '2px solid white',
                            },
                            '& .MuiTypography-body1': {
                                fontSize: 14, pr: 1
                            }
                        }}
                    >
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                handleMenuClose(event);
                                if (type === "org") {
                                    setActiveOrg(entity);
                                    handleEditOrganizationClick(entity);
                                } else if (type === "project") {
                                    setActiveOrg(parent);
                                    setActiveProject(entity);
                                    handleEditProjectClick(entity);
                                }
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>

                        </MenuItem>

                        {type === "org" && <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                handleMenuClose(event);
                                setActiveOrg(entity);
                                handleAddUserToOrganizationClick(entity);
                            }}
                        >
                            <ListItemIcon>
                                <PersonAddIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Add user</ListItemText>

                        </MenuItem>}

                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                handleMenuClose(event);
                                if (type === "org") {
                                    setActiveOrg(entity);
                                    handleViewOrganizationDetailsClick();
                                } else if (type === "project") {
                                    setActiveProject(entity);
                                    handleViewProjectDetailsClick();
                                }
                            }}
                        >
                            <ListItemIcon>
                                <InfoIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Details</ListItemText>
                        </MenuItem>

                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                handleMenuClose(event);
                                if (type === "org") {
                                    setActiveOrg(entity);
                                    handleRemoveOrganizationClick();
                                } else if (type === "project") {
                                    setActiveProject(entity);
                                    handleRemoveProjectClick();
                                }
                            }}
                        >
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </Menu>
                </>
            )
        }

        const styles = {
            projectItem: {
                cursor: "pointer"
            },
            organizationItem: {

            }
        }

        return (
            <Grid
                container
                alignItems="center"
                sx={{ pr: 1.5 }}
            >
                <Grid container item alignItems="center"
                    sx={type === "project" ? styles.projectItem : styles.organizationItem}
                    onMouseEnter={() => setShowIcons(true)}
                    onMouseLeave={() => setShowIcons(false)}
                    onClick={(event) => {
                        if (type === "project") {
                            event.stopPropagation();
                            setActiveOrg(parent);
                            setActiveProject(entity);
                            handleGoToScenariosClick(parent, entity);
                        }
                    }}>

                    <Tooltip title={type === "project" ? "Click To View Scenarios" : ""}>
                        <Grid container xs>
                            <Typography sx={{ fontSize: 14 }}>
                                {labelText}
                            </Typography>
                        </Grid>
                    </Tooltip>

                    {showIcons && (
                        <>
                            {type === "org" && (
                                <Tooltip title="Add Project">
                                    <AddIcon
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setActiveOrg(entity);
                                            handleAddProjectClick();
                                        }}
                                        sx={{
                                            fontSize: 20,
                                            cursor: 'pointer',
                                            '&:hover': { bgcolor: 'white', borderRadius: 1 },
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <MoreActions />
                        </>
                    )}
                </Grid>
            </Grid>
        );
    };

    function stringAvatar(name) {
        const words = name.split(' ');
        let initials;
        if (words.length >= 2) {
            initials = words[0][0] + words[words.length - 1][0];
        } else {
            initials = words[0][0];
        }
        return {
            sx: {
                bgcolor: "primary.main",
                fontSize: 14,
                width: 38,
                height: 38
            },
            children: initials,
        };
    }

    return (
        <Grid container item direction='column' sx={{ flex: 1, backgroundColor: "primary.background", justifyContent: "left", minHeight: '100vh' }}>

            {/*BASIC SIDEBAR STRUCTURE UI*/}
            <Grid container item spacing={2} sx={{ p: 2, borderBottom: '1px solid lightgray' }} >
                <Grid item>
                    <Avatar {...stringAvatar(user['name'])} />
                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: 16 }}>{user['name']}</Typography>
                    <Typography sx={{ fontSize: 10, color: 'primary.greyText' }}>{user['email']}</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2} sx={{ p: 2 }}>
                <Grid container item direction='row' alignItems='center'>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: "IBM Plex Sans" }}>Your Organizations</Typography>
                    <Grid item xs />
                    <Tooltip title="Add Organization">
                        <Fab size="small" color="primary" aria-label="add" onClick={handleAddOrganizationClick}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
                <Grid container item>
                    <TextField size="small" sx={{ width: '100%', '& .MuiOutlinedInput-input': { backgroundColor: 'white', borderRadius: 2 } }}
                        label="Find an organization..." onChange={handleSearchChange} />
                </Grid>
                <Grid container item>
                    {loading ?
                        <Grid container justifyContent="center" alignItems="center" sx={{ width: '100%', height: 100 }} >
                            <CircularProgress />
                        </Grid>
                        :
                        userOrgs &&
                        (userOrgs.length > 0 ?
                            <>
                                <TreeView
                                    aria-label="file system navigator"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    expanded={expanded}
                                    onNodeToggle={handleToggle}
                                    sx={{
                                        flexGrow: 1, overflowX: "hidden", overflowY: "auto"
                                        , '& .MuiTreeItem-content': {
                                            height: 33,
                                        },
                                    }}
                                >
                                    {filteredOrgs.map((org, idx) => (
                                        <TreeItem
                                            key={idx}

                                            nodeId={`org${org.id}`}
                                            label={<CustomTreeItemLabel type={'org'} entity={org} parent={null} labelText={org.name} />}
                                        >
                                            {userOrgsProjects[org.id] && userOrgsProjects[org.id].map((project, pi) => (
                                                <TreeItem
                                                    key={pi}
                                                    nodeId={`project${project.id}`}
                                                    label={<CustomTreeItemLabel type={'project'} entity={project} parent={org} labelText={project.name} />}
                                                />
                                            ))}
                                        </TreeItem>
                                    ))}
                                </TreeView>
                                {filteredOrgs.length === 0 &&
                                    <Alert severity="info" sx={{ mb: 2, fontSize: 14, width: '100%' }}>
                                        No such organization found!
                                    </Alert>}
                            </>
                            :
                            <Alert severity="info" sx={{ mb: 2, fontSize: 14, width: '100%' }}>
                                Add your first organization by clicking on the + icon above.
                            </Alert>)
                    }
                    {(error && isDialogOpen === "") && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}
                </Grid>
            </Grid>

            {/*DIALOGS*/}
            {/*ADD ORG DIALOG*/}
            <Dialog open={isDialogOpen === "addOrganization"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 280, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 280, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Add Organization</Typography>
                        <Typography sx={{ mb: 3, fontSize: 14 }}>You can add an organization by creating a new organization or requesting
                            access for an already existing one by selecting it.</Typography>
                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}


                        <TextField
                            variant="standard"
                            size="small"
                            label="Enter Organization"
                            type="text"
                            value={name}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <GroupsIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => { setName(e.target.value); setSelectedOrg(false);  }}
                        />

                        {/*<Autocomplete*/}
                        {/*    freeSolo*/}
                        {/*    isOptionEqualToValue={(option, value) => option.id === value.id}*/}
                        {/*    getOptionLabel={(option) => option.name}*/}
                        {/*    options={orgs}*/}
                        {/*    onChange={onTagsChange}*/}
                        {/*    renderInput={(params) => <TextField*/}
                        {/*        {...params}*/}
                        {/*        variant="standard"*/}
                        {/*        size="small"*/}
                        {/*        label="Enter Organization"*/}
                        {/*        type="text"*/}
                        {/*        value={name}*/}
                        {/*        InputProps={{*/}
                        {/*            ...params.InputProps,*/}
                        {/*            startAdornment: (*/}
                        {/*                <InputAdornment position="start">*/}
                        {/*                    <GroupsIcon />*/}
                        {/*                </InputAdornment>*/}
                        {/*            )*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => { setName(e.target.value); setSelectedOrg(false) }}*/}
                        {/*    />} />*/}
                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" sx={{ mt: 4 }} onClick={handleDialogClose}>Cancel</Button>
                            {!selectedOrg && <Button disabled={!validateForm()} variant="outlined" size="medium" onClick={createOrganization} sx={{ ml: 1, mt: 4 }}>Create Organization</Button>}
                            {/* {selectedOrg && <Button variant="outlined" size="medium" onClick={() => { }} sx={{ ml: 1, mt: 4 }}>Request Access</Button>} */}
                            {/* {selectedOrg && <Typography>Please request access for this org</Typography>} */}
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*ADD USER*/}
            <Dialog open={isDialogOpen === "addUser"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 280, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <GrantAccess
                        org={activeOrg}
                        setSnackNotif={setSnackNotif}
                        onCancel={handleDialogClose} />
                }
            </Dialog>

            {/*ADD PROJECT DIALOG*/}
            <Dialog open={isDialogOpen === "addProject"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 280, boxShadow: 2, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 280, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Add Project</Typography>
                        <Typography sx={{ mb: 3, fontSize: 14 }}>You can create a new project by entering the project name and clicking on the create project button below.</Typography>
                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}
                        <TextField
                            variant="standard"
                            size="small"
                            label="Enter Project"
                            type="text"
                            value={name}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SchemaIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => { setName(e.target.value); }}
                        />
                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" sx={{ mt: 4 }} onClick={handleDialogClose}>Cancel</Button>
                            <Button disabled={!validateForm()} variant="outlined" size="medium" onClick={() => createProject(activeOrg.id)} sx={{ ml: 1, mt: 4 }}>Create Project</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*EDIT ORG DIALOG*/}
            <Dialog open={isDialogOpen === "editOrganization"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Edit Organization</Typography>
                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}
                        <TextField
                            variant="standard"
                            margin="dense"
                            label="Name"
                            id="user-name"
                            aria-describedby="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" sx={{ mt: 4 }} onClick={handleDialogClose}>Cancel</Button>
                            <Button disabled={!validateForm()} variant="outlined" size="medium" onClick={editOrganization} sx={{ ml: 1, mt: 4 }}>Save</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*EDIT PROJECT DIALOG*/}
            <Dialog open={isDialogOpen === "editProject"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Edit Project</Typography>
                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                        <TextField
                            variant="standard"
                            margin="dense"
                            label="Name"
                            id="user-name"
                            aria-describedby="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" sx={{ mt: 4 }} onClick={handleDialogClose}>Cancel</Button>
                            <Button disabled={!validateForm()} variant="outlined" size="medium" onClick={editProject} sx={{ ml: 1, mt: 4 }}>Save</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*ORGANIZATION DETAILS DIALOG*/}
            <Dialog open={isDialogOpen === "viewOrganizationDetails"} onClose={handleDialogClose}>
                <Card sx={{ width: 380 }}>
                    <CardHeader title="Organization Details"
                        titleTypographyProps={{ align: 'center', fontSize: 20, fontWeight: 'bold' }}
                        sx={{ color: "primary.main" }} />
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ pl: 3, pr: 10 }}>Name</TableCell>
                                <TableCell>{activeOrg.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ pl: 3, pr: 10 }}>Created by</TableCell>
                                <TableCell>{activeOrg.created_by_user}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ pl: 3, pr: 10 }}>Created on</TableCell>
                                <TableCell>{new Date(activeOrg.created_date).toLocaleString('en-US',
                                    {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    })}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Dialog>

            {/*PROJECT DETAILS DIALOG*/}
            <Dialog open={isDialogOpen === "viewProjectDetails"} onClose={handleDialogClose}>
                <Card sx={{ width: 380 }}>
                    <CardHeader title="Project Details"
                        titleTypographyProps={{ align: 'center', fontSize: 20, fontWeight: 'bold' }}
                        sx={{ color: "primary.main" }} />
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ pl: 3, pr: 10 }}>Name</TableCell>
                                <TableCell>{activeProject.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ pl: 3, pr: 10 }}>Created by</TableCell>
                                <TableCell>{activeProject.created_by_user}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ pl: 3, pr: 10 }}>Created on</TableCell>
                                <TableCell>{new Date(activeProject.created_date).toLocaleString('en-US',
                                    {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    })}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Dialog>

            {/*REMOVE ORG DIALOG*/}
            <Dialog open={isDialogOpen === "removeOrganization"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Remove Organization From User</Typography>

                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Are you sure you want to remove this organization from your organization list?
                        </Alert>

                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>
                            <Button variant="contained" color="error" size="medium" onClick={removeOrganizationFromUser} sx={{ ml: 1 }}>Remove</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*SNACKBARS NOTIFICATIONS FOR CRUD DIALOGS*/}
            {/*ADD ORGANIZATION NOTIFICATION*/}
            <Snackbar open={snackNotif === "addOrganization"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Organization added successfully!
                </Alert>
            </Snackbar>

            {/*ADD PROJECT NOTIFICATION*/}
            <Snackbar open={snackNotif === "addProject"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Project added successfully!
                </Alert>
            </Snackbar>

            {/*REMOVE ORG NOTIFICATION*/}
            <Snackbar open={snackNotif === "removeOrganization"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Organization removed successfully!
                </Alert>
            </Snackbar>

            {/*EDIT ORG NOTIFICATION*/}
            <Snackbar open={snackNotif === "editOrganization"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Organization updated successfully!
                </Alert>
            </Snackbar>

            {/*EDIT PROJECT NOTIFICATION*/}
            <Snackbar open={snackNotif === "editProject"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Project updated successfully!
                </Alert>
            </Snackbar>

            {/*EDIT PROJECT NOTIFICATION*/}
            <Snackbar open={snackNotif === "grantAccess"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Granted access to user successfully!
                </Alert>
            </Snackbar>
        </Grid>
    );
}

import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, LinearProgress, Menu, Tooltip } from "@mui/material";
import React from "react";
import CurrencyField from "components/CurrencyField";
import PriceModelsDataContext from "pages/scenarios/PriceModelsDataContext";
import ScenarioDataContext from "pages/scenarios/ScenarioDataContext";
import ScenarioUIStateContext from "pages/scenarios/ScenarioUIStateContext";
import { getReadablePriceMetric } from 'pages/scenarios/price-modelling/components/PriceModelDefinitions';
import { textNum } from 'utils/Utils';

export default function FinalizedPriceInput(props) {
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const { priceModels } = React.useContext(PriceModelsDataContext);
    const { scenarioUIState } = React.useContext(ScenarioUIStateContext);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const segmentId = props.segmentId;
    const currentPriceModelId = scenarioUIState.currentPriceModelId;
    const currMetrics = priceModels.find(x => String(x.id) === String(currentPriceModelId))?.price_metrics ?? [];

    const getFinalizedPricesFromScenario = () => {
        const segment = scenario["params"]["segment_params"]
            .find(x => String(x["id"]) === String(segmentId))
        const inputs = segment["strategy_inputs"];
        return currMetrics.map(metric =>
            inputs["finalized_prices"]
                ?.find(x => String(x.price_model_id) === String(currentPriceModelId) && String(x.price_metric_id) === String(metric.id))
                ?.price?.value ?? 0.0)
    }

    const [finalizedPrices, setFinalizedPrices] = React.useState(getFinalizedPricesFromScenario());

    const applyFinalizedPrice = () => {
        const sc = { ...scenario };
        const inputs = sc["params"]["segment_params"].find(x => String(x["id"]) === String(segmentId))["strategy_inputs"];
        const updatedMetricPrices = currMetrics.map((metric, idx) => {
            return {
                price_model_id: currentPriceModelId,
                price_metric_id: metric.id,
                price: { value: textNum(finalizedPrices[idx]) }
            }
        });
        const updatedFinalizedPrices = inputs["finalized_prices"]?.filter(f => String(f.price_model_id) !== String(currentPriceModelId)) ?? [];
        updatedFinalizedPrices.push(...updatedMetricPrices);
        inputs["finalized_prices"] = updatedFinalizedPrices;

        sc["fragment"] = "FINALIZED_PRICE";
        updateScenario(sc, true, () => {
            if (props.onChange) {
                props.onChange();
            }
        });
    }

    const onCancel = () => {
        setFinalizedPrices(getFinalizedPricesFromScenario());
        setAnchorEl(null);
    }

    const setFinalizedPrice = (value, idx) => {
        const fp = { ...finalizedPrices };
        fp[idx] = value;
        setFinalizedPrices(fp);
    }

    const handleEditClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    return (<Grid sx={{ textAlign: "right" }}>
        <Tooltip title="Finalize price">
            <EditIcon sx={{ cursor: "pointer" }} onClick={handleEditClick}></EditIcon>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCancel}>
            <Grid sx={{ width: 200, p: 2 }}>
                {currMetrics.map((metric, idx) => {
                    return <Box sx={{ mb: 2, width: '100%' }}>
                        <CurrencyField key={idx}
                            size="small"
                            value={finalizedPrices[idx]}
                            label={"Finalized price for " + getReadablePriceMetric(metric)}
                            onChange={(e) => setFinalizedPrice(e.target.value, idx)} /></Box>
                }
                )}
                {scenarioUIState.loading && <LinearProgress sx={{ mt: 2 }} />}
                <Button onClick={onCancel} disabled={scenarioUIState.loading}>Cancel</Button>
                <Button onClick={applyFinalizedPrice} disabled={scenarioUIState.loading}>Apply</Button>
            </Grid>
        </Menu>
    </Grid>
    )
}
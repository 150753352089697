import { Grid } from "@mui/material";
import React from "react";
import PricePoint from "../components/PricePoint";

export default function SegmentStrategyElement(props) {
    const [segmentPrice] = React.useState(props.segmentPrice);
    const StrategyInputElement = props.inputElement;

    return (<React.Fragment>
        <Grid container sx={{ maxWidth: 270, height: "auto", p: 1, borderRadius: 1, pb: 2, textAlign: "center" }} direction="column">
            <Grid item sx={{ mt: 2 }}>
                {StrategyInputElement}
            </Grid>
            <Grid item sx={{ mt: 1 }}>
                <PricePoint width={270} pricePoint={segmentPrice}></PricePoint>
            </Grid>
        </Grid>
    </React.Fragment>)
}
import { Box, Button, Checkbox, FormControlLabel, Grid, InputLabel, Typography } from "@mui/material";
import React from "react";
import CustField from "../../components/CustField";
import { copy, formattedLabel, getRevRangeLabel } from "../../utils/Utils";


export default function DefineMarketSegment(props) {
    const filterTypes = Object.keys(props.allOptions);
    const allOptions = props.allOptions;

    const [segmentFilters, setSegmentFilters] = React.useState(props.segmentFilters);
    const [customSegmentName, setCustomSegmentName] = React.useState("");

    const getTitleBySegment = (segmentType) => {
        return formattedLabel(segmentType);
    }
    const selectAllByType = (segmentType) => {
        const filters = copy(segmentFilters);
        filters[segmentType] = allOptions[segmentType];
        setSegmentFilters(filters);
        props.handleSegmentFilterChange(filters, segmentType);
    }
    const unselectAllByType = (segmentType) => {
        const filters = copy(segmentFilters);
        filters[segmentType] = [];
        setSegmentFilters(filters);
        props.handleSegmentFilterChange(filters, segmentType);
    }
    const handleSelection = (e, segmentType, opt) => {
        const filters = copy(segmentFilters);
        if (e.target.checked) {
            filters[segmentType].push(opt);
        } else {
            filters[segmentType] = filters[segmentType].filter(x => x !== opt);
        }
        setSegmentFilters(filters);
        props.handleSegmentFilterChange(filters, segmentType);
    }
    const createSegment = () => {
        var segment = {
            'segment_name': customSegmentName,
            'segment_params': segmentFilters
        }

        props.createSegment(segment);
    }

    return (
        <React.Fragment>
            <Grid container item spacing={2} xs={12} sx={{ border: 1, borderRadius: 1, p: 2, mb: 2, mt: 1, borderColor: "primary.groupBorder" }} direction="row">
                {filterTypes.map((segmentType, idx) => {
                    return (<Grid container item key={idx} xs={3} direction="column" sx={{ border: 1, borderRadius: 1, borderColor: "primary.groupBorder", p: 2 }}>
                        <Box>
                            <InputLabel sx={{ m: 0, p: 0, fontSize: 13 }}>{getTitleBySegment(segmentType)}</InputLabel>
                            <Button sx={{ m: 0, ml: 2, height: 20, fontSize: 12, p: 0, textTransform: 'None', float: 'right' }} onClick={() => unselectAllByType(segmentType)}>
                                Remove all
                            </Button>
                            <Button sx={{ height: 20, fontSize: 12, p: 0, m: 0, textTransform: 'None', float: 'right' }} onClick={() => selectAllByType(segmentType)}>
                                Select all
                            </Button>
                        </Box>
                        {segmentFilters && <Grid container directon="row">
                            {allOptions[segmentType].map((opt, idx) => {
                                return <Grid key={idx} item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox sx={{ p: 0, m: 0, transform: 'scale(0.7)' }}
                                                checked={segmentFilters[segmentType].includes(opt)}
                                                onChange={(e) => handleSelection(e, segmentType, opt)}
                                            />}
                                        label={<Typography sx={{ fontSize: 12 }}>
                                            {segmentType !== 'revenue' ? opt : getRevRangeLabel(opt)}
                                        </Typography>} />
                                </Grid>
                            })}
                        </Grid>}
                    </Grid>)
                })}
                <Grid container justifyContent='center' sx={{ mt: 2 }} direction="row">
                    <CustField
                        variant="standard"
                        sx={{ maxWidth: 250, mt: 0, mr: 3 }}
                        label="Custom segment name"
                        value={customSegmentName}
                        onChange={(e) => { setCustomSegmentName(e.target.value); }}
                    ></CustField>
                    <Button variant="outlined" sx={{ height: 35 }} onClick={createSegment}>Save</Button>
                    <Button variant="outlined" sx={{ ml: 2, height: 35 }} onClick={props.cancelSegment}>Cancel</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
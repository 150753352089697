import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Select, Switch, TextField, Tooltip, Typography, InputAdornment, Slider } from "@mui/material";
import React, { useContext } from "react";
import { importData } from '../../apis/ClientDataService';
import CurrencyField from '../../components/CurrencyField';
import { useErrorContext } from '../../state/ErrorDialogContext';
import { getUser } from '../../state/state';
import { adjustArrayValuesToSumTo100, copy, textNum } from "../../utils/Utils";
import Expenses, { DEFAULT_EXPENSES, ExpenseField, getExpenseLabel } from './price-modelling/components/Expenses';
import ScenarioDataContext from "./ScenarioDataContext";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ScenarioUIStateContext from './ScenarioUIStateContext';
import { ensureEndsWithS } from '../../utils/Utils';
import { textTransform } from '@mui/system';

export default function ScenarioInputGrid(props) {
    const { scenario, updateScenario } = useContext(ScenarioDataContext);
    const { scenarioUIState, updateScenarioUIState } = useContext(ScenarioUIStateContext);
    const { loadErrorDialog } = useErrorContext();

    const [scenarioState, setScenarioState] = React.useState(copy(scenario));
    const [importDataLoader, setImportDataLoader] = React.useState(false);
    const [fyYear, setFyYear] = React.useState(scenarioState["fy_year"] ?? "FY - 2023");

    const params = scenarioState["params"];
    const [expenseDataLevel, setExpenseDataLevel] = React.useState(params?.["expense_data_level"] ?? "OVERALL");

    const scenarioPriceModels = props.priceModels.filter(model => scenario["price_model_ids"].indexOf(model.id) != -1);

    const segmentParams = scenarioState["params"]["segment_params"];

    const findPriceUnits = () => {
        const priceUnits = {};
        scenarioPriceModels.forEach(pm => {
            const priceModelType = pm["type"];

            if (priceModelType !== "FLAT_PRICING" && pm["price_metrics"]) {
                pm["price_metrics"].forEach(pm => {
                    if (pm["price_units"]) {
                        pm["price_units"].forEach(pu => {
                            const unit = pu["unit"];

                            if (unit.toLowerCase() !== "month") {
                                priceUnits[unit] = pu;
                            }
                        });
                    }
                });
            }
        });

        const units = Object.values(priceUnits);
        return units;
    }

    const styles = {
        textField: {
            mt: 0,
            fontSize: 12,
            height: 20,
            padding: "3px",
            paddingLeft: "5px",
            '& input::placeholder': {
                fontSize: 12,
                padding: 0
            }
        },
        label: {
            fontSize: 12,
            mr: "15px",
            textAlign: "right",
            textTransform: "capitalize"
        },
        heading: {
            fontSize: 12,
            color: "primary.main"
        },
        datepicker: {
            mr: 1,
            mt: 1,
            '& label': {
                fontSize: "12px",
                padding: 0,
                marginTop: "-10px"
            },
            '& input': {
                fontSize: 12,
                height: "30px",
                p: 0,
                pl: 1,
                '&::placeholder': {
                    fontSize: 12
                }
            },
            '& input::placeholder': {
                fontSize: 12,
                padding: 0
            },
            '& .MuiInputLabel-shrink': {
                marginTop: "0px"
            }
        },
        inputGroup: {
            borderBottom: 1, borderColor: "primary.groupBorder", pb: 1,
            mt: 1
        }
    }

    const inputProps = {
        style: styles.textField
    }

    const getOverallExpenses = () => {
        return scenarioState["params"]["overall_expenses"] && scenarioState["params"]["overall_expenses"].length > 0 ?
            scenarioState["params"]["overall_expenses"] : DEFAULT_EXPENSES;
    }

    const handleOverallExpensesUpdate = (expenses) => {
        const sc = { ...scenarioState };
        sc["params"]["overall_expenses"] = expenses;
        setScenarioState(sc);
    }

    const findSegment = (scenario, segmentId) => {
        return scenario["params"]["segment_params"].find(x => x.id === segmentId);
    }

    const handleScenarioNameChange = (value) => {
        const sc = { ...scenarioState };
        sc["scenario_name"] = value;
        setScenarioState(sc);
    }

    const handleSegmentNameUpdate = (segmentId, value) => {
        const sc = { ...scenarioState };
        findSegment(sc, segmentId)["segment_name"] = value;
        setScenarioState(sc);
    }

    const handleElasticityUpdate = (segmentId, value) => {
        const sc = { ...scenarioState };
        findSegment(sc, segmentId)["price_elasticity"] = value;
        setScenarioState(sc);
    }

    const handleNumCustomersUpdate = (segmentId, value) => {
        const sc = { ...scenarioState };
        findSegment(sc, segmentId)["num_customers"] = value;
        setScenarioState(sc);
    }

    const handleBenchmarkPriceUpdate = (segmentId, value) => {
        const sc = { ...scenarioState };
        findSegment(sc, segmentId)["strategy_inputs"]["benchmark_price"] = { "value": value };
        setScenarioState(sc);
    }

    const handlePriceUnitValueUpdate = (segmentId, priceUnit, value) => {
        const sc = { ...scenarioState };
        const segment = findSegment(sc, segmentId);
        if (!segment.price_units) segment.price_units = [];
        const unit = segment.price_units?.find(x => x.unit === priceUnit);
        unit ? (unit.value = value) : segment.price_units?.push({ unit: priceUnit, value });
        setScenarioState(sc);
    };

    const handleExpenseLevel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const expenseDataLevel = e.target.checked ? "SEGMENT" : "OVERALL"
        setExpenseDataLevel(expenseDataLevel);
        const sc = { ...scenarioState };
        sc["params"]["expense_data_level"] = expenseDataLevel;
        setScenarioState(sc);
    }

    const handleGeneratePriceModel = () => {
        updateScenario(scenarioState);
        props.runWorkbench(scenarioState);
    }

    const getPriceUnitValue = (segment, priceUnit) => {
        const unitInput = segment.price_units?.find(x => x.unit === priceUnit);
        return unitInput?.value;
    };

    const addSegment = () => {
        const sc = { ...scenarioState };
        const segments = sc["params"]?.["segment_params"] ?? [];
        const maxId = segments.length;
        segments.push({
            "id": maxId + 1,
            "segment_name": "Segment " + (maxId + 1),
            "price_elasticity": 1.0,
            "num_customers": 10,
            "expense_split_ratio": segments?.filter(x => !x["deleted"])?.length === 0 ? 100 : 0,
            "strategy_inputs": {
                "benchmark_price": { "value": 100.0 },
                "value_modelling_value": 0.0,
                "value_modelling_percentage": 20.0
            }
        });
        sc["params"]["segment_params"] = segments;
        setScenarioState(sc);
    }

    const removeSegment = (segment) => {
        const sc = { ...scenarioState };
        const seg = sc["params"]["segment_params"].find(x => x.id === segment.id);
        seg["deleted"] = true;

        handleSplitRatioUpdateInternal(0, seg);

        setScenarioState(sc);
    }

    const getCommonExpenses = () => {
        const allExpenses = {}
        scenarioState?.["params"]?.["segment_params"]?.forEach(segment => {
            if (segment["expenses"]) {
                segment["expenses"].forEach(e => {
                    allExpenses[e["name"]] = e;
                });
            }
        });
        return Object.keys(allExpenses).length > 0 ? Object.values(allExpenses) : DEFAULT_EXPENSES;
    }

    const getExpenseAtSegmentLevel = (segment, expense) => {
        const expenses = findSegment(scenarioState, segment.id)["expenses"];
        const defaultValue = { ...expense };
        defaultValue["value"] = 0.0;

        return expenses ? expenses.find(x => x["name"] === expense["name"]) || defaultValue : defaultValue;
    }

    const setExpenseValue = (seg, expense, expenseValue) => {
        const sc = { ...scenarioState };
        const segment = findSegment(sc, seg.id);
        const e = copy(expense);
        e["value"] = textNum(expenseValue);

        if (!segment["expenses"] || segment["expenses"].length === 0) {
            segment["expenses"] = getCommonExpenses();
            segment["expenses"].forEach(e => e["value"] = 0.0);
        }
        const existing = segment["expenses"].find(x => x["name"] === expense["name"]);
        if (!existing) {
            segment["expenses"].push(e);
        } else {
            existing["value"] = e["value"];
        }
        setScenarioState(sc);
    }

    const handleDebugCheck = (e) => {
        const sc = { ...scenarioState }
        sc["run_tracking_enabled"] = e.target.checked;
        setScenarioState(sc);
    }

    const getYears = () => {
        return ["FY - 2023", "FY - 2022", "FY - 2021", "FY - 2020"];
    }

    const handleImportClick = () => {
        setImportDataLoader(true);
        importData(scenario.id, fyYear, (scenario) => {
            setImportDataLoader(false);

            setScenarioState(scenario);
            setExpenseDataLevel(scenario["params"]["expense_data_level"]);
        }, err => {
            setImportDataLoader(false);
            loadErrorDialog({ title: "Import failure", message: "Unable to import data at the moment" });
        });
    }

    const handleSplitRatioUpdate = (e, seg) => {
        handleSplitRatioUpdateInternal(e.target.value, seg);
    }

    const handleSplitRatioUpdateInternal = (value, seg) => {
        const sc = { ...scenarioState };
        const currSegSplitPercent = value;
        const params = sc["params"]["segment_params"].filter(x => !x["deleted"]);

        const arr = params.map(x => x.expense_split_ratio);
        const index = params.map(x => x.id).indexOf(seg.id);

        const adjusted = adjustArrayValuesToSumTo100(arr, index, currSegSplitPercent);
        params.map((x, idx) => x.expense_split_ratio = adjusted[idx]);

        setScenarioState(sc);
    }

    const handleUseEqualSplit = () => {
        const sc = { ...scenarioState };
        const params = sc["params"]["segment_params"].filter(x => !x["deleted"]);
        const len = params.length;

        params.forEach(x => x.expense_split_ratio = Math.round(100 / len));

        const arr = params.map(x => x.expense_split_ratio);
        const adjusted = adjustArrayValuesToSumTo100(arr, 0, Math.round(100 / len));
        params.map((x, idx) => x.expense_split_ratio = adjusted[idx]);
        setScenarioState(sc);
    }

    return (
        <Grid container item>
            <Grid container overflow="auto"
                sx={{ p: 2, border: 1, borderRadius: 2, borderColor: "primary.groupBorder" }}>
                <Grid container item sx={styles.inputGroup} direction="column">
                    <Grid>
                        <table>
                            <tbody>
                                <tr>
                                    <td><Typography sx={styles.label}>Scenario name</Typography></td>
                                    <td><TextField
                                        value={scenarioState.scenario_name}
                                        error={!scenarioState.scenario_name}
                                        size="small"
                                        inputProps={inputProps} sx={{ width: "200px" }}
                                        onChange={(e) => { handleScenarioNameChange(e.target.value) }} /></td>
                                </tr>
                                <tr>
                                    <td><Typography sx={styles.label}>Period</Typography></td>
                                    <td>
                                        <Select size="small" value={fyYear} sx={{ fontSize: 13 }} inputProps={{ style: { height: 20 } }}
                                            onChange={(e) => setFyYear(e.target.value)}>
                                            {
                                                getYears().map((x, idx) => {
                                                    return <MenuItem sx={{ fontSize: 13 }} key={idx} value={x}>{x}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                             <DatePicker label="Start date" sx={styles.datepicker} />
                                         </LocalizationProvider>
                                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                                             <DatePicker label="End date" sx={styles.datepicker} />
                                         </LocalizationProvider> */}
                                    </td>
                                    <td>
                                        <Button sx={{ textTransform: "none", fontSize: 12, maxWidth: 100, mt: 1 }}
                                            endIcon={<GetAppIcon />}
                                            onClick={handleImportClick}>
                                            Import
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <FormControlLabel sx={{ width: 300 }}
                        control={<Switch key={expenseDataLevel} checked={expenseDataLevel === "SEGMENT"} onChange={handleExpenseLevel} />}
                        label={<Typography sx={{ fontSize: 12, color: "primary.text" }}>Have expense data at each segment?</Typography>} />
                </Grid>
                {expenseDataLevel === "OVERALL" && <Grid container item sx={styles.inputGroup}>
                    <React.Fragment>
                        <Expenses expenses={getOverallExpenses()} updateExpenses={(expenses) => { handleOverallExpensesUpdate(expenses) }}></Expenses>

                        <Grid sx={{ mt: 2 }} container>
                            <table>
                                <tr>
                                    <td>

                                    </td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, idx) => {
                                        return <td><Typography sx={{ fontSize: 11 }}>{segment.segment_name}</Typography></td>
                                    })}
                                </tr>
                                <tr>
                                    <td>
                                        <Box sx={{ width: 140 }}>
                                            <Tooltip title="Percentage split of expenses for each segment">
                                                <Typography sx={[styles.label]}>Expense split percentage</Typography>
                                            </Tooltip>
                                        </Box>
                                    </td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, idx) => {
                                        return <td key={idx}>
                                            <Box width={145} display="flex" justifyContent="center">
                                                <Slider size="small"
                                                    value={segment["expense_split_ratio"]}
                                                    onChange={(e) => { handleSplitRatioUpdate(e, segment) }}
                                                    min={0}
                                                    sx={{ display: "inline-block", width: 110 }}
                                                    valueLabelDisplay="auto"
                                                    max={100}></Slider>
                                                <Typography fontSize={11}
                                                    sx={{ pt: "4px", ml: "6px" }}
                                                    display="inline">{segment["expense_split_ratio"]}%</Typography>
                                                {/* <TextField
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <Typography sx={{ fontSize: 14 }}>%</Typography></InputAdornment>
                                                        }}
                                                        inputProps={inputProps}
                                                        type="number"
                                                        value={segment["expense_split_ratio"] ?? 0}
                                                        onChange={(e) => { handleSplitRatioUpdate(e, segment) }}>
                                                    </TextField> */}
                                            </Box>
                                        </td>
                                    })}
                                </tr>
                            </table>
                        </Grid>
                        <Grid container display="flex" justifyContent="center">
                            <Button sx={{ fontSize: 12, ml: 3, mt: 1, p: 0, pl: 1, pr: 1 }}
                                disabled={segmentParams?.filter(x => !x["deleted"])?.length === 0}
                                onClick={handleUseEqualSplit}>Use equal split</Button>
                        </Grid>
                    </React.Fragment>
                </Grid>}
                <Grid container item sx={[styles.inputGroup, { border: "none" }]}>
                    <Grid container item xs={12}>
                        <Typography sx={styles.heading}>Segment data</Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                        <Button sx={{ textTransform: "none" }} onClick={addSegment} startIcon={<AddIcon />}>Add a new segment</Button>
                    </Grid>
                    <Grid>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography sx={styles.label}>Segment Name</Typography>
                                    </td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, index) => {
                                        return <td key={index}>
                                            <TextField
                                                value={segment.segment_name}
                                                error={!segment?.segment_name}
                                                size="small"
                                                inputProps={inputProps}
                                                onChange={(e) => { handleSegmentNameUpdate(segment.id, e.target.value) }} />
                                        </td>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography sx={styles.label}>Elasticity</Typography>
                                    </td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, index) => {
                                        return <td key={index}>
                                            <TextField
                                                value={segment.price_elasticity}
                                                error={!segment.price_elasticity}
                                                size="small"
                                                type="number"
                                                inputProps={{ ...inputProps, step: 0.1, min: 0 }}
                                                onChange={(e) => { handleElasticityUpdate(segment.id, e.target.value) }} />
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    <td>
                                        <Typography sx={styles.label}>Number of customers</Typography>
                                    </td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, index) => {
                                        return <td key={index}>
                                            <TextField
                                                value={segment.num_customers}
                                                error={!segment?.num_customers}
                                                type="number"
                                                size="small"
                                                inputProps={{ ...inputProps, step: 1, min: 1 }}
                                                onChange={(e) => { handleNumCustomersUpdate(segment.id, e.target.value) }} />
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    <td>
                                        <Typography sx={styles.label}>Benchmark price</Typography>
                                    </td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, index) => {
                                        return <td key={index}>
                                            <Box width={145}>
                                                <CurrencyField value={segment?.["strategy_inputs"]?.benchmark_price?.value} size="small" inputProps={inputProps}
                                                    onChange={(e) => { handleBenchmarkPriceUpdate(segment.id, textNum(e.target.value)) }} />
                                            </Box>
                                        </td>
                                    })}
                                </tr>

                                {expenseDataLevel === "SEGMENT" && getCommonExpenses().map((expense, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <Typography sx={[styles.label]}>{getExpenseLabel(expense)}</Typography>
                                        </td>
                                        {segmentParams?.filter(x => !x["deleted"])?.map((segment, idx) => {
                                            return <td key={idx}>
                                                <Box width={145}>
                                                    <ExpenseField expense={getExpenseAtSegmentLevel(segment, expense)}
                                                        onChange={(e, value) => setExpenseValue(segment, e, value)}>
                                                    </ExpenseField>
                                                </Box>
                                            </td>
                                        })}
                                    </tr>
                                })}


                                {findPriceUnits().map((pu, index) => {
                                    console.log(pu);
                                    return <tr key={index}>
                                        <td>
                                            <Typography sx={styles.label}>{pu["plural"] ?? ensureEndsWithS(pu["name"])}</Typography>
                                        </td>
                                        {segmentParams?.filter(x => !x["deleted"])?.map((segment, index) => {
                                            const value = getPriceUnitValue(segment, pu["unit"]);
                                            return <td key={index}>
                                                <TextField value={value} size="small"
                                                    type="number"
                                                    error={!value}
                                                    inputProps={{ ...inputProps, step: 1, min: 0 }}
                                                    onChange={(e) => { handlePriceUnitValueUpdate(segment.id, pu.unit, e.target.value) }} />
                                            </td>
                                        })}
                                    </tr>
                                })}

                                <tr>
                                    <td></td>
                                    {segmentParams?.filter(x => !x["deleted"])?.map((segment, index) => {
                                        return <td key={index} style={{ textAlign: "right" }}>
                                            <Button
                                                sx={{ textTransform: "none", fontSize: 12, pt: 0, pb: 0 }}
                                                onClick={(e) => { removeSegment(segment) }}>Delete segment</Button>
                                        </td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </Grid >
            {
                getUser()["email"].includes("sarat.deevi") &&
                <Grid>
                    <FormControlLabel control={<Checkbox checked={scenarioState["run_tracking_enabled"]} onChange={handleDebugCheck} />} label="Debug" />
                </Grid>
            }
            < Grid container justifyContent="flex-end" gap={1} >
                <Button variant="outlined" onClick={handleGeneratePriceModel} endIcon={<PlayArrowIcon />} disabled={scenarioUIState.loading}>Generate price models</Button>
                <Button variant="outlined" onClick={props?.onClose}>Cancel</Button>
            </Grid >
            {
                importDataLoader && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={importDataLoader}
                    onClick={() => setImportDataLoader(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Grid >
    )
}
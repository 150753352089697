import { Box, InputAdornment, InputLabel, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import CustField from "components/CustField";
import React from "react";
import { currencyFormatter, getCurrency, isValidNumber, currencyFractionFormatterMini } from "../utils/Utils";

export default function CurrencyField(props) {
  const [val, setVal] = React.useState(
    String(props.value ?? 0)
  );
  const [text, setText] = React.useState(currencyFormatter(String(props.value)));

  const handleChange = (e) => {
    if (!isValidNumber(e.target.value)) return;
    var fraction = currencyFractionFormatterMini(e.target.value);
    setText(fraction[0] + (fraction[1] == 1 ? "" : "/" + fraction[1]));
    setVal(e.target.value);
    return props.onChange(e);
  };
  const currency = getCurrency();

  return (
    <Tooltip title={text} placement="top">
      <Box>
        {props.showLabel &&
          <InputLabel
            sx={{ fontSize: 11, color: grey[500], float: "right", pr: 1 }}
          >
            {text}
          </InputLabel>}
        <CustField
          {...props}
          // sx={[props.sx, { marginTop: 2 }]}
          required
          value={val}
          onChange={(e) => {
            handleChange(e);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{currency.symbol}</InputAdornment>
            ),
          }}
        />
      </Box>
    </Tooltip>
  );
}

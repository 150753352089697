import PercentIcon from '@mui/icons-material/Percent';
import { InputAdornment, TextField, Grid } from "@mui/material";
import React from "react";
import { copy, currencyFormatter, textNum } from "utils/Utils";
import EditableInputsForm from "./EditableInputsForm";
import InputLabelGroup from "./InputLabelGroup";
import ScenarioDataContext from 'pages/scenarios/ScenarioDataContext';
import CurrencyField from 'components/CurrencyField';

export default function ValueModellingStrategyInput(props) {
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const segmentId = props.segmentId;

    const getValueModelParams = () => {
        const segment = scenario["params"]["segment_params"]
            .find(x => String(x["id"]) === String(segmentId))
        const inputs = segment["strategy_inputs"];
        return {
            "valueRealized": inputs["value_modelling_value"] ?? 0,
            "percentage": inputs["value_modelling_percentage"] ?? 0
        }
    }
    const values = getValueModelParams()

    const [valueRealized, setValueRealized] = React.useState(values["valueRealized"]);
    const [percent, setPercent] = React.useState(values["percentage"]);

    const applyValueInput = () => {
        const sc = copy(scenario);
        const segment = sc["params"]["segment_params"].find(x => String(x["id"]) === String(segmentId));
        segment["strategy_inputs"]["value_modelling_value"] = textNum(valueRealized);
        segment["strategy_inputs"]["value_modelling_percentage"] = textNum(percent);
        sc["fragment"] = "VALUE_MODELLING";
        updateScenario(sc, true, () => {
            if (props.onChange) {
                props.onChange();
            }
        }, () => { });
    }

    const refresh = () => {
        const values = getValueModelParams();
        setValueRealized(values["valueRealized"]);
        setPercent(values["percentage"]);
    }

    return (
        <EditableInputsForm
            miniView={
                <InputLabelGroup labels={
                    [
                        { name: "Value realized", value: currencyFormatter(valueRealized) },
                        { name: "Percent", value: percent + "%" }
                    ]
                } />
            } editView={
                <React.Fragment>
                    <Grid sx={{ width: 200 }}>
                        <CurrencyField
                            size="small"
                            value={valueRealized}
                            label="Value realized"
                            onChange={(e) => setValueRealized(e.target.value)} />
                        <TextField
                            size="small"
                            sx={{ width: 200, mt: 2 }}
                            value={percent}
                            InputProps={{
                                endAdornment: <InputAdornment position="start"><PercentIcon sx={{ width: 16 }} /></InputAdornment>
                            }}
                            label="Percentage"
                            onChange={(e) => setPercent(e.target.value)}
                            type="number"
                            inputProps={{ min: 0, max: 100, step: 0.1 }} />
                    </Grid>
                </ React.Fragment>
            }
            onSubmit={applyValueInput}
            onCancel={refresh} />
    )
}
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import SideBar from './OrgHierarchyPage';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useTheme } from '@mui/material/styles';
import Link from "@mui/material/Link";

const workSpaceText = {}

const steps = [
    {
        label: "Organization",
        description: "Organization is essentially defined as your company." +
            " An organization will contain the different Projects → Scenarios → Workspaces. " +
            "If you are a member of an organization, your Org entity will be the Org you will be working in."
    },
    {
        label: "Project",
        description: "Projects are independent of each other." +
            " The point of the project it defines a space for teams to collaborate on the larger scope. " +
            "A project will contain Scenarios → Workspaces."
    },
    {
        label: "Scenario",
        description: "A project can contain multiple Scenarios. Since our tool is a central repository for " +
            "all Pricing processes, scenarios will help plan different scenarios (inputs) to see" +
            " what is the best scenario for maximizing value capture."
    },
    {
        label: "Workspace",
        description: "PriceOps provides a standard list of workspaces." +
            " Pricing Research - Value Modelling - Price Modelling - Packaging." +
            " Each scenario can have a combination of all of these Workspaces."
    },
];


export default function OrganizationSelection(props) {

    // RESIZABLE SIDEBAR

    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(Math.max((window.innerWidth) / 3.5, 400));

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback(
        (mouseMoveEvent) => {
            if (isResizing) {
                setSidebarWidth(
                    mouseMoveEvent.clientX -
                    sidebarRef.current.getBoundingClientRect().left
                );
            }
        },
        [isResizing]
    );

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);
        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setActiveStep(0)
        }
        else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            setActiveStep(steps.length - 1)
        }
        else {
            setActiveStep(activeStep - 1);
        }
    };


    return (
        <Grid container>
            <Grid container item
                ref={sidebarRef}
                sx={{ width: sidebarWidth, minWidth: '21vw', maxWidth: '50vw' }}
            >
                <SideBar />
                <Grid item sx={{
                    width: '2px', cursor: 'w-resize', resize: 'horizontal', backgroundColor: isResizing ? 'primary.main' : 'lightgray',
                    '&:hover': { backgroundColor: 'primary.main' },
                }} onMouseDown={startResizing} />
            </Grid>
            <Grid item xs p={3} >
                {/*REST RIGHT SIDE PAGE... */}

                {/*<Grid container width={'100%'} mt={-1}>*/}
                {/*    <Typography fontSize={26} fontWeight={800} pb={2} color={'primary.main'}>*/}
                {/*        Lets simplify and optimize pricing together!*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}

                <Grid container width={'100%'} justifyContent={'space-between'}
                // borderTop={'1px solid lightgray'}
                >

                    <Grid container width={'65%'} p={0} direction={'column'}>
                        <Typography fontSize={20} fontWeight={800} pb={2}>
                            Getting Started
                        </Typography>

                        <Grid container width={'100%'}
                            border={'1px solid lightgray'}
                            borderRadius={2}
                            boxShadow={2}
                            p={3}
                            mb={3}
                        >
                            <Typography fontSize={16} fontWeight={800} pb={1}>
                                Watch PriceOps Tutorials
                            </Typography>
                            <Typography fontSize={14}>
                                To get started using PriceOps we highly recommend you to
                                watch our curated list of video tutorials{' '}
                                <Link
                                    href="https://loom.com/share/folder/a990113d253d441e8cc9751255fb6240"
                                    target="_blank">
                                    here
                                </Link>.
                            </Typography>
                        </Grid>

                        <Grid container width={'100%'}
                              border={'1px solid lightgray'}
                              borderRadius={2}
                              boxShadow={2}
                            p={3}
                        >
                            <Typography fontSize={16} fontWeight={800} pb={1}>
                                Understanding PriceOps Hierarchy
                            </Typography>
                            <Box sx={{ width: '100%', flexGrow: 1 }}>
                                <Grid container px={2}>
                                    <Box sx={{
                                        height: sidebarWidth / 3.5,
                                        width: '100%',
                                        minHeight: 110,
                                        maxHeight: 150
                                    }}
                                        pt={1}>
                                        <Typography fontWeight={800} fontSize={14}>
                                            What is {steps[activeStep].label}?
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {steps[activeStep].description}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <MobileStepper
                                    variant="dots"
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleNext();
                                            }}
                                        >
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </IconButton>
                                    }
                                    backButton={
                                        <IconButton size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleBack();
                                            }}
                                        >
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowRight />
                                            ) : (
                                                <KeyboardArrowLeft />
                                            )}
                                        </IconButton>
                                    }
                                />
                            </Box>

                            {/*{Object.keys(hierarchyText).map((key)=>{*/}
                            {/*    return(*/}
                            {/*        <Grid*/}
                            {/*            container*/}
                            {/*            border={'1px solid lightgray'}*/}
                            {/*            borderRadius={2}*/}
                            {/*            direction={'column'}*/}
                            {/*            width={'100%'}*/}
                            {/*            p={2}*/}
                            {/*            mt={2}*/}
                            {/*        >*/}
                            {/*            <Typography fontSize={14} fontWeight={800} pb={2}>{`What is ${key}?`}</Typography>*/}
                            {/*            <Typography fontSize={14} pb={2}>*/}
                            {/*                {hierarchyText[key]}*/}
                            {/*            </Typography>*/}
                            {/*            /!*<Typography fontSize={14} fontWeight={800} color={'primary.main'}>Add Organization</Typography>*!/*/}
                            {/*        </Grid>*/}
                            {/*    )*/}
                            {/*})}*/}
                        </Grid>
                    </Grid>

                    <Divider orientation="vertical"
                        // variant="middle"
                        flexItem />

                    <Grid container width={'30%'} p={0} direction={'column'}>
                        <Typography fontSize={20} fontWeight={800} pb={2}>
                            PriceOps Documentation
                        </Typography>

                        <Grid container width={'100%'}
                            // border={'2px solid #6C63FF'}
                            borderRadius={4}
                            p={0}
                            mb={2}
                            display={'column'}
                        // justifyContent={'center'}
                        >
                            <Typography fontSize={16} sx={{width:'100%'}} fontWeight={800} pb={1}>
                                <Link
                                    href="https://docs.priceops.net/documentation/"
                                    target="_blank">
                                        Packaging
                                </Link>
                            </Typography>

                            <Typography fontSize={16} sx={{width:'100%'}} fontWeight={800} pb={1}>
                                <Link
                                    href="https://docs.priceops.net/documentation/payment-gateway-intergration/integrate-with-stripe"
                                    target="_blank">
                                    Stripe Integration
                                </Link>

                            </Typography>

                            <Grid container width={'100%'} direction={'row'} >
                                <Typography fontSize={16} fontWeight={800} pb={1}>
                                    <Link
                                        href="https://docs.priceops.net/documentation/pricing-engine/"
                                        target="_blank">
                                        Pricing Engine
                                    </Link>
                                </Typography>
                                <Typography
                                    fontSize={8}
                                    fontWeight={600}
                                    color={'darkgoldenrod'}
                                    mt={-0.3}
                                >
                                    BETA
                                </Typography>
                            </Grid>





                            {/*<Typography fontSize={14}>*/}
                            {/*    To get started using PriceOps we highly recommend you to*/}
                            {/*    watch our curated list of video tutorials here.*/}
                            {/*</Typography>*/}
                        </Grid>

                        {/*<Grid container width={'100%'}*/}
                        {/*      border={'2px solid #6C63FF'}*/}
                        {/*      borderRadius={4}*/}
                        {/*      p={3}*/}
                        {/*      mb={2}*/}
                        {/*      justifyContent={'center'}*/}

                        {/*>*/}
                        {/*    <Typography fontSize={16} fontWeight={800} pb={1}>*/}
                        {/*        Stripe Integration*/}
                        {/*    </Typography>*/}
                        {/*    /!*<Typography fontSize={14}>*!/*/}
                        {/*    /!*    To get started using PriceOps we highly recommend you to*!/*/}
                        {/*    /!*    watch our curated list of video tutorials here.*!/*/}
                        {/*    /!*</Typography>*!/*/}
                        {/*</Grid>*/}

                        {/*<Grid container width={'100%'}*/}
                        {/*      border={'2px solid #6C63FF'}*/}
                        {/*      borderRadius={4}*/}
                        {/*      p={3}*/}
                        {/*      mb={2}*/}
                        {/*      justifyContent={'center'}*/}
                        {/*>*/}
                        {/*    <Typography fontSize={16} fontWeight={800} pb={1}>*/}
                        {/*        Price Modelling*/}
                        {/*    </Typography>*/}
                        {/*    /!*<Typography fontSize={14}>*!/*/}
                        {/*    /!*    To get started using PriceOps we highly recommend you to*!/*/}
                        {/*    /!*    watch our curated list of video tutorials here.*!/*/}
                        {/*    /!*</Typography>*!/*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>



            </Grid>
        </Grid>
    );
}


import { Alert, Button, CircularProgress, Grid, Typography, Snackbar, IconButton } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { addOrUpdateProperty, deleteProperty, fetchAllAppProperties } from "../../apis/ClientDataService";
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from 'components/ConfirmationDialog';

export default function AppProperties() {
    const dateComparator = (v1, v2) => new Date(v1) - new Date(v2);
    const [snackbar, setSnackbar] = React.useState(null);

    const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [rowForDeletion, setRowForDeletion] = useState(null);

    const handleDelete = (row) => {
        setRowForDeletion(row);
        setDeleteConfirmationDialog(true);
    }

    const onDeleteConfirmation = () => {
        const row = rowForDeletion;
        deleteProperty(row, () => {
            setProperties((prev) => prev.filter(x => x.rowIndex !== row.rowIndex))
            setSnackbar({ children: 'Deleted property successfully', severity: 'success' });
            setRowForDeletion(null);
            setDeleteConfirmationDialog(false);
        }, err => {
            setDeleteConfirmationDialog(false);
            if (err.error === 403) {
                setSnackbar({ children: 'You are not authorized to delete', severity: 'error' });
            } else {
                setSnackbar({ children: err?.error_message ?? 'Delete failed', severity: 'error' });
            }
        });
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'rowIndex',
            headerName: '#',
            disableColumnMenu: true,
            sortable: false,
            width: 5
        },
        { field: 'id', headerName: 'id', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1, editable: true },
        { field: 'value', headerName: 'Value', flex: 3, editable: true },
        {
            field: 'actions', headerName: '', width: 50, renderCell: (params) => {
                return (
                    <IconButton sx={{ height: 22, width: 22 }}
                        onClick={() => handleDelete(params.row)}>
                        <DeleteIcon sx={{ width: 20, height: 20 }}></DeleteIcon>
                    </IconButton>
                );
            }
        }
    ];

    const [properties, setProperties] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setError(null);
        fetchAllAppProperties((data) => {
            const newData = data.map((appProperty, index) => ({
                ...appProperty,
                rowIndex: index + 1,
            }));
            setProperties(newData);
            setLoading(false);
        }, (err) => {
            if (err.error === 403) {
                setError("You are not authorized to access this data");
            } else {
                setError("App properties could not be fetched!");
            }
            setLoading(false);
        });
    }, []);

    const handleAddProperty = () => {
        setProperties((prev) => [...prev, { rowIndex: prev.length + 1, name: '', value: '' }]);
    }

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            if (newRow.name && newRow.value) {
                const response = addOrUpdateProperty(newRow, data => {
                    setSnackbar({ children: 'Property saved successfully', severity: 'success' });
                }, err => {
                    setSnackbar({ children: 'Update error ' + err?.error_message ?? "", severity: 'error' });
                });
                return response;
            } else {
                return newRow;
            }
        },
        [],
    );

    const handleCloseSnackbar = () => setSnackbar(null);

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                {deleteConfirmationDialog && <ConfirmationDialog
                    noText="No"
                    yesText="Yes"
                    open={deleteConfirmationDialog}
                    show={(state) => setDeleteConfirmationDialog(state)}
                    message="Do you want to delete this property?"
                    accepted={() => onDeleteConfirmation()}
                    rejected={() => setDeleteConfirmationDialog(false)}>
                </ConfirmationDialog>}
                <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                    <Typography fontSize={30}>
                        App settings
                    </Typography>
                </Grid>
                <Grid container item
                    sx={{
                        height: '75vh',
                        bgcolor: 'white',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {loading ?
                        <CircularProgress />
                        :
                        <>
                            {error ?
                                <Alert sx={{ width: "80%", maxWidth: 600 }} severity="error">{error}</Alert>
                                :
                                properties.length
                                    ?
                                    <DataGrid
                                        getRowId={(row) => row.rowIndex}
                                        sx={{
                                            overflowX: 'hidden',
                                            // disable cell selection style
                                            '.MuiDataGrid-cell:focus': {
                                                outline: 'none'
                                            },
                                            // pointer cursor on ALL rows
                                            '& .MuiDataGrid-row:hover': {
                                                cursor: 'pointer'
                                            },
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1, fontSize: 12,
                                            }
                                        }}
                                        getRowHeight={() => 'auto'}
                                        rows={properties}
                                        // loading={loading}
                                        columns={columns}
                                        columnVisibilityModel={{
                                            id: false
                                        }}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 100 },
                                            },
                                        }}
                                        processRowUpdate={processRowUpdate}
                                        onProcessRowUpdateError={err => { }}
                                        pageSizeOptions={[10, 50, 100]}
                                    // onCellClick={handleRowHover}
                                    // checkboxSelection
                                    />
                                    :
                                    <Typography color={'primary.greyText'}>
                                        No properties present in the database!
                                    </Typography>
                            }
                        </>
                    }
                </Grid>
                <Button variant="outlined" onClick={handleAddProperty}>Add property</Button>
                {!!snackbar && <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>}
            </Grid >
        </>
    );
}


import { Grid, Tab, Tabs } from '@mui/material';
import {getCoupons, getPackagingProducts} from 'apis/ClientDataService';
import ScenarioDataContext from 'pages/scenarios/ScenarioDataContext';
import ScenarioUIStateContext from 'pages/scenarios/ScenarioUIStateContext';
import React, {createContext, useState} from 'react';
import DesignFlow from "./DesignFlow";
import ProductFlow from "./ProductFlow";
import CouponFlow from "./CouponFlow";

export const ProductContext = createContext();
export const CouponContext = createContext();

const PackagingFlow = () => {
    const { scenarioUIState, updateScenarioUIState } = React.useContext(ScenarioUIStateContext);
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const [products, setProducts] = React.useState([]);
    const [coupons, setCoupons] = React.useState([]);

    const updateProducts = (newProducts) => {
        setProducts(newProducts)
    }

    const updateCoupons = (newCoupons) => {
        setCoupons(newCoupons)
    }

    const [value, setValue] = React.useState(0);
    const [subValue, setSubValue] = React.useState(scenarioUIState?.packagingTab ?? "design");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubChange = (event, newValue) => {
        setSubValue(newValue);

        const st = { ...scenarioUIState };
        st.packagingTab = newValue;
        updateScenarioUIState(st);
    };

    const verticalTabs = [
        'Good Better Best',
        'One Single Package',
        'Independent Modules',
        'Platform + Extensions',
    ];

    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        // setLoading(true);
        // setError(null);
        getPackagingProducts(scenario.id, (data) => {
            // console.log(data)
            updateProducts(data);
            // setTimeout(() => {
            //     setLoading(false);
            // }, 100);
            setLoading(false);

        }, err => {
            setLoading(false);
            // setError(err?.error_message ?? "Organization And Scenario's products could not be fetched!");
        });

        getCoupons(scenario.id, (data) => {
            // console.log(data)
            updateCoupons(data);
            setLoading(false);
        }, err => {
            setLoading(false);
            // setError("Organization coupons could not be fetched!");
            // console.log(err);
        });

    }, []);

    return (
        <ProductContext.Provider value={{ products, updateProducts }}>
            <CouponContext.Provider value={{ coupons, updateCoupons }}>
                <Grid container sx={{ minHeight: '80vh' }}>
                    {/*<Tabs*/}
                    {/*    orientation="vertical"*/}
                    {/*    variant="scrollable"*/}
                    {/*    value={value}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    sx={{*/}
                    {/*        width: '20vw',*/}
                    {/*        pt: 10,*/}
                    {/*        borderRight: '1px solid lightgray',*/}
                    {/*        bgcolor:'primary.background'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {verticalTabs.map((label) => (*/}
                    {/*        <Tab*/}
                    {/*            key={label}*/}
                    {/*            sx={{ fontWeight: 600, fontSize: 14 }}*/}
                    {/*            label={label}*/}
                    {/*        />*/}
                    {/*    ))}*/}
                    {/*</Tabs>*/}
                    <Grid sx={{ flexGrow: 1 }}>
                        <Tabs
                            value={subValue}
                            onChange={handleSubChange}
                            // sx={{ borderBottom: '1px solid lightgray' }}
                        >
                            <Tab sx={{ fontWeight: 600, fontSize: 14 }} label="Design" value="design" />
                            <Tab sx={{ fontWeight: 600, fontSize: 14 }} label="Product" value="product" />
                            <Tab sx={{ fontWeight: 600, fontSize: 14 }} label="Coupon" value="coupon" />
                        </Tabs>
                        <Grid container item sx={{}}>
                            {subValue === "design" && <DesignFlow />}
                            {subValue === "product" && <ProductFlow loading={loading} />}
                            {subValue === "coupon" && <CouponFlow loading={loading}/>}
                        </Grid>
                    </Grid>
                </Grid>
            </CouponContext.Provider>
        </ProductContext.Provider>
    );
};

export default PackagingFlow;



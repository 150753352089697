import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PasswordIcon from "@mui/icons-material/Password";
import { Alert, Box, Button, Grid, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import React from "react";
import { resetPassword } from "../../apis/ClientDataService";

export default function ResetPasswordPage() {
    const [resetParamsJson] = React.useState(localStorage.getItem("reset-password"));

    if (resetParamsJson == null) window.location = '/';

    const [password, setPassword] = React.useState("");
    const [reenterPassword, setReenterPassword] = React.useState("");
    const [changed, setChanged] = React.useState(false);
    const [error, setError] = React.useState(null);

    const validateForm = () => {
        return password.length > 0 && reenterPassword.length > 0;
    }

    const resetParams = resetParamsJson ? JSON.parse(resetParamsJson) : null;

    const updatePassword = (e) => {
        if (password !== reenterPassword) {
            setError("Passwords do not match");
            return;
        }

        setError(null);
        const email = resetParams["email"];
        const token = resetParams["token"];

        resetPassword(email, token, password, () => {
            setChanged(true);
            localStorage.removeItem("reset-password");
        }, (err) => {
            setError(err ? err["error_message"] : "Unable to change password, please try again!");
        })
    }

    return (<React.Fragment>
        <Grid container item xs={12} justifyContent="center" alignItems="center" height="70vh">
            <Grid container item sx={{ width: 400, borderRadius: 2, boxShadow: 2, p: 6 }} alignItems="center" justifyContent="center">
                {resetParams &&
                    <React.Fragment>
                        {error && <Alert onClose={() => setError(null)} severity="error">{error}</Alert>}
                        {!changed &&
                            <React.Fragment>
                                <Typography color="primary.main">{resetParams["email"]}
                                </Typography>
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    label="Password"
                                    id="login-password"
                                    aria-describedby="Login password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    autoComplete="new-password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    label="Re-enter password"
                                    id="re-enter-password"
                                    aria-describedby="Re enter password"
                                    value={reenterPassword}
                                    onChange={(e) => setReenterPassword(e.target.value)}
                                    type="password"
                                    autoComplete="new-password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Button
                                    sx={{ mt: 4 }}
                                    block
                                    size="lg"
                                    type="submit"
                                    disabled={!validateForm()}
                                    variant="contained"
                                    onClick={(e) => updatePassword(e)}
                                >Update password
                                </Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {changed &&
                    <React.Fragment>
                        <Box sx={{ textAlign: "center" }}>
                            <CheckCircleIcon sx={{ color: "primary.main", height: 100, width: 100, pb: 5 }}></CheckCircleIcon>
                            <Typography>Password changed</Typography>
                            <Button variant="text" sx={{ fontSize: 12, mt: 10, textTransform: "none" }} onClick={() => {
                                localStorage.clear();
                                window.location =
                                    '/'
                            }}>Back to login</Button>
                        </Box>
                    </React.Fragment>}
                {!changed && !resetParams &&
                    <React.Fragment>
                        <LinearProgress></LinearProgress>
                    </React.Fragment>}
            </Grid>
        </Grid>
    </React.Fragment>)
}
import { Alert, CircularProgress, Grid, Link, Typography, TextField, Button, Chip, MenuItem, Box, Tooltip, Fab, InputAdornment, Paper, FormControl, Backdrop} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { fetchCustomerPlansForClient, getPackagingProducts, getScenarios, getScenariosOfProject, listProjects } from "../../apis/ClientDataService";
import OrganizationContext from "../../state/OrganizationContext";
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { MuiColorInput } from 'mui-color-input';
import PortalPreview from './PortalPreview';

const fonts = [
    'Open Sans',
    'Roboto',
    'IBM Plex Sans',
    'Inter',
    'Lexend Deca',
    'system-ui',
    'Montserrat',
    'Chakra Petch',
    'Helvetica',
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Gill Sans',
    'Times New Roman',
    'Georgia',
    'Palatino',
    'Baskerville',
    'Andalé Mono',
    'Courier',
    'Lucida',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS'
];

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

export default function CustomerPortal() {

    const { globalOrganization, updateGlobalOrganization } = useContext(OrganizationContext);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [products, setProducts] = useState([]);
    const [currSelectedProduct, setCurrSelectedProduct] = useState(null);
    const [currSelectedScenario, setCurrSelectedScenario] = useState(null);
    const [currSelectedProject, setCurrSelectedProject] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const [loginRedirectLink, setLoginRedirectLink] = useState('');
    const handleLoginRedirectLinkChange = (event) => {
        setLoginRedirectLink(event.target.value);
    };

    const [portalHeader, setPortalHeader] = useState(`${globalOrganization.name} partners with PriceOps for secure payments.`);
    const handlePortalHeaderChange = (event) => {
        setPortalHeader(event.target.value);
    };

    const [fontStyle, setFontStyle] = useState(fonts[3]);
    const handleFontStyleChange = (event) => {
        setFontStyle(event.target.value);
    };

    const [highlightColor, setHighlightColor] = useState('#4f46e5')
    const handleHighlightColorChange = (color) => {
        setHighlightColor(color)
    }

    useEffect(()=>{
        listProjects(globalOrganization.id, (updatedProjects) => {
            setLoading(false)
            setProjects(updatedProjects);
            },
            err => {
                setError("Projects could not be fetched!");
                setLoading(false)
                console.log(err);
            })
    },[])

    useEffect(() => {
        setSearchResults((prevSearchResults) =>
            prevSearchResults.filter((product) => !selectedProducts.some((selected) => selected.id === product.id))
        );
    }, [selectedProducts,currSelectedProject,currSelectedScenario]);
    
    const handleAddProduct = (result) => {
        // console.log(result)
        let value = result
        if (typeof value !== 'string') {
            if (value) {
                setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, value]);
                setSearchResults((prevSearchResults) =>
                    prevSearchResults.filter((product) => product.name !== value.name)
                );
                setCurrSelectedProduct(value.name)
            }
        }
    };

    const handleDeleteProduct = (productToDelete) => () => {
        setSelectedProducts((prevSelectedProducts) =>
            prevSelectedProducts.filter((product) => product.name !== productToDelete.name)
        );

        if(currSelectedScenario.id === productToDelete.scenario_id){
            setSearchResults((prevSearchResults) => [...prevSearchResults, productToDelete]);
        }
    };

    const handleProjectChange = (currentProject) => {
        setLoading(true)
        getScenariosOfProject(currentProject.id,data => {
            setScenarios(data);
            setCurrSelectedProject(currentProject)
            setLoading(false);
        }, (err) => {
            setError("Scenarios could not be fetched!");
            setLoading(false)
            console.log(err);
        });        
    };

    const handleScenarioChange = (currentScenario) => {
        setLoading(true)
        getPackagingProducts(currentScenario.id, (data) => {
            setProducts(data);
            setSearchResults(
                data.filter(product => product.payment_integration.gateway_type === 'Stripe' && 
                product.add_on_product !== true ))
            setCurrSelectedScenario(currentScenario)
            setLoading(false);
        }, (err) => {
            setError("Products could not be fetched!");
            setLoading(false)
            console.log(err);
        });        
    };

    const [expanded, setExpanded] = React.useState('panel1');

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container item sx={{ p: 1 }} justifyContent={'space-between'}>
                    <Typography fontSize={30}>
                        {globalOrganization.name} Customer Portal
                    </Typography>
                    <Grid item>
                        <Tooltip title="Save Changes">
                            <Fab color="primary" aria-label="add" size="small"
                                // onClick={handleSavePortalChanges}
                            >
                                <SaveIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item
                    sx={{
                        height: '80vh',
                        bgcolor: 'white',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <>
                        {error ?
                            <Alert sx={{ width: "80%", maxWidth: 600 }} severity="error">{error}</Alert>
                            :
                            <Grid sx={{width: "100%", height: "100%"}} container py={3} pl={3} pr={1}>
                                <Grid container width={"65%"} direction={'column'} pr={3} gap={2}>
                                    <Typography fontSize={16} fontWeight={800}>
                                        Customer Portal Preview
                                    </Typography>

                                    <Paper elevation={4} sx={{height:'70vh',width:'100%'}}>
                                        <PortalPreview 
                                            zoomScale={0.7}
                                            portalHeader={portalHeader}
                                            highlightColor={highlightColor}
                                            fontStyle={fontStyle}
                                        />
                                    </Paper>
                                </Grid>

                                <Grid container width={"35%"} direction={'column'} borderLeft={'1px solid lightgray'} px={2}
                                    wrap="nowrap"
                                    sx={{
                                        pr: 2,
                                        pb: 2,
                                        height: '75vh',
                                        overflowY: 'scroll',
                                        "&::-webkit-scrollbar": {
                                            width: 7,
                                            // bgcolor: ''
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: 'lightgray',
                                            outline: "1px solid lightgray",
                                            borderRadius: 4
                                        }
                                    }}
                                >
                                    <Grid container gap={2} pb={2}>
                                        <Typography fontSize={16} fontWeight={800}>
                                            Customer Portal Script
                                        </Typography>
                                        <TextField
                                            disabled={true}
                                            sx={{
                                                width: '100%',
                                            }}
                                            inputProps={{style: {fontSize: 16}}} 
                                            size={'small'}
                                            value={`<object data="https://app.priceops.net/customer-portal/${globalOrganization.id}"   
                                                style="width:100%;height:100%;"   type="text/html"></object>`}
                                            // onChange={handleLoginRedirectLinkChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Grid container borderLeft={'1px solid lightgray'} pl={1} my={0.5}>
                                                            <ContentCopyIcon/>
                                                        </Grid>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    
                                    <Typography fontSize={16} fontWeight={800} pb={2}>
                                        Customer Portal Settings
                                    </Typography>

                                    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography>General Configuration</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container gap={2}>
                                                <Typography fontSize={16} fontWeight={800}>
                                                    Customer Login Redirect
                                                </Typography>
                                                <TextField
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    size={'small'}
                                                    label="User Login Page URL"
                                                    value={loginRedirectLink}
                                                    onChange={handleLoginRedirectLinkChange}
                                                />
                                                <Alert severity={'info'} sx={{width:'100%'}}>
                                                    Customer will be redirected to this page if not logged in.
                                                </Alert>
                                            </Grid>
                                        
                                            <Grid container width={'100%'} gap={2} pt={2} justifyContent={'space-between'}>
                                                <Typography fontSize={16} fontWeight={800} sx={{width:'100%'}}>
                                                    Product Selection
                                                </Typography>
                                                <TextField
                                                    sx={{width: '47%'}}
                                                    select
                                                    size='small'
                                                    labelId="project-select-label"
                                                    id="project-select"
                                                    label="Project"
                                                    value={currSelectedProject}
                                                >
                                                    {projects.map((result) => (
                                                        <MenuItem
                                                            value={result}
                                                            onClick={() => handleProjectChange(result)}
                                                        >
                                                            {result.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                                <TextField
                                                    sx={{width: '47%'}}
                                                    select
                                                    size='small'
                                                    labelId="scenario-select-label"
                                                    id="scenario-select"
                                                    label="Scenario"
                                                    value={currSelectedScenario}
                                                >
                                                    {scenarios.map((result) => (
                                                        <MenuItem
                                                            value={result}
                                                            onClick={() => handleScenarioChange(result)}
                                                        >
                                                            {result.scenario_name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                
                                                <TextField
                                                    sx={{width: '100%'}}
                                                    select
                                                    size='small'
                                                    labelId="product-select-label"
                                                    id="product-select"
                                                    label="Select Product"
                                                    value={currSelectedProduct}
                                                    helperText={"Only Stripe integrated main products allowed for selection"}
                                                >
                                                    {searchResults.map((result) => (
                                                        <MenuItem
                                                            value={result}
                                                            onClick={() => handleAddProduct(result)}
                                                        >
                                                            {result.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {selectedProducts.length ?
                                                    <Grid container item gap={1} mt={1}>
                                                        {selectedProducts.map((product) => (
                                                            <Chip
                                                                key={product.name}
                                                                label={
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography pr={2}>
                                                                            {product.name}
                                                                        </Typography>
                                                                        {/* <EditIcon fontSize="small"
                                                                                onClick={(e) => {
                                                                                    setActiveProduct(product)
                                                                                    handleUpdateProductClick()
                                                                                }}
                                                                                sx={{
                                                                                    color: '#aeaeae',
                                                                                    cursor: 'pointer',
                                                                                    '&:hover': {
                                                                                        color: '#818589'
                                                                                    }
                                                                                }}
                                                                        /> */}
                                                                    </Box>
                                                                }
                                                                deleteIcon={<DeleteIcon sx={{
                                                                    '&:hover': {
                                                                        color: '#818589'
                                                                    }
                                                                }} />}
                                                                onDelete={handleDeleteProduct(product)}
                                                            />
                                                        ))}
                                                    </Grid>
                                                    :
                                                    <Alert severity="info" sx={{ fontSize: 12, mt: 1, width:'100%' }}>
                                                        Select your first product from the above dropdown menu.
                                                    </Alert>
                                                }
                                            </Grid>

                                            <Grid container gap={2} pt={2}>
                                                <Typography fontSize={16} fontWeight={800}>
                                                    Portal Header
                                                </Typography>
                                                <TextField
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    multiline
                                                    rows={2}
                                                    size={'small'}
                                                    label="Portal Header"
                                                    value={portalHeader}
                                                    onChange={handlePortalHeaderChange}
                                                />
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                        <Typography>Style Settings</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container justifyContent={'space-between'} gap={2} py={1}>
                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    value={highlightColor}
                                                    label="Highlight Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleHighlightColorChange}
                                                />
                                                <FormControl
                                                    sx={{
                                                        width: '45%'
                                                    }}>
                                                    {/*<InputLabel id="font-select-label">Font Style</InputLabel>*/}
                                                    <TextField
                                                        select
                                                        size={'small'}
                                                        labelId="font-select-label"
                                                        id="font-select"
                                                        label="Font Style"
                                                        value={fontStyle}
                                                        onChange={handleFontStyleChange}
                                                    >
                                                        {fonts.map((font) => (
                                                            <MenuItem key={font} value={font}>
                                                                {font}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>    
                                        </AccordionDetails>
                                    </Accordion>
                                    {/* <Accordion expanded={expanded === 'panel7'} onChange={handleAccordionChange('panel7')}>
                                        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                        <Typography>Invoices</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Typography>Customer Information</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                        <Typography>Subscriptions</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
                                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                        <Typography>Cancellations</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                    
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
                                        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                        <Typography>Payment Methods</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                    
                                        </AccordionDetails>
                                    </Accordion> */}
                                </Grid>
                            </Grid>
                        }
                    </>
                </Grid>
            </Grid >
        </>
    );
}


import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import SelectElement from "../components/SelectElement";

export default function SelectorComponent(props) {
  const selectOptions = props.selectoptions;

  const [selection, setSelection] = React.useState(props.hidecards ? selectOptions[0] : null);

  const styles = {
    selectionImage: {
      width: 60,
      height: 60,
      // color: "icon"
    },
    selectionTab: {
      padding: 1,
      marginLeft: 1,
      marginRight: 1,
      backgroundColor: "primary.main",
      color: "white",
      borderRadius: 20,
      marginBottom: 1,
      cursor: "pointer",
      boxShadow: 1,
      border: 1,
      borderColor: grey[100],
    },
    selectionTabUnselected: {
      backgroundColor: grey[500],
    },
  };

  const onSelection = (opt) => {
    setSelection(opt);
  };

  const cancel = (event) => {
    setSelection(null);
  };

  const getSelectedTabStyles = (selected) => {
    if (selected) return [styles.selectionTab];
    else return [styles.selectionTab, styles.selectionTabUnselected];
  };

  const getIcon = (index) => {
    const Icon = props.icons[index];
    return <Icon key={index} sx={styles.selectionImage} />;
  };

  const getTabIcon = (index, selected, opt) => {
    const Icon = props.icons[index];
    return (
      <Tooltip title={selectOptions[index]} key={index} placement="top">
        <Icon
          key={index}
          sx={getSelectedTabStyles(selected)}
          size="medium"
          onClick={(event) => onSelection(opt)}
        />
      </Tooltip>
    );
  };

  const getBox = (opt, index) => {
    return (
      <Grid key={index} sx={{ m: 5 }}>
        <Box onClick={(event) => {
          onSelection(opt);
        }}>
          {
            props.selectelements ?
              props.selectelements[index]
              :
              (<SelectElement
                key={index}
                onClick={(event) => {
                  onSelection(opt);
                }}
                text={opt}
                startIcon={getIcon(index)}
              />)
          }
        </Box>
      </Grid>
    );
  };

  const getView = (index) => {
    return props.views[index];
  };

  return (
    <Grid item xs={12} alignItems="center"
      {...props}
      sx={{
        borderColor: "primary.border",
        m: 1,
        mt: 0,
        pt: 0,
        pb: 1,
        borderRadius: 2
      }}
    >
      {selection && !props.hidebackbutton && (
        <Button
          startIcon={<ArrowBackSharpIcon />}
          sx={{ float: "left", cursor: "pointer", mr: 2, mt: 0, mb: 1, pt: 0, color: "primary.sub", textTransform: 'None' }}
          onClick={cancel}
        >Back</Button>
      )}
      <Grid
        container
        item
        alignItems="flex-start"
        justifyContent="center"
        xs={12}
      >
        {selectOptions.map((opt, index) => {
          if (!selection) return getBox(opt, index);
          else return <React.Fragment key={index} />;
        })}
        {selection && (
          <Grid container ml={1}>
            {selection && !props.hidetabicons &&
              selectOptions.map((opt, index) =>
                getTabIcon(index, selection === opt, opt)
              )}
            {selectOptions.map((opt, index) => {
              if (selection === opt)
                return (
                  <Grid
                    key={index}
                    container
                    item xs={12}
                    sx={{ minHeight: '75vh' }}
                  // justifyContent="center"
                  >
                    {getView(index)}
                  </Grid>
                );
              else return <React.Fragment />;
            })}
          </Grid>
        )}
      </Grid>
    </Grid >
  );
}

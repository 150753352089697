// BottomBar.js
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

const BottomBar = (props) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window?.pageYOffset ?? 0;
        const windowHeight = window?.innerHeight ?? 0;
        const bottomBarHeight = document.getElementById("bottom-bar")?.offsetHeight ?? 0;

        if (bottomBarHeight > 0.3 * windowHeight) {
            setIsVisible(currentScrollPos < 100)
        } else {
            setIsVisible(
                currentScrollPos == 0 ||
                document.body.scrollHeight - currentScrollPos - windowHeight > bottomBarHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    return (
        <React.Fragment>
            <Grid id="bottom-bar" container sx={{ position: "fixed", bottom: 0, transition: 'height 1s ease' }}
                // sx={{ width: "100%", position: "absolute", bottom: 0, , height: isVisible ? 'auto' : 0 }}
                style={{ transform: isVisible ? 'translateY(0)' : 'translateY(100%)', transition: 'transform 0.5s ease' }}
            >
                {props.Component}
            </Grid>
        </React.Fragment >
    );
};

export default BottomBar;

import React from 'react';
import PricingGraph from './PricingGraph';

export default function TimelineChart(props) {

    return <PricingGraph
        {...props}
        data={props.data}
        xAxisDataKey={props.xAxisDataKey}
        xLabel={props.xLabel}
        yAxisDataKey={props.yAxisDataKey}
        yLabel={props.yLabel}
        hideDot={true}>
    </PricingGraph>
}
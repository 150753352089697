import React from "react";
import {
    Bar, BarChart, Cell, Label, Tooltip, XAxis,
    YAxis
} from "recharts";
import { COLORS } from "../themes/themes";

export default function PricingBarChart(props) {
    const data = props.data;
    const xAxisLabel = props.xAxisLabel;
    const yAxisLabel = props.yAxisLabel;
    const xAxisKey = props.xKey;
    const yAxisKey = props.yKey;

    const yFormatter = props.yFormatter ? props.yFormatter : (value) => value;

    const CustomizedLabel = (props) => {
        const { x, y, fill, value } = props;
        return (
            <text
                x={x}
                y={y}
                dy={-4}
                dx={14}
                fontSize="10"
                fontFamily="sans-serif"
                fill={fill}
                textAnchor="middle"
            >
                {yFormatter(value)}
            </text>
        );
    }

    return (<BarChart width={600} height={400} data={data}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey={xAxisKey} fontSize={10} dy={30} dx={-10} height={100} angle={300}>
            <Label fontSize={12} dy={40} value={xAxisLabel} sx={{ color: "primary.text" }}></Label>
        </XAxis>
        <YAxis style={{ fontSize: 12 }} tickFormatter={yFormatter}>
            <Label
                dx={-25}
                fontSize={13}
                sx={{
                    textAnchor: "middle",
                    fill: "primary.text",
                    color: "primary.text",
                    marginLeft: -20,
                }}
                angle={270}
                value={yAxisLabel} />
        </YAxis>
        <Tooltip formatter={yFormatter} />
        <Bar dataKey={yAxisKey} fill="#8884d8" barSize={25} label={CustomizedLabel}>
            {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
        </Bar>
    </BarChart>)
}
import React from "react";
import PricingBarChart from "../../components/PricingBarChart";
import PricingPieChart from "../../components/PricingPieChart";
import { currencyFormatter } from "../../utils/Utils";

import { Box, Grid, Typography } from "@mui/material";


export default function MarketSegmentView(props) {
    const [graph] = React.useState(props.data);
    const xLabel = props.xLabel;
    const isRevenue = xLabel.toLowerCase() === 'revenue';

    const view = (xKey, yKey, yLabel, yFormatter, title) => {
        return (<Grid container item xs={12} sx={{ mb: 2 }} justifyContent="center">
            <Typography variant="h6" component="h2" sx={{ mt: 1 }}>
                {title}
            </Typography>
            <React.Fragment>
                <Grid container item xs={12} direction="row" justifyContent="center"
                    sx={{ mt: 1 }}>
                    <Grid item xs={7} align="center">
                        <PricingBarChart
                            data={graph}
                            xKey={xKey}
                            yKey={yKey}
                            xAxisLabel={xLabel}
                            yFormatter={yFormatter ? yFormatter : (value) => value}
                            yAxisLabel={yLabel} />
                    </Grid>
                    <Grid item xs={5} sx={{ pt: 1 }} align="center">
                        <PricingPieChart
                            data={graph}
                            dataKey={yKey}
                            formatter={yFormatter}
                        ></PricingPieChart>
                    </Grid>
                </Grid>
            </React.Fragment></Grid>)
    }

    return (
        <Box sx={{ width: '100%' }}>
            {view("name", "num_customers", "Number of customers", null, "Customer segmentation")}
            {!isRevenue && view("name", "revenue", "Revenue", currencyFormatter, "Revenue segmentation")}
        </Box>
    )
}
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    ListItem,
    TextField,
    ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import { grey, orange } from '@mui/material/colors';
import { addPriceModel, addPriceUnit, deletePriceUnit, listPriceUnits } from "apis/ClientDataService";
import CustField from "components/CustField";
import "index.css";
import { getReadablePriceMetric, getReadableUnits } from 'pages/scenarios/price-modelling/components/PriceModelDefinitions';
import React from "react";
import { getCurrentOrgId } from "state/state";
import { copy } from "utils/Utils";

export function AddPriceModel(props) {
    const priceMetrics = props.priceMetrics;

    return (<Grid sx={{ mt: 2 }}>
        <Typography fontSize={14} fontWeight={600} pb={1}>Price Metrics ({priceMetrics.length})</Typography>
        <Grid sx={{ border: 1, borderRadius:2, borderColor: "primary.groupBorder" }} justifyContent="center" alignItems="center">
            {priceMetrics.length > 0 && priceMetrics.map((metric, index) => {
                return <Grid sx={{ pr: 2, pl: 2, pt: 1, pb: 1, borderRadius:1 }}>
                    <ListItem key={index} sx={{ width: "100%", display: "flex", justifyContent: "center", boxShadow: 3, borderRadius: 2 }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                            {getReadablePriceMetric(metric)}
                        </Typography>
                        {props.onRemoveMetric && <Button onClick={() => { props.onRemoveMetric(metric) }}>Remove</Button>}
                    </ListItem></Grid>
            })}
            {priceMetrics.length === 0 &&
                <Grid sx={{ p: 2, borderRadius:2, backgroundColor: "primary.background", textAlign: "center" }}>
                    <Typography sx={{ fontSize: 12 }}>
                        Please add metrics
                    </Typography>
                    <WarningAmberIcon sx={{ color: orange[800] }} /></Grid>
            }
        </Grid>
    </Grid>)
}


export default function CreatePriceModelDefinition(props) {
    const [priceUnits, setPriceUnits] = React.useState(null);
    const [priceMetrics, setPriceMetrics] = React.useState([]);

    const [newModelName, setNewModelName] = React.useState("");
    const [newPriceUnitName, setNewPriceUnitName] = React.useState("");
    const [newPriceUnitPlural, setNewPriceUnitPlural] = React.useState("");
    const [selectedPriceUnits, setSelectedPriceUnits] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showAddPriceUnit, setShowAddPriceUnit] = React.useState(false);
    const [priceModelType, setPriceModelType] = React.useState("CUSTOM_PRICING");

    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        listPriceUnits((units) => {
            // updateOrganization(org);
            setPriceUnits(units);
            setLoading(false);
        }, err => {
            setLoading(false);
            setError("Unable to fetch price units, please try again");
        });
    }, []);

    const addToSelectedUnits = (unit) => {
        const s = copy(selectedPriceUnits);
        if (!s.find(x => x.unit === unit.unit)) {
            s.push(unit);
        }
        setSelectedPriceUnits(s);
    }

    const removeSelectedUnit = (unit) => {
        const s = copy(selectedPriceUnits).filter(x => x.unit !== unit.unit);
        setSelectedPriceUnits(s);
    }

    const checkTieredModelPriceMetric = (priceMetric) =>{
        const units = priceMetric["price_units"]
        let periodUnitCounter = 0
        let nonPeriodUnitCounter = 0
        for(let x of units ){
            let unitName = x["unit"].toLowerCase()
            if(unitName === 'month' || unitName === 'year'){
                periodUnitCounter +=1
            }else{
                nonPeriodUnitCounter +=1
            }
        }

        return periodUnitCounter <= 1 && nonPeriodUnitCounter === 1;
    }

    const isModelValid = () => {
        return priceMetrics.length > 0 && newModelName.length > 0
            && (priceModelType !== "TIERED_PRICING" ||
                (priceMetrics.length === 1 && checkTieredModelPriceMetric(priceMetrics[0]))
            );
    }

    const createPriceModel = () => {
        const clientId = getCurrentOrgId();
        setLoading(true);
        setError(null);
        addPriceModel({
            "name": newModelName,
            "client_id": clientId,
            "type": priceModelType,
            "price_metrics": priceMetrics
        }, (priceModel) => {
            setLoading(false);
            if (props.onSuccess) {
                props.onSuccess(priceModel);
            }
        }, (err) => {
            setError(err?.error_message ?? "Unable to add price model");
            setLoading(false);
        });
    }

    const isPriceUnitValid = () => {
        return newPriceUnitName.length > 0 && newPriceUnitPlural.length > 0
    }

    const setGlow = (unit, glow) => {
        const cp = copy(priceUnits);
        cp.find(x => x.unit.toLowerCase() === unit.toLowerCase())["glow"] = glow;
        setPriceUnits(cp);
        setKey(key + 1);
    }

    const [key, setKey] = React.useState(1);
    const handleAddPriceUnit = () => {
        const clientId = getCurrentOrgId();
        if (priceUnits.find(x => x.unit.toLowerCase() === newPriceUnitName.toLowerCase())) {
            setGlow(newPriceUnitName, true);
            setTimeout(() => setGlow(newPriceUnitName, false), 300);
        } else {
            setLoading(true);
            setError(null);
            addPriceUnit({
                "name": newPriceUnitName,
                "client_id": clientId,
                "unit": newPriceUnitName,
                "plural": newPriceUnitPlural
            }, (priceUnit) => {
                setNewPriceUnitName("");
                setNewPriceUnitPlural("");
                setPriceUnits([...priceUnits, priceUnit]);
                setSelectedPriceUnits([...selectedPriceUnits, priceUnit]);
                setLoading(false);
            }, (err) => {
                setError(err?.error_message ?? "Unable to add price unit");
                setLoading(false);
            })
        }
    }

    const [metricCounter, setMetricCounter] = React.useState(1);
    const addPriceMetric = () => {
        const exists = priceMetrics.find(x => getReadablePriceMetric(x) === getReadableUnits(selectedPriceUnits));

        if (!exists) {
            const priceMetric = {
                "client_id": getCurrentOrgId(),
                "name": getReadableUnits(selectedPriceUnits),
                "price_units": selectedPriceUnits
            }

            setMetricCounter(metricCounter + 1);
            setPriceMetrics([...priceMetrics, priceMetric]);
        }
    }

    const removePriceMetric = (metric) => {
        setPriceMetrics([...(priceMetrics.filter(x => x.name !== metric.name))]);
    }

    const PriceUnitElement = ({ unit }) => {
        const iconBtnStyle = {
            height: 25
        }
        const iconStyle = {
            width: 20, height: 20
        }

        const handleDeletePriceUnit = (unit) => {
            setLoading(true);
            deletePriceUnit(unit, () => {
                setPriceUnits([...priceUnits.filter(x => x.unit !== unit.unit)]);
                setSelectedPriceUnits([...selectedPriceUnits.filter(x => x.unit !== unit.unit)]);
                setPriceMetrics([...priceMetrics.filter(metric => !metric["price_units"].find(x => x.unit === unit.unit))]);
                setLoading(false);
            }, (err) => {
                setError("Unable to delete price unit");
                setLoading(false);
            });
        }

        return <Box sx={{ boxShadow: 2, borderRadius: 10 }}>
            {!selectedPriceUnits.find(x => x.unit === unit.unit) && <Button
                onClick={() => addToSelectedUnits(unit)}
                sx={iconBtnStyle}
                endIcon={<AddIcon sx={iconStyle}></AddIcon>}>
                {unit.unit}
            </Button>}
            {selectedPriceUnits.find(x => x.unit === unit.unit) && <Button
                onClick={() => removeSelectedUnit(unit)}
                sx={iconBtnStyle}
                endIcon={<RemoveCircleIcon sx={[iconStyle, { color: "primary.remove" }]}></RemoveCircleIcon>}>
                {unit.unit}
            </Button>}
            <IconButton sx={{ ml: 1, width: 20, height: 20 }} onClick={() => handleDeletePriceUnit(unit)}>
                <DeleteIcon sx={{ transform: "scale(0.7)" }} />
            </IconButton>
        </Box>
    }

    return (<Grid container item xs={12} width={'50vw'}>
        <Grid container item xs={12} direction="column"
              wrap="nowrap"
              sx={{
                  px: 3, mr:1,
                  height: '75vh',
                  overflowY: 'scroll',
                  "&::-webkit-scrollbar": {
                      width: 7,pr:2
                      // bgcolor: ''
                  },
                  "&::-webkit-scrollbar-thumb": {
                      backgroundColor: 'lightgray',
                      outline: "1px solid lightgray",
                      borderRadius: 4
                  }
              }}
        >

            <Grid container width={'100%'} justifyContent={'space-between'}>
                <Grid container direction={'column'} width={'48%'} gap={1}>
                    <Typography fontSize={14} fontWeight={600}>
                        Price Model Details
                    </Typography>

                    <TextField
                        value={newModelName}
                        onChange={(e) => setNewModelName(e.target.value)}
                        label="Name"
                        size="small"
                    />
                </Grid>

                <Grid container direction={'column'} width={'48%'} gap={1}>
                    <Typography fontSize={14} fontWeight={600}>
                        Price Model Type
                    </Typography>

                    <ToggleButtonGroup
                        size='small'
                        color="primary"
                        value={priceModelType}
                        exclusive
                        onChange={(e) => setPriceModelType(e.target.value)}
                        aria-label="Platform"
                        sx={{width:'100%'}}
                    >
                        <ToggleButton
                            value="CUSTOM_PRICING"
                            sx={{
                                textTransform: 'none',
                                borderRightColor:'primary.main',
                                '&.Mui-selected': {
                                    borderColor: 'primary.main',
                                },
                                width:'50%'
                            }}
                        >
                            Custom
                        </ToggleButton>
                        <ToggleButton value="TIERED_PRICING" sx={{
                            textTransform: 'none',
                            '&.Mui-selected': {
                                borderColor: 'primary.main',
                            },
                            width:'50%'
                        }}>
                            Tiered
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                {priceModelType === "TIERED_PRICING" &&
                    <Alert severity={'info'} sx={{fontSize: 12, mt:1 }}>
                        Only 1 Price Metric with only 1 Non-Periodic Price Unit allowed in Tiered Pricing Model.
                        Periodic Price Units in PriceOps are <b>month</b> and <b>year</b>.
                    </Alert>
                }

            </Grid>

            {priceMetrics && <AddPriceModel priceMetrics={priceMetrics} onRemoveMetric={(metric) => { removePriceMetric(metric) }}></AddPriceModel>}

            {loading && <Box width="100%" mb={1}><LinearProgress></LinearProgress></Box>}
            {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}
            <Grid sx={{ pt: 2 }}>
                <Typography fontSize={14} fontWeight={600}>Price Metric Builder</Typography>
                <Grid sx={{ mt: 1 }}>
                    <Grid display="flex" flexWrap="wrap" key={key}>
                        {priceUnits && priceUnits.map((unit, index) => {
                            return <Box key={index} className={unit.glow === true ? "glow-shadow" : ""} sx={{ mr: 2, mb: 1 }}>
                                <PriceUnitElement key={index} unit={unit} />
                            </Box>
                        })}
                    </Grid>
                    <Box sx={{ display: "block", mt: 2, mb: 1 }}>
                        <Typography
                            sx={{ fontSize: 12, mb: 2, cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => setShowAddPriceUnit(!showAddPriceUnit)}>
                            Click to create a new price unit if you don't see it above
                        </Typography>
                        {showAddPriceUnit && <React.Fragment>
                            <Box display="inline"><CustField
                                size="small"
                                value={newPriceUnitName}
                                onChange={(e) => setNewPriceUnitName(e.target.value)}
                                label="New price unit"></CustField></Box>
                            <Box display="inline" sx={{ ml: 1 }}><CustField
                                size="small"
                                value={newPriceUnitPlural}
                                onChange={(e) => setNewPriceUnitPlural(e.target.value)}
                                label="Plural"></CustField></Box>
                            <Button disabled={!isPriceUnitValid()} sx={{ml:1}} variant={'outlined'} onClick={handleAddPriceUnit} >Add Price Unit</Button></React.Fragment>}
                    </Box>
                </Grid>
                <Grid container mb={3} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontSize={14} fontWeight={600} sx={{width:'100%', my:1}}>Price Metric Preview</Typography>
                    <Typography sx={{
                        fontSize: 12,
                        py: 1,
                        height: 20,
                        textAlign: "center", borderRadius: 2,
                        backgroundColor: "primary.background",borderColor: grey[300],
                        width: '65%'
                    }}>
                        {getReadableUnits(selectedPriceUnits)}
                    </Typography>
                    <Button sx={{ width: "30%" }}
                        variant={'outlined'}
                        disabled={selectedPriceUnits.length === 0}
                        onClick={addPriceMetric}>
                        Add Price Metric
                    </Button>
                </Grid>
            </Grid>
        </Grid >
        <Grid container justifyContent="flex-end" py={2} px={1} mx={2} borderTop={'1px solid lightgray'} width={'100%'}>
            <Button variant="outlined" onClick={createPriceModel} disabled={!isModelValid()} sx={{ height: 25 }}>
                Add Price Model - Metrics ({priceMetrics.length})</Button>
        </Grid>
    </Grid >);
}
import React from "react";
import { getCustomerSegments } from "../apis/ClientDataService";
import { Button, Grid, FormControlLabel, Checkbox, Typography, Box, InputLabel } from "@mui/material";
import { copy } from "../utils/Utils";
import { formattedLabel } from "../utils/Utils";
import { getRevRangeLabel } from "../utils/Utils";

export default function SegmentFilters(props) {
    const [, setSegments] = React.useState([]);
    const [, setSegmentData] = React.useState([]);
    const [, setSelectedSegmentName] = React.useState("");
    const [segmentFilters, setSegmentFilters] = React.useState(null);
    const [, setSegmentTypes] = React.useState([]);
    const [, setFormState] = React.useState(false);
    const [, setYears] = React.useState(null);
    const [allOptions, setAllOptions] = React.useState(null);
    const [filterTypes, setFilterTypes] = React.useState(null);
    const [key, setKey] = React.useState(1);

    const getTitleBySegment = (segmentType) => {
        return formattedLabel(segmentType);
    }
    const selectAllByType = (segmentType) => {
        const filters = copy(segmentFilters);
        filters[segmentType] = allOptions[segmentType];
        setSegmentFilters(filters);
        if (props.handleSegmentFilterChange) {
            props.handleSegmentFilterChange(filters, segmentType);
        }
    }
    const unselectAllByType = (segmentType) => {
        const filters = copy(segmentFilters);
        filters[segmentType] = [];
        setSegmentFilters(filters);
        if (props.handleSegmentFilterChange) {
            props.handleSegmentFilterChange(filters, segmentType);
        }
    }
    const handleSelection = (e, segmentType, opt) => {
        const filters = copy(segmentFilters);
        if (e.target.checked) {
            filters[segmentType].push(opt);
        } else {
            filters[segmentType] = filters[segmentType].filter(x => x !== opt);
        }
        setSegmentFilters(filters);
        if (props.handleSegmentFilterChange) {
            props.handleSegmentFilterChange(filters, segmentType);
        }
    }

    const init = (currSegName) => {
        setFormState('loading');
        getCustomerSegments((data) => {
            console.log(data);
            setSegments(data["segments"]);
            setSegmentData(data["data"]);

            const years = new Set();
            const segTypes = new Set();
            for (const [segType, dataPoints] of Object.entries(data["data"])) {

                dataPoints.forEach(x => years.add(x.year));
                segTypes.add(segType);
            }
            setYears([...years].sort());
            setSegmentTypes([...segTypes]);

            if (data["segments"].length > 0) {
                setSelectedSegmentName(currSegName);
                const segment = data["segments"].find(x => x.segment_name === currSegName);
                setSegmentFilters(segment["segment_params"]);
            }

            const allOptions = data["segments"].find(x => x.segment_name === 'All')['segment_params'];
            setAllOptions(allOptions);

            setFilterTypes(Object.keys(allOptions));
            setKey(key + 1);
            setFormState('success');
        }, (err) => {
            console.log(err.error);
            setFormState('error');
        });
    }

    React.useEffect(() => {
        init('All');
    }, []);

    return (
        <React.Fragment key={key}>
            {filterTypes && filterTypes.map((segmentType, idx) => {
                return (<Grid container item key={idx} xs={3} direction="column" sx={{ border: 1, borderRadius: 1, borderColor: "primary.groupBorder", p: 2 }}>
                    <Box>
                        <InputLabel sx={{ m: 0, p: 0, fontSize: 13 }}>{getTitleBySegment(segmentType)}</InputLabel>
                        <Button sx={{ m: 0, ml: 2, height: 20, fontSize: 12, p: 0, textTransform: 'None', float: 'right' }} onClick={() => unselectAllByType(segmentType)}>
                            Remove all
                        </Button>
                        <Button sx={{ height: 20, fontSize: 12, p: 0, m: 0, textTransform: 'None', float: 'right' }} onClick={() => selectAllByType(segmentType)}>
                            Select all
                        </Button>
                    </Box>
                    {segmentFilters && <Grid container directon="row">
                        {allOptions[segmentType].map((opt, idx) => {
                            return <Grid key={idx} item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox sx={{ p: 0, m: 0, transform: 'scale(0.7)' }}
                                            checked={segmentFilters[segmentType].includes(opt)}
                                            onChange={(e) => { handleSelection(e, segmentType, opt) }}
                                        />}
                                    label={<Typography sx={{ fontSize: 12 }}>
                                        {segmentType !== 'revenue' ? opt : getRevRangeLabel(opt)}
                                    </Typography>} />
                            </Grid>
                        })}
                    </Grid>}
                </Grid>)
            })}
        </React.Fragment >);
}
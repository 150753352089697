import { Grid, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import PricingGraph from "components/PricingGraph";
import PricePointTotals from "pages/scenarios/price-modelling/components/PricePointTotals";
import PricingResultDataContext from "pages/scenarios/price-modelling/PricingResultDataContext";
import PricingErrorPage from "pages/scenarios/PricingErrorPage";
import React from "react";
import { currencyFormatter } from "utils/Utils";
import PricePointCompact from "../components/PricePointCompact";
import SegmentStrategyElement from "./SegmentStrategyElement";

export default function MarketBackTab() {
  const [modelType, setModelType] = React.useState("revenue");
  const { pricingResult } = React.useContext(PricingResultDataContext);

  const extractModelData = () => {
    return pricingResult ? pricingResult?.["optimization_models"]?.find(
      x => x["pricing_calc_options"]?.["compute_mode"] === "MARKET_BACK" &&
        x["pricing_calc_options"]?.["optimizer_type"] === modelType.toUpperCase()) : undefined
  }

  const modelData = extractModelData();

  const [key, setKey] = React.useState(1);

  const styles = {
    toggleButton: {
      height: 25,
      textTransform: "none"
    }
  }

  const getGraphValues = () => {
    return modelData["grid_points"].map((point, idx) => ({ "y": point[modelType]["value"], "x": idx }))
  }

  const handleProfitRevenueToggle = (event) => {
    setModelType(event.target.value);
    setKey(key + 1);
  };

  const GridPointTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const currGridPoint = modelData["grid_points"][parseInt(label)];
      return (
        <Grid container item sx={{ maxWidth: 400 }}>
          <PricePointTotals pricePoint={currGridPoint} variant="xs"></PricePointTotals>
          <Grid sx={{ width: "100%" }} container item justifyContent="center">
            <PricePointCompact data={currGridPoint}></PricePointCompact>
          </Grid>
        </Grid >)
    }
  }

  return (<React.Fragment>
    {pricingResult && pricingResult["error_code"] && <PricingErrorPage error="Pricing error" />}
    {pricingResult && !pricingResult["error_code"] && <Grid key={key} item xs={12}>
      {modelData && <PricePointTotals pricePoint={modelData["optimum_point"]} />}
      <Grid container item xs={12} sx={{ pt: 2 }}>
        <Grid container item xs={12} sx={{ boxShadow: 1, borderRadius: 2, pt: 2, backgroundColor: "primary.bg2" }}>
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Tooltip title="Switch between revenue and profit models" placement="bottom">
              <ToggleButtonGroup
                value={modelType}
                exclusive
                onChange={handleProfitRevenueToggle}
                sx={{ borderRadius: 20 }}
                aria-label="text alignment"
              >
                <ToggleButton value="revenue" sx={styles.toggleButton}>
                  Revenue
                </ToggleButton>
                <ToggleButton value="profit" sx={styles.toggleButton}>
                  Profit
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
          </Grid>
          <Grid container item flex="flex-start" justifyContent="center" gap={3}>
            {modelData && modelData["optimum_point"]["params"]["attributes"].map((segmentPrice, idx) => {
              return <SegmentStrategyElement key={idx} segmentPrice={segmentPrice} inputElement={
                <React.Fragment />
              } />
            })}
          </Grid>
        </Grid>
        {modelData && <Grid container item xs={12} sx={{ mt: 2, pt: 4, borderRadius: 2, boxShadow: 1, backgroundColor: "primary.bg2" }}>
          <PricingGraph
            // xReference={1}
            // yReference={scenario.target_revenue}
            data={getGraphValues()}
            xAxisDataKey="x"
            xLabel="Price"
            legend={modelType === "revenue" ? "Revenue" : "Profit"}
            yAxisDataKey="y"
            yLabel={modelType === "revenue" ? "Revenue" : "Profit"}
            yFormatter={currencyFormatter}
            tooltipContent={(<GridPointTooltip />)}
          // xFormatter={xFormatter}
          // yRange={[0, getMaxRevenueDataPoint()]}
          />
        </Grid>}
      </Grid>
    </Grid>}
  </React.Fragment>)
}

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert, Backdrop,
    Button, Checkbox,
    Chip, CircularProgress, Dialog, Divider, Drawer, FormControl, FormControlLabel, FormGroup, FormLabel,
    Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup,
    Select, Slider, Snackbar,
    TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, {useContext, useState, useRef, useEffect} from "react";
import { v4 as uuidv4 } from "uuid";
import {addCoupon, addProduct, updateProduct} from "../../apis/ClientDataService";
import CustDialogTitle from "../../components/CustDialogTitle";
import { getCurrentOrgId, getOrgFromLocalStore } from "../../state/state";
import CreatePriceModelDefinition from "../scenarios/price-modelling/components/CreatePriceModelDefinition";
import { getReadablePriceMetric } from "../scenarios/price-modelling/components/PriceModelDefinitions";
import PriceModelsDataContext from "../scenarios/PriceModelsDataContext";
import ScenarioDataContext from "../scenarios/ScenarioDataContext";
import {CouponContext, ProductContext} from "./PackagingFlow";
import PriceAdd from "./PriceAdd";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useErrorContext } from "state/ErrorDialogContext";
import Link from "@mui/material/Link";
import OrganizationContext from "../../state/OrganizationContext";
import {Box} from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import ProductActions from "./ProductActions";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CouponCodeAdd from "./CouponCodeAdd";
import DoneIcon from "@mui/icons-material/Done";

const durations = ['Forever', 'Once', 'Multiple Months'];

export default function CouponActions(props) {

    const { scenario, updateScenario } = useContext(ScenarioDataContext);
    const { products, updateProducts } = useContext(ProductContext);
    const { coupons, updateCoupons } = useContext(CouponContext);
    const { loadErrorDialog } = useErrorContext();
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [duration, setDuration] = useState('');
    const [durationInMonths, setDurationInMonths] = useState('');
    const [redemptionTimes, setRedemptionTimes] = useState('');
    const [expireTimeStamp, setExpireTimeStamp] = useState(dayjs('2024-12-31T23:59').unix());
    const [isDialogOpen, setIsDialogOpen] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [snackbar, setSnackbar] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);



    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: '#f2f2f2',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(0),
    }));

    const handleCloseSnackbar = () => setSnackbar(null);

    const [currSelectedProduct, setCurrSelectedProduct] = React.useState("");

    const [selectedProducts, setSelectedProducts] = React.useState([]);
    const [searchResults, setSearchResults] = React.useState(
        products.filter(product => product.payment_integration.gateway_type === 'Stripe')
    );

    const handleAddProduct = (result) => {
        // console.log(result)
        let value = result
        if (typeof value !== 'string') {
            if (value) {
                setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, value]);
                setSearchResults((prevSearchResults) =>
                    prevSearchResults.filter((product) => product.name !== value.name)
                );
                setCurrSelectedProduct(value.name)
            }
        }
    };

    const handleDeleteProduct = (productToDelete) => () => {
        setSelectedProducts((prevSelectedProducts) =>
            prevSelectedProducts.filter((product) => product.name !== productToDelete.name)
        );
        setSearchResults((prevSearchResults) => [...prevSearchResults, productToDelete]);
    };

    const [activeProduct, setActiveProduct] = useState();
    const [snackNotif, setSnackNotif] = useState("");

    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }

    const [openAdd, setOpenAdd] = useState(false);
    const handleCreateProductClick = () => {
        setOpenAdd(!openAdd);
    };

    const [openEdit, setOpenEdit] = useState(false);
    const handleUpdateProductClick = () => {
        setOpenEdit(!openEdit);
    };

    const [isFocused, setIsFocused] = useState(
        {
            percentage: props.mode ==='View'? true : false,
            times: props.mode ==='View' ? true : false,
            months: props.mode ==='View' ? true : false,
        });

    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(
        {
            maxRedemptions: false,
            redeemBy: false,
            firstTransaction: false,
        });

    const [dateValue, setDateValue] = useState(dayjs('2024-12-31T23:59'));

    const handleCouponCodeAddDialog = () => {
        setIsDialogOpen("couponCodeAdd")
    }

    const handleCouponCodeDialogClose = () => {
        setIsDialogOpen("")
    }

    const handleDiscountPercentageChange = (event) => {
        let curr = event.target.value;
        if(curr.length === 0 ){
            setDiscountPercentage("")
        }
        else{
            let numCurr = Number(curr);
            if (!isNaN(numCurr) && numCurr > 0 && numCurr <= 100) {
                setDiscountPercentage(numCurr);
            }
        }
    }

    const handleDurationInMonthsChange = (event) => {
        let curr = event.target.value;
        if(curr.length === 0 ){
            setDurationInMonths("")
        }
        else{
            let numCurr = Number(curr);
            if (!isNaN(numCurr) && numCurr > 0 && numCurr <= 999999) {
                setDurationInMonths(numCurr);
            }
        }
    }

    const handleCouponCodeChange = (event) => {
        let curr = event.target.value;

        if(curr.length === 0 ){
            setCode('')
        }
        else
        {
            // Check if the string is alphanumeric (contains only letters and numbers)
            const isAlphanumeric = /^[a-z0-9]+$/i.test(curr);

            // Check if the string does not contain any spaces
            const hasNoSpaces = !(/\s/.test(curr));

            if (isAlphanumeric && hasNoSpaces) {
                setCode(curr.toUpperCase());
            }
        }
    }

    const handleRedemptionTimesChange= (event) => {
        let curr = event.target.value;
        if(curr.length === 0 ){
            setRedemptionTimes("")
        }
        else{
            let numCurr = Number(curr);
            if (!isNaN(numCurr) && numCurr > 0 && numCurr <= 999999999) {
                setRedemptionTimes(numCurr);
            }
        }
    }

    useEffect(() => {
        if (snackNotif === "addProduct") {
            // Find the new product
            const newProduct = products.find(product =>
                !selectedProducts.some(selectedProduct => selectedProduct.id === product.id) &&
                !searchResults.some(searchResult => searchResult.id === product.id)
            );

            console.log(newProduct)

            // Add the new product to selectedProducts Only if product has stripe gateway
            if (newProduct.payment_integration.gateway_type === 'Stripe') {
                setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, newProduct]);
            }
        }
        if (snackNotif === "updateProduct") {
            // Update selectedProducts
            setSelectedProducts(prevSelectedProducts => {
                return prevSelectedProducts
                    .map(selectedProduct => {
                        return products.find(product => product.id === selectedProduct.id) || null;
                    })
                    .filter(product => product !== null);
            });

            // Update searchResults
            setSearchResults(products.filter(product => !selectedProducts.some(selectedProduct => selectedProduct.id === product.id)));
        }
    }, [snackNotif]);


    const handleAddCoupon = () => {
        setLoading(true)
        const newCoupon = {
            "name": name.trim(),
            "code": code,
            "client_id": getCurrentOrgId(),
            "scenario_id": scenario.id,
            "discount_percentage": discountPercentage,
            "duration": duration,
            "selected_products_ids": selectedProducts.map(product => product.id),
        }

        duration === "Multiple Months" && (newCoupon["duration_in_months"] = durationInMonths);
        isCheckBoxChecked['maxRedemptions'] && (newCoupon["max_redemptions"] = redemptionTimes);
        isCheckBoxChecked['redeemBy'] && (newCoupon["redeem_by"] = expireTimeStamp);
        isCheckBoxChecked["firstTransaction"] && (newCoupon["first_transaction"] = isCheckBoxChecked["firstTransaction"]);

        console.log(newCoupon, "SENDING !!!!")
        addCoupon(JSON.parse(JSON.stringify(newCoupon)), (data) => {
            console.log(data, "WORKING !!!!")
            updateCoupons([...coupons,data])
            props.handleSnackNotif("addCoupon")
            props.closeActionsCoupon()
            setLoading(false)
        }, (err) => {
            setLoading(false);
            console.log(err);
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to add coupon at the moment" });
        })
    }

    const validateForm = () => {
        return (
            name.trim() &&
            code &&
            scenario.id &&
            discountPercentage &&
            (duration === 'Multiple Months' ? durationInMonths : duration) &&
            selectedProducts.length > 0 &&
            (isCheckBoxChecked["maxRedemptions"] ? redemptionTimes : true) &&
            (isCheckBoxChecked["redeemBy"] ? expireTimeStamp : true)
        )
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container sx={{ width: '43vw', px:3, pt: 2 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container justifyContent='space-between' alignItems='center' pb={1}>
                <Grid container item width={'40%'} gap={0.5}>
                    <Typography sx={{
                        color: "primary.main",
                        fontSize: 24,
                        fontWeight: "bold",
                        pl: 1
                    }}>
                        {props.mode} Coupon
                    </Typography>
                </Grid>

                <Grid item container width={'50%'} justifyContent={'flex-end'}>

                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{ mt: 0 }}
                        onClick={props.closeActionsCoupon}
                    >
                        {props.mode === "Add" && 'Cancel'}
                        {props.mode === "View" && 'Close'}
                    </Button>

                    {props.mode === "Add" &&
                        <Button
                        disabled={!validateForm()}
                        variant="outlined"
                        size="medium"
                        onClick={handleAddCoupon}
                        sx={{ ml: 1, mt: 0 }}
                        >
                            Add Coupon
                        </Button>
                    }
                </Grid>
            </Grid>
            <Grid container item sx={{ borderTop: '1px solid lightgray', p: 2 }}
                  justifyContent='space-between'>
                <Grid container direction='column'
                      wrap="nowrap"
                      sx={{
                          width: '100%',
                          maxHeight: '83vh',
                          overflowY: 'scroll',
                          pr: 2,
                          "&::-webkit-scrollbar": {
                              width: 7,
                              // bgcolor: ''
                          },
                          "&::-webkit-scrollbar-thumb": {
                              backgroundColor: 'lightgray',
                              outline: "1px solid lightgray",
                              borderRadius: 4
                          }
                      }}
                >
                    <Typography sx={{ fontWeight: 600, fontSize: 14, pb: 2 }}>
                        Coupon Details
                    </Typography>
                    <TextField
                        size='small'
                        label="Coupon Name"
                        variant="outlined"
                        value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            pb: 1
                        }}
                        // helperText="Some important text"
                    />
                    {props.mode === 'Add' &&
                        <Alert severity="info" sx={{ fontSize: 12, mb: 1 }}>
                            This name should be unique in Coupons List.
                        </Alert>
                    }

                    <TextField
                        size='small'
                        label="Coupon Code"
                        variant="outlined"
                        value={props.mode === 'View' ? props.coupon.code : code}
                        readOnly={props.mode === 'View'}
                        onChange={handleCouponCodeChange}
                        sx={{
                            my: 1
                        }}
                        // helperText="Some important text"
                    />

                    {props.mode === 'Add' &&
                        <Alert severity="info" sx={{ fontSize: 12, mb: 1 }}>
                            This code should be unique amongst all coupon codes across
                            all of projects and scenarios in {getOrgFromLocalStore().name}.
                        </Alert>
                    }

                    <TextField
                        size='small'
                        label="Discount Percentage"
                        variant="outlined"
                        value={props.mode === 'View' ? props.coupon.discount_percentage : discountPercentage}
                        readOnly={props.mode === 'View'}
                        onChange={handleDiscountPercentageChange}
                        onFocus={() => setIsFocused({ ...isFocused, percentage : true })}
                        onBlur={() => setIsFocused({ ...isFocused, percentage : false })}
                        InputProps={{
                            endAdornment: (isFocused["percentage"] || discountPercentage) ? (
                                <InputAdornment position="end"> % </InputAdornment>
                            ) : null
                        }}
                        sx={{mt:1}}
                    />

                    <TextField
                        select
                        sx={{
                            mt:2
                        }}
                        size={'small'}
                        label="Coupon Duration"
                        value={props.mode === 'View' ? props.coupon.duration : duration}
                        readOnly={props.mode === 'View'}
                        onChange={(e)=>setDuration(e.target.value)}
                    >
                        {durations.map((dur) => (
                            <MenuItem key={dur} value={dur}>
                                {dur}
                            </MenuItem>
                        ))}
                    </TextField>

                    {(duration === 'Multiple Months' || props.coupon?.duration === 'Multiple Months') &&
                        <TextField
                            size='small'
                            label="Number of months"
                            variant="outlined"
                            value={props.mode === 'View' ? props.coupon.duration_in_months : durationInMonths}
                            readOnly={props.mode === 'View'}
                            onChange={handleDurationInMonthsChange}
                            sx={{
                                mt: 2
                            }}
                            onFocus={() => setIsFocused({ ...isFocused, months : true })}
                            onBlur={() => setIsFocused({ ...isFocused, months : false })}
                            InputProps={{
                                endAdornment: (isFocused["months"] || durationInMonths) ? (
                                    <InputAdornment position="end"> months </InputAdornment>
                                ) : null
                            }}
                        />
                    }

                    { props.mode === 'Add' &&
                        <>

                            {/*<Typography sx={{fontWeight:600,fontSize:14,py:2}}>*/}
                            {/*    Select products to apply coupon to :*/}
                            {/*</Typography>*/}
                            {/*<ToggleButtonGroup*/}
                            {/*    size='small'*/}
                            {/*    color="primary"*/}
                            {/*    value={couponApplyType}*/}
                            {/*    exclusive*/}
                            {/*    onChange={handleCouponApplyTypeChange}*/}
                            {/*    aria-label="Platform"*/}
                            {/*    sx={{}}*/}
                            {/*>*/}
                            {/*    <ToggleButton*/}
                            {/*        value="all"*/}
                            {/*        sx={{*/}
                            {/*            width:'50%',*/}
                            {/*            textTransform: 'none',*/}
                            {/*            borderRightColor:'primary.main',*/}
                            {/*            '&.Mui-selected': {*/}
                            {/*                borderColor: 'primary.main',*/}
                            {/*            },*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        All products*/}
                            {/*    </ToggleButton>*/}
                            {/*    <ToggleButton*/}
                            {/*        value="specific"*/}
                            {/*        sx={{*/}
                            {/*        width:'50%',*/}
                            {/*        textTransform: 'none',*/}
                            {/*        '&.Mui-selected': {*/}
                            {/*            borderColor: 'primary.main',*/}
                            {/*        },*/}
                            {/*    }}>*/}
                            {/*        Specific products*/}
                            {/*    </ToggleButton>*/}
                            {/*</ToggleButtonGroup>*/}
                            {/*{ couponApplyType === 'specific' &&*/}
                            {/*    <>*/}
                            <Typography sx={{ fontWeight: 600, fontSize: 14 ,py:2}}>
                                Product Selection
                            </Typography>
                            <TextField
                                width={'100%'}
                                select
                                size='small'
                                labelId="product-select-label"
                                id="product-select"
                                label="Select Product"
                                value={currSelectedProduct}
                                helperText={"Only Stripe integrated products allowed for selection"}
                            >
                                <MenuItem
                                    onClick={handleCreateProductClick}
                                    value={'ADD_PRODUCT'}
                                    sx={{
                                        '&.MuiMenuItem-root': {
                                            backgroundColor: 'primary.main', color: 'white',
                                        },
                                    }}
                                >
                                    <Grid container >
                                        <AddIcon mr={1} />
                                        <Typography pl={1}>Add Product</Typography>
                                    </Grid>
                                </MenuItem>
                                {searchResults.map((result) => (
                                    <MenuItem
                                        value={result}
                                        onClick={() => handleAddProduct(result)}
                                    >
                                        {result.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {selectedProducts.length ?
                                <Grid container item gap={1} mt={1}>
                                    {selectedProducts.map((product) => (
                                        <Chip
                                            key={product.name}
                                            label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography pr={2}>
                                                        {product.name}
                                                    </Typography>
                                                    <EditIcon fontSize="small"
                                                              onClick={(e) => {
                                                                  setActiveProduct(product)
                                                                  handleUpdateProductClick()
                                                              }}
                                                              sx={{
                                                                  color: '#aeaeae',
                                                                  cursor: 'pointer',
                                                                  '&:hover': {
                                                                      color: '#818589'
                                                                  }
                                                              }}
                                                    />
                                                </Box>
                                            }
                                            deleteIcon={<DeleteIcon sx={{
                                                '&:hover': {
                                                    color: '#818589'
                                                }
                                            }} />}
                                            onDelete={handleDeleteProduct(product)}
                                        />
                                    ))}
                                </Grid>
                                :
                                <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>
                                    Select your first product from the above dropdown menu.
                                </Alert>
                            }
                            {/*    </>*/}
                            {/*}*/}
                            <Typography sx={{ fontWeight: 600, fontSize: 14 ,py:2}}>
                                Coupon Settings
                            </Typography>
                            <Grid container justifyContent={'space-between'}>
                                <FormControlLabel control={
                                    <Checkbox
                                        size={'small'}
                                        onChange={(e) => {
                                            setIsCheckBoxChecked({...isCheckBoxChecked, maxRedemptions: e.target.checked})
                                        }}
                                    />
                                } label={
                                    <Typography fontSize={12}>Maximum Redemptions</Typography>
                                } />
                                <TextField
                                    size='small'
                                    label="Number of times"
                                    variant="outlined"
                                    sx={{width:252}}
                                    value={redemptionTimes}
                                    onChange={handleRedemptionTimesChange}
                                    disabled={!isCheckBoxChecked["maxRedemptions"]}
                                    onFocus={() => setIsFocused({ ...isFocused, times : true })}
                                    onBlur={() => setIsFocused({ ...isFocused, times : false })}
                                    InputProps={{
                                        endAdornment: (isFocused["times"] || redemptionTimes) ? (
                                            <InputAdornment position="end"> times </InputAdornment>
                                        ) : null
                                    }}
                                />
                            </Grid>
                            <Grid container justifyContent={'space-between'} pt={2}>
                                <FormControlLabel control={
                                    <Checkbox
                                        size={'small'}
                                        onChange={(e) => {
                                            setIsCheckBoxChecked({...isCheckBoxChecked, redeemBy: e.target.checked})
                                        }}
                                    />
                                } label={
                                    <Typography fontSize={12}>Set Expiration</Typography>
                                } />
                                <DateTimePicker
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            disabled:!isCheckBoxChecked["redeemBy"]
                                        }
                                    }}
                                    label="Coupon expires at"
                                    value={dateValue}
                                    format="lll"
                                    onChange={(newDateValue) => {
                                        setDateValue(newDateValue)
                                        setExpireTimeStamp(dayjs(newDateValue).unix())
                                    }}
                                />
                            </Grid>
                            <Grid container pt={2}>
                                <FormControlLabel control={
                                    <Checkbox
                                        size={'small'}
                                        onChange={(e) => {
                                            setIsCheckBoxChecked({...isCheckBoxChecked, firstTransaction: e.target.checked})
                                        }}
                                    />} label={
                                    <Typography fontSize={12} sx={{width:'100%'}}>Eligible for first-time order only</Typography>
                                } />
                            </Grid>
                        </>
                    }

                    {props.mode === 'View' &&
                        <>
                            <Typography sx={{ fontWeight: 600, fontSize: 14 ,pt:2}}>
                                Selected Products
                            </Typography>
                            <Grid container item gap={1} mt={1}>
                                {products.map((product) => {
                                    if (props.coupon.selected_products_ids.includes(product.id)) {
                                        return (
                                            <Chip
                                                key={product.name}
                                                label={product.name}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </Grid>

                            <Typography sx={{ fontWeight: 600, fontSize: 14 ,pt:2}}>
                                Coupon Specifications
                            </Typography>
                            <Grid container p={1.2} rowGap={1} direction={'column'}>
                                <FormControlLabel control={
                                    <DoneIcon
                                        sx={{
                                            fontSize: 14,
                                            color: 'green',
                                            pr:0.5
                                        }}
                                    />
                                } label={
                                    <Typography fontSize={12}>
                                        Expires at {props.coupon.redeem_by ?
                                        `${dayjs.unix(props.coupon.redeem_by).format('LT LL')}` :
                                        'Coupon deletion'}
                                    </Typography>
                                } />

                                <FormControlLabel control={
                                    <DoneIcon
                                        sx={{
                                            fontSize: 14,
                                            color: 'green',
                                            pr:0.5
                                        }}
                                    />
                                } label={
                                    <Typography fontSize={12}>
                                        Can be redeemed at {props.coupon.max_redemptions ?
                                            `max ${props.coupon.max_redemptions} times` :
                                            'as many times as possible'}
                                    </Typography>
                                } />

                                {props.coupon.first_transaction &&
                                    <FormControlLabel control={
                                        <DoneIcon
                                            sx={{
                                                fontSize: 14,
                                                color: 'green',
                                                pr:0.5
                                            }}
                                        />
                                    } label={
                                        <Typography fontSize={12}>
                                            Is eligible for first-time order only
                                        </Typography>
                                    } />
                                }
                            </Grid>
                        </>
                    }
                </Grid>

                {/*<Grid container direction='column' width='58.5%' alignItems={'space-between'} mt={-2}>*/}
                {/*    <Grid container direction={'column'}>*/}
                {/*        /!*<Typography sx={{ fontWeight: 600, fontSize: 14, pb: 1.5 }}>*!/*/}
                {/*        /!*    Price / Usage Details*!/*/}
                {/*        /!*</Typography>*!/*/}
                {/*        <Accordion*/}
                {/*            expandIcon={''}*/}
                {/*            expanded={expanded === 'panel1'}*/}
                {/*        >*/}
                {/*            <AccordionSummary*/}
                {/*                aria-controls="panel1d-content"*/}
                {/*                id="panel1d-header"*/}
                {/*            >*/}
                {/*                <Grid container item sx={{ justifyContent: 'space-between', alignItems: 'center' }} >*/}
                {/*                    <Typography fontSize={14} fontWeight={600}>*/}
                {/*                        Promotion Codes*/}
                {/*                    </Typography>*/}
                {/*                    <Grid item alignItem={'center'}>*/}
                {/*                        <Tooltip title="Add Coupon Code">*/}
                {/*                            /!*<Fab color="primary" aria-label="add" size="small" >*!/*/}
                {/*                            <AddIcon*/}
                {/*                                onClick={handleCouponCodeAddDialog}*/}
                {/*                                sx={{*/}
                {/*                                    // transition: 'background-color 0.3s ease',*/}
                {/*                                    '&:hover': {*/}
                {/*                                        backgroundColor: 'white',*/}
                {/*                                        borderRadius: 4*/}
                {/*                                    },*/}
                {/*                                }}*/}
                {/*                            />*/}
                {/*                            /!*</Fab>*!/*/}
                {/*                        </Tooltip>*/}
                {/*                        <CouponCodeAdd*/}
                {/*                            isDialogOpen={isDialogOpen}*/}
                {/*                            handleCouponCodeDialogClose={handleCouponCodeDialogClose}*/}
                {/*                            // handleAddPrice={handleAddPrice}*/}
                {/*                            // prices={prices}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </AccordionSummary>*/}
                {/*            <AccordionDetails>*/}
                {/*                <Grid container item sx={{ height: '63vh', bgcolor: 'white', }}>*/}
                {/*                    {false ?*/}
                {/*                        <DataGrid*/}
                {/*                            sx={{*/}
                {/*                                // disable cell selection style*/}
                {/*                                '.MuiDataGrid-cell:focus': {*/}
                {/*                                    outline: 'none'*/}
                {/*                                },*/}
                {/*                                // pointer cursor on ALL rows*/}
                {/*                                '& .MuiDataGrid-row:hover': {*/}
                {/*                                    cursor: 'pointer'*/}
                {/*                                },*/}
                {/*                                '& .MuiDataGrid-cell': {*/}
                {/*                                    fontSize: 12,*/}
                {/*                                },*/}
                {/*                                width: '40vw'*/}
                {/*                            }}*/}
                {/*                            rowHeight={40}*/}
                {/*                            // rows={prices}*/}
                {/*                            // loading={loading}*/}
                {/*                            // columns={columns1}*/}
                {/*                            initialState={{*/}
                {/*                                pagination: {*/}
                {/*                                    paginationModel: { page: 0, pageSize: 100 },*/}
                {/*                                },*/}
                {/*                            }}*/}
                {/*                            // onRowClick={handleRowClick}*/}
                {/*                            pageSizeOptions={[5, 10]}*/}
                {/*                            // onCellClick={handleRowHover}*/}
                {/*                            // checkboxSelection*/}
                {/*                        />*/}
                {/*                        :*/}
                {/*                        <Grid container*/}
                {/*                              justifyContent={'center'}*/}
                {/*                              alignItems={'center'}*/}
                {/*                              height={'100%'}*/}
                {/*                        >*/}
                {/*                            {false ?*/}
                {/*                                <Typography color={'primary.greyText'}>*/}
                {/*                                    Add your first coupon code by clicking on the + icon above.*/}
                {/*                                </Typography>*/}
                {/*                                :*/}
                {/*                                <Typography color={'primary.greyText'}>*/}
                {/*                                    Add your first coupon code by clicking on the + icon above.*/}
                {/*                                </Typography>*/}
                {/*                            }*/}
                {/*                        </Grid>*/}
                {/*                    }*/}
                {/*                </Grid>*/}
                {/*            </AccordionDetails>*/}
                {/*        </Accordion>*/}

                {/*        <Alert severity="info" sx={{ fontSize: 12, mt: 1.5 }}>*/}
                {/*            Every coupon code added to the promotion code table above should be unique.*/}
                {/*        </Alert>*/}

                {/*        */}

                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>

            {/*CREATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openAdd} onClose={() => setOpenAdd(false)}>
                <ProductActions
                    mode={'Add'}
                    closeActionsProduct={handleCreateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*UPDATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
                <ProductActions
                    mode={'Edit'}
                    product={activeProduct}
                    closeActionsProduct={handleUpdateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*ADD PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "addProduct"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product added successfully!
                </Alert>
            </Snackbar>

            {/*UPDATE PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "updateProduct"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product updated successfully!
                </Alert>
            </Snackbar>
        </Grid>
        </LocalizationProvider>
    )
}
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import DashboardCustomizeSharpIcon from '@mui/icons-material/DashboardCustomizeSharp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, FormControl,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    Step,
    StepLabel,
    Stepper,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    Drawer
} from "@mui/material";
import { grey } from '@mui/material/colors';
import { getScenario, getScenarioResult, getScenarios, listPriceModels, listProjects, runWorkBenchFlatPricing } from "apis/ClientDataService";
import CustDialogTitle from 'components/CustDialogTitle';
import UnderConstruction from 'components/UnderConstruction';
import "index.css";
import AddPriceModel from 'pages/scenarios/price-modelling/components/CreatePriceModelDefinition';
import HierarchyBreadCrumbs from 'pages/scenarios/price-modelling/components/HierarchyBreadCrumbs';
import PriceModelDefinitions from 'pages/scenarios/price-modelling/components/PriceModelDefinitions';
import PricingResultComparisonView from 'pages/scenarios/price-modelling/PricingResultComparisonView';
import PricingResultDataContext from 'pages/scenarios/price-modelling/PricingResultDataContext';
import PricingResultTabs from 'pages/scenarios/price-modelling/strategies/PricingResultTabs';
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useErrorContext } from 'state/ErrorDialogContext';
import ProjectContext from 'state/ProjectContext';
import { copy, idString } from "utils/Utils";
import PackagingFlow from "../packaging/PackagingFlow";
import FinalizePriceBar from 'pages/scenarios/price-modelling/strategies/FinalizePriceBar';
import PriceModelsDataContext from './PriceModelsDataContext';
import ScenarioDataContext from './ScenarioDataContext';
import ScenarioInputGrid from "./ScenarioInputGrid";
import ScenarioUIStateContext from './ScenarioUIStateContext';
import BottomBar from './price-modelling/components/BottomBar';

export default function Scenario(props) {
    const { scenarioId, step, params } = useParams();
    const [scenario, setScenario] = React.useState(null);
    const { loadErrorDialog } = useErrorContext();

    const location = useLocation();

    const queryParams = new URLSearchParams(params);

    // Access specific query parameters
    const mid = queryParams.get('mid');
    const pt = queryParams.get('pt');
    const mt = queryParams.get('mt');

    const [loading, setLoading] = React.useState(false);
    // const [switchScenario, setSwitchScenario] = React.useState(false);
    const [listKey, setListKey] = React.useState(1);
    const [modelsKey, setModelsKey] = React.useState(1);
    const [pricingResult, setPricingResult] = React.useState(null);
    const [scenarioResult, setScenarioResult] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);

    const [workbenchLoader, setWorkbenchLoader] = React.useState(null);

    const [showInputs, setShowInputs] = React.useState(false);
    const [openCreateModelDialog, setOpenCreateModelDialog] = React.useState(false);
    const [openModelsDialog, setOpenModelsDialog] = React.useState(false);

    const [priceModels, setPriceModels] = React.useState(null);

    const [scenarios, setScenarios] = React.useState([]);
    const priceModelsLoaderGraphic = "all-models-loader";

    const [comparisonView, setComparisonView] = React.useState(false);

    const initialState = {
        step: step,
        tab: mt ?? "market_back",
        currentPriceModelId: mid ?? null,
        packagingTab: pt ?? "design"
    }

    const [scenarioUIState, setScenarioUIState] = React.useState(initialState);

    const updateScenarioUIState = (state) => {
        setScenarioUIState({ ...state });

        if (scenarioId && state.step) {
            const url = '/scenarios/' + scenarioId + '/' + state.step
                + (
                    state.step === "pricemodelling" ? '/mt=' + state.tab + "&mid=" + state.currentPriceModelId :
                        state.step === "packaging" ? '/pt=' + state.packagingTab : "")
            navigate(url, { replace: true });
            navigate(url);
        }
    }

    const toggleInputs = () => {
        setShowInputs(!showInputs);
    };

    const { globalProject, updateGlobalProject } = React.useContext(ProjectContext);

    const setWorkflowStep = (scenario) => {
        const steps = scenario?.workspaces?.map(x => idString(x));
        const s = { ...scenarioUIState };
        if (!s.step) {
            s.step = steps[0];
        }

        const activeIdx = steps?.indexOf(s.step);
        setActiveStep(activeIdx === -1 ? 0 : activeIdx);
        updateScenarioUIState(s);
    }

    React.useEffect(() => {
        // fetch scenario
        setLoading(true);
        const loadData = async () => {
            const result = {};
            const scenarioCall = getScenario(scenarioId, (scenario) => {
                // updateOrganization(org);
                if (scenario.workspaces.length === 0) {
                    scenario["workspaces"] = ["Price Modelling"];
                }
                if (!scenario["year"]) scenario["year"] = "FY - 2023";

                result["scenario"] = scenario;

                if (!globalProject || String(globalProject?.id ?? "") !== String(scenario.project_id)) {
                    listProjects(scenario.client_id, (projects) => {
                        updateGlobalProject(projects.find(x => String(x.id) === String(scenario.project_id)));
                    }, (err) => {
                    });
                }
            }, err => {
            });

            // fetch price model result
            setWorkbenchLoader(priceModelsLoaderGraphic);
            const scenarioResultCall = getScenarioResult(scenarioId, scenarioResult => {
                setWorkbenchLoader(null);

                result["result"] = scenarioResult;
            }, (err) => {
                setWorkbenchLoader(null);
            });

            // list price models;
            const listModelsCall = listPriceModels(models => {
                result["models"] = models;
            }, () => { });

            await Promise.all([scenarioCall, scenarioResultCall, listModelsCall])
                .then(() => {
                    try {
                        const scenario = result["scenario"];
                        const scenarioResult = result["result"];
                        const priceModels = result["models"];

                        setScenario(scenario);
                        setPriceModels(priceModels);
                        setScenarioResult(scenarioResult);

                        const currPriceModelId = scenario["price_model_ids"]?.[0] ?? null;

                        if (scenarioResult) {
                            setPricingResult(scenarioResult.find(x => String(x["price_model_id"]) === String(currPriceModelId)));
                        } else {
                            setShowInputs(true);
                        }

                        if (!initialState.step) {
                            initialState.step = scenario.workspaces.map(x => idString(x)).includes('pricemodelling') ? 'pricemodelling' : idString(scenario.workspaces[0]);
                        }
                        if (!initialState.currentPriceModelId) {
                            initialState.currentPriceModelId = currPriceModelId;
                        }

                        updateScenarioUIState({ ...initialState });
                        setWorkflowStep(scenario);
                    } catch (err) {
                        loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to load scenario at the moment", reload: true });
                    }

                    setLoading(false);
                });
        };
        loadData();

        getScenarios(data => {
            setScenarios(data);
        }, (err) => {
        });
    }, []);

    const styles = {
        imgBtn: {
            cursor: "pointer",
            maxWidth: 20,
            maxHeight: 20,
            color: "primary.main",
            float: "right",
            ml: 1
        },
        loaderText: {
            fontSize: 14,
            fontWeight: 'bold',
            mb: 2
        },
        loaderInfo: {
            fontSize: 30,
            lineHeight: "30px",
            fontWeight: "bold",
            color: "primary.main"
        },
        toolbarItem: {
            mr: 1,
            border: 1,
            // p: "3px",
            pl: "8px",
            pr: "8px",
            display: "flex",
            alignItems: "center",
            borderColor: grey[300],
            borderRadius: 1,
            height: 32,
            mb: "3px"
        }
    }

    const onRename = (sc) => {
        // const scs = copy(scenarios);
        // const idx = scs.findIndex(x => x.id === sc.id);
        // scs[idx] = sc;
        // setScenarios(scs);
        setListKey(listKey + 1);
    }

    const updateScenario = (sc, regeneratePriceModels, success, failure) => {
        setScenario(sc);
        if (regeneratePriceModels) {
            runWorkBench(sc, success, failure);
        }
    }

    const removeDeletedSegments = (scenario) => {
        const sc = copy(scenario);
        const segmentParams = sc["params"]?.["segment_params"]?.filter(x => !x["deleted"]);
        sc["params"]["segment_params"] = segmentParams;
        return sc;
    }

    const generateNowHandler = () => {
        const sc = { ...scenario };
        sc["fragment"] = "ALL";
        runWorkBench(sc);
    }

    const updateWorkbenchLoadingState = (loading) => {
        const state = { ...scenarioUIState }
        state.loading = loading;
        updateScenarioUIState(state);
    }

    const runWorkBench = (scenario, success, failure) => {
        const miniLoader = success;
        if (miniLoader) setWorkbenchLoader("minimalist"); else setWorkbenchLoader(priceModelsLoaderGraphic);
        setShowInputs(false);
        updateWorkbenchLoadingState(true);

        runWorkBenchFlatPricing(removeDeletedSegments(scenario),
            (engineResult) => {
                updateWorkbenchLoadingState(false);
                setWorkbenchLoader(null);

                setScenarioResult(engineResult);
                if (engineResult) {
                    setPricingResult(engineResult.find(x => String(x["price_model_id"]) === String(scenarioUIState.currentPriceModelId)));
                }

                if (success) {
                    // Callback for input modification on each tab.
                    success();
                } else {
                    // Refresh entire page only if callback function is not there.
                    setModelsKey(modelsKey + 1);
                    getScenario(scenarioId, (scenario) => {
                        updateScenario(scenario);
                    }, (err) => { /*ignore*/ });
                }
            },
            (err) => {
                updateWorkbenchLoadingState(false);
                if (failure) failure();
                if (err.error === 403) {
                    loadErrorDialog({ title: "Access denied", message: "You do not have access to make modifications on this scenario, please contact your org admin for access!" });
                } else {
                    loadErrorDialog({ title: "Pricing run failed", message: "The current pricing run failed, please check your inputs and try again!" });
                }
                setWorkbenchLoader(null);
            }
        );
    }

    const handlePriceModelToggle = (e) => {
        const selected = e.target.value;
        handlePriceModelChange(selected);
        if (scenarioResult) {
            const pricingByModel = scenarioResult.find(x => String(x["price_model_id"]) === String(selected));
            setPricingResult(pricingByModel);
            setModelsKey(modelsKey + 1);
        }
    }

    const handleStepSelection = (st) => {
        setActiveStep(scenario.workspaces.indexOf(st));
        const state = { ...scenarioUIState };
        state.step = idString(st);

        updateScenarioUIState(state);
    }

    const isActiveStep = (expected) => {
        const result = scenario?.workspaces?.map(x => idString(x))?.indexOf(idString(expected)) === activeStep;
        return result;
    }

    const handleNewPriceModel = (priceModel) => {
        const sc = copy(scenario);
        setPriceModels([...priceModels, priceModel]);
        sc["price_model_ids"].push(priceModel["id"]);
        updateScenario(sc, true);

        setOpenCreateModelDialog(false);
    }

    const toggleModelOnScenario = (priceModel) => {
        const priceModelId = priceModel.id;
        const sc = copy(scenario);
        if (sc["price_model_ids"].indexOf(priceModelId) < 0) {
            sc["price_model_ids"].push(priceModelId);
        } else {
            sc["price_model_ids"] = sc["price_model_ids"].filter(id => String(id) !== String(priceModelId));
        }
        updateScenario(sc, false);
    }

    const navigate = useNavigate();
    const onScenarioChange = (e) => {
        const scenarioId = e.target.value;
        navigate("/scenarios/" + scenarioId);
        window.location.reload();
    }

    const handlePriceModelChange = (currentPriceModelId) => {
        const state = { ...scenarioUIState };
        state.currentPriceModelId = currentPriceModelId;
        updateScenarioUIState(state);
    }

    const Loader = () => {
        return (<Grid container justifyContent="center" alignItems="center" height="75vh">
            <Stack alignItems="center" className="pulse" sx={{
                boxShadow: 4, p: 3, borderRadius: 2,
                width: 300, backgroundColor: "#f4f4f4",
                border: 3, borderColor: "primary.disabledBackground"
            }}>
                <Typography sx={styles.loaderText}>
                    Computing pricing for </Typography>
                <Typography sx={styles.loaderInfo}>{(scenario["price_model_ids"]?.length ?? 0)}</Typography>
                <Typography sx={styles.loaderText}>price models</Typography>
                <Typography sx={styles.loaderInfo}>{scenario["params"]?.["segment_params"]?.filter(x => !x.deleted)?.length ?? 0}</Typography>
                <Typography sx={styles.loaderText}>target segments</Typography>
                <Typography sx={styles.loaderInfo}>4</Typography>
                <Typography sx={styles.loaderText}>strategies</Typography>
                <img className="scaling-pulse"
                    src={require("../../menubar/just_logo.png")} width={100} height={50} alt="logo" />
            </Stack>
        </Grid>)
    }

    const CreatePriceModelDialog = () => {
        return (<Drawer
            anchor="right"
            open={openCreateModelDialog}
            onClose={() => setOpenCreateModelDialog(false)}>
            <CustDialogTitle onClose={() => setOpenCreateModelDialog(false)}>
                <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>
                    Define New Price Model
                </Typography>
            </CustDialogTitle>
            {/*<DialogContent>*/}
            {/*    <DialogContentText id="alert-dialog-description">*/}
                    <AddPriceModel
                        onSuccess={(priceModel) => handleNewPriceModel(priceModel)}
                        onClose={() => setOpenCreateModelDialog(false)} />
                {/*</DialogContentText>*/}
            {/*</DialogContent>*/}
            {/*<DialogActions>*/}
            {/*</DialogActions>*/}
        </Drawer>)
    }

    const AddOrRemovePriceModelDialog = () => {
        return (<Drawer
            anchor="right"
            open={openModelsDialog}
            onClose={() => setOpenModelsDialog(false)}
            maxWidth="xl">
            <CustDialogTitle onClose={() => setOpenModelsDialog(false)}>
                Manage price models and metrics
            </CustDialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid sx={{ minHeight: 350 }}>
                        <Typography sx={{ mb: 1, fontSize: 14 }}>
                            You can add/remove price models from the current scenario here
                        </Typography>
                        <Grid item container xs={12} direction="row">
                            <Typography sx={{ fontSize: 12, mt: "3px", mr: 3 }}>Models</Typography>
                            {scenario && priceModels && priceModels
                                .map((model, index) => {
                                    const name = model.name;
                                    const hasModel = scenario["price_model_ids"].indexOf(model.id) !== -1;

                                    return <Tooltip key={index} title={hasModel ? "Remove from scenario" : "Add to scenario"}>
                                        <Button
                                            startIcon={hasModel ? <RemoveCircleOutlineIcon /> : <AddIcon />}
                                            onClick={() => toggleModelOnScenario(model)}
                                            sx={{
                                                fontWeight: "bold", whiteSpace: "nowrap",
                                                textOverflow: "ellipsis", mb: 1, mr: 1,
                                                color: hasModel ? "primary.main" : "grey"
                                            }}>
                                            {name}
                                        </Button>
                                    </Tooltip>
                                }
                                )}
                        </Grid>
                        <PriceModelDefinitions hideDelete priceModels={priceModels} />
                        <DialogActions>
                            <Button onClick={() => { setOpenModelsDialog(false); updateScenario(scenario, true) }}>Done</Button>
                        </DialogActions>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Drawer>)
    }

    const [viewKey, setViewKey] = React.useState(1);

    React.useEffect(() => {
        updateScenarioUIState(initialState);
        setViewKey(viewKey + 1);
    }, [location.pathname]);

    return (
        <Grid container item xs={12} key={viewKey}>
            <HierarchyBreadCrumbs showOrg showProjects />
            {scenario &&
                <React.Fragment>
                    <Grid container borderTop={1} pb={1} borderColor="primary.groupBorder">
                        <Grid container pt={1} borderColor="primary.groupBorder">
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid display="flex" alignItems={'center'}>
                                    <Typography sx={{ fontSize: 14, color: "primary.sub", ml: 2 }}>Scenario </Typography>
                                    {scenarios && <Select
                                        size="small"
                                        sx={{ color: "primary.main", border: "none", fontWeight: "bold", fontSize: 15, ml: 1 }}
                                        inputProps={{ style: { height: 20 } }}
                                        value={scenario.id}
                                        onChange={onScenarioChange}>
                                        {scenarios.map((sc, index) => {
                                            return <MenuItem key={index} sx={{ fontSize: 14 }} value={sc.id}>{sc.scenario_name}</MenuItem>
                                        })}
                                    </Select>}
                                </Grid>
                            </Box>
                            <Grid container
                                sx={{ width: '70%', pr: 5 }}
                                alignItems={'center'}
                                justifyContent={'flex-end'}
                            >
                                {scenario && <Stepper activeStep={activeStep}>
                                    {scenario.workspaces.map((step, index) => {
                                        return <Step key={step}>
                                            <StepLabel
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => handleStepSelection(step)}>
                                                <Grid container item alignItems={'center'} gap={2}>
                                                    <Typography fontWeight={(isActiveStep(step)) ? 600 : 200} fontSize={14}>
                                                        {step === "Price Modelling" ? "Pricing Engine" : step}
                                                    </Typography>
                                                    {
                                                        step === "Price Modelling" &&
                                                        <Typography
                                                            fontSize={8}
                                                            fontWeight={600}
                                                            color={'darkgoldenrod'}
                                                            mt={-2.2}
                                                            ml={-2}
                                                        >
                                                            BETA
                                                        </Typography>
                                                    }
                                                    {/*{ index !== scenario.workspaces.length-1 &&*/}
                                                    {/*    <ArrowRightAltIcon />*/}
                                                    {/*}*/}
                                                </Grid>
                                            </StepLabel>
                                        </Step>
                                    })}
                                </Stepper>}
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            <Grid container borderBottom={1} borderTop={1} borderColor="primary.groupBorder" direction="row">
                {
                    scenario && isActiveStep("Willingness to pay") &&
                    <Grid container item xs={12}>
                        <UnderConstruction />
                    </Grid>
                }
                {
                    scenario && isActiveStep("Value Modelling") &&
                    <Grid container item xs={12}>
                        <UnderConstruction />
                    </Grid>
                }
                {
                    scenario && isActiveStep("Packaging") &&
                    <PriceModelsDataContext.Provider value={{ priceModels, setPriceModels }}>
                        <ScenarioDataContext.Provider value={{ scenario, updateScenario }}>
                            <ScenarioUIStateContext.Provider value={{ scenarioUIState, updateScenarioUIState }}>
                                <PackagingFlow />
                            </ScenarioUIStateContext.Provider>
                        </ScenarioDataContext.Provider>
                    </PriceModelsDataContext.Provider>
                }
                {
                    scenario && priceModels && isActiveStep("Price Modelling") &&
                    <React.Fragment>
                        <Grid container item>
                            <React.Fragment>
                                <CreatePriceModelDialog />
                                <AddOrRemovePriceModelDialog />

                                <Grid container item xs={12} justifyContent="center"
                                    sx={{ p: 1, borderBottom: 1, borderColor: "primary.groupBorder" }}
                                    backgroundColor="#fafafa">
                                    <Box>
                                        <FormControl size="small">
                                            <Tooltip title="Switch between price model types" placement="left">
                                                <ToggleButtonGroup
                                                    disabled={scenarioUIState.loading}
                                                    color="primary"
                                                    fontWeight="bold"
                                                    value={String(scenarioUIState.currentPriceModelId)}
                                                    exclusive
                                                    size="small"
                                                    sx={{ mr: 1, ml: 2 }}
                                                    onChange={handlePriceModelToggle}
                                                    aria-label="text alignment"
                                                >
                                                    {scenario && priceModels && priceModels
                                                        .filter(model => scenario["price_model_ids"].find(id => String(id) === String(model.id)))
                                                        .map((model, index) => {
                                                            const model_id = model.id;
                                                            const name = model.name;

                                                            return <ToggleButton key={index}
                                                                title={name}
                                                                value={String(model_id)}
                                                                sx={{ textTransform: "none", height: 33, fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                {name}
                                                            </ToggleButton>
                                                        }
                                                        )};
                                                </ToggleButtonGroup>
                                            </Tooltip>
                                        </FormControl>
                                    </Box>
                                    <Box sx={styles.toolbarItem}>
                                        <Tooltip title="Add or remove price models from scenario">
                                            <Button
                                                disabled={scenarioUIState.loading}
                                                startIcon={<AddCircleOutlineSharpIcon />}
                                                endIcon={<RemoveCircleOutlineIcon />}
                                                onClick={() => setOpenModelsDialog(true)}>/</Button>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={styles.toolbarItem}>
                                        <Tooltip title="Define your own custom model based on the usage of your product">
                                            <Button startIcon={<DashboardCustomizeSharpIcon />}
                                                sx={{ textTransform: "none" }}
                                                disabled={scenarioUIState.loading}
                                                onClick={() => setOpenCreateModelDialog(true)}>
                                                New Price Model
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={styles.toolbarItem}>
                                        <Button variant="standard"
                                            onClick={toggleInputs}
                                            disabled={scenarioUIState.loading}
                                            sx={{ cursor: "pointer", color: "primary.sub" }}
                                            startIcon={<TableChartIcon />}
                                        >Edit Data - ({scenario["params"]?.["segment_params"]?.filter(x => !x.deleted)?.length ?? 0} Segment{scenario["params"]?.["segment_params"]?.length === 1 ? '' : 's'})
                                        </Button>
                                    </Box>
                                    <Box sx={styles.toolbarItem}>
                                        <Tooltip title="Comparison view">
                                            <IconButton
                                                onClick={() => setComparisonView(true)}
                                                disabled={scenarioUIState.loading}>
                                                <ViewComfyAltIcon sx={{ p: 0, color: "primary.main" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Drawer open={comparisonView} onClose={() => setComparisonView(false)} fullWidth anchor="top">
                                            <CustDialogTitle onClose={() => setComparisonView(false)}>
                                                <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>
                                                    Compare Models
                                                </Typography>
                                            </CustDialogTitle>
                                            <DialogContent>
                                                <Grid className='sceanrio-input-wrapper' container item xs={12} sx={{ minWidth: 800 }}>
                                                    <PriceModelsDataContext.Provider value={{ priceModels, setPriceModels }}>
                                                        <ScenarioDataContext.Provider value={{ scenario, updateScenario }}>
                                                            <ScenarioUIStateContext.Provider value={{ scenarioUIState, updateScenarioUIState }}>
                                                                <PricingResultComparisonView scenarioResult={scenarioResult} />
                                                            </ScenarioUIStateContext.Provider>
                                                        </ScenarioDataContext.Provider>
                                                    </PriceModelsDataContext.Provider>
                                                </Grid>
                                            </DialogContent>
                                        </Drawer>
                                    </Box>
                                    <Box sx={styles.toolbarItem}>
                                        <Button onClick={() => generateNowHandler()} endIcon={<PlayArrowIcon />}
                                            disabled={scenarioUIState.loading}>Generate Now</Button>
                                    </Box>
                                </Grid>
                            </React.Fragment>

                            {scenarioId && scenario &&
                                <Drawer open={showInputs} onClose={toggleInputs} anchor="top">
                                    <CustDialogTitle onClose={toggleInputs}>
                                        <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>
                                            Enter Scenario Details
                                        </Typography>
                                    </CustDialogTitle>
                                    <DialogContent>
                                        <Grid className='sceanrio-input-wrapper' container item xs={12} sx={{ minWidth: 800 }}>
                                            <ScenarioDataContext.Provider value={{ scenario, updateScenario }}>
                                                <ScenarioUIStateContext.Provider value={{ scenarioUIState, updateScenarioUIState }}>
                                                    <ScenarioInputGrid
                                                        priceModels={priceModels}
                                                        key={showInputs}
                                                        runWorkbench={(scenario) => { scenario["fragment"] = "ALL"; runWorkBench(scenario); }}
                                                        onClose={toggleInputs} />
                                                </ScenarioUIStateContext.Provider>
                                            </ScenarioDataContext.Provider>
                                        </Grid>
                                    </DialogContent>
                                </Drawer>}


                            <ScenarioDataContext.Provider value={{ scenario, updateScenario }}>
                                <PricingResultDataContext.Provider value={{ pricingResult }}>
                                    <PriceModelsDataContext.Provider value={{ priceModels }}>
                                        <ScenarioUIStateContext.Provider value={{ scenarioUIState, updateScenarioUIState }}>

                                            <Grid key={modelsKey} item xs={12} height="auto"
                                                sx={{ backgroundColor: "primary.background" }}>
                                                {workbenchLoader === "minimalist" &&
                                                    <Grid key={modelsKey} item xs={12}>
                                                        <LinearProgress></LinearProgress>
                                                    </Grid>}
                                                <Grid>
                                                    {workbenchLoader === "all-models-loader" && <Loader />}
                                                    {workbenchLoader !== "all-models-loader" && scenario &&

                                                        <React.Fragment>
                                                            {pricingResult &&
                                                                <PricingResultTabs></PricingResultTabs>
                                                            }
                                                            {!pricingResult &&
                                                                <React.Fragment>
                                                                    <Grid container justifyContent="center" sx={{ p: 4 }} direction="column">
                                                                        <Typography>
                                                                            Price Models not yet generated.
                                                                        </Typography>
                                                                    </Grid>
                                                                </React.Fragment>}
                                                        </React.Fragment>
                                                    }
                                                </Grid>
                                            </Grid>



                                            {pricingResult && workbenchLoader !== "all-models-loader" &&
                                                <BottomBar Component={
                                                    <Grid container
                                                        width="100%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        sx={{
                                                            backgroundColor: "#cccccc",
                                                            boxShadow: 2, width: '100%',
                                                            borderTopRightRadius: 15,
                                                            borderTopLeftRadius: 15
                                                        }}>
                                                        <FinalizePriceBar></FinalizePriceBar>
                                                    </Grid>
                                                } />}
                                        </ScenarioUIStateContext.Provider>
                                    </PriceModelsDataContext.Provider>
                                </PricingResultDataContext.Provider>
                            </ScenarioDataContext.Provider>

                        </Grid>
                    </React.Fragment>
                }
            </Grid>
            {
                loading &&
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <LinearProgress sx={{ width: '100%', p: 0, m: 0 }} />
                </Grid>
            }
        </Grid >
    )
}
import React from "react";
import { Grid } from "@mui/material";
import MetricsGraph from "./MetricsGraph";

export default function DetailView(props) {
    const [metric] = React.useState(props.metric);
    const [period] = React.useState(props.period);
    const [data] = React.useState(props.data);

    return (
        <Grid key={metric + period} container item xs={12} flex="flex-start">
            <MetricsGraph key={metric + period}
                metric={metric['metric']}
                period={period}
                title={metric['title']}
                data={data}
                xLabel={period}
                yLabel={metric['y']}
                yFormatter={metric['formatter']}
                chartType="simple"
                variant="detail">
            </MetricsGraph>
        </Grid>
    )
}
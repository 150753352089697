import { Alert, CircularProgress, Grid, Link, MenuItem, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { fetchAllClientPlans } from "../../apis/ClientDataService";

export default function ClientPlanListPage() {

    const columns = [
        {
            field: 'rowIndex',
            headerName: '#',
            disableColumnMenu: true,
            sortable: false,
            width: 5
        },
        { field: 'customer_email', headerName: 'Customer Email', flex: 1.5 },
        { field: 'client_customer_id', headerName: 'Customer ID', flex: 1},
        {
            field: 'org_name', headerName: 'Organization', flex: 1, renderCell: (params) => {
                const row = params.row;
                return <MenuItem sx={{
                    m: 0, p: "3px", '&:hover': {
                        background: 'none',
                    },
                }}>
                    <Typography sx={{ fontSize: 12, p: 0, m: 0 }}>
                    {/*    <Link href={"/organization/" + row.org_id}>*/}
                            {row.org_name}
                        {/*</Link>*/}
                    </Typography>
                </MenuItem>
            }
        },
        {
            field: 'product_name', headerName: 'Product', flex: 1, renderCell: (params) => {
                return params.row.product.name
            }
        },
        {
            field: 'plan_name', headerName: 'Plan', flex: 1, renderCell: (params) => {
                return params.row.product.header_name
            }
        },
        { field: 'scenario_name', headerName: 'Scenario', flex: 1 },
        { field: 'project_name', headerName: 'Project', flex: 1 },
        { field: 'mode', headerName: 'Mode', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            field: 'created_date',
            headerName: 'Created On',
            flex: 1.2,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString('en-US',
                    {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });
            }
        },
        {
            field: 'modified_date',
            headerName: 'Updated On',
            flex: 1.2,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString('en-US',
                    {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });
            }
        },

    ];

    const [clientPlans, setClientPlans] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setError(null);
        fetchAllClientPlans((data) => {
            const newData = data.map((user, index) => ({
                ...user,
                rowIndex: index + 1,
            }));
            setClientPlans(newData);
            console.log(data)
            setLoading(false);
        }, (err) => {
            if (err.error === 403) {
                setError("You are not authorized to access this data");
            } else {
                setError("Users could not be fetched!");
            }
            setLoading(false);
        });
    }, []);

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                    <Typography fontSize={20}>
                        Customer plans across clients - Admin view
                    </Typography>
                </Grid>
                <Grid container item
                    sx={{
                        height: '75vh',
                        bgcolor: 'white',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {loading ?
                        <CircularProgress />
                        :
                        <>
                            {error ?
                                <Alert sx={{ width: "80%", maxWidth: 600 }} severity="error">{error}</Alert>
                                :
                                clientPlans.length
                                    ?
                                    <DataGrid
                                        sx={{
                                            overflowX: 'hidden',
                                            // disable cell selection style
                                            '.MuiDataGrid-cell:focus': {
                                                outline: 'none'
                                            },
                                            // pointer cursor on ALL rows
                                            '& .MuiDataGrid-row:hover': {
                                                cursor: 'pointer'
                                            },
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1, fontSize: 12,
                                            },
                                        }}
                                        getRowHeight={() => 'auto'}
                                        rows={clientPlans}
                                        // loading={loading}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 100 },
                                            },
                                        }}
                                        // onRowClick={handleRowClick}
                                        pageSizeOptions={[10, 50, 100]}
                                    // onCellClick={handleRowHover}
                                    // checkboxSelection
                                    />
                                    :
                                    <Typography color={'primary.greyText'}>
                                        No client plans available!
                                    </Typography>
                            }
                        </>
                    }
                </Grid>
            </Grid >
        </>
    );
}


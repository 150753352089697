
export const getMonth = (month) => {
    return new Date(2022, month - 1, 1).toLocaleString('default', { month: 'short' });
}

export const extractMetricData = (data, duration, metric) => {
    if (!data) {
        return [];
    }
    const metrics = duration === "month" ? data["monthly_metrics"] : data["yearly_metrics"];
    return metrics.map(pt => {
        var obj = {}
        if (duration === 'month') obj['month'] = getMonth(pt['month']) + ' ' + pt['year'];
        if (duration === 'year') obj['year'] = pt['year'];
        if (metric in pt) {
            obj[metric] = pt[metric]
        } else {
            return null;
        }
        return obj;
    }).filter(x => x);
}
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import ScenarioDataContext from "pages/scenarios/ScenarioDataContext";
import React from "react";
import { currencyFormatter } from "utils/Utils";
import PriceLabel from "./PriceLabel";

export default function PricePointCompact(props) {
    const { scenario } = React.useContext(ScenarioDataContext);
    const data = props.data;

    const styles = {
        headers: {
            color: "primary.main",
            fontSize: 12
        },
        centered: {
            width: "100%",
            textAlign: "center"
        }
    }

    const getSegmentNameById = (segmentId) => {
        const segment = scenario["params"]["segment_params"].find(x => String(x.id) === String(segmentId));
        return segment["segment_name"];
    }

    return (
        <Grid container xs={12} item sx={{ boxShadow: 2, borderRadius: 2, p: 2 }}>
            <table style={{ fontSize: 12, textAlign: "center", p: "2px", width: "100%" }}>
                <thead>
                    <tr>
                        <td><Typography sx={styles.headers}>Segment</Typography></td>
                        <td><Typography sx={styles.headers}>Price</Typography></td>
                        <td><Typography sx={styles.headers}>Revenue</Typography></td>
                        <td><Typography sx={styles.headers}>Customers</Typography></td>
                        <td><Typography sx={styles.headers}>Profit</Typography></td>
                    </tr>
                </thead>
                <tbody>
                    {data["params"]["attributes"].map((pricePoint, idx) => {
                        return <tr style={{ borderBottom: 1 }}>
                            <td>
                                {getSegmentNameById(pricePoint["segment_id"])}
                            </td>
                            <td>
                                <PriceLabel pricePoint={pricePoint} variant="normal"></PriceLabel>
                            </td>
                            <td>
                                {currencyFormatter(pricePoint["revenue"]?.["annual_revenue"] ?? 0)}
                            </td>
                            <td>
                                {Math.ceil(pricePoint["updated_customers"])}
                            </td>
                            <td>
                                {currencyFormatter(pricePoint["profit"]?.["value"] ?? 0)}
                            </td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </Grid>)
}

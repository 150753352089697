import { CompareArrows } from "@mui/icons-material";
import { Button, Drawer, Grid, Typography } from "@mui/material";
import PriceModelsDataContext from "pages/scenarios/PriceModelsDataContext";
import ScenarioDataContext from "pages/scenarios/ScenarioDataContext";
import ScenarioUIStateContext from "pages/scenarios/ScenarioUIStateContext";
import React from "react";
import { useErrorContext } from "state/ErrorDialogContext";
import { pricePointFormatter } from "utils/Utils";
import PriceLabel from "../components/PriceLabel";
import PricingResultDataContext from "../PricingResultDataContext";
import StrategyTab from "./StrategyTab";

export default function FinalizePriceBar() {
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const { priceModels } = React.useContext(PriceModelsDataContext);
    const { scenarioUIState } = React.useContext(ScenarioUIStateContext);
    const { pricingResult } = React.useContext(PricingResultDataContext);
    const { loadErrorDialog } = useErrorContext();
    const [edit, setEdit] = React.useState(false);

    const segmentParams = scenario["params"]["segment_params"];
    const strategyPricePoint = pricingResult["price_points"]["FINALIZE_PRICE"];

    const optimum_revenue = strategyPricePoint["revenue"]["annual_revenue"];
    const optimum_profit = strategyPricePoint["profit"]["value"];

    const getSegmentNameById = (segmentId) => {
        const segment = scenario["params"]["segment_params"].find(x => String(x.id) === String(segmentId));
        return segment["segment_name"];
    }

    const styles = {
        info: {
            fontSize: 12,
            ml: 1,
            backgroundColor: "primary.bg2",
            borderRadius: 1,
            pl: 2,
            pr: 2,
            border: 1,
            borderColor: "primary.disabledBorder"
        }
    }

    return <Grid>
        <Drawer open={edit} anchor="bottom" onClose={() => setEdit(false)}>
            <Grid sx={{ p: 2 }}>
                <StrategyTab strategy="FINALIZE_PRICE"></StrategyTab>
                <Button variant="contained" sx={{ float: "right", mt: 3 }} onClick={() => setEdit(false)}>Close</Button>
            </Grid>
        </Drawer>
        <Grid>
            <Grid display="flex" sx={{ pt: 1 }} justifyContent="center">
                <Typography sx={styles.info}>Revenue : {pricePointFormatter(optimum_revenue)}</Typography>
                <Typography sx={styles.info}>Profit: {pricePointFormatter(optimum_profit)}</Typography>
                <Typography sx={styles.info}>Model: {priceModels.find(x => String(x.id) === String(scenarioUIState.currentPriceModelId))?.name ?? "Unknown"}</Typography>
            </Grid>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center" sx={{ p: 1, pt: 1 }} gap={1} flexWrap="wrap">
            {segmentParams.map((segment, idx) => {
                const segmentId = segment["id"];
                const pricePoint = strategyPricePoint["params"]["attributes"]
                    .find(x => String(x["segment_id"]) === String(segmentId));

                return <Grid sx={{ minWidth: 230, p: "4px", pl: 2, pr: 2, boxShadow: 2, borderRadius: 2, backgroundColor: "primary.bg2", cursor: "pointer" }}
                    onClick={() => setEdit(true)}>
                    <Typography sx={{ fontWeight: "bold", opacity: 0.5, fontSize: 12 }}>{getSegmentNameById(segmentId)}</Typography>
                    <PriceLabel pricePoint={pricePoint} />
                </Grid>
            })}
        </Grid>
        <Grid container justifyContent="center" alignItems="center" sx={{ pb: 1 }}>
            <Button variant="contained" endIcon={<CompareArrows />} disabled>Compare models, coming soon...</Button>
        </Grid>
    </Grid>
}
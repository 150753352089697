import React, { useState, useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import config from './MicrosoftAuthConfig';

const msalInstance = new PublicClientApplication(config);

const MicrosoftAuthContext = React.createContext();

const MicrosoftAuthProvider = ({ children }) => {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        const getAccount = async () => {
            const accounts = await msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                setAccount(accounts[0]);
            }
        };

        getAccount();
    }, []);

    const login = async () => {
        const loginResponse = await msalInstance.loginPopup();
        setAccount(loginResponse.account);
    };

    const logout = () => {
        msalInstance.logout();
        setAccount(null);
    };

    return (
        <MicrosoftAuthContext.Provider value={{ account, login, logout }}>
            {children}
        </MicrosoftAuthContext.Provider>
    );
};

export { MicrosoftAuthContext, MicrosoftAuthProvider };

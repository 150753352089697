import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Button, Grid, Slider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import * as React from "react";
import CardContentContainer from "../../components/CardContentContainer";
import CurrencyField from "../../components/CurrencyField";
import CustField from "../../components/CustField";
import { updateParam } from "../../state/state";

import AdjustIcon from '@mui/icons-material/Adjust';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UpdateIcon from '@mui/icons-material/Update';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { getFinancialMetrics, saveData } from "../../apis/ClientDataService";
import { copy } from "../../utils/Utils";


export default function FinancialMetrics(props) {
  const [financialMetrics, setFinancialMetrics] = React.useState({ data: [] });
  const [formState, setFormState] = React.useState(null);
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    setFormState('loading');
    getFinancialMetrics((data) => {
      updateParam('financialMetrics', data);

      setFinancialMetrics(data);
      setFormState('success');
    }, (err) => {
      setFormState('error');
      alert(err);
    });
  }, []);


  const styles = {
    group: {
      border: 0,
      borderColor: grey[200],
      pb: 1,
      m: 0
    },
    sectionTitle: {
    },
    titleFont: {
      variant: 'title'
    },
    futureTimelineConnector: {
      backgroundColor: grey[300]
    },
    completedTimelineConnector: {
      backgroundColor: "primary.main",
      opacity: 0.5
    },
    timeLineContent: {
      margin: '0 0 2 2',
      borderBottom: 1,
      borderColor: grey[300]
    }
  }

  const setAndUpdate = (fm) => {
    setFinancialMetrics(fm);
    updateParam('financialMetrics', fm);
    setFormState('yet_to_save');
  }

  const changeFixedCosts = (e, year) => {
    var fm = copy(financialMetrics);
    var yrData = fm.data.find(x => x.year === year)
    yrData.fixedCosts = e.target.value;
    setAndUpdate(fm);
  }

  const changeVariableCosts = (e, year) => {
    var fm = copy(financialMetrics);
    var yrData = fm.data.find(x => x.year === year);
    console.log(e);
    yrData.variableCosts = e.target.value;
    setAndUpdate(fm);
  }

  const changeMargin = (e, year) => {
    var fm = copy(financialMetrics);
    var yrData = fm.data.find(x => x.year === year)
    yrData.margin = e.target.value;
    setAndUpdate(fm);
  }

  const changeRevenue = (e, year) => {
    var fm = copy(financialMetrics);
    var yrData = fm.data.find(x => x.year === year)
    yrData.revenue = e.target.value;
    setAndUpdate(fm);
  }

  const changeProfit = (e, year) => {
    var fm = copy(financialMetrics);
    var yrData = fm.data.find(x => x.year === year);
    yrData.profit = e.target.value;
    setAndUpdate(fm);
  }

  const changeNumCustomers = (e, year) => {
    var fm = copy(financialMetrics);
    var yrData = fm.data.find(x => x.year === year);
    yrData.numCustomers = e.target.value;
    setAndUpdate(fm);
  }

  const getTimelineIcon = (year) => {
    if (year < currentYear) return <TimelineDot sx={{ backgroundColor: "primary.main" }}><TaskAltIcon /></TimelineDot>;
    else if (year === currentYear) return <TimelineDot sx={{ backgroundColor: "primary.main" }}><UpdateIcon /></TimelineDot>;
    else return <TimelineDot><AdjustIcon /></TimelineDot>;
  }

  const getTimelineConnectorStyle = (year) => {
    if (year > 0) return styles.futureTimelineConnector; else return styles.completedTimelineConnector;
  }

  const getLabels = (year) => {
    if (year < currentYear - 1) return { year: 'Year ' + year, revenue: 'Revenue', profit: 'Profit', customers: 'Number of customers' };
    else if (year === currentYear - 1) return { year: 'Last year', revenue: 'Revenue', profit: 'Profit', customers: 'Number of target customers' };
    else if (year === currentYear) return { year: 'Current year', revenue: 'Forecasted revenue', profit: 'Forecasted profit', customers: 'Number of customers' };
    else if (year === currentYear + 1) return { year: 'Next year', revenue: 'Target revenue', profit: 'Target profit', customers: 'Number of target customers' };
    else return { year: 'Year ' + year, revenue: 'Target revenue', profit: 'Target profit', customers: 'Number of target customers' };
  }

  const addYear = () => {
    var maxYear = Math.max(...financialMetrics.data.map(o => o.year));
    var fm = copy(financialMetrics);
    var newYear = copy(fm.data[fm.data.length - 1]);
    newYear.year = maxYear + 1;
    fm.data.push(newYear);
    setFinancialMetrics(fm);
  }

  const removeYear = () => {
    var fm = copy(financialMetrics);
    var maxYear = Math.max(...financialMetrics.data.map(o => o.year));
    if (maxYear > currentYear) {
      fm.data.pop();
      setFinancialMetrics(fm);
    }
  }

  const save = () => {
    if (!financialMetrics.data || financialMetrics.data.length === 0) return;
    setFormState('loading');
    saveData('financial_metrics', financialMetrics, () => { setFormState('success'); }, () => { setFormState('error') });
  }

  return (
    <CardContentContainer
      {...props}
      cardIcon={<AttachMoneyIcon />}
      title="Financial metrics"
      data={financialMetrics.data}
      formState={formState}
      save={() => save()}
      content={
        <Grid container item xs={24}>
          <Grid container item xs={16}>
            <Timeline sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2
              },
            }}>
              {financialMetrics.data && financialMetrics.data.map((yrData, idx) => {
                const labels = getLabels(yrData.year);
                return (<TimelineItem key={idx}>
                  <TimelineOppositeContent color={grey[700]}>
                    {labels.year}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector sx={getTimelineConnectorStyle(yrData.year)} />
                    {getTimelineIcon(yrData.year)}
                    <TimelineConnector sx={getTimelineConnectorStyle(yrData.year)} />
                  </TimelineSeparator>
                  <TimelineContent sx={styles.timeLineContent}>
                    <Grid container item xs={8} columnSpacing={15} direction="row">
                      <Grid item sx={styles.group} xs={6}>
                        <CurrencyField label={labels.revenue} value={yrData.revenue} onChange={(e) => changeRevenue(e, yrData.year)} />
                        <CurrencyField label={labels.profit} value={yrData.profit} onChange={(e) => changeProfit(e, yrData.year)} />
                        <CustField label={labels.customers} value={yrData.numCustomers} onChange={(e) => changeNumCustomers(e, yrData.year)} />
                      </Grid>
                      <Grid item sx={styles.group} xs={6}>
                        <CurrencyField label="Fixed costs" value={yrData.fixedCosts} onChange={(e) => changeFixedCosts(e, yrData.year)} />
                        <CurrencyField label="Variable costs" value={yrData.variableCosts} onChange={(e) => changeVariableCosts(e, yrData.year)} />
                        <Typography sx={{ mt: 1, fontSize: 14 }} gutterBottom id="margin-percent" >
                          Expected margin: {yrData.margin}%
                        </Typography>
                        <Slider
                          aria-labelledby="margin-percent"
                          defaultValue={yrData.margin}
                          step={1}
                          valueLabelDisplay="auto"
                          onChange={(e) => changeMargin(e, yrData.year)}
                        />
                      </Grid>
                    </Grid>
                  </TimelineContent>
                </TimelineItem>)
              })}
            </Timeline>
          </Grid>
          <Grid container justifyContent='center'>
            <Button variant="outlined" onClick={addYear}>Add more years</Button>
            {financialMetrics.data && <Button variant="outlined" disabled={Math.max(...financialMetrics.data.map(o => o.year)) <= currentYear} sx={{ ml: 2 }} onClick={removeYear}>Remove year</Button>}
          </Grid>
        </ Grid>
      }
    ></CardContentContainer>
  );
}

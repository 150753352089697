const state = {
  visualizationType: 'PRICE_REVENUE',
  competitors: [{ 'pricing': 0 }]
};

export const setRawParam = (key, propValue) => {
  localStorage.setItem(key, JSON.stringify(propValue));
};

export const updateParam = (key, propValue) => {
  const userId = getUser()?.email ?? "";
  const dataKey = userId + "__" + key;
  setRawParam(dataKey, propValue);
};

export const getRawParam = (key) => {
  const item = localStorage.getItem(key);
  return (item && item !== 'undefined') ? JSON.parse(item) : undefined;
}

export const getParam = (key) => {
  const userId = getUser()?.email ?? "";
  const dataKey = userId + "__" + key;
  return getRawParam(dataKey);
};

export const getDefaultOrgId = () => {
  const user = getUser();
  if (user && user["company"]) return user["company"];
  if (user && user["organizations"] && user["organizations"].length > 0) {
    return user["organizations"][0]["id"];
  }
}

export const getCurrentOrgId = () => {
  return getOrgFromLocalStore()?.['id'];
}

export const getOrgFromLocalStore = () => {
  return getParam("org");
}

export const setOrgInLocalStore = (org) => {
  updateParam("org", org);
}


export const getUser = () => {
  return getRawParam("user");
}

export const setUser = (user) => {
  setRawParam("user", user);
}

export const getProjectFromLocalStore = () => {
  return getParam("project");
}

export const setProjectInLocalStore = (project) => {
  updateParam("project", project);
}

export const getState = () => {
  return state;
};

export const getPricingOptions = () => {
  return getParam('pricingOptions');
};
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Grid, IconButton, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export function SortableFeatureItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable(
        {id:props.id}
    )

    const style = {
        transform: CSS.Transform.toString(transform),
        // transition
    }

    const[editing,setEditing] = useState(false)
    const[editVal,setEditVal] = useState("")

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {editing ?
                <TextField
                    sx={{ width: '100%',mb:2 }}
                    size='small'
                    value={editVal}
                    autoFocus
                    onBlur={(e)=>setEditing(false)}
                    onChange={(e) => setEditVal(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && editVal.length > 0) {
                            const updatedFeatures = props.features.map((feature) => {
                                if (feature.id === props.id) {
                                    return { ...feature, label: editVal };
                                }
                                return feature;
                            });
                            props.setFeatures(updatedFeatures);
                            setEditing(false)
                        }
                    }}
                />
                :
                <Grid
                    container
                    alignItems={'center'}
                    border={isDragging ? '2px solid' : '2px solid'}
                    borderColor={isDragging ? 'primary.main' : "#b2b2b2"}
                    borderRadius={1}
                    py={1}
                    pl={2}
                    pr={1}
                    sx={{
                        cursor: 'move',
                        '&:hover':{
                            border: '2px solid',
                            borderColor: 'primary.main'
                        }
                    }}
                >
                    <Grid container width={'80%'}>
                        <Typography fontSize={16}>
                            {props.label}
                        </Typography>
                    </Grid>

                    <Grid container width={'20%'} justifyContent={'flex-end'}>
                        <EditIcon
                            onClick={(e)=>{
                                setEditing(true)
                                setEditVal(props.label)
                            }}
                            fontSize="small"
                            sx={{
                                color:'#b2b2b2',
                                '&:hover': {
                                    color:'primary.main',
                                    cursor:'pointer'
                                },
                                pr:1
                            }}
                        />

                        <DeleteIcon
                            onClick={(e)=>{
                                props.setFeatures(props.features.filter((feature) => feature.id !== props.id));
                            }}
                            fontSize="small"
                            sx={{
                                color:'#b2b2b2',
                                '&:hover': {
                                    color:'primary.main',
                                    cursor:'pointer'
                                },

                            }}/>
                    </Grid>
                </Grid>
            }
        </div>
    )
}
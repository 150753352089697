import {
    Alert,
    Button,
    CircularProgress,
    Dialog, FormControl, FormControlLabel, FormLabel,
    Grid,
    InputAdornment, InputLabel, MenuItem, Radio, RadioGroup,
    Select, Slider,
    TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React, {useState} from "react";
import {currencies} from './CurrencyList'
import AddIcon from "@mui/icons-material/Add";
import {getReadablePriceMetric} from "../scenarios/price-modelling/components/PriceModelDefinitions";
import CloseIcon from "@mui/icons-material/Close";

export default function PriceAdd(props) {

    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(false);
    const [priceMetric,setPriceMetric] = useState(props.priceMetrics[0]);
    const [priceValue,setPriceValue] = useState();
    const [currency,setCurrency] = useState(currencies[105]);
    const [paymentType,setPaymentType] = useState("Recurring");
    const [billingPeriod,setBillingPeriod] = useState("")
    const [customBillingPeriod,setCustomBillingPeriod] = useState("months")
    const [tiers,setTiers] = useState([
        {from: 0, up_to: 1, unit_price_value: null, flat_price_value: null},
        {from: 2, up_to: -1, unit_price_value: null, flat_price_value: null},
    ]);

    const getTiers = () => {
        let newTiers = []
        for(let index= 0; index< tiers.length; index++){
            let currUpTo = tiers[index]['up_to']
            let currUnitPriceValue = tiers[index]['unit_price_value']
            let currFlatPriceValue = tiers[index]['flat_price_value']

            if(!currFlatPriceValue) currFlatPriceValue = 0
            if(!currUnitPriceValue) currUnitPriceValue = 0

            newTiers.push({
                up_to: currUpTo,
                unit_price_value: currUnitPriceValue,
                flat_price_value: currFlatPriceValue
            })
        }

        console.log(newTiers)

        return newTiers
    }

    const checkTiersValidity = () => {

        for(let index = 0; index < tiers.length; index++) {

            let currUpTo = tiers[index]['up_to']
            let currUnitPriceValue = tiers[index]['unit_price_value']
            let currFlatPriceValue = tiers[index]['flat_price_value']

            if(!currFlatPriceValue) currFlatPriceValue = 0
            if(!currUnitPriceValue) currUnitPriceValue = 0

            if(isNaN(currFlatPriceValue)) return false
            if(isNaN(currUnitPriceValue)) return false

            let currFlatPriceValueNum = Number(currFlatPriceValue)
            let currUnitPriceValueNum = Number(currUnitPriceValue)

            // console.log(currUpTo,currUnitPriceValueNum,currFlatPriceValueNum)

            //Checking if every tier's upto is less than upto of next tier
            //excluding the last one which is infinity or here defined as -1
            if (index + 1 < tiers.length - 1) {
                if (currUpTo >= tiers[index + 1]['up_to']) {
                    return false
                }
            }

            if((index!== tiers.length -1 && currUpTo < 1) || currUpTo > 999999999 ||
                currUnitPriceValueNum < 0 || currUnitPriceValueNum > 999999999 ||
                currFlatPriceValueNum < 0 || currFlatPriceValueNum > 999999999){
                return false
            }

        }

        return true
    }

    const handleAddTier = () => {
        let tiersCopy = [...tiers];
        let lastTier = tiersCopy[tiersCopy.length - 1];

        // Update the 'up_to' value of the last tier
        lastTier.up_to = lastTier.from + 1;

        // Add a new tier
        tiersCopy.push({
            from: lastTier.up_to + 1,
            up_to: -1,
            unit_price_value: null,
            flat_price_value: null
        });

        setTiers(tiersCopy);
    }


    const handleRemoveTier = (index) => {
        if(index > 0){
            let tiersCopy = [...tiers];

            // Update the 'up_to' value of the previous tier
            tiersCopy[index - 1].up_to = tiersCopy[index].up_to;

            // Remove the tier at the given index
            tiersCopy.splice(index, 1);

            setTiers(tiersCopy);
        }
    }

    const handleLastUnitValueChange = (event,index) =>{

        if(isNaN(event.target.value) ){
            return
        }

        let tiersCopy = [...tiers];
        // Update the price per unit value of the index tier
        tiersCopy[index].up_to = Number(event.target.value)
        tiersCopy[index+1].from = Number(event.target.value)+1
        setTiers(tiersCopy)
    }

    const handlePricePerUnitValueChange = (event,index) =>{

        let tiersCopy = [...tiers];
        // Update the price per unit value of the index tier
        tiersCopy[index].unit_price_value = event.target.value
        setTiers(tiersCopy)
    }

    const handleFlatFeeValueChange = (event,index) =>{

        let tiersCopy = [...tiers];
        // Update the price per unit value of the index tier
        tiersCopy[index].flat_price_value = event.target.value
        setTiers(tiersCopy)
    }

    const handlePriceValueChange = (event) =>{
        if(isNaN(event.target.value)){
            return
        }
        setPriceValue(event.target.value)
    }

    const handleCurrencyChange = (event) =>{
        setCurrency(event.target.value)
    }

    const handlePaymentTypeChange = (event) =>{

        if(props.priceModelType !== 'TIERED_PRICING'){
            setPaymentType(event.target.value)
            if(event.target.value === 'One Time'){
                setBillingPeriod("N/A")
            }
        }

    }

    const handleBillingPeriodChange = (event) =>{
        setBillingPeriod(event.target.value)
    }

    const handleCustomBillingPeriodChange = (event) =>{
        setCustomBillingPeriod(event.target.value)
    }

    const customToBillingPeriod = () => {
        if (billingPeriod === 'Custom') {
            if (customBillingPeriodUnit === '1' && customBillingPeriod === 'months') {
                return 'Monthly';
            } else if (customBillingPeriodUnit === '12' && customBillingPeriod === 'months') {
                return 'Yearly';
            } else if (customBillingPeriod === 'months' && Number(customBillingPeriodUnit) % 12 === 0 && Number(customBillingPeriodUnit) > 12) {
                return `Every ${Number(customBillingPeriodUnit) / 12} years`;
            } else {
                return `Every ${customBillingPeriodUnit} ${customBillingPeriod}`;
            }
        } else {
            return billingPeriod;
        }
    }

    const handlePriceAdd = () => {
        props.handleAddPrice(
            {
                "id": customToBillingPeriod().concat(" ",priceMetric.name),
                "tiers": getTiers(),
                "price_value": priceValue,
                "price_metric": priceMetric,
                "payment_type": paymentType,
                "currency": currency.Symbol,
                "currency_code":currency.Code.toLowerCase(),
                "billing_period": customToBillingPeriod(),
                "discount_price_value": radioDiscountValue==='Yes' ? discountPriceValue : priceValue,
            }
        );
        props.handlePriceDialogClose();
    };

    const validateForm = () => {
        const numPriceValue = Number(priceValue);
        const numDiscountPriceValue = Number(discountPriceValue);
        // console.log(numPriceValue,numDiscountPriceValue)
        const numCustomBillingPeriodUnit = Number(customBillingPeriodUnit)

        return (
            priceMetric &&
            (props.priceModelType === 'TIERED_PRICING' ||  (priceValue &&
            !isNaN(numPriceValue)) && numPriceValue >= 0 && numPriceValue <= 999999999 ) &&
            (props.priceModelType !== 'TIERED_PRICING' ||  (checkTiersValidity())) &&
            (radioDiscountValue !== 'Yes' ||
                (numDiscountPriceValue <= numPriceValue && numDiscountPriceValue >= 0) && discountPriceValue) &&
            billingPeriod &&
            (billingPeriod !== 'Custom' ||
                (
                    !isNaN(numCustomBillingPeriodUnit) &&
                    numCustomBillingPeriodUnit > 0 &&
                    numCustomBillingPeriodUnit <= 100 &&
                    !props.prices.some(price => price.billing_period === customToBillingPeriod())
                )
            )&&
            !props.prices.some(price =>
                ((price.billing_period === billingPeriod) && (price.price_metric.id === priceMetric.id))) &&
            (!props.addOnProduct ||
                !props.prices.some(price =>
                    ((price.billing_period === 'N/A') || (billingPeriod ==='N/A')) &&
                    price.billing_period !== billingPeriod
                ))
        )
    };

    const [radioDiscountValue, setRadioDiscountValue] = React.useState('No');

    const handleRadioDiscountChange = (event) => {
        setRadioDiscountValue(event.target.value)
    };

    const [discountPriceValue, setDiscountPriceValue] = useState();

    const handleDiscountPriceValueChange = (event) => {
        // console.log(event.target.value)
        setDiscountPriceValue(event.target.value);
    };

    const [customBillingPeriodUnit, setCustomBillingPeriodUnit] = useState();
    const handleCustomBillingPeriodUnitChange = (event) => {
        setCustomBillingPeriodUnit(event.target.value);
    };

    const handlePriceMetricChange = (event) =>{
        let metric = event.target.value
        // console.log(metric)
        setPriceMetric(metric)
    }

    const processPriceMetric = (paymentType, metric) =>{
        let readablePriceMetric = getReadablePriceMetric(metric);
        if (paymentType === 'One Time') {
            readablePriceMetric = readablePriceMetric.replace('/month', '').replace('/year', '');
            if (readablePriceMetric === '') {
                readablePriceMetric = '/';
            }
        }
        return readablePriceMetric;
    }


    return (
        <Dialog
            open={props.isDialogOpen==='priceAdd'}
            onClose={props.handlePriceDialogClose}
            onClick={(e)=>{e.stopPropagation();}}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: props.priceModelType === 'TIERED_PRICING' ? 800 : 550,  // Set your width here
                    },
                },
            }}
        >
            <Grid container item width={'100%'} sx={{minHeight: 200, borderRadius: 2, p: 4 }} >
                    <Typography sx={{ color: "primary.main", pb: 2, fontSize: 20, fontWeight: "bold" }}>Add Price</Typography>
                    {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                    {props.priceModelType !== 'TIERED_PRICING' ?
                        <Grid container justifyContent='space-between'>
                            <Typography sx={{fontWeight:600,fontSize:14,pb:2}}>
                                Price Metric
                            </Typography>
                            <TextField
                                sx={{width:'100%',pb:2}}
                                select
                                size='small'
                                labelId="price-metric-select-label"
                                id="price-metric-select"
                                label="Price Metric"
                                value={priceMetric}
                                onChange={handlePriceMetricChange}
                            >
                                {props.priceMetrics.map((metric) => (
                                    <MenuItem value={metric}>
                                        {`${processPriceMetric(paymentType,metric)}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Grid container item gap={2} width='50%' direction='column'>
                                <Typography sx={{fontWeight:600,fontSize:14}}>
                                    Price Info
                                </Typography>
                                <TextField
                                    size="small"
                                    sx={{width:210,}}
                                    label="Price"
                                    value={priceValue}
                                    onChange={handlePriceValueChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{currency.Symbol}</InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Select
                                                    size='small'
                                                    sx={{
                                                        zIndex:1,
                                                        mr:-1.8,
                                                        backgroundColor:"primary.background",
                                                        borderTopLeftRadius:0,
                                                        borderBottomLeftRadius:0,
                                                        '& .MuiOutlinedInput-input': {
                                                            p:1.07,
                                                        }
                                                    }}
                                                    value={currency}
                                                    onChange={handleCurrencyChange}
                                                    renderValue={(selectedCurrency) => selectedCurrency.Code}
                                                >
                                                    {currencies.map((currencyObj) => (
                                                        <MenuItem key={currencyObj.Code} value={currencyObj}>
                                                            {currencyObj.CountryName} - {currencyObj.Currency} - {currencyObj.Code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Typography sx={{fontWeight:600,fontSize:14,pb:0.1}}>
                                    Payment Type
                                </Typography>
                                <ToggleButtonGroup
                                    size='small'
                                    color="primary"
                                    value={paymentType}
                                    exclusive
                                    onChange={handlePaymentTypeChange}
                                    aria-label="Platform"
                                    sx={{}}
                                >
                                    <ToggleButton
                                        value="Recurring"
                                        sx={{
                                            width:105,
                                            textTransform: 'none',
                                            borderRightColor:'primary.main',
                                            '&.Mui-selected': {
                                                borderColor: 'primary.main',
                                            },
                                        }}
                                    >
                                        Recurring
                                    </ToggleButton>
                                    <ToggleButton value="One Time" sx={{
                                        width:105,
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            borderColor: 'primary.main',
                                        },
                                    }}>
                                        One Time
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {paymentType === "Recurring" &&
                                    <>
                                        <FormControl sx={{width:210}}>
                                            {/*<InputLabel size='small' id="billing-period-select-label">Billing Period</InputLabel>*/}
                                            <TextField
                                                select
                                                size='small'
                                                labelId="billing-period-select-label"
                                                id="billing-period-select"
                                                label="Billing Period"
                                                value={billingPeriod}
                                                onChange={handleBillingPeriodChange}
                                            >
                                                {/*<MenuItem value={"Daily"}>Daily</MenuItem>*/}
                                                {/*<MenuItem value={"Weekly"}>Weekly</MenuItem>*/}
                                                <MenuItem value={"Monthly"}>Monthly</MenuItem>
                                                <MenuItem value={"Every 3 months"}>Every 3 months</MenuItem>
                                                <MenuItem value={"Every 6 months"}>Every 6 months</MenuItem>
                                                <MenuItem value={"Yearly"}>Yearly</MenuItem>
                                                <MenuItem value={"Custom"}>Custom</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </>
                                }
                                { paymentType === "Recurring" && billingPeriod==="Custom" &&
                                    <>
                                        <TextField
                                            size='small'
                                            sx={{width:210}}
                                            // label="Custom Billing Period"
                                            value={customBillingPeriodUnit}
                                            onChange={handleCustomBillingPeriodUnitChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography sx={{pr:1,color:'primary.greyText'}}>Every</Typography>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Select
                                                            size='small'
                                                            sx={{
                                                                zIndex:1,
                                                                mr:-1.8,
                                                                backgroundColor:"primary.background",
                                                                borderTopLeftRadius:0,
                                                                borderBottomLeftRadius:0,
                                                                '& .MuiOutlinedInput-input': {
                                                                    p:1.05,
                                                                }
                                                            }}
                                                            value={customBillingPeriod}
                                                            onChange={handleCustomBillingPeriodChange}
                                                        >
                                                            {/*<MenuItem value={"days"}>days</MenuItem>*/}
                                                            {/*<MenuItem value={"weeks"}>weeks</MenuItem>*/}
                                                            <MenuItem value={"months"}>months</MenuItem>
                                                            <MenuItem value={"years"}>years</MenuItem>
                                                        </Select>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </>
                                }
                            </Grid>

                            <Grid container width='45%' item gap={2} direction='column' pr={4}>
                                <Typography sx={{fontWeight:600,fontSize:14}}>
                                    Add Discount
                                </Typography>

                                <FormControl
                                    disabled={props.addOnProduct}
                                >
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={radioDiscountValue}
                                        onChange={handleRadioDiscountChange}
                                    >
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>

                                <Typography sx={{fontWeight:600,fontSize:14,mt:-0.3}}>
                                    Discount Info
                                </Typography>

                                <TextField
                                    size="small"
                                    disabled={radioDiscountValue === 'No'}
                                    sx={{width:'100%',}}
                                    label="Discounted Price"
                                    value={discountPriceValue}
                                    onChange={handleDiscountPriceValueChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{currency.Symbol}</InputAdornment>
                                        ),
                                        // readOnly: true
                                    }}
                                />

                                {
                                    (radioDiscountValue === 'No' ||
                                        (Number(discountPriceValue >= 0) && Number(priceValue) >= Number(discountPriceValue))) ?
                                        <TextField
                                            size="small"
                                            disabled={radioDiscountValue === 'No'}
                                            sx={{width:'100%',pb:0.6}}
                                            label="Discounted Percentage"
                                            // defaultValue={priceValue}
                                            value={isNaN((100*((priceValue-discountPriceValue)/priceValue))) ? "" :
                                                `${(100*((priceValue-discountPriceValue)/priceValue)).toFixed(2)} %`}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                                readOnly: true
                                            }}
                                        />
                                        :
                                        <Alert severity="error" sx={{fontSize:10,mt:-0.5}}>
                                            Enter valid discount price
                                        </Alert>
                                }

                                {/*<Slider*/}
                                {/*    disabled={radioDiscountValue === 'No'}*/}
                                {/*    defaultValue={priceValue}*/}
                                {/*    max={priceValue}*/}
                                {/*    aria-label="Default"*/}
                                {/*    valueLabelDisplay="auto"*/}
                                {/*    onChange={handleDiscountSliderValueChange}*/}
                                {/*    valueLabelFormat={(value) => `${value}% Discount`}*/}
                                {/*/>*/}

                            </Grid>
                        </Grid>
                        :
                        <Grid container width={'100%'} justifyContent={'space-between'}>
                            <Grid container width={'30%'}>
                                <Typography sx={{fontWeight:600,fontSize:14,pb:2}}>
                                    Price Metric
                                </Typography>
                                <TextField
                                    sx={{width:'100%',pb:2}}
                                    select
                                    size='small'
                                    labelId="price-metric-select-label"
                                    id="price-metric-select"
                                    label="Price Metric"
                                    value={priceMetric}
                                    onChange={handlePriceMetricChange}
                                >
                                    {props.priceMetrics.map((metric) => (
                                        <MenuItem value={metric}>
                                            {`${processPriceMetric(paymentType,metric)}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container width={'70%'} direction={'column'} pl={3}>
                                <Typography sx={{fontWeight:600,fontSize:14}}>
                                    Currency
                                </Typography>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    size="small"
                                    sx={{width:485,mt:2}}
                                    label="Currency"
                                    value={`${currency.CountryName} - ${currency.Currency}`}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{currency.Symbol}</InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Select
                                                    size='small'
                                                    sx={{
                                                        width:150,
                                                        zIndex:1,
                                                        mr:-1.8,
                                                        backgroundColor:"primary.background",
                                                        borderTopLeftRadius:0,
                                                        borderBottomLeftRadius:0,
                                                        '& .MuiOutlinedInput-input': {
                                                            p:1.07,
                                                        }
                                                    }}
                                                    value={currency}
                                                    onChange={handleCurrencyChange}
                                                    renderValue={(selectedCurrency) => selectedCurrency.Code}
                                                >
                                                    {currencies.map((currencyObj) => (
                                                        <MenuItem key={currencyObj.Code} value={currencyObj}>
                                                            {currencyObj.CountryName} - {currencyObj.Currency} - {currencyObj.Code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid container item gap={2} pt={1.5} width='30%' direction='column'>
                                <Typography sx={{fontWeight:600,fontSize:14,pb:0.1}}>
                                    Payment Type
                                </Typography>
                                <ToggleButtonGroup
                                    size='small'
                                    color="primary"
                                    value={paymentType}
                                    exclusive
                                    onChange={handlePaymentTypeChange}
                                    aria-label="Platform"
                                    sx={{}}
                                >
                                    <ToggleButton
                                        value="Recurring"
                                        sx={{
                                            width:110,
                                            textTransform: 'none',
                                            borderRightColor:'primary.main',
                                            '&.Mui-selected': {
                                                borderColor: 'primary.main',
                                            },
                                        }}
                                    >
                                        Recurring
                                    </ToggleButton>
                                    <ToggleButton value="One Time" sx={{
                                        width:110,
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            borderColor: 'primary.main',
                                        },
                                    }}>
                                        One Time
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {paymentType === "Recurring" &&
                                    <>
                                        <FormControl sx={{width:220}}>
                                            {/*<InputLabel size='small' id="billing-period-select-label">Billing Period</InputLabel>*/}
                                            <TextField
                                                select
                                                size='small'
                                                labelId="billing-period-select-label"
                                                id="billing-period-select"
                                                label="Billing Period"
                                                value={billingPeriod}
                                                onChange={handleBillingPeriodChange}
                                            >
                                                {/*<MenuItem value={"Daily"}>Daily</MenuItem>*/}
                                                {/*<MenuItem value={"Weekly"}>Weekly</MenuItem>*/}
                                                <MenuItem value={"Monthly"}>Monthly</MenuItem>
                                                <MenuItem value={"Every 3 months"}>Every 3 months</MenuItem>
                                                <MenuItem value={"Every 6 months"}>Every 6 months</MenuItem>
                                                <MenuItem value={"Yearly"}>Yearly</MenuItem>
                                                <MenuItem value={"Custom"}>Custom</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </>
                                }
                                { paymentType === "Recurring" && billingPeriod==="Custom" &&
                                    <>
                                        <TextField
                                            size='small'
                                            sx={{width:220}}
                                            // label="Custom Billing Period"
                                            value={customBillingPeriodUnit}
                                            onChange={handleCustomBillingPeriodUnitChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography sx={{pr:1,color:'primary.greyText'}}>Every</Typography>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Select
                                                            size='small'
                                                            sx={{
                                                                zIndex:1,
                                                                mr:-1.8,
                                                                backgroundColor:"primary.background",
                                                                borderTopLeftRadius:0,
                                                                borderBottomLeftRadius:0,
                                                                '& .MuiOutlinedInput-input': {
                                                                    p:1.05,
                                                                }
                                                            }}
                                                            value={customBillingPeriod}
                                                            onChange={handleCustomBillingPeriodChange}
                                                        >
                                                            {/*<MenuItem value={"days"}>days</MenuItem>*/}
                                                            {/*<MenuItem value={"weeks"}>weeks</MenuItem>*/}
                                                            <MenuItem value={"months"}>months</MenuItem>
                                                            <MenuItem value={"years"}>years</MenuItem>
                                                        </Select>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </>
                                }
                            </Grid>

                            <Grid container width={'70%'} direction={'column'} pl={3} pt={1}>
                                <Grid container item sx={{ justifyContent: 'space-between', alignItems: 'center' }} >
                                    <Typography fontSize={14} fontWeight={600}>
                                        Price Tiers
                                    </Typography>
                                    <Grid item alignItem={'center'}>
                                        <Tooltip title="Add Tier">
                                            <AddIcon
                                                onClick={handleAddTier}
                                                sx={{
                                                    '&:hover': {
                                                        filter: 'brightness(130%)',
                                                        cursor: 'pointer'
                                                    },
                                                    backgroundColor:'primary.main',
                                                    borderRadius: 4,
                                                    color: 'white'
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid container width={'95%'} px={0.5} py={1} >
                                        <Grid container width={'25%'}>
                                            <Typography fontSize={12} fontWeight={600}>
                                                First Unit
                                            </Typography>
                                        </Grid>
                                        <Grid container width={'25%'}>
                                            <Typography fontSize={12} fontWeight={600}>
                                                Last Unit
                                            </Typography>
                                        </Grid>
                                        <Grid container width={'25%'}>
                                            <Typography fontSize={12} fontWeight={600}>
                                                Price Per Unit
                                            </Typography>
                                        </Grid>
                                        <Grid container width={'25%'}>
                                            <Typography fontSize={12} fontWeight={600}>
                                                Flat Fee
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {tiers.map((tier, index) =>(
                                        <Grid container width={'100%'} px={0.5} pb={1} justifyContent={'space-between'} alignItems={'center'}>
                                            <Grid container width={'95%'}>
                                                <Grid container width={'25%'}>
                                                    <TextField size={'small'} sx={{width:'95%'}}
                                                               disabled={true}
                                                               value={index === 0 ? 0 : tiers[index-1]['up_to'] + 1}
                                                               inputProps={{
                                                                   sx: {
                                                                       height: 7,
                                                                       fontSize: 12
                                                                   },
                                                               }}
                                                    />
                                                </Grid>
                                                <Grid container width={'25%'}>
                                                    <TextField size={'small'} sx={{width:'95%'}}
                                                               error={( index > 0 &&
                                                                   index !== tiers.length -1 &&
                                                                   tiers[index].up_to <= tiers[index-1].up_to )||
                                                                   Number(tiers[index].up_to < 1) ||
                                                                   Number(tiers[index].up_to > 999999999)}
                                                               onChange={(e)=>{handleLastUnitValueChange(e,index)}}
                                                               disabled={index === tiers.length -1}
                                                               value={tier.up_to !== -1 ? tier.up_to : '∞' }
                                                               inputProps={{
                                                                   sx: {
                                                                       height: 7,
                                                                       fontSize: index === tiers.length -1 ? 18 : 12
                                                                   },
                                                               }}
                                                    />
                                                </Grid>
                                                <Grid container width={'25%'}>
                                                    <TextField size={'small'} sx={{width:'95%'}}
                                                               error={isNaN(tiers[index].unit_price_value)||
                                                                   Number(tiers[index].unit_price_value < 0) ||
                                                                   Number(tiers[index].unit_price_value > 999999999)}
                                                               value={tiers[index].unit_price_value}
                                                               onChange={(e)=>{handlePricePerUnitValueChange(e,index)}}
                                                               placeholder={'0.00'}
                                                               inputProps={{
                                                                   sx: {
                                                                       height: 7,
                                                                       fontSize: 12
                                                                   },
                                                               }}
                                                               InputProps={{
                                                                   startAdornment: (
                                                                       <InputAdornment position="start" sx={{ml:-0.5}}>
                                                                           <Typography fontSize={14}>
                                                                               {currency.Symbol}
                                                                           </Typography>
                                                                       </InputAdornment>)
                                                               }}
                                                    />
                                                </Grid>
                                                <Grid container width={'25%'}>
                                                    <TextField size={'small'} sx={{width:'95%'}}
                                                               error={isNaN(tiers[index].flat_price_value) ||
                                                                   Number(tiers[index].flat_price_value < 0) ||
                                                                   Number(tiers[index].flat_price_value > 999999999)}
                                                               value={tiers[index].flat_price_value}
                                                               onChange={(e)=>{handleFlatFeeValueChange(e,index)}}
                                                               placeholder={'0.00'}
                                                               inputProps={{
                                                                   sx: {
                                                                       height: 7,
                                                                       fontSize: 12
                                                                   },
                                                               }}
                                                               InputProps={{
                                                                   startAdornment: (
                                                                       <InputAdornment position="start" sx={{ml:-0.5}}>
                                                                           <Typography fontSize={14}>
                                                                               {currency.Symbol}
                                                                           </Typography>
                                                                       </InputAdornment>)
                                                               }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {index > 0 &&
                                                <CloseIcon
                                                    onClick={()=>{handleRemoveTier(index)}}
                                                    fontSize="small"
                                                    sx={{
                                                        color:'#b2b2b2',
                                                        '&:hover': {
                                                            color:'primary.main',
                                                            cursor:'pointer'
                                                        },

                                                    }}/>
                                            }
                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>

                        </Grid>
                    }

                    <Grid item container justifyContent='flex-end'>
                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{ mt: 4 }}
                            onClick={props.handlePriceDialogClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!validateForm()}
                            variant="outlined" size="medium"
                            onClick={handlePriceAdd}
                            sx={{ ml: 1, mt: 4 }}>
                            Add Price
                        </Button>
                    </Grid>
                </Grid>
        </Dialog>
    )
}
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from "@mui/icons-material/Person";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    Drawer,
    Fab,
    Grid,
    ListItemIcon, ListItemText,
    Snackbar,
    Tooltip,
    Typography
} from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useState } from 'react';
import { deleteProduct } from "../../apis/ClientDataService";
import { dateFormatterLocal } from "../../utils/Utils";
import IconContextMenu from "../scenarios/price-modelling/components/IconContextMenu";
import { ProductContext } from "./PackagingFlow";
import ProductActions from "./ProductActions";

export default function ProductFlow(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const openMoreActions = Boolean(anchorEl);

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    }

    const getWhoColumns = (row) => {
        const styles = {
            label: {
                fontSize: 12,
                pr: 5
            },
            value: {
                fontSize: 14
            },
        }

        return <table>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.created_by_user ?? "System"}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.created_date)}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Last updated by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.modified_by_user ?? 'System'}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Updated on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.modified_date)}</Typography>
                </td>
            </tr>
        </table>
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Product Name', flex: 3 },
        { field: 'header_name', headerName: 'Plan Name', flex: 3 },
        {
            field: 'price_model',
            headerName: 'Price Model',
            renderCell: (params) => {
                return params.row.price_model.name;
            },
            flex: 3
        },
        {
            field: 'gateway_type',
            headerName: 'Payment Gateway',
            renderCell: (params) => {
                return params.row.payment_integration.gateway_type;
            },
            flex: 3
        },
        {
            field: 'details', headerName: 'Who?', flex: 2, renderCell: (params) => {
                const row = params.row;
                return <IconContextMenu Icon={PersonIcon} Content={
                    <Grid sx={{ p: 2, zoom: 0.9 }}>
                        {getWhoColumns(row)}
                    </Grid>
                }></IconContextMenu>
            }
        },
        // {
        //     field: 'price_metric',
        //     headerName: 'Price Metrics',
        //     renderCell: (params) => '/' + `${params.row.price_metrics.join('/')}`,
        //     flex:2
        // },
        // { field: 'created_by_user', headerName: 'Created By', flex:3 },
        // { field: 'modified_by_user', headerName: 'Updated By', flex:3 },
        // {
        //     field: 'created_date',
        //     headerName: 'Created On',
        //     flex:3,
        //     valueFormatter: (params) => {
        //         const date = new Date(params.value);
        //         return date.toLocaleString('en-US',
        //             {
        //                 day: 'numeric',
        //                 month: 'short',
        //                 year: 'numeric',
        //                 hour: '2-digit',
        //                 minute:'2-digit',
        //                 hour12:true,
        //             });
        //     }
        // },
        // {
        //     field: 'modified_date',
        //     headerName: 'Updated On',
        //     flex:3,
        //     valueFormatter: (params) => {
        //         const date = new Date(params.value);
        //         return date.toLocaleString('en-US',
        //             {
        //                 day: 'numeric',
        //                 month: 'short',
        //                 year: 'numeric',
        //                 hour: '2-digit',
        //                 minute:'2-digit',
        //                 hour12:true,
        //             });
        //     }
        // },
        {
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            align: "center",
            renderCell: (params) => {
                const onClickFix = (event) => {
                    // console.log(params.row);
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                    setActiveProduct(params.row)
                };
                return <>
                    <>
                        <Tooltip title="More Actions">
                            <MoreHorizIcon
                                id="more-actions"
                                aria-controls={openMoreActions ? 'more-actions-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMoreActions ? 'true' : undefined}
                                onClick={(event) => onClickFix(event)}
                                sx={{
                                    fontSize: 24,
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'primary.background', borderRadius: 1 },
                                }}
                            />
                        </Tooltip>
                        <Menu
                            id="more-actions-menu"
                            aria-labelledby="more-actions"
                            anchorEl={anchorEl}
                            open={openMoreActions}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onClose={(event) => {
                                handleMenuClose(event);
                            }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                            elevation={2}
                            sx={{
                                '& .MuiMenuItem-root': {
                                    '&:hover': {
                                        borderLeft: '2px solid',
                                        borderLeftColor: 'primary.main',
                                        backgroundColor: 'primary.background'
                                    },
                                    borderLeft: '2px solid white',
                                },
                                '& .MuiTypography-body1': {
                                    fontSize: 14, pr: 1
                                }
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    handleMenuClose(event);
                                    handleUpdateProductClick()
                                }}
                            >
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Edit</ListItemText>

                            </MenuItem>

                            {/*<MenuItem*/}
                            {/*    onClick={(event) => {*/}
                            {/*        event.stopPropagation();*/}
                            {/*        event.preventDefault();*/}
                            {/*        handleMenuClose(event);*/}
                            {/*        // fn to open details display here*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <ListItemIcon>*/}
                            {/*        <InfoIcon fontSize="small" />*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText>Details</ListItemText>*/}
                            {/*</MenuItem>*/}

                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    handleMenuClose(event);
                                    setIsDialogOpen("removeProduct")
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                    {/*<Tooltip title="Delete Product">*/}
                    {/*    <DeleteIcon*/}
                    {/*        onClick={(event) => {*/}
                    {/*            event.preventDefault();*/}
                    {/*            event.stopPropagation();*/}
                    {/*            setIsDialogOpen("removeProduct")*/}
                    {/*            setActiveProduct(params.row)*/}
                    {/*        }}*/}
                    {/*        sx={{*/}
                    {/*            color: "lightgray",*/}
                    {/*            "&:hover": {*/}
                    {/*                color: "black",*/}
                    {/*                cursor: "pointer"*/}
                    {/*            },*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Tooltip>*/}
                    {/*<Tooltip title="Edit Product">*/}
                    {/*    <EditIcon*/}
                    {/*        onClick={(event) => {*/}
                    {/*            event.preventDefault();*/}
                    {/*            event.stopPropagation();*/}
                    {/*            setActiveProduct(params.row);*/}
                    {/*            handleUpdateProductClick();*/}
                    {/*        }}*/}
                    {/*        sx={{*/}
                    {/*            color: "lightgray",*/}
                    {/*            "&:hover": {*/}
                    {/*                color: "black",*/}
                    {/*                cursor: "pointer"*/}
                    {/*            },*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Tooltip>*/}
                </>;
            },
        },
    ];

    const { products, updateProducts } = useContext(ProductContext);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [activeProduct, setActiveProduct] = useState();
    const [snackNotif, setSnackNotif] = useState("");

    const handleDialogClose = () => {
        setIsDialogOpen("")
        setError(null)
    }

    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }

    const [open, setOpen] = useState(false);
    const handleCreateProductClick = () => {
        setOpen(!open);
    };

    const [openEdit, setOpenEdit] = useState(false);
    const handleUpdateProductClick = () => {
        setOpenEdit(!openEdit);
    };

    const removeProduct = () => {
        const productId = activeProduct.id
        setLoading(true);
        setError(null);
        deleteProduct(productId, () => {
            updateProducts(products.filter(product => product.id !== productId));
            setLoading(false);
            handleSnackNotif("removeProduct")
            handleDialogClose();
        }, err => {
            setLoading(false);
            setError(err?.error_message ?? "Products could not be removed!");
        });
    }

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                    <Typography fontSize={30}>
                        Products
                    </Typography>
                    <Grid item>
                        <Tooltip title="Add Product">
                            <Fab color="primary" aria-label="add" size="small"
                                onClick={handleCreateProductClick}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item
                    sx={{
                        height: '60vh',
                        bgcolor: 'white',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {props.loading ?
                        <CircularProgress />
                        :
                        <>
                            {products.length
                                ?
                                <DataGrid
                                    sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                            outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiDataGrid-row:hover': {
                                            cursor: 'pointer'
                                        },
                                        width: '50vw',
                                        '& .MuiDataGrid-cell': {
                                            fontSize: 12,
                                        },
                                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                            display: 'none'
                                        }
                                    }}
                                    rowHeight={40}
                                    rows={products}
                                    // loading={loading}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 100 },
                                        },
                                    }}
                                    // onRowClick={handleRowClick}
                                    pageSizeOptions={[10, 50, 100]}
                                // onCellClick={handleRowHover}
                                // checkboxSelection
                                />
                                :
                                <Typography color={'primary.greyText'}>
                                    Add your first product by clicking on the + icon above.
                                </Typography>
                            }
                        </>
                    }
                </Grid>
            </Grid >

            {/*CREATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <ProductActions
                    mode={'Add'}
                    closeActionsProduct={handleCreateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*UPDATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
                <ProductActions
                    mode={'Edit'}
                    product={activeProduct}
                    closeActionsProduct={handleUpdateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*REMOVE PRODUCT DIALOG*/}
            <Dialog open={isDialogOpen === "removeProduct"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Remove Product From User</Typography>

                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Are you sure you want to remove this product from your products list?
                        </Alert>

                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>
                            <Button variant="contained" color="error" size="medium" onClick={removeProduct} sx={{ ml: 1 }}>Remove</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*ADD PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "addProduct"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product added successfully!
                </Alert>
            </Snackbar>

            {/*REMOVE PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "removeProduct"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product removed successfully!
                </Alert>
            </Snackbar>

            {/*UPDATE PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "updateProduct"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product updated successfully!
                </Alert>
            </Snackbar>
        </>
    );
}


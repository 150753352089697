import React from "react";
import { Typography, Tooltip } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { percentFormatter_2 } from "utils/Utils";

export default function PercentageDeltaLabel(props) {
    const value = props.value;
    const formatter = (x) => percentFormatter_2(Math.abs(x));

    const getTitle = () => {
        return value > 0 ? "Increase by " + formatter(value)
            : value < 0 ? "Decrease by " + formatter(value)
                : "No change";
    }

    return (<React.Fragment><Typography sx={[{ display: "inline", mr: 1, fontSize: 13 }]}> {formatter(value)}
    </Typography>
        <Tooltip title={getTitle()}>
            {value === 0 ?
                <React.Fragment></React.Fragment>
                : value > 0 ?
                    <ArrowUpwardIcon color="success" sx={{ display: "inline", fontSize: 13, fontWeight: "bold", cursor: "pointer" }} />
                    : <ArrowDownwardIcon color="error" sx={{ display: "inline", fontSize: 13, fontWeight: "bold", cursor: "pointer" }} />}
        </Tooltip></React.Fragment>)
}
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BarChartIcon from '@mui/icons-material/BarChart';
import ClearIcon from '@mui/icons-material/Clear';
import InventoryIcon from '@mui/icons-material/Inventory';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { Grid, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useState } from "react";
import { useDrop } from 'react-dnd';
import DraggableItem from '../../components/DraggableItem';

export const selectionList = [
    // {
    //     id: 1,
    //     name: "Willingness To Pay",
    //     Icon: PriceCheckIcon
    // },
    // {
    //     id: 2,
    //     name: "Value Modelling",
    //     Icon: BarChartIcon
    // },
    {
        id: 3,
        name: "Price Modelling",
        Icon: AutoGraphIcon
    },
    {
        id: 4,
        name: "Packaging",
        Icon: InventoryIcon
    }
];


export default function WorkspaceDropSelector(props) {
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: "workspace",
        drop: (item) => addWorkspace(item.id),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const addWorkspace = (id) => {
        const option = selectionList.find((item) => item.id === id);
        setSelectedWorkspaces((selectedWorkspaces) => {
            const update = (selectedWorkspaces.length < 4 && !selectedWorkspaces.some((item) => item.id === id)) ?
                [...selectedWorkspaces, option] : selectedWorkspaces;

            notifyWorkspaceUpdate(update);
            return update;
        });
    };

    const notifyWorkspaceUpdate = (workspaces) => {
        console.log(workspaces);
        if (props.onUpdate) props.onUpdate(workspaces);
    }

    const removeWorkspace = (id) => {
        const updated = selectedWorkspaces.filter((item) => item.id !== id);
        setSelectedWorkspaces(updated);
        notifyWorkspaceUpdate(updated);
    };

    return (
        <>
            <Grid
                ref={drop}
                container
                item
                sx={{
                    height: 200,
                    width: "100%",
                    border: "3px dashed #b2b2b2",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 2,
                    backgroundColor: "primary.lightMain"
                }}
            >
                {selectedWorkspaces.length === 0 ? (
                    <Typography color="primary.greyText" fontSize={12}>Drag options here to select</Typography>
                ) : (
                    selectedWorkspaces
                        .sort((a, b) => a.id - b.id)
                        .map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid
                                    item
                                    key={item.id}
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <DraggableItem id={item.id} name={item.name} />
                                    <Tooltip title="Remove Workspace">
                                        <IconButton
                                            onClick={() => removeWorkspace(item.id)}
                                            size="small"
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "primary.background",
                                                    color: "black",
                                                },
                                                mt: -2,
                                                transform: "scale(0.8)",
                                                width: 25,
                                                height: 25
                                            }}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {index !== selectedWorkspaces.length - 1 && (
                                    <ArrowRightAltIcon sx={{ pb: 2.5 }} />
                                )}
                            </React.Fragment>
                        ))
                )}
            </Grid>
        </>
    );
}
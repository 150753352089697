import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Alert, Button, Checkbox,
    CircularProgress, Divider, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography
} from "@mui/material";
import Link from "@mui/material/Link";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {deauthorizeStripe, getOrganization, getStripeOnboardingUrl} from "../../apis/ClientDataService";
import { useErrorContext } from "../../state/ErrorDialogContext";
import OrganizationContext from "../../state/OrganizationContext";
import { getCurrentOrgId } from "../../state/state";
import { countryCodes } from "./CountryList";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DiscountIcon from '@mui/icons-material/Discount';
import {isTesModeLink, isTestModeLink} from "../../utils/Utils";

export default function PaymentIntegrationFlow(props) {

    const { loadErrorDialog } = useErrorContext();
    const { globalOrganization, updateGlobalOrganization } = React.useContext(OrganizationContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [radioValue, setRadioValue] = useState("Stripe");

    const [countryCode, setCountryCode] = useState("US");

    const handleRadioValue = (event) => {
        setRadioValue(event.target.value)
    };

    const [activeStep, setActiveStep] = useState(1);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //STRIPE ONBOARDING PORTAL
    const handleStripeOnboardingPortal = () => {

        setLoading(true)
        setStatus("PENDING")

        getStripeOnboardingUrl(countryCode, (data) => {
            // console.log(data)
            window.open(data.url, '_blank', 'noopener,noreferrer');
            setLoading(false)
        }, (err) => {
            setLoading(false)
            console.log(err)
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to create onboarding url" });
        })

    }

    const [status, setStatus] = useState("NONE");
    let intervalId = null;
    let intervalDeauthId = null;
    const [connectId, setConnectId] = useState("");
    const [deauthAgreement, setDeauthAgreement] = useState(false);

    const getOrgFromId = () => {

        // if(globalOrganization.payment_integration?.stripe_properties?.activated === true){
        //     setStatus("ACTIVATED");
        //     setConnectId(globalOrganization.payment_integration.stripe_properties.connect_id)
        //     clearInterval(intervalId);
        //     // console.log("ACTIVATED", globalOrganization)
        // }
        // else{
            getOrganization(getCurrentOrgId(), (org) => {
                if (org.payment_integration?.stripe_properties?.activated === true) {
                    updateGlobalOrganization(org)
                    setStatus("ACTIVATED");
                    setConnectId(org.payment_integration.stripe_properties.connect_id)
                    clearInterval(intervalId);
                    console.log("ACTIVATED", org)
                }
                else {
                    console.log("NOT ACTIVATED", org)
                }
            }, (err) => {
                console.log(err)
            })
        // }
    }

    useEffect(() => {
        if(globalOrganization.payment_integration?.stripe_properties?.activated === true){
            setStatus("ACTIVATED");
            setConnectId(globalOrganization.payment_integration.stripe_properties.connect_id)
            // clearInterval(intervalId);
            // console.log("ACTIVATED", globalOrganization)
        }
        else{
            getOrgFromId()
            intervalId = setInterval(getOrgFromId, 5000);
        }
        return () => clearInterval(intervalId);
    }, []);

    const getDeauthOrgFromId = () => {
        getOrganization(getCurrentOrgId(), (org) => {
            if (org.payment_integration?.stripe_properties?.activated === true) {
                console.log("ACTIVATED", org)
            }
            else {
                updateGlobalOrganization(org)
                setStatus("NONE");
                setConnectId("")
                clearInterval(intervalDeauthId);
                console.log("NOT ACTIVATED", org)
            }
        }, (err) => {
            console.log(err)
        })
    }

    const deauthStripe =() => {
        setLoading(true)
        setStatus("PENDINGDEAUTH")

        deauthorizeStripe( (data) => {
            // console.log(data)
            setLoading(false)
            getDeauthOrgFromId()
            intervalDeauthId = setInterval(getDeauthOrgFromId, 5000);
        }, (err) => {
            setLoading(false)
            console.log(err)
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to deauthorize Stripe Connect Account" });
        })
        return () => clearInterval(intervalDeauthId);
    }

    return (
        <Grid container>
            {loading ?
                <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 350, boxShadow: 2, borderRadius: 2, p: 4 }} >
                    <CircularProgress />
                </Grid>
                :
                <Grid container item sx={{ width: 550, minHeight: 350, borderRadius: 2, p: 4 }} direction="column">
                    {status === 'ACTIVATED' ?
                        <Typography sx={{ color: "primary.main", mb: 2, fontSize: 20, fontWeight: "bold" }}>
                            Manage Stripe Integration Of {globalOrganization.name}
                        </Typography>
                        :
                        <>
                            {
                                (status === 'DEAUTHORIZE' || status === 'PENDINGDEAUTH') ?
                                    <Typography sx={{ color: "primary.main", mb: 2, fontSize: 20, fontWeight: "bold" }}>
                                        Disconnect Stripe Integration From {globalOrganization.name}
                                    </Typography>
                                    :
                                    <Typography sx={{ color: "primary.main", mb: 2, fontSize: 20, fontWeight: "bold" }}>
                                        Add Stripe Integration To {globalOrganization.name}
                                    </Typography>
                            }
                        </>
                    }

                    <React.Fragment>
                        {activeStep === 0 ?
                            <Grid container >
                                <FormControl>
                                    <Typography my={1}>Choose your payment gateway :</Typography>
                                    <RadioGroup
                                        row
                                        value={radioValue}
                                        onChange={handleRadioValue}
                                    >
                                        <FormControlLabel value="Stripe" control={<Radio />} label={
                                            <>
                                                <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png'}
                                                    alt='Stripe' width="100px" height="auto" style={{ marginRight: "7px" }} />
                                            </>
                                        } />

                                        <FormControlLabel value="Paypal" control={<Radio />} label={
                                            <>
                                                <img src={'https://1000logos.net/wp-content/uploads/2021/04/Paypal-logo.png'}
                                                    alt='Paypal' width="100px" height="auto" style={{ marginRight: "7px" }} />
                                            </>
                                        } />

                                        {/*<FormControlLabel value="Togai"  control={<Radio />} label={*/}
                                        {/*    <>*/}
                                        {/*        <img src={'https://www.togai.com/wp-content/uploads/2023/03/Togai-Logotype-1-1.png'}*/}
                                        {/*             width="80px" height="auto" style={{ marginRight: "7px" }} />*/}
                                        {/*    </>*/}
                                        {/*} />*/}

                                    </RadioGroup>
                                </FormControl>

                                <Alert severity="info" sx={{ my: 2 }}>
                                    {radioValue.length === 0 ?
                                        'Please select your payment gateway you want to connect with current organization.' :
                                        radioValue === 'Stripe' ?
                                            'Please make sure you have setup your Stripe account properly to connect to PriceOps using Stripe Connect.' :
                                            `${radioValue} Payment Integration is under construction and will be coming soon.`
                                    }
                                </Alert>


                                {/*{error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}*/}
                                {/*<Grid item container justifyContent='flex-end'>*/}
                                {/*  <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>*/}
                                {/*  <Button variant="contained" color="error" size="medium" onClick={removeProduct} sx={{ ml: 1 }}>Remove</Button>*/}
                                {/*</Grid>*/}
                            </Grid>
                            :
                            <Grid container >
                                {status === 'NONE' &&
                                    <Grid container>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            select
                                            size='small'
                                            label="Select a country"
                                            value={countryCode}
                                            onChange={(e) => { setCountryCode(e.target.value) }}
                                        >
                                            {Object.keys(countryCodes).map((code) => (
                                                <MenuItem value={code}>{countryCodes[code]}</MenuItem>
                                            ))}
                                        </TextField>

                                        <Alert severity="info" sx={{ my: 2 }}>
                                            Click on the button below to open a Stripe Connect portal and
                                            complete the verification process to enable Stripe Connect.
                                        </Alert>

                                        <Button
                                            sx={{
                                                py: 3,
                                                my: 1,
                                                fontSize: 14,
                                                fontWeight: 600,
                                                width: '100%'
                                            }}
                                            // disabled={countryCode.length === 0}
                                            onClick={handleStripeOnboardingPortal}
                                            variant={'contained'}
                                        >
                                            Open Stripe Connect Onboarding Portal
                                        </Button>
                                    </Grid>
                                }
                                {status === 'PENDING' &&
                                    <Grid justifyContent={'center'} alignItems={'center'} container>
                                        <Grid container justifyContent={'center'} alignItems={'center'} py={3}>
                                            <CircularProgress />
                                            <>
                                                <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png'}
                                                    alt='Stripe' width="180px" height="auto" style={{ marginRight: "7px" }} />
                                            </>
                                        </Grid>
                                        <Alert severity={'info'}>
                                            {globalOrganization.name} onboarding is pending. Please complete the onboarding process to PriceOps using Stripe Connect.
                                        </Alert>
                                    </Grid>
                                }
                                {status === 'PENDINGDEAUTH' &&
                                    <Grid justifyContent={'center'} alignItems={'center'} container>
                                        <Grid container justifyContent={'center'} alignItems={'center'} py={3}>
                                            <CircularProgress />
                                            <>
                                                <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png'}
                                                     alt='Stripe' width="180px" height="auto" style={{ marginRight: "7px" }} />
                                            </>
                                        </Grid>
                                        <Alert severity={'info'}>
                                            {globalOrganization.name} is deauthorizing from Stripe. Please wait for the process to complete.
                                        </Alert>
                                    </Grid>
                                }
                                {status === 'ACTIVATED' &&
                                    <Grid justifyContent={'center'} alignItems={'center'} container rowGap={3}>
                                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                            <Grid container width={'55%'} >
                                                <CheckCircleIcon
                                                    sx={{
                                                        fontSize: 80,
                                                        color: '#635bff'
                                                    }}
                                                />
                                                <>
                                                    <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png'}
                                                         alt='Stripe' width="180px" height="auto" />
                                                </>
                                            </Grid>
                                            <Grid container direction={'column'} width={'45%'}
                                                borderLeft={'1px solid lightgray'}
                                            >
                                                <Link
                                                    href={`https://dashboard.stripe.com/${connectId}/`}
                                                    target="_blank"
                                                    underline='none'
                                                    color={'inherit'}
                                                >
                                                    <Grid container alignItems={'center'} pl={3} py={1}
                                                          sx={{
                                                              '&:hover': {bgcolor: 'primary.background',cursor:'pointer'}
                                                          }}
                                                    >
                                                        <DashboardIcon sx={{color:'#635bff'}}/>
                                                        <Typography fontSize={14} pl={1} fontWeight={600}>View Dashboard</Typography>
                                                    </Grid>
                                                </Link>
                                                <Link
                                                    href={`https://dashboard.stripe.com/${connectId}${isTestModeLink()}/tax`}
                                                    target="_blank"
                                                    underline='none'
                                                    color={'inherit'}
                                                >
                                                    <Grid container alignItems={'center'} pl={3} py={1}
                                                          sx={{
                                                              '&:hover': {bgcolor: 'primary.background',cursor:'pointer'}
                                                          }}
                                                    >
                                                        <MonetizationOnIcon sx={{color:'#635bff'}}/>
                                                        <Typography fontSize={14} pl={1} fontWeight={600}>Setup Stripe Tax</Typography>
                                                    </Grid>
                                                </Link>
                                                <Link
                                                    href={`https://dashboard.stripe.com/${connectId}${isTestModeLink()}/settings/billing/portal`}
                                                    target="_blank"
                                                    underline='none'
                                                    color={'inherit'}
                                                >
                                                    <Grid container alignItems={'center'} pl={3} py={1}
                                                          sx={{
                                                              '&:hover': {bgcolor: 'primary.background',cursor:'pointer'}
                                                          }}
                                                    >
                                                        <ManageAccountsIcon sx={{color:'#635bff'}}/>
                                                        <Typography fontSize={14} pl={1} fontWeight={600}>Manage Customers</Typography>
                                                    </Grid>
                                                </Link>
                                                {/*<Link*/}
                                                {/*    href={`https://dashboard.stripe.com/${connectId}${isTestModeLink()}/coupons`}*/}
                                                {/*    target="_blank"*/}
                                                {/*    underline='none'*/}
                                                {/*    color={'inherit'}*/}
                                                {/*>*/}
                                                {/*    <Grid container alignItems={'center'} pl={3} py={1}*/}
                                                {/*          sx={{*/}
                                                {/*              '&:hover': {bgcolor: 'primary.background',cursor:'pointer'}*/}
                                                {/*          }}*/}
                                                {/*    >*/}
                                                {/*        <DiscountIcon sx={{color:'#635bff'}}/>*/}
                                                {/*        <Typography fontSize={14} pl={1} fontWeight={600}>View Coupons</Typography>*/}
                                                {/*    </Grid>*/}
                                                {/*</Link>*/}
                                            </Grid>
                                        </Grid>
                                        <Alert severity={'success'} sx={{width:'100%'}}>
                                            {globalOrganization.name} has been onboarded to PriceOps using Stripe Connect.
                                            Manage your account by clicking on the settings above.
                                        </Alert>
                                    </Grid>
                                }
                                {status === 'DEAUTHORIZE' &&
                                    <Grid container>

                                        <Grid container alignItems={'center'}>
                                            <Checkbox
                                                checked={deauthAgreement}
                                                onChange={(e)=>setDeauthAgreement(e.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                I agree to disconnect {globalOrganization.name} Stripe Connect account
                                            </Typography>
                                        </Grid>

                                        <Alert severity="warning" sx={{ my: 2 }}>
                                            Disconnecting your Stripe Connect account will remove the account from PriceOps' platform.
                                            You will lose any platform controls including funds and transaction access,
                                            which can’t be restored further.
                                        </Alert>

                                        <Button
                                            sx={{
                                                py: 3,
                                                my: 1,
                                                fontSize: 14,
                                                fontWeight: 600,
                                                width: '100%'
                                            }}
                                            color="error"
                                            disabled={!deauthAgreement}
                                            onClick={deauthStripe}
                                            variant={'contained'}
                                        >
                                            Disconnect Stripe Connect Account
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            {/*<Button*/}
                            {/*    disabled={activeStep === 0}*/}
                            {/*    onClick={handleBack}*/}
                            {/*    sx={{ mr: 1 }}*/}
                            {/*    variant={'outlined'}*/}
                            {/*>*/}
                            {/*    Back*/}
                            {/*</Button>*/}
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button
                                onClick={props.handlePaymentIntegrationDialog}
                                // disabled={status==='ACTIVATED'}
                                variant={'outlined'}
                                sx={{ mr: 1 }}
                            >
                                Cancel
                            </Button>
                            {/*{(status !== 'ACTIVATED' || activeStep === 0) ?*/}
                            {/*    <Button*/}
                            {/*        onClick={handleNext}*/}
                            {/*        disabled={activeStep === 1 || radioValue !== 'Stripe'}*/}
                            {/*        variant={'outlined'}*/}
                            {/*    >*/}
                            {/*        Next*/}
                            {/*    </Button>*/}
                            {/*    :*/}
                            {/*{status !== "DEAUTHORIZE" ?*/}
                                <Button
                                    onClick={(e)=>setStatus("DEAUTHORIZE")}
                                    disabled={status !== 'ACTIVATED'}
                                    variant={'contained'}
                                    color='error'
                                >
                                    Disconnect
                                </Button>
                                {/*:*/}
                                {/*<Button*/}
                                {/*    onClick={(e)=>setStatus("ACTIVATED")}*/}
                                {/*    variant={'outlined'}*/}
                                {/*>*/}
                                {/*    Back*/}
                                {/*</Button>*/}
                            {/*}*/}
                            {/*}*/}
                        </Box>
                    </React.Fragment>

                </Grid>
            }
        </Grid>

    )
}
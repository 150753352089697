import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { getBasicProfile, getCompany, saveData } from "../../apis/ClientDataService";
import CardContentContainer from "../../components/CardContentContainer";
import CustField from "../../components/CustField";
import { getCurrentOrgId, getParam, updateParam } from "../../state/state";
import { copy } from "../../utils/Utils";

export default function BasicProfileCard(props) {
  const [basicProfileParams, setBasicProfileParams] = React.useState(getParam('basic_profile'));
  const [formState, setFormState] = React.useState(null);

  React.useEffect(() => {
    setFormState('loading');
    getBasicProfile((data) => {
      setBasicProfileParams(data);
      setFormState('success');
    }, (err) => {
      setFormState('error');
    });
  }, [])

  const setAndUpdate = (profile) => {
    setBasicProfileParams(profile);
    updateParam('basic_profile', profile);
    setFormState('yet_to_save');
  }

  const fetchCrunchBase = () => {
    setFormState('loading');
    getCompany(
      getCurrentOrgId(),
      (data) => {
        var properties = data.properties;
        var profile = copy(basicProfileParams);
        profile.name = properties.name;
        profile.about = properties.short_description;
        setAndUpdate(profile);
        setFormState('success');
      },
      (err) => { setFormState('error'); alert(err['error_message']); }
    );
  };

  const onChange = (e, field) => {
    var profile = copy(basicProfileParams)
    profile[field] = e.target.value;

    setAndUpdate(profile);
  }

  const save = () => {
    setFormState('loading');
    saveData('basic_profile', basicProfileParams, () => { setFormState('success'); }, () => { setFormState('error') });
  }

  return (
    <CardContentContainer
      {...props}
      cardIcon={<AccountBoxIcon />}
      title="Company information"
      data={basicProfileParams}
      formState={formState}
      save={() => save()}
      content={
        <React.Fragment>
          <Grid container item xs={12} sx={{ p: 2 }} justifyContent="right">
            <Button onClick={() => fetchCrunchBase()} variant="outlined" sx={{ textTransform: 'None' }}>Import from Crunchbase</Button>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3} sx={{ border: 1, borderRadius: 2, p: 2, borderColor: "primary.groupBorder" }}
              noValidate
              autoComplete="off"
            >
              <CustField
                label="Company name"
                value={basicProfileParams.name}
                onChange={(e) => { onChange(e, 'name') }}
              />
              <CustField
                multiline
                minRows={2}
                sx={{ mt: 2 }}
                maxRows={10}
                placeholder=""
                label="About"
                value={basicProfileParams.about ? basicProfileParams.about : ''}
                onChange={(e) => { onChange(e, 'about') }}
              />
              <CustField
                label="Head quarters"
                value={basicProfileParams.headquarters}
                onChange={(e) => { onChange(e, 'headquarters') }}
              />
              <InputLabel id="industry">Industry</InputLabel>
              <Select
                labelId="industry"
                id="industry"
                value={basicProfileParams.industry ? basicProfileParams.industry : 'IT'}
                label="Industry"
                sx={{ height: 35 }}
                onChange={(e) => onChange(e, 'industry')}
              >
                <MenuItem value={'IT'}>IT</MenuItem>
                <MenuItem value={'Business'}>Business</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ border: 1, borderRadius: 2, p: 2, ml: 3, borderColor: "primary.groupBorder" }}>
              <CustField
                label="Contact information"
                value={basicProfileParams.contact}
                onChange={(e) => { onChange(e, 'contact') }}
              />
              <CustField
                label="Email id"
                value={basicProfileParams.email}
                onChange={(e) => { onChange(e, 'email') }}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      }
    ></CardContentContainer>
  );
}

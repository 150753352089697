import {
    Backdrop,
    CircularProgress, Grid
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { getDesign } from "../../apis/ClientDataService";
import DesignPreview from "./DesignPreview";

const fonts = [
    'Open Sans',
    'Roboto',
    'IBM Plex Sans',
    'Inter',
    'system-ui',
    'Montserrat',
    'Chakra Petch',
    'Helvetica',
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Gill Sans',
    'Times New Roman',
    'Georgia',
    'Palatino',
    'Baskerville',
    'Andalé Mono',
    'Courier',
    'Lucida',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS'
];

export default function PublisherDesign(props) {
    //
    // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    //     useGeolocated({
    //         positionOptions: {
    //             enableHighAccuracy: false,
    //         },
    //         userDecisionTimeout: 5000,
    //     });
    //
    // const [location, setLocation] = useState(null);
    // const [loadingLocation, setLoadingLocation] = React.useState(true);
    //
    // useEffect(() => {
    //     if (coords) {
    //         fetch(`https://geocode.maps.co/reverse?lat=${coords.latitude}&lon=${coords.longitude}`)
    //             .then(response => response.json())
    //             .then(data =>
    //                 {
    //                     setLocation(data)
    //                     setLoadingLocation(false)
    //                     console.log("Current Location Is",data.address.country)
    //                 }
    //             );
    //     }
    // }, [coords]);

    const { designId } = useParams('designId');
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [design, setDesign] = React.useState(null);

    useEffect(() => {
        setError(null);
        getDesign(designId, (design) => {

            // console.log(design)

            // console.log("Allowed Location Are ",design.properties.geo_location_countries_allowed)

            // setTimeout(() => {
                setDesign(design);
                setLoading(false);
            // }, 1500);
        }, err => {
            setLoading(false);
            setError("Design could not be fetched!");
        });
    }, []);

    return (
        <Grid sx={{ minHeight: '100vh' }}>
            <Backdrop
                sx={{
                    color: 'primary.main',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    bgcolor: 'primary.background'
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/*{ loadingLocation ?*/}

            {/*    <Grid direction={'column'}*/}
            {/*          height={'100vh'} width={'100vw'}*/}
            {/*          alignItems={'center'} container justifyContent={'center'} gap={2}*/}
            {/*    >*/}
            {/*        <Dialog*/}
            {/*            open={loadingLocation}*/}
            {/*        >*/}
            {/*            <DialogTitle id="alert-dialog-title">*/}
            {/*                <Grid container alignItems={'center'} gap={1}>*/}
            {/*                    <LocationOnIcon/>*/}
            {/*                    <Typography fontWeight={800} fontSize={20}>*/}
            {/*                        Allow Location Sharing*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*            </DialogTitle>*/}
            {/*            <DialogContent>*/}
            {/*                <Typography fontSize={16}>*/}
            {/*                    This Pricing Section is location restricted.*/}
            {/*                    <br/>*/}
            {/*                    Please enable location sharing to proceed further.*/}
            {/*                </Typography>*/}
            {/*            </DialogContent>*/}
            {/*        </Dialog>*/}

            {/*        <Grid container gap={3} width={'100vw'} justifyContent={'center'} >*/}
            {/*            <Skeleton variant="rounded" width={300} height={400} />*/}
            {/*            <Skeleton variant="rounded" width={300} height={400} />*/}
            {/*            <Skeleton variant="rounded" width={300} height={400} />*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*    :*/}
            {/*    <>*/}
            {design && <Grid sx={{ boxShadow: "none" }}>
                <DesignPreview
                    designFrameId={`priceops-design-${designId}`}
                    scale={1.0}
                    products={design.selected_products}
                    designBgColor={design.properties.bg_color}
                    designButtonColor={design.properties.button_color}
                    designCardColor={design.properties.card_color}
                    designHighlightedButtonColor={design.properties.highlighted_button_color}
                    designHighlightedCardColor={design.properties.highlighted_card_color}
                    designCtaButtonColor={design.properties.cta_button_color}
                    designCtaCardColor={design.properties.cta_card_color}
                    designCardBoxShadow={design.properties.card_box_shadow}
                    designCardBorderRadius={design.properties.card_border_radius}
                    designButtonBorderRadius={design.properties.button_border_radius}
                    designCardBorderColor={design.properties.card_border_color}
                    designCtaBorderColor={design.properties.cta_border_color}
                    designHighlightedPlanNameColor={design.properties.highlighted_plan_name_color}
                    designCardWidth={design.properties.card_width}
                    designCtaButtonLink={design.properties.cta_button_link}
                    designTickColor={design.properties.tick_color}
                    designHighlightedTickColor={design.properties.highlighted_tick_color}
                    designCtaTickColor={design.properties.cta_tick_color}
                    designButtonVariant={design.properties.button_variant}
                    designHighlightedButtonVariant={design.properties.highlighted_button_variant}
                    designStyleMode={design.properties.design_style_mode}
                    designFontStyle={design.properties.font_style}
                    designShape={design.properties.shape}
                    designHighlightedProduct={design.selected_products?.find(x => x.id === design.properties.highlighted_product_id)?.name}
                    designHighlightedText={design.properties.highlighted_text}
                    designButtonText={design.properties.button_text}
                    designCta={design.properties.cta}
                    designCtaName={design.properties.cta_name}
                    designCtaDescription={design.properties.cta_description}
                    designCtaButtonText={design.properties.cta_button_text}
                    designCtaFeatures={design.properties.cta_features}
                    loginRedirectLink={design.login_redirect_link}
                    requiresLoggedInUsers={design.requires_logged_in_users}
                    addOnSection={design.add_on_section}
                    headerSection={design.header_section}
                    designHeaderText={design.properties.header_text}
                    designSubheaderText={design.properties.subheader_text}
                    removeWatermark={design.properties.remove_watermark}
                    addOnProducts={design.add_on_products}
                />
            </Grid>}
            {/*    </>*/}
            {/*}*/}
        </Grid>
    )
}
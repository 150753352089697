import React, { useState, useEffect } from "react";
import { Alert, Button, TextField, CircularProgress, FormControl, Grid, InputAdornment, Typography, Box } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { renewOtp } from "../../apis/ClientDataService";
import { verifyUser } from "../../apis/ClientDataService";
import {LoadingButton} from "@mui/lab";

export default function OtpVerificationFlow(props) {
    const [otp, setOtp] = useState("");
    const [status, setStatus] = React.useState("OTP has been sent to your email and is only valid for the next 60 minutes!");
    const [state, setState] = React.useState("info");
    const [loading, setLoading] = React.useState(false);
    const [otpExpired, setOtpExpired] = React.useState(false);

    function validateForm() {
        return otp.length === 6 && /^\d+$/.test(otp);
    }

    function handleOTP(event) {
        // console.log(props.verifyUserId,parseInt(otp));
        event.preventDefault();

        if (!validateForm()) {
            setState("error");
            setStatus("Please enter a valid 6-digit OTP");
            return;
        }

        setLoading(true);
        verifyUser(props.verifyUserId,parseInt(otp), () => {
            setLoading(false);
            setStatus("Welcome to PriceOps. You have been verified successfully and can login now using your credentials.");
            setState("success");
            setOtp();
        }, err => {
            setLoading(false);
            setStatus("Failed to verify user, " + err.error_message);
            setState("error");
            if(err.error === "EXPIRED_OTP") setOtpExpired(true);
        });
    }

    function handleResendOTP(event) {
        event.preventDefault();
        setLoading(true);
        renewOtp(props.verifyUserId, () => {
            setLoading(false);
            setStatus("A new OTP has been sent to your email and is only valid for the next 5 minutes!");
            setOtpExpired(false);
            setState("info");
            setOtp();
        }, err => {
            setLoading(false);
            setStatus("Failed to resend otp, " + err.error_message);
            setState("error");
        });
    }

    return (
        <FormControl sx={{width: 400, height:250 ,p:4}}>
            <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>
                {state === 'success' ? 'Welcome To PriceOps' : 'Verify your account'}
            </Typography>
            <Grid
                  container item
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
            >
                {state === "success" ?
                    <Grid sx={{  py:2  }}>
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <CheckCircleIcon sx={{ color: "primary.main", height: 80, width: 80 }}></CheckCircleIcon>
                            <img src={require("./../../menubar/just_logo.png")} width={70} height={38} alt="logo" />
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: 42,
                                    fontWeight: 400,
                                    fontFamily: "Open Sans",
                                }}
                            >
                                PriceOps
                            </Typography>
                        </Grid>
                        <Alert severity={'success'} align="left" sx={{fontSize:12,mt:2}}>{status}</Alert>
                    </Grid>
                :
                    <React.Fragment>
                        <Grid item container
                              sx={{
                                // width: 90,
                                  py:2,
                                  width:'100%'
                            }}
                              // width={'100%'}
                              gap={1}
                        >
                            <Typography
                                fontSize={14}
                                width
                            >
                                Enter the OTP sent to your email
                            </Typography>
                            <Grid container width={'100%'} justifyContent={'space-between'} pt={0.5}>
                                <TextField
                                    size={'small'}
                                    label="OTP"
                                    value={otp}
                                    onChange={e => setOtp(e.target.value)}
                                    variant="outlined"
                                    autoFocus
                                    sx={{
                                        width:'45%'
                                    }}
                                    InputProps={{
                                        sx: {
                                            bgcolor: 'white',
                                            // height:50
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: 'gray',
                                        },
                                    }}

                                />

                                <Grid container item justifyContent="center" alignItems="center" direction="column" sx={{ width: '50%' }}>
                                    {!otpExpired ?
                                        <LoadingButton
                                            loading={loading}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{py:2.5}}
                                            onClick={handleOTP}
                                            // loadingIndicator="Verifying OTP…"
                                            loadingPosition="start"
                                        >
                                            <span>Verify OTP</span>
                                        </LoadingButton>
                                        :
                                        <LoadingButton
                                            loading={loading}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{py:2.5}}
                                            onClick={handleResendOTP}
                                            // loadingIndicator="Rending OTP…"
                                            loadingPosition="start"
                                        >
                                            <span>Resend OTP</span>
                                        </LoadingButton>
                                    }
                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid spacing={1} justifyContent="center" alignItems="center" direction="column" item sx={{ width: '100%',pb:2.8 }}>
                            {state === "error" && <Alert sx={{fontSize:12}} severity="error">{status}</Alert>}
                            {state === "info" && <Alert sx={{fontSize:12}} severity="info">{status}</Alert>}
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
            <Box sx={{ flex: '1 1 auto' }} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={() => {
                        props.updateLoading(false);
                        props.updateStatus(null);
                        props.updateState(null);
                        if (props.updateOtpDialogOpen) {
                            props.updateOtpDialogOpen(false);
                        }
                    }}
                    disabled={state === 'success'}
                    variant={'outlined'}
                    sx={{ mr: 1 }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        props.updateLoading(false);
                        props.updateStatus(null);
                        props.updateState(null);
                        if (props.updateOtpDialogOpen) {
                            props.updateOtpDialogOpen(false);
                        }
                    }}
                    disabled={state !== 'success'}
                    variant={'outlined'}
                >
                    Done
                </Button>
            </Box>
        </FormControl>
    );
}

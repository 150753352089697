import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert, Backdrop,
    Button, Checkbox,
    Chip, CircularProgress, Dialog, Divider, Drawer, FormControl, FormControlLabel, FormLabel,
    Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup,
    Select, Snackbar, Switch,
    TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, {useContext, useState, useRef, useEffect} from "react";
import { v4 as uuidv4 } from "uuid";
import { addProduct, updateProduct } from "../../apis/ClientDataService";
import CustDialogTitle from "../../components/CustDialogTitle";
import { getCurrentOrgId, getOrgFromLocalStore } from "../../state/state";
import CreatePriceModelDefinition from "../scenarios/price-modelling/components/CreatePriceModelDefinition";
import { getReadablePriceMetric } from "../scenarios/price-modelling/components/PriceModelDefinitions";
import PriceModelsDataContext from "../scenarios/PriceModelsDataContext";
import ScenarioDataContext from "../scenarios/ScenarioDataContext";
import {CouponContext, ProductContext} from "./PackagingFlow";
import PriceAdd from "./PriceAdd";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useErrorContext } from "state/ErrorDialogContext";
import Link from "@mui/material/Link";
import OrganizationContext from "../../state/OrganizationContext";
import {Box} from "@mui/system";
import CouponActions from "./CouponActions";
import EditIcon from "@mui/icons-material/Edit";
import {DndContext, closestCenter, useSensors, useSensor, PointerSensor} from "@dnd-kit/core";
import {arrayMove,SortableContext,verticalListSortingStrategy} from "@dnd-kit/sortable";
import {SortableFeatureItem} from "./SortableFeatureItem";
import {isTestModeLink} from "../../utils/Utils";
import LayersIcon from '@mui/icons-material/Layers';
import IconContextMenu from "../scenarios/price-modelling/components/IconContextMenu";
import PersonIcon from "@mui/icons-material/Person";

const gateways = ['Custom', 'Stripe',
    // 'Paypal', 'Togai'
];

const meteredUsageChargeMethods = {
    'sum' : 'Sum of usage values during period',
    'max' : 'Maximum usage value during period',
    'last_during_period' : 'Most recent usage value during period',
    // 'last_ever' : 'Most recent usage value'
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function ProductActions(props) {

    const { globalOrganization, updateGlobalOrganization , limitationsRef } = useContext(OrganizationContext);
    const [limitationAddOn,setLimitationAddOn] = useState(true)
    const [limitationStripe,setLimitationStripe] = useState(true)
    useEffect(()=>{
        setLimitationAddOn(limitationsRef.current.add_on)
        setLimitationStripe(limitationsRef.current.stripe)
    },[])

    const { coupons, updateCoupons } = useContext(CouponContext);
    const { priceModels, setPriceModels } = useContext(PriceModelsDataContext);
    const { scenario, updateScenario } = useContext(ScenarioDataContext);
    const { products, updateProducts } = useContext(ProductContext);
    const { loadErrorDialog } = useErrorContext();
    const [name, setName] = useState(props.mode === "Edit" ? props.product.name : '');
    const [headerName, setHeaderName] = useState(props.mode === "Edit" ? props.product.header_name : '');
    const [description, setDescription] = useState(props.mode === "Edit" ? props.product.description : '');
    const [priceModel, setPriceModel] = useState(props.mode === "Edit" ? props.product.price_model : null);
    const [addOnProduct, setAddOnProduct] = useState(props.mode === "Edit" ? props.product.add_on_product : false);
    const [isDialogOpen, setIsDialogOpen] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [snackbar, setSnackbar] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');
    // panel1 is price table, panel2 is usage table, panel3 is payment integration
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [gatewayType, setGatewayType] = useState(props.mode === "Edit" ?
        props.product.payment_integration.gateway_type : '');
    const [stripePriceIds, setStripePriceIds] = useState(props.mode === "Edit" ?
        props.product.payment_integration.stripe_properties?.price_ids : {})

    const [taxBehaviour, setTaxBehaviour] = useState(props.mode === "Edit" ?
        props.product.tax_integration?.tax_behaviour : 'inclusive');
    const [productTaxCategory, setProductTaxCategory] = useState(props.mode === "Edit" ?
        props.product.tax_integration?.tax_code : '');
    const [freeTrialActive, setFreeTrialActive] = useState(props.mode === "Edit" ?
        props.product.free_trial_integration?.active : false);
    const [freeTrialBehaviour, setFreeTrialBehaviour] = useState(props.mode === "Edit" ?
        props.product.free_trial_integration?.behaviour : 'withPaymentDetails');
    const [freeTrialEndBehaviour, setFreeTrialEndBehaviour] = useState(props.mode === "Edit" ?
        props.product.free_trial_integration?.end_behaviour : 'pause');
    const [freeTrialDays, setFreeTrialDays] = useState(props.mode === "Edit" ?
        props.product.free_trial_integration?.days : '');
    const [freeTrialSkip, setFreeTrialSkip] = useState(props.mode === "Edit" ?
        props.product.free_trial_integration?.skip_trial_option : false);
    const [freeTrialCoupon, setFreeTrialCoupon] = useState(props.mode === "Edit" ?
        props.product.free_trial_integration?.coupon : null);

    const [usageType, setUsageType] = useState(props.mode === "Edit" ?
        props.product.usage_type : '');
    const [usageAggregateStrategy, setUsageAggregateStrategy] = useState(props.mode === "Edit" ?
        props.product.usage_aggregate_strategy : '');

    const [currSelectedProduct, setCurrSelectedProduct] = useState("");

    const [selectedProducts, setSelectedProducts] = useState(() => {
        if (props.mode === 'Edit') {
            // Map over the selected product IDs
            return props.product.selected_products_ids.map(productId => {
                // Find the product object with the matching ID
                return products.find(product => product.id === productId);
            });
        } else {
            return [];
        }
    });


    const [searchResults, setSearchResults] = useState(
        props.mode === 'Edit'
            ? products.filter(product => product.payment_integration.gateway_type === 'Stripe' &&
                !props.product.selected_products_ids?.some(selectedProductId => selectedProductId === product.id))
            : products.filter(product => product.payment_integration.gateway_type === 'Stripe')
    );

    const handleAddToSelectedProducts = (result) => {
        // console.log(result)
        let value = result
        if (typeof value !== 'string') {
            if (value) {
                setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, value]);
                setSearchResults((prevSearchResults) =>
                    prevSearchResults.filter((product) => product.name !== value.name)
                );
                setCurrSelectedProduct(value.name)
            }
        }
    };

    const handleAddOnProductCheckbox = (result) => {
        if(result){
            setPrices([]);
            setGatewayType("Stripe")
        }
        setAddOnProduct(result)
    };

    const handleDeleteFromSelectedProducts = (productToDelete) => () => {
        setSelectedProducts((prevSelectedProducts) =>
            prevSelectedProducts.filter((product) => product.name !== productToDelete.name)
        );
        setSearchResults((prevSearchResults) => [...prevSearchResults, productToDelete]);
    };

    const [prices, setPrices] = useState(
        props.mode === "Edit"
            ? props.product.prices.map(price => ({
                ...price,
                id: price.billing_period.concat(' ', price.price_metric.name)
            }))
            : []
    );
    const [usages, setUsages] = useState(
        props.mode === "Edit"
            ? props.product.usages.map(usage => ({
                ...usage,
                id: usage.unit
            }))
            : []
    );
    const [chipData, setChipData] = useState(
        props.mode === "Edit"
            ? props.product.features
                .map((feature) => ({ id: uuidv4(), label: feature }))
            : []
    );

    const [buttonLinks, setButtonLinks] = useState(props.mode === "Edit" ? props.product.payment_integration.custom_button_links : {});

    const handleButtonLinksChange = (event, buttonName) => {
        setButtonLinks(prevLinks => ({
            ...prevLinks,
            [buttonName]: event.target.value
        }));

        // console.log(buttonLinks)
    };


    // const stripeSecretApiKeyRef = useRef(props.mode === "Edit" ?
    //     props.product.payment_integration.stripe_secret_api_key : '');
    // const handleStripeSecretApiKeyChange = (event) => {
    //     stripeSecretApiKeyRef.current = event.target.value
    // };
    //
    // const stripePublishableApiKeyRef = useRef(props.mode === "Edit" ?
    //     props.product.payment_integration.stripe_publishable_api_key : '');
    // const handleStripePublishableApiKeyChange = (event) => {
    //     stripePublishableApiKeyRef.current = event.target.value
    // };

    const [stripeSuccessUrl,setStripeSuccessUrl] = useState(props.mode === "Edit" ?
        props.product.payment_integration.stripe_properties?.success_url : '');
    const handleStripeSuccessUrlChange = (event) => {
        setStripeSuccessUrl(event.target.value)
    };

    const [stripeCancelUrl,setStripeCancelUrl] = useState(props.mode === "Edit" ?
        props.product.payment_integration.stripe_properties?.cancel_url : '');
    const handleStripeCancelUrlChange = (event) => {
        setStripeCancelUrl(event.target.value)
    };

    const handleTaxBehaviourChange = (event) => {
        setTaxBehaviour(event.target.value)
    }

    const handleProductTaxCategoryChange = (event) => {
        setProductTaxCategory(event.target.value)
    }

    // console.log(priceModels,"This is list of price models");

    const initializeUsageTable = (model) => {
        // Adding Price Units For Usage Metrics Input
        const units = new Set(); // To ensure no duplicate units

        const usageList = [];

        model.price_metrics.forEach((price_metric, index) => {
            price_metric.price_units.forEach((price_unit) => {
                // Exclude 'month' and 'year' units
                if (price_unit.unit.toLowerCase() !== 'month' && price_unit.unit.toLowerCase() !== 'year') {
                    const unit = price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1); // Capitalize the first letter

                    // console.log(price_unit)

                    // Check if the unit already exists
                    if (!units.has(unit)) {
                        units.add(unit);
                        usageList.push({ id: unit, "unit": unit, "plural": price_unit.plural, "min": 1, "max": 10, "default": 5, "step": 1, "type": 'Text Field' });
                    }
                }
            });
        });
        // console.log(usageList)
        setUsages(usageList);
    }

    const handlePriceModelChange = (event) => {
        if (typeof event.target.value !== 'string') {
            let model = event.target.value

            setPrices([]);
            setUsageType('');
            setPriceModel(model);
            initializeUsageTable(model);
        }
    }

    const [tiersMode,setTiersMode] = useState(props.mode === "Edit" ?
        props.product.tiers_mode : 'volume');
    const handleTiersModeChange = (event) => {
        setTiersMode(event.target.value)
    };

    const handleGatewayTypeChange = (event) => {

        let type = event.target.value
        // LIMITATION STRIPE GATEWAY
        if(type === "Stripe"){
            if(limitationStripe){
                setGatewayType(type)
            }
        }else{
            setGatewayType(type)
        }

    }

    const handlePriceAddDialog = (event) => {
        event.stopPropagation();
        setIsDialogOpen("priceAdd")
    }

    const handlePriceModelAddDialog = () => {
        setIsDialogOpen("priceModelAdd")
    }

    const handleAddPrice = (price) => {
        setPrices([...prices, price])
        setButtonLinks(prevLinks => ({
            ...prevLinks,
            [price.billing_period]: ""
        }));
        // setSnackbar({ children: 'Price added successfully', severity: 'success' });
    }

    const handlePriceDialogClose = () => {
        setIsDialogOpen("")
    }

    const handlePriceModelDialogClose = () => {
        setIsDialogOpen("")
    }

    const handleAdd = () => {
        if (inputValue.trim() !== '') {
            setChipData([...chipData, { id: uuidv4(), label: inputValue }]);
            setInputValue('');
        }
    };

    const processPriceMetric = (paymentType, metric) => {
        let readablePriceMetric = getReadablePriceMetric(metric);
        if (paymentType === 'One Time') {
            readablePriceMetric = readablePriceMetric.replace('/month', '').replace('/year', '');
            if (readablePriceMetric === '') {
                readablePriceMetric = '/';
            }
        }
        return readablePriceMetric;
    }

    const showPriceTiers = (tiers, currency) => {
        return <Grid container width={500}>
            <Grid container width={'100%'} px={0.5} py={1} >
                <Grid container width={'25%'}>
                    <Typography fontSize={12} fontWeight={600}>
                        First Unit
                    </Typography>
                </Grid>
                <Grid container width={'25%'}>
                    <Typography fontSize={12} fontWeight={600}>
                        Last Unit
                    </Typography>
                </Grid>
                <Grid container width={'25%'}>
                    <Typography fontSize={12} fontWeight={600}>
                        Price Per Unit
                    </Typography>
                </Grid>
                <Grid container width={'25%'}>
                    <Typography fontSize={12} fontWeight={600}>
                        Flat Fee
                    </Typography>
                </Grid>
            </Grid>
            {tiers.map((tier, index) =>(
                <Grid container width={'100%'} px={0.5} pb={1} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid container width={'100%'}>
                        <Grid container width={'25%'}>
                            <TextField size={'small'} sx={{width:'95%'}}
                                       InputProps={{
                                           readOnly: true
                                       }}
                                       value={index === 0 ? 0 : tiers[index-1]['up_to'] + 1}
                                       inputProps={{
                                           sx: {
                                               height: 7,
                                               fontSize: 12
                                           },
                                       }}
                            />
                        </Grid>
                        <Grid container width={'25%'}>
                            <TextField size={'small'} sx={{width:'95%'}}
                                       InputProps={{
                                           readOnly: true
                                       }}
                                       value={tier.up_to !== -1 ? tier.up_to : '∞' }
                                       inputProps={{
                                           sx: {
                                               height: 7,
                                               fontSize: index === tiers.length -1 ? 18 : 12
                                           },
                                       }}
                            />
                        </Grid>
                        <Grid container width={'25%'}>
                            <TextField size={'small'} sx={{width:'95%'}}
                                       value={tiers[index].unit_price_value}
                                       placeholder={'0.00'}
                                       inputProps={{
                                           sx: {
                                               height: 7,
                                               fontSize: 12
                                           },
                                       }}
                                       InputProps={{
                                           readOnly: true,
                                           startAdornment: (
                                               <InputAdornment position="start" sx={{ml:-0.5}}>
                                                   <Typography fontSize={14}>
                                                       {currency}
                                                   </Typography>
                                               </InputAdornment>)
                                       }}
                            />
                        </Grid>
                        <Grid container width={'25%'}>
                            <TextField size={'small'} sx={{width:'95%'}}
                                       value={tiers[index].flat_price_value}
                                       placeholder={'0.00'}
                                       inputProps={{
                                           sx: {
                                               height: 7,
                                               fontSize: 12
                                           },
                                       }}
                                       InputProps={{
                                           readOnly: true,
                                           startAdornment: (
                                               <InputAdornment position="start" sx={{ml:-0.5}}>
                                                   <Typography fontSize={14}>
                                                       {currency}
                                                   </Typography>
                                               </InputAdornment>)
                                       }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                ))}

        </Grid>
    }

    const columns1 = [
        {
            field: 'amount',
            headerName: priceModel?.type !== 'TIERED_PRICING' ? 'Amount' : "Starting Amount",
            renderCell: (params) => (priceModel?.type !== 'TIERED_PRICING' ?
                `${params.row.currency}${params.row.price_value} ${processPriceMetric(params.row.payment_type, params.row.price_metric)}`:
                `${params.row.currency}${params.row.tiers[0]['unit_price_value']} ${processPriceMetric(params.row.payment_type, params.row.price_metric)}
                ${params.row.tiers[0]['flat_price_value'] !== 0 ? ' + ' + params.row.currency + params.row.tiers[0]['flat_price_value']
                    : ''} ${params.row.payment_type === 'Recurring' ? ' /month' : ''}`),
            flex: 4,
        },
        ...(priceModel?.type !== 'TIERED_PRICING' ? [{
            field: 'discount_price_value',
            headerName: 'Discount Price',
            renderCell: (params) => `${params.row.currency}${params.row.discount_price_value}`,
            flex: 3
        }] : [{
            field: 'view_price_tiers',
            headerName: 'View All Tiers',
            renderCell: (params) => {
                const tiers = params.row.tiers;
                return <IconContextMenu TitleText={'Click to view all tiers'} Icon={LayersIcon} Content={
                    <Grid sx={{ px: 2, zoom: 0.9 }}>
                        {showPriceTiers(tiers,params.row.currency)}
                    </Grid>
                }></IconContextMenu>
            },
            flex: 3
        }]),
        { field: 'payment_type', headerName: 'Payment Type', flex: 3 },
        { field: 'billing_period', headerName: 'Billing Period', flex: 3 },
        {
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            align: "center",
            renderCell: (params) => (
                <Tooltip title="Delete Price">
                    <DeleteIcon
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            // console.log(params)
                            setPrices(prices.filter(price => price.id !== params.row.id))

                            // Create a new object from stripePriceIds
                            const newStripePriceIds = { ...stripePriceIds };

                            // Delete the key-value pair where the key is params.row.id
                            delete newStripePriceIds[params.row.id];

                            // Update the state
                            setStripePriceIds(newStripePriceIds);
                        }}
                        sx={{
                            color: "lightgray",
                            "&:hover": {
                                color: "black",
                                cursor: "pointer"
                            },
                        }}
                    />
                </Tooltip>
            ),
        },
    ];

    const handleAddProduct = () => {
        setLoading(true)
        const newProduct = {
            "name": name.trim(),
            "header_name": headerName.trim(),
            "client_id": getCurrentOrgId(),
            "scenario_id": scenario.id,
            "description": description.trim(),
            "price_model": priceModel,
            "add_on_product": addOnProduct,
            "selected_products_ids": selectedProducts.map(product => product.id),
            "features": chipData.map((chip) => chip.label.trim()),
            "prices": prices,
            "usages": usages,
            "usage_type": usageType,
            "usage_aggregate_strategy": usageAggregateStrategy,
            "tiers_mode": tiersMode,
            "payment_integration": {
                "gateway_type": gatewayType,
                "stripe_properties": {
                    "success_url": stripeSuccessUrl,
                    "cancel_url": stripeCancelUrl,
                },
                "custom_button_links": buttonLinks,
            },
            "tax_integration": {
                "tax_behaviour": taxBehaviour,
                "tax_code": productTaxCategory,
            },
            "free_trial_integration": {
                "active": freeTrialActive,
                "behaviour": freeTrialBehaviour,
                "end_behaviour": freeTrialEndBehaviour,
                "days": freeTrialDays !== "" ? freeTrialDays : null,
                "skip_trial_option": freeTrialSkip,
                "coupon": freeTrialCoupon,
            },

        }
        console.log(newProduct, "SENDING !!!!")
        addProduct(JSON.parse(JSON.stringify(newProduct)), (data) => {
            // console.log(data, "WORKING !!!!")
            updateProducts([...products, data])
            setLoading(false)
            props.handleSnackNotif("addProduct")
            props.closeActionsProduct()
        }, (err) => {
            setLoading(false);
            console.log(err);
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to add product at the moment" });
        })
    }

    const handleEditProductSaveClick = () => {
        setLoading(true)
        const newProduct = Object.assign({}, props.product, {
            "name": name.trim(),
            "header_name": headerName.trim(),
            "description": description.trim(),
            "price_model": priceModel,
            "add_on_product": addOnProduct,
            "selected_products_ids": selectedProducts.map(product => product.id),
            "features": chipData.map((chip) => chip.label.trim()),
            "prices": prices,
            "usages": usages,
            "usage_type": usageType,
            "usage_aggregate_strategy": usageAggregateStrategy,
            "tiers_mode": tiersMode,
            "payment_integration": {
                "gateway_type": gatewayType,
                "stripe_properties": {
                    "price_ids": stripePriceIds,
                    "product_id": props.product.payment_integration.stripe_properties?.product_id,
                    "connect_id": props.product.payment_integration.stripe_properties?.connect_id,
                    "success_url": stripeSuccessUrl,
                    "cancel_url": stripeCancelUrl,
                },
                "custom_button_links": buttonLinks,
            },
            "tax_integration": {
                "tax_behaviour": taxBehaviour,
                "tax_code": productTaxCategory,
            },
            "free_trial_integration": {
                "active": freeTrialActive,
                "behaviour": freeTrialBehaviour,
                "end_behaviour": freeTrialEndBehaviour,
                "days": freeTrialDays !== "" ? freeTrialDays : null,
                "skip_trial_option": freeTrialSkip,
                "coupon": freeTrialCoupon,
            },
        });

        // console.log(JSON.parse(JSON.stringify(newProduct)))

        updateProduct(JSON.parse(JSON.stringify(newProduct)), (data) => {
            // console.log(data)
            updateProducts(products.map(product => product.id === data.id ? data : product));
            setLoading(false)
            props.handleSnackNotif("updateProduct");
            props.closeActionsProduct();
        }, (err) => {
            setError(err);
            setLoading(false);
            console.log(err);
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to update product at the moment" });
        })
    }

    const validateForm = () => {
        return (
            name.trim() &&
            headerName.trim() &&
            description.trim() &&
            // chipData.length &&
            prices.length &&
            priceModel &&
            gatewayType &&
            (usageType !== 'metered' || usageAggregateStrategy) &&
            (gatewayType !== 'Stripe' ||
                (stripeSuccessUrl.trim() && stripeCancelUrl.trim()) || addOnProduct) &&
            (gatewayType !== 'Custom' ||
                (Object.keys(buttonLinks).length &&
                    Object.values(buttonLinks).every(value => value.trim()))) &&
            (gatewayType === 'Custom' ||
                (
                    (!freeTrialActive || (freeTrialDays && (!freeTrialSkip || freeTrialCoupon))) &&
                    productTaxCategory &&
                    taxBehaviour
                )
            )
        )
    }

    const handlePriceUsageSwitchChange = (panel) => {
        setExpanded(panel === expanded ? (panel === 'panel1' ? 'panel2' : 'panel1') : panel);
    };


    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: '#f2f2f2',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(0),
    }));

    const columns2 = [
        { field: 'unit', headerName: 'Unit', flex: 2, editable: false },
        { field: 'min', headerName: 'Min', type: 'number', flex: 2, editable: true },
        { field: 'default', headerName: 'Default', type: 'number', flex: 2, editable: true },
        { field: 'max', headerName: 'Max', type: 'number', flex: 2, editable: true },
        { field: 'step', headerName: 'Step', type: 'number', flex: 2, editable: true },
        // {
        //     field: 'type',
        //     headerName: 'Type',
        //     flex: 3,
        //     renderCell: (params) => (
        //         <Select
        //             sx={{width:140}}
        //             value={params.value}
        //             onChange={(e) => {
        //                 const updatedRows = [...usages];
        //                 const rowIndex = usages.findIndex((row) => row.id === params.id);
        //                 updatedRows[rowIndex].type = e.target.value;
        //                 setUsages(updatedRows);
        //             }}
        //         >
        //             <MenuItem value="Text Field">Text Field</MenuItem>
        //             <MenuItem value="Slider">Slider</MenuItem>
        //         </Select>
        //     ),
        // },
    ];


    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = (newRow) => {
        if (newRow['min'] <= newRow['default'] && newRow['default']
            <= newRow['max'] && newRow['min'] >= 1 && newRow['step'] >= 1 && newRow['step'] <= newRow['max']) {
            // console.log(newRow['min'], newRow['default'], newRow['max']);
            const updatedRows = [...usages];
            const rowIndex = usages.findIndex((row) => row.id === newRow.id);
            // console.log(rowIndex,newRow)
            updatedRows[rowIndex] = newRow;
            setUsages(updatedRows);
            setSnackbar({ children: 'Row successfully saved', severity: 'success' });
        } else {
            // console.log("FAAAAAAAAAAAAAILEEEEEEEED");
            setSnackbar({
                children: "Error while saving row: " +
                    "Condition \"1 <= min <= default <= max\" not met.", severity: 'error'
            });

        }
    };

    // const handleProcessRowUpdateError = (error) => {
    //     setSnackbar({ children: error.message, severity: 'error' });
    // };

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFreeTrialDaysChange = (event) => {
        let curr = event.target.value;
        if(curr.length === 0 ){
            setFreeTrialDays("")
        }
        else{
            let numCurr = Number(curr);
            if (!isNaN(numCurr) && numCurr > 0 && numCurr <=365 ) {
                setFreeTrialDays(numCurr);
            }
        }
    }

    const [openAdd, setOpenAdd] = useState(false);
    const handleCreateCouponClick = () => {
        setOpenAdd(!openAdd);
    };

    const [snackNotif, setSnackNotif] = useState("");
    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }

    useEffect(() => {
        if (snackNotif === "addCoupon") {
            // Find the newly added coupon
            const latestCoupon = coupons.reduce((latest, coupon) => {
                return (!latest || new Date(coupon.created_date) > new Date(latest.created_date)) ? coupon : latest;
            }, null);
            // Now latestCoupon is the last coupon that got created
            setFreeTrialCoupon(latestCoupon)
        }
    }, [snackNotif]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    )

    return (
        <Grid container sx={{ width: '100vw', pl: 4, pr: 2, pt: 2 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container justifyContent='space-between' alignItems='center' pb={1}>
                <Grid container item width={'40%'} gap={0.5}>
                    <IconButton
                        onClick={props.closeActionsProduct}
                        sx={{
                            '&:hover': {
                                bgcolor: 'primary.background',
                            },
                        }}
                    >
                        <ClearIcon fontSize="medium" />
                    </IconButton>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Typography sx={{
                        color: "primary.main",
                        fontSize: 24,
                        fontWeight: "bold",
                        pl: 1
                    }}>
                        {props.mode} Product
                    </Typography>
                </Grid>

                <Grid item container width={'50%'} justifyContent={'flex-end'}>
                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{ mt: 0 }}
                        onClick={props.closeActionsProduct}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!validateForm()}
                        variant="outlined"
                        size="medium"
                        onClick={props.mode === "Add" ? handleAddProduct : handleEditProductSaveClick}
                        sx={{ ml: 1, mt: 0 }}
                    >
                        Save {props.mode === "Add" && 'and Add Product'}
                    </Button>
                </Grid>
            </Grid>
            <Grid container item sx={{ borderTop: '1px solid lightgray', p: 2 }}
                justifyContent='space-between'>
                {activeStep === 0 ?
                    <>
                        <Grid container direction='column'
                              wrap="nowrap"
                              sx={{
                                  width: '37%',
                                  height: '78vh',
                                  overflowY: 'scroll',
                                  pr: 2,
                                  "&::-webkit-scrollbar": {
                                      width: 7,
                                      // bgcolor: ''
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: 'lightgray',
                                      outline: "1px solid lightgray",
                                      borderRadius: 4
                                  }
                              }}
                        >
                            <Typography sx={{ fontWeight: 600, fontSize: 14, pb: 2 }}>
                                Product Details
                            </Typography>
                            <TextField
                                size='small'
                                label="Product Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                sx={{
                                    pb: 1
                                }}
                            />
                            <Alert severity="info" sx={{ fontSize: 12, mb: 2 }}>
                                This name should be unique in Products List.
                            </Alert>

                            <Grid container item alignItems={'center'} mt={-1} pb={1}>
                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                    Add-on Product
                                </Typography>
                                <Checkbox

                                    // LIMITATION ADD-ON FROM PRODUCT

                                    disabled={props.mode === 'Edit' || !limitationAddOn}
                                    checked={addOnProduct}
                                    onChange={(e)=>handleAddOnProductCheckbox(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Grid>

                            <TextField
                                size='small'
                                label={addOnProduct ? "Add-on Name" : "Plan Name"}
                                variant="outlined"
                                value={headerName}
                                onChange={(e) => setHeaderName(e.target.value)}
                                sx={{
                                    pb: 2
                                }}
                            />
                            {/*<Alert severity="info" sx={{fontSize:12,mb:2}}>*/}
                            {/*    This name will be rendered on top of Price Card in Design. This does not have to be unique.*/}
                            {/*</Alert>*/}

                            <TextField
                                size='small'
                                label={addOnProduct ? "Add-on Description" : "Product Description"}
                                variant="outlined"
                                multiline
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{
                                    pb: 2
                                }}
                            />
                            <FormControl >
                                {/*<InputLabel id="price-model-select-label">Price Model</InputLabel>*/}
                                {props.mode === 'Add' ?
                                    <TextField
                                        select
                                        size='small'
                                        labelId="price-model-select-label"
                                        id="price-model-select"
                                        label="Price Model"
                                        value={priceModel}
                                        onChange={handlePriceModelChange}
                                    >
                                        <MenuItem
                                            onClick={handlePriceModelAddDialog}
                                            value={'ADD_CUSTOM_PRICE_MODEL'}
                                            sx={{
                                                '&.MuiMenuItem-root': {
                                                    backgroundColor: 'primary.main', color: 'white',
                                                },
                                            }}
                                        >
                                            <Grid container >
                                                <AddIcon mr={1} />
                                                <Typography pl={1}>Add Price Model</Typography>
                                            </Grid>
                                        </MenuItem>
                                        {priceModels.map((model) => (
                                            <MenuItem value={model}>{model.name}</MenuItem>
                                        ))}
                                    </TextField>
                                    :
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        size='small'
                                        labelId="price-model-select-label"
                                        id="price-model-select"
                                        label="Price Model"
                                        value={props.product.price_model.name}
                                        // onChange={handlePriceModelChange}
                                    >
                                    </TextField>
                                }

                                {priceModel?.type === "TIERED_PRICING" &&
                                    <>
                                        {props.mode === 'Add' ?
                                            <TextField
                                                select
                                                size='small'
                                                labelId="tiers-mode-select-label"
                                                id="tiers-mode-select"
                                                label="Tiers Mode"
                                                value={tiersMode}
                                                SelectProps={{
                                                    renderValue: mode => mode.charAt(0).toUpperCase() + mode.slice(1)
                                                }}
                                                onChange={handleTiersModeChange}
                                                sx={{mt:2}}
                                            >
                                                {['volume','graduated'].map((mode) => (
                                                    <MenuItem value={mode} sx={{
                                                            mx:1, my:0.5, borderRadius:2,
                                                            "&:hover": {
                                                                bgcolor: "primary.background",
                                                            }
                                                        }}
                                                    >
                                                        <Grid container direction={'column'} gap={0.5} m={0.5}>
                                                            <Typography fontSize={16}>
                                                                {mode.charAt(0).toUpperCase() + mode.slice(1)}
                                                            </Typography>
                                                            <Typography fontSize={12} color={'primary.greyText'}>
                                                                {mode === 'volume' ?
                                                                    'All units price based on final tier reached.' :
                                                                    'Tiers apply progressively as quantity increase.'
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            :
                                            <TextField
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                size='small'
                                                labelId="tiers-mode-select-label"
                                                id="tiers-mode-select"
                                                label="Tiers Mode"
                                                value={props.product.tiers_mode.charAt(0).toUpperCase() + props.product.tiers_mode.slice(1)}
                                                sx={{mt:2}}
                                                // onChange={handlePriceModelChange}
                                            >
                                            </TextField>
                                        }
                                    </>
                                }

                                {/*Scenario NEW PRICE MODEL DIALOG*/}
                                <Drawer
                                    anchor="right"
                                    open={isDialogOpen === 'priceModelAdd'}
                                    onClose={() => handlePriceModelDialogClose()}>
                                    <CustDialogTitle onClose={() => handlePriceModelDialogClose()}>
                                        <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>
                                            Define New Price Model
                                        </Typography>
                                    </CustDialogTitle>
                                    <CreatePriceModelDefinition
                                        onSuccess={(priceModel) => {
                                            handlePriceModelDialogClose();
                                            setPriceModels([...priceModels, priceModel])
                                            setPriceModel(priceModel)
                                            initializeUsageTable(priceModel)
                                            setPrices([]);
                                        }}
                                        onClose={() => handlePriceModelDialogClose()}
                                    />
                                </Drawer>

                                <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>
                                    {!priceModel
                                        ? "Choose a Price Model to apply the price metric."
                                        : <>Price Metrics: <strong>{
                                            priceModel.price_metrics
                                                .map((metric, index) =>
                                                    <Chip
                                                        size='small'
                                                        label={`${getReadablePriceMetric(metric)}`}
                                                        key={index}
                                                        sx={{ fontSize: 10, m: 0.2, fontWeight: 800 }} />
                                                )
                                        }</strong></>
                                    }
                                </Alert>
                            </FormControl>

                            {addOnProduct ?
                                <>
                                    <Typography sx={{ fontWeight: 600, fontSize: 14 ,py:2}}>
                                        Product Selection
                                    </Typography>
                                    <TextField
                                        width={'100%'}
                                        select
                                        size='small'
                                        labelId="product-select-label"
                                        id="product-select"
                                        label="Select Product"
                                        // value={currSelectedProduct}
                                        helperText={"Only Stripe integrated products allowed for selection"}
                                    >
                                        {/*<MenuItem*/}
                                        {/*    onClick={handleCreateProductClick}*/}
                                        {/*    value={'ADD_PRODUCT'}*/}
                                        {/*    sx={{*/}
                                        {/*        '&.MuiMenuItem-root': {*/}
                                        {/*            backgroundColor: 'primary.main', color: 'white',*/}
                                        {/*        },*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <Grid container >*/}
                                        {/*        <AddIcon mr={1} />*/}
                                        {/*        <Typography pl={1}>Add Product</Typography>*/}
                                        {/*    </Grid>*/}
                                        {/*</MenuItem>*/}
                                        {searchResults.map((result) => (
                                            <MenuItem
                                                value={result}
                                                onClick={() => handleAddToSelectedProducts(result)}
                                            >
                                                {result.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {selectedProducts.length ?
                                        <Grid container item gap={1} mt={1}>
                                            {selectedProducts.map((product) => (
                                                <Chip
                                                    key={product.name}
                                                    label={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography pr={2}>
                                                                {product.name}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    deleteIcon={<DeleteIcon sx={{
                                                        '&:hover': {
                                                            color: '#818589'
                                                        }
                                                    }} />}
                                                    onDelete={handleDeleteFromSelectedProducts(product)}
                                                />
                                            ))}
                                        </Grid>
                                        :
                                        <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>
                                            Select your first product from the above dropdown menu.
                                        </Alert>
                                    }
                                </>
                                :
                                <Grid container direction='column' pt={2} pb={2}>
                                    <Typography sx={{ fontWeight: 600, fontSize: 14, pb: 2 }}>
                                        Features
                                    </Typography>
                                    <Grid container justifyContent='space-between' alignItems='center'>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            size='small'
                                            label="Feature"
                                            placeholder={'Type and press enter'}
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleAdd()
                                                }
                                            }}
                                        />
                                        {/*<IconButton*/}
                                        {/*    onClick={handleAdd}*/}
                                        {/*    size="small"*/}
                                        {/*    sx={{*/}
                                        {/*        '&:hover': {*/}
                                        {/*            bgcolor: 'primary.background',*/}
                                        {/*            color:'primary.main',*/}
                                        {/*            borderRadius:1,*/}
                                        {/*        },*/}

                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <AddIcon fontSize="small" />*/}
                                        {/*</IconButton>*/}
                                    </Grid>
                                    {chipData.length > 0 ?
                                        <>
                                            <DndContext
                                                collisionDetection={closestCenter}
                                                sensors={sensors}
                                                onDragEnd={(event)=>{

                                                    // console.log("Drag end called");
                                                    const {active, over} = event;
                                                    // console.log("ACTIVE: " + active.id);
                                                    // console.log("OVER :" + over.id);

                                                    if(active.id !== over.id) {
                                                        setChipData((items) => {
                                                            const activeIndex = items.findIndex((item) => item.id === active.id);
                                                            const overIndex = items.findIndex((item) => item.id === over.id);
                                                            // console.log(arrayMove(items, activeIndex, overIndex));
                                                            return arrayMove(items, activeIndex, overIndex);
                                                        });

                                                    }
                                                }}
                                            >
                                                <Grid container direction={'column'} mt={2}>
                                                    <SortableContext
                                                        items={chipData}
                                                        strategy={verticalListSortingStrategy}
                                                    >
                                                        {chipData.map(data =>
                                                            <SortableFeatureItem
                                                                features={chipData}
                                                                setFeatures={setChipData}
                                                                label={data.label}
                                                                id={data.id}
                                                            />)
                                                        }
                                                    </SortableContext>
                                                </Grid>
                                            </DndContext>
                                            <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>
                                                Drag and drop to change order of the features.
                                                You can also edit/delete a feature. Press enter to save after editing.
                                            </Alert>
                                        </>
                                        :
                                        <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>
                                            Please add your first feature.
                                        </Alert>
                                    }
                                </Grid>
                            }

                        </Grid>

                        {/*<Grid container direction='column' gap={2} width={'11vw'}>*/}
                        {/*    <Typography sx={{fontWeight:600,fontSize:14}}>*/}
                        {/*        Product Image*/}
                        {/*    </Typography>*/}
                        {/*    <Grid container sx={{*/}
                        {/*        height:'11vw',*/}
                        {/*        width:'11vw',*/}
                        {/*        border:'2px dashed lightgray',*/}
                        {/*        bgcolor:'primary.background',*/}
                        {/*        justifyContent:'center',*/}
                        {/*        alignItems:'center',*/}
                        {/*        cursor:'pointer'*/}
                        {/*    }}>*/}
                        {/*        <UploadIcon sx={{color:'primary.main'}}/>*/}
                        {/*        <Typography sx={{fontWeight:600,fontSize:14,color:'primary.main'}}>*/}
                        {/*            Upload*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        <Grid container direction='column' width='60%' alignItems={'space-between'} mt={-2}>
                            <Grid container direction={'column'}>
                                {/*<Typography sx={{ fontWeight: 600, fontSize: 14, pb: 1.5 }}>*/}
                                {/*    Price / Usage Details*/}
                                {/*</Typography>*/}
                                <Accordion
                                    expanded={expanded === 'panel1'} onChange={() => handlePriceUsageSwitchChange('panel1')}>
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                    >
                                        <Grid container item sx={{ justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Typography fontSize={14} fontWeight={600}>
                                                Prices
                                            </Typography>
                                            {priceModel &&
                                                <Grid item alignItem={'center'}>
                                                    <Tooltip title="Add Price">
                                                        {/*<Fab color="primary" aria-label="add" size="small" >*/}
                                                        <AddIcon
                                                            onClick={handlePriceAddDialog}
                                                            sx={{
                                                                // transition: 'background-color 0.3s ease',
                                                                '&:hover': {
                                                                    backgroundColor: 'white',
                                                                    borderRadius: 4
                                                                },
                                                            }}
                                                        />
                                                        {/*</Fab>*/}
                                                    </Tooltip>
                                                    <PriceAdd
                                                        isDialogOpen={isDialogOpen}
                                                        handlePriceDialogClose={handlePriceDialogClose}
                                                        handleAddPrice={handleAddPrice}
                                                        prices={prices}
                                                        addOnProduct={addOnProduct}
                                                        priceMetrics={priceModel.price_metrics}
                                                        priceModelType={priceModel.type}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container item sx={{ height: '45vh', bgcolor: 'white', }}>
                                            {prices.length ?
                                                <DataGrid
                                                    sx={{
                                                        // disable cell selection style
                                                        '.MuiDataGrid-cell:focus': {
                                                            outline: 'none'
                                                        },
                                                        // pointer cursor on ALL rows
                                                        '& .MuiDataGrid-row:hover': {
                                                            cursor: 'pointer'
                                                        },
                                                        '& .MuiDataGrid-cell': {
                                                            fontSize: 12,
                                                        },
                                                        width: '40vw'
                                                    }}
                                                    rowHeight={40}
                                                    rows={prices}
                                                    // loading={loading}
                                                    columns={columns1}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: { page: 0, pageSize: 100 },
                                                        },
                                                    }}
                                                    // onRowClick={handleRowClick}
                                                    pageSizeOptions={[5, 10]}
                                                    // onCellClick={handleRowHover}
                                                    // checkboxSelection
                                                />
                                                :
                                                <Grid container
                                                      justifyContent={'center'}
                                                      alignItems={'center'}
                                                      height={'100%'}
                                                >
                                                    {priceModel ?
                                                        <Typography color={'primary.greyText'}>
                                                            Add your first price by clicking on the + icon above.
                                                        </Typography>
                                                        :
                                                        <Typography color={'primary.greyText'}>
                                                            Please select a Price Model first to start adding prices.
                                                        </Typography>
                                                    }
                                                </Grid>
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={() => handlePriceUsageSwitchChange('panel2')}>
                                    <AccordionSummary
                                        aria-controls="panel2d-content"
                                        id="panel2d-header"
                                    >
                                        <Grid container item sx={{ justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Typography fontSize={14} fontWeight={600}>
                                                Usages
                                            </Typography>
                                            {/*{ priceModel &&*/}
                                            {/*    <Grid item>*/}
                                            {/*        <Tooltip title="Add Usage">*/}
                                            {/*            /!*<Fab color="primary" aria-label="add" size="small" >*!/*/}
                                            {/*                <AddIcon*/}
                                            {/*                    onClick={handlePriceAddDialog}*/}
                                            {/*                    sx={{*/}
                                            {/*                        // transition: 'background-color 0.3s ease',*/}
                                            {/*                        '&:hover': {*/}
                                            {/*                            backgroundColor: 'white',*/}
                                            {/*                            borderRadius: 4*/}
                                            {/*                        }*/}
                                            {/*                    }}*/}
                                            {/*                />*/}
                                            {/*            /!*</Fab>*!/*/}
                                            {/*        </Tooltip>*/}
                                            {/*        <PriceAdd*/}
                                            {/*            isDialogOpen={isDialogOpen}*/}
                                            {/*            handlePriceDialogClose={handlePriceDialogClose}*/}
                                            {/*            handleAddPrice={handleAddPrice}*/}
                                            {/*            prices={prices}*/}
                                            {/*            priceMetrics={priceModel.price_metrics}*/}
                                            {/*        />*/}
                                            {/*    </Grid>*/}
                                            {/*}*/}
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container px={2} py={1} alignItems={'center'} borderBottom={'1px solid lightgray'}>
                                            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                Usage Based Pricing
                                            </Typography>
                                            <Checkbox
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                checked={usageType}
                                                disabled={props.mode === 'Edit' || !priceModel ||
                                                    (priceModel?.type !== 'CUSTOM_PRICING' && priceModel?.type !== 'TIERED_PRICING')
                                                }
                                                onChange={(e)=>{
                                                    if(e.target.checked){
                                                        setUsageType("metered")
                                                    }else{
                                                        setUsageType("")
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid container item sx={{ height: '45vh', bgcolor: 'white' }}>
                                            {usageType === 'metered' ?
                                                <Grid container item gap={2} alignItems={'center'} justifyContent={'center'}>
                                                    <Typography fontSize={16}>
                                                        Select a usage charge method :
                                                    </Typography>

                                                    {props.mode === 'Add' ?
                                                        <TextField
                                                            select
                                                            sx={{
                                                                width: '60%'
                                                            }}
                                                            size={'small'}
                                                            label="Metered Usage Charge Method"
                                                            value={usageAggregateStrategy}
                                                            renderValue={meteredUsageChargeMethods[usageAggregateStrategy]}
                                                            onChange={(e)=>setUsageAggregateStrategy(e.target.value)}
                                                        >
                                                            {Object.keys(meteredUsageChargeMethods).map((method) => (
                                                                <MenuItem key={method} value={method}>
                                                                    {meteredUsageChargeMethods[method]}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                        :
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                            sx={{
                                                                width: '60%'
                                                            }}
                                                            size='small'
                                                            label="Metered Usage Charge Method"
                                                            value={meteredUsageChargeMethods[usageAggregateStrategy]}
                                                            // onChange={handlePriceModelChange}
                                                        >
                                                        </TextField>
                                                    }
                                                </Grid>
                                                :
                                                <>
                                                    {priceModel ?
                                                        <DataGrid
                                                            sx={{
                                                                // disable cell selection style
                                                                '.MuiDataGrid-cell:focus': {
                                                                    outline: 'none'
                                                                },
                                                                // pointer cursor on ALL rows
                                                                '& .MuiDataGrid-row:hover': {
                                                                    cursor: 'pointer'
                                                                },
                                                                '& .MuiDataGrid-cell': {
                                                                    fontSize: 12,
                                                                },
                                                                width: '40vw'
                                                            }}
                                                            rowHeight={40}
                                                            rows={usages}
                                                            editMode="row"
                                                            columns={columns2}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { page: 0, pageSize: 100 },
                                                                },
                                                            }}
                                                            pageSizeOptions={[5, 10]}
                                                            processRowUpdate={processRowUpdate}
                                                            // onProcessRowUpdateError={handleProcessRowUpdateError}
                                                        />
                                                        :
                                                        <Grid container
                                                              justifyContent={'center'}
                                                              alignItems={'center'}
                                                              height={'100%'}
                                                        >
                                                            <Typography color={'primary.greyText'}>
                                                                Please select a Price Model first to start editing usages.
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {!!snackbar && (
                                    <Snackbar
                                        open
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        onClose={handleCloseSnackbar}
                                        autoHideDuration={snackbar.severity === 'success' ? 5000 : 10000}
                                    >
                                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                    </Snackbar>
                                )}

                                <Grid container >
                                    <Alert severity="info" sx={{ fontSize: 12, mt: 1, width:'100%'}} >
                                        Click on Usages to view Usage Table and <strong>Double Click </strong>
                                        on any row to edit its Usage Metric.
                                    </Alert>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                :
                <Grid container
                      sx={{
                          maxHeight: '78vh',
                          justifyContent:'space-between',
                      }}
                >
                    <Grid container item
                          direction={'column'}
                          gap={2}
                          wrap="nowrap"
                          sx={{
                              width: '33%',
                              maxHeight: '78vh',
                              overflowY: 'scroll',
                              pr:2,
                              "&::-webkit-scrollbar": {
                                  width: 7,
                                  // bgcolor: ''
                              },
                              "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: 'lightgray',
                                  outline: "1px solid lightgray",
                                  borderRadius: 4
                              }
                          }}
                    >
                        <Typography sx={{ fontWeight: 600, fontSize: 14,  }}>
                            Payment Integration
                        </Typography>

                        <Grid container item gap={2} alignItems={'center'}>
                            <Typography fontSize={16}>
                                Select a payment gateway :
                            </Typography>

                            {/*{props.mode === 'Add' ?*/}
                                <TextField
                                    select
                                    sx={{
                                        width: '100%'
                                    }}
                                    size={'small'}
                                    label="Payment Gateway"
                                    value={gatewayType}
                                    onChange={handleGatewayTypeChange}
                                    disabled={addOnProduct}
                                >
                                    {gateways.map((gateway) => (
                                        <MenuItem key={gateway} value={gateway}>
                                            {gateway}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            {/*    :*/}
                            {/*    <TextField*/}
                            {/*        InputProps={{*/}
                            {/*            readOnly: true*/}
                            {/*        }}*/}
                            {/*        sx={{*/}
                            {/*            width: '100%'*/}
                            {/*        }}*/}
                            {/*        size='small'*/}
                            {/*        label="Payment Gateway"*/}
                            {/*        value={gatewayType}*/}
                            {/*        // onChange={handlePriceModelChange}*/}
                            {/*    >*/}
                            {/*    </TextField>*/}
                            {/*}*/}

                            {gatewayType.length === 0 &&
                                <Alert severity="info" sx={{ fontSize: 12, width: '100%' }} >
                                    Please select a payment gateway from above.
                                </Alert>
                            }

                            {(gatewayType.length !== 0 && gatewayType !== 'Custom' && gatewayType !== 'Stripe') &&
                                <Alert severity="info" sx={{ fontSize: 12, width: '100%' }} >
                                    {gatewayType} Payment Integration is under construction and will be available soon.
                                </Alert>
                            }

                            {(gatewayType.length !== 0 && gatewayType === 'Custom' && prices.length === 0) &&
                                <Alert severity="info" sx={{ fontSize: 12, width: '100%' }} >
                                    Please select a price model and add a price.
                                </Alert>
                            }
                        </Grid>


                        {(gatewayType === 'Custom' && prices.length > 0) &&
                            <>
                                <Typography fontSize={16}>
                                    To make use of Custom Payment Integration please enter the following button redirect links.
                                </Typography>
                                {prices.map((price, index) => {
                                    let billingPeriod = price.billing_period;
                                    let label = billingPeriod === 'N/A' ? 'One Time Payment Link' : `${billingPeriod} Payment Link`;
                                    return (
                                        <Grid container item alignItems={'center'} justifyContent={'space-between'} >
                                            <Typography fontSize={16} pb={1}>
                                                {index + 1}. {label} :
                                            </Typography>
                                            <TextField
                                                key={price.id}
                                                size='small'
                                                // label={label}
                                                variant="outlined"
                                                value={buttonLinks[billingPeriod]}
                                                onChange={(e) => handleButtonLinksChange(e, billingPeriod)}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </>
                        }

                        {gatewayType === 'Stripe' &&
                            <Grid container gap={2}>
                                {/*<Alert severity="info" sx={{ fontSize: 12, width: '100%' }} >*/}
                                {/*    Please make sure you have completed Stripe Integration to PriceOps.*/}
                                {/*</Alert>*/}

                                {globalOrganization.payment_integration?.stripe_properties?.activated === true ?
                                    <Alert severity="success" sx={{ fontSize: 12, width: '100%' }} >
                                        You have completed Stripe Integration to PriceOps.
                                    </Alert>
                                    :
                                    <Alert severity="warning" sx={{ fontSize: 12, width: '100%' }} >
                                    You have not completed Stripe Integration to PriceOps.
                                    </Alert>
                                }

                                {!addOnProduct &&
                                    <>
                                        <Grid container item alignItems={'center'} justifyContent={'space-between'} gap={1}>
                                            <Typography fontSize={16}>
                                                Enter Payment Success URL :
                                            </Typography>
                                            <TextField
                                                size='small'
                                                variant="outlined"
                                                value={stripeSuccessUrl}
                                                onChange={handleStripeSuccessUrlChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            />
                                        </Grid>

                                        <Grid container item alignItems={'center'} justifyContent={'space-between'} gap={1}>
                                            <Typography fontSize={16}>
                                                Enter Payment Cancel URL :
                                            </Typography>
                                            <TextField
                                                size='small'
                                                variant="outlined"
                                                value={stripeCancelUrl}
                                                onChange={handleStripeCancelUrlChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        }
                    </Grid>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Grid container item
                          direction={'column'}
                          gap={2}
                          wrap="nowrap"
                          sx={{
                              width: '33%',
                              height: '78vh',
                              overflowY: 'scroll',
                              pr:2,
                              pl:3,
                              "&::-webkit-scrollbar": {
                                  width: 7,
                                  // bgcolor: ''
                              },
                              "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: 'lightgray',
                                  outline: "1px solid lightgray",
                                  borderRadius: 4
                              }
                          }}
                    >
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                            Free Trial Integration
                        </Typography>

                        {(gatewayType !== 'Custom' && !addOnProduct) ?
                            <>
                                <Grid container item alignItems={'center'} width={'100%'} gap={1} mt={0}>
                                    {/*<Typography sx={{ fontWeight: 600, fontSize: 14 }}>*/}
                                    {/*    Do you want to activate Free Trial?*/}
                                    {/*</Typography>*/}
                                    {/*<Checkbox*/}
                                    {/*    checked={freeTrialActive}*/}
                                    {/*    onChange={(e)=>setFreeTrialActive(!freeTrialActive)}*/}
                                    {/*    inputProps={{ 'aria-label': 'controlled' }}*/}
                                    {/*/>*/}

                                    <Typography fontSize={16}>
                                        Activate Free Trial Integration:
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch
                                                    checked={freeTrialActive}
                                                    onChange={(e)=>setFreeTrialActive(!freeTrialActive)}
                                                    sx={{
                                                        ml:3,
                                                        size:'small'
                                                    }}
                                                />
                                            }
                                        />
                                    </Typography>

                                    {freeTrialActive ?
                                        <Alert severity={'success'} sx={{width:'100%',fontSize:12}}>
                                            <b>
                                                Free Trial Integration is active and will be in use.
                                            </b>
                                        </Alert>
                                        :
                                        <Alert severity={'info'} sx={{width:'100%',fontSize:12}}>
                                            <b>
                                                Free Trial Integration is deactivated and will not be in use.
                                            </b>
                                        </Alert>
                                    }

                                </Grid>

                                <Grid container item alignItems={'center'} gap={1}>
                                    <Typography fontSize={16}>
                                        Choose Free Trial Behavior :
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={freeTrialBehaviour}
                                        onChange={(e)=>setFreeTrialBehaviour(e.target.value)}
                                    >
                                        <FormControlLabel value="withPaymentDetails" control={<Radio />} label="With Payment Details" />
                                        <FormControlLabel value="withoutPaymentDetails" control={<Radio />} label="Without Payment Details" />
                                    </RadioGroup>

                                    {freeTrialBehaviour === "withPaymentDetails" ?
                                        <Alert severity="info" sx={{ fontSize: 12 }} >
                                            Customer enters payment details at the start of the trial. Charges auto-apply after trial period unless cancelled.
                                        </Alert>
                                        :
                                        <Alert severity="info" sx={{ fontSize: 12 }} >
                                            No payment details required to start trial. Subscription either paused or cancelled based on Trial End Behaviour.
                                        </Alert>
                                    }

                                    {freeTrialBehaviour === 'withoutPaymentDetails' &&
                                        <>
                                            <Typography fontSize={16} mt={1}>
                                                Trial End Behavior :
                                            </Typography>

                                            <ToggleButtonGroup
                                                size='small'
                                                color="primary"
                                                value={freeTrialEndBehaviour}
                                                exclusive
                                                onChange={(e) => setFreeTrialEndBehaviour(e.target.value)}
                                                aria-label="Platform"
                                                sx={{width:'100%'}}
                                            >
                                                <ToggleButton
                                                    value="cancel"
                                                    sx={{
                                                        textTransform: 'none',
                                                        borderRightColor:'primary.main',
                                                        '&.Mui-selected': {
                                                            borderColor: 'primary.main',
                                                        },
                                                        width:'50%'
                                                    }}
                                                >
                                                    Cancel Subscription
                                                </ToggleButton>
                                                <ToggleButton value="pause" sx={{
                                                    textTransform: 'none',
                                                    '&.Mui-selected': {
                                                        borderColor: 'primary.main',
                                                    },
                                                    width:'50%'
                                                }}>
                                                    Pause Subscription
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </>
                                    }
                                </Grid>

                                <Grid container item alignItems={'center'} justifyContent={'space-between'} gap={1}>
                                    <Typography fontSize={16}>
                                        Enter Free Trial Days :
                                    </Typography>
                                    <TextField
                                        size='small'
                                        variant="outlined"
                                        value={freeTrialDays}
                                        onChange={handleFreeTrialDaysChange}
                                        sx={{
                                            width: '45%'
                                        }}
                                    />
                                </Grid>
                                <Grid container item alignItems={'center'} gap={1}>
                                    <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                        Allow skip trial and add discount
                                    </Typography>
                                    <Checkbox
                                        checked={freeTrialSkip}
                                        onChange={(e)=>setFreeTrialSkip(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                    {freeTrialSkip &&
                                        <Grid container alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography fontSize={16}>
                                                Select Coupon Code :
                                            </Typography>

                                            {/*<TextField*/}
                                            {/*    size='small'*/}
                                            {/*    variant="outlined"*/}
                                            {/*    value={freeTrialCouponCode}*/}
                                            {/*    onChange={(e)=>setFreeTrialCouponCode(e.target.value)}*/}
                                            {/*    sx={{*/}
                                            {/*        width: '45%'*/}
                                            {/*    }}*/}
                                            {/*/>*/}

                                            <TextField
                                                sx={{width:'45%'}}
                                                select
                                                size='small'
                                                // label="Select Coupon Code"
                                                value={freeTrialCoupon?.code}
                                            >
                                                <MenuItem
                                                    onClick={handleCreateCouponClick}
                                                    // value={'ADD_COUPON'}
                                                    sx={{
                                                        '&.MuiMenuItem-root': {
                                                            backgroundColor: 'primary.main',
                                                            color: 'white',
                                                        },
                                                        '&.MuiMenuItem-root:hover':{
                                                            backgroundColor: 'primary.main',
                                                            color: 'white',
                                                        }
                                                    }}
                                                >
                                                    <Grid container >
                                                        <AddIcon mr={1} />
                                                        <Typography pl={1}>Add Coupon</Typography>
                                                    </Grid>
                                                </MenuItem>

                                                {coupons.map((coupon) => {
                                                    const currentTime = Math.floor(Date.now()/1000 ); // get current Unix timestamp
                                                    // console.log(currentTime, coupon.redeem_by,"COUPON")
                                                    // dont show expired coupons to select from
                                                    if (!(coupon.redeem_by < currentTime)) {
                                                        return (
                                                            <MenuItem
                                                                value={coupon.code}
                                                                onClick={(e) => setFreeTrialCoupon(coupon)}
                                                            >
                                                                {coupon.code}
                                                            </MenuItem>
                                                        );
                                                    }
                                                })}

                                            </TextField>
                                        </Grid>
                                    }
                                </Grid>
                            </>
                            :
                            <Grid container height={'100%'} alignItems={'center'} justifyContent={'center'}>
                                Not available for {addOnProduct ? 'Add-on Product' : 'Custom payment gateway'}.
                            </Grid>
                        }
                    </Grid>


                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Grid container item
                          direction={'column'}
                          gap={2}
                          wrap="nowrap"
                          sx={{
                              width: '33%',
                              maxHeight: '78vh',
                              overflowY: 'scroll',
                              pr:2,
                              pl:3,
                              "&::-webkit-scrollbar": {
                                  width: 7,
                                  // bgcolor: ''
                              },
                              "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: 'lightgray',
                                  outline: "1px solid lightgray",
                                  borderRadius: 4
                              }
                          }}
                    >
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                            Tax Integration
                        </Typography>

                        {gatewayType !== 'Custom' ?
                            <>
                                <Grid container item alignItems={'center'} gap={1}>
                                    <Typography fontSize={16}>
                                        Choose Tax Behavior :
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={taxBehaviour}
                                        onChange={handleTaxBehaviourChange}
                                    >
                                        <FormControlLabel value="inclusive" control={<Radio />} label="Inclusive" />
                                        <FormControlLabel value="exclusive" control={<Radio />} label="Exclusive" />
                                    </RadioGroup>

                                    {taxBehaviour === 'exclusive' &&
                                        <Alert severity="info" sx={{fontSize: 12}}>
                                            <b> An additional 0.5% will be charged per transaction.</b>
                                        </Alert>
                                    }
                                </Grid>
                                <Grid container item alignItems={'center'} gap={2} >
                                    <Typography fontSize={16}>
                                        Choose Product Tax Category :
                                    </Typography>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        select
                                        size='small'
                                        label="Product Tax Category"
                                        value={productTaxCategory}
                                        onChange={handleProductTaxCategoryChange}
                                    >
                                        <MenuItem
                                            value={"txcd_10103000"}
                                        >
                                            Software as a service (SaaS) - personal use
                                        </MenuItem>

                                        <MenuItem
                                            value={"txcd_10103001"}
                                        >
                                            Software as a service (SaaS) - business use
                                        </MenuItem>

                                        <MenuItem
                                            value={"txcd_10103100"}
                                        >
                                            Software as a service (SaaS) - electronic download - personal use
                                        </MenuItem>

                                        <MenuItem
                                            value={"txcd_10103101"}
                                        >
                                            Software as a service (SaaS) - electronic download - business use
                                        </MenuItem>

                                    </TextField>
                                </Grid>
                                {taxBehaviour === 'exclusive' &&
                                    <>
                                        <Alert severity="info" sx={{ fontSize: 12 }} >
                                            Please make sure to setup and enable Stripe Tax in your <Link
                                            href={`https://dashboard.stripe.com/${globalOrganization.payment_integration?.stripe_properties?.connect_id}${isTestModeLink()}/tax/`}
                                            target="_blank"
                                        >
                                            Stripe Tax Dashboard
                                        </Link>
                                            .
                                        </Alert>

                                        {/*<Alert severity="success" sx={{ fontSize: 12 }} >*/}
                                        {/*    You have completed Stripe Tax setup in your account. Click to view your  <Link*/}
                                        {/*    href={`https://dashboard.stripe.com/${globalOrganization.payment_integration?.stripe_properties?.connect_id}${isTestModeLink()}/tax/`}*/}
                                        {/*    target="_blank"*/}
                                        {/*>*/}
                                        {/*    Stripe Tax Dashboard*/}
                                        {/*</Link>*/}
                                        {/*    {' to make any tax setting changes.'}*/}
                                        {/*</Alert>*/}

                                        {/*<Alert severity="warning" sx={{ fontSize: 12 }} >*/}
                                        {/*    You have not completed Stripe Tax setup in your account. Click to view your  <Link*/}
                                        {/*    href={`https://dashboard.stripe.com/${globalOrganization.payment_integration?.stripe_properties?.connect_id}${isTestModeLink()}/tax/`}*/}
                                        {/*    target="_blank"*/}
                                        {/*>*/}
                                        {/*    Stripe Tax Dashboard*/}
                                        {/*</Link>*/}
                                        {/*    {' to complete your setup.'}*/}
                                        {/*</Alert>*/}
                                    </>
                                }
                            </>
                            :
                            <Grid container height={'100%'} alignItems={'center'} justifyContent={'center'}>
                                Not available for Custom payment gateway.
                            </Grid>
                        }
                    </Grid>

                </Grid>
                }
                <Grid container justifyContent={'flex-end'} sx={{ width:'100%'}}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep === 1 ?
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            // sx={{ mr: 1 }}
                            variant="outlined"
                        >
                            Back
                        </Button>
                        :
                        <Button
                            onClick={handleNext}
                            variant="outlined"
                            disabled={activeStep === 1}
                        >
                            Next
                        </Button>
                    }
                </Grid>
            </Grid>

            {/*ADD COUPON DRAWER*/}
            <Drawer anchor="right" open={openAdd} onClose={() => setOpenAdd(false)}>
                <CouponActions
                    mode={'Add'}
                    closeActionsCoupon={handleCreateCouponClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*ADD COUPON NOTIFICATION*/}
            <Snackbar open={snackNotif === "addCoupon"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Coupon added successfully!
                </Alert>
            </Snackbar>
        </Grid>
    )
}

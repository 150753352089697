import { Grid, Typography } from "@mui/material";
import React from "react";
import PricingErrorPage from "pages/scenarios/PricingErrorPage";
import ScenarioDataContext from "pages/scenarios/ScenarioDataContext";
import PricingResultDataContext from "../PricingResultDataContext";
import CompetitorStrategyInput from "./CompetitorStrategyInput";
import CostPlusStrategyInput from "./CostPlusStrategyInput";
import FinalizedPriceInput from "./FinalizePriceInput";
import SegmentStrategyElement from "./SegmentStrategyElement";
import PricePointTotals from "../components/PricePointTotals";
import ValueModellingStrategyInput from "./ValueModellingStrategyInput"

export default function StrategyTab(props) {
  const strategy = props.strategy;
  const { pricingResult } = React.useContext(PricingResultDataContext);
  const { scenario } = React.useContext(ScenarioDataContext);
  const [key, setKey] = React.useState(1);

  const segmentParams = scenario["params"]["segment_params"];
  const strategyPricePoint = pricingResult["price_points"][strategy];

  const refresh = () => {
    setKey(key + 1);
  }

  return (<React.Fragment key={key}>
    {pricingResult && pricingResult["error_code"] && <PricingErrorPage error="Pricing error"></PricingErrorPage>}
    {pricingResult && !pricingResult["error_code"] && <Grid container item xs={12} flex="flex-start">
      {strategyPricePoint &&
        <React.Fragment>
          <PricePointTotals pricePoint={strategyPricePoint}></PricePointTotals>
          <Grid container key={"g1" + key} item xs={12} sx={{ boxShadow: 1, borderRadius: 2, backgroundColor: "primary.bg2", mt: 2 }} justifyContent="center" gap={3}>
            {segmentParams.map((segment, idx) => {
              const segmentId = segment["id"];
              const pricePoint = strategyPricePoint;
              const segmentPrice = pricePoint["params"]["attributes"]
                .find(x => String(x["segment_id"]) === String(segmentId));
              return (<React.Fragment key={idx}>
                {segmentPrice && <SegmentStrategyElement key={idx} segmentPrice={segmentPrice} inputElement={
                  strategy === "COST_PLUS_STRATEGY" ?
                    <CostPlusStrategyInput segmentId={segmentId} onChange={refresh}></CostPlusStrategyInput> :
                    strategy === "COMPETITOR_STRATEGY" ?
                      <CompetitorStrategyInput segmentId={segmentId} onChange={refresh}></CompetitorStrategyInput> :
                      strategy === "VALUE_BASED_STRATEGY" ?
                        <ValueModellingStrategyInput segmentId={segmentId} onChange={refresh}></ValueModellingStrategyInput> :
                        strategy === "FINALIZE_PRICE" ?
                          <FinalizedPriceInput segmentId={segmentId} onChange={refresh}></FinalizedPriceInput>
                          : <React.Fragment></React.Fragment>} />}
              </React.Fragment>)
            })}
          </Grid>
        </React.Fragment>}
      {!strategyPricePoint && <Typography>No price models found</Typography>}
    </Grid>}
  </React.Fragment>)
}

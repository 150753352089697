import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Backdrop,
    Button, Checkbox, CircularProgress, Divider, Grid, IconButton, Popover, Slider,
    TextField, Tooltip, Typography
} from "@mui/material";
import Link from "@mui/material/Link";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    createStripeCheckoutSession
} from "../../apis/ClientDataService";
import { useErrorContext } from "../../state/ErrorDialogContext";
import priceops from 'priceops';
import {Box} from "@mui/system";
import AddOnSection from "./AddOnSection";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {TieredPricingPreview} from "./TieredPricingPreview";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

function getContrastYIQ(hexcolor, opacity) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "rgba(17,24,39," + (opacity ?? "1.0") + ")" : "rgba(255,255,255," + (opacity ?? "1.0") + ")";
}

export default function DesignPreview(
    {
        designFrameId,
        scale,
        products,
        notification,
        designBgColor,
        designButtonColor,
        designCardColor,
        designHighlightedButtonColor,
        designHighlightedCardColor,
        designCtaButtonColor,
        designCtaCardColor,
        designCardBoxShadow,
        designCardBorderRadius,
        designButtonBorderRadius,
        designCardBorderColor,
        designCtaBorderColor,
        designHighlightedPlanNameColor,
        designCardWidth,
        designCtaButtonLink,
        designTickColor,
        designHighlightedTickColor,
        designCtaTickColor,
        designButtonVariant,
        designHighlightedButtonVariant,
        designStyleMode,
        designFontStyle,
        designShape,
        designHighlightedProduct,
        designHighlightedText,
        designButtonText,
        designCta,
        designCtaName,
        designCtaDescription,
        designCtaButtonText,
        designCtaFeatures,
        loginRedirectLink,
        requiresLoggedInUsers,
        addOnSection,
        headerSection,
        designHeaderText,
        designSubheaderText,
        addOnProducts,
        removeWatermark
    }
) {

    const PricingPreviewTooltip = styled(({ className, ...props }) => (
        <Tooltip arrow {...props} classes={{ popper: className }} placement="top"/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'black',
            boxShadow: theme.shadows[10]
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#f5f5f9',
        },
    }));

    const [organization, setOrganization] = React.useState(null);

    const [stripePublishableApiKey, setStripePublishableApiKey] = useState(null);

    const [loading, setLoading] = useState(false)

    const { loadErrorDialog } = useErrorContext();

    const [customer,setCustomer] = useState(null);

    const [isTabDevice,setIsTabDevice] = useState(false);

    // const Priceops = window.Priceops ?? window.top?.Priceops ?? window.parent?.Priceops;

    useEffect(() => {
        // Send a message to the parent window which the client will have

        window.parent.postMessage('getCustomer', '*');

        // WE CANT DO THE BELOW AS IT RERENDERS AND MAKES TEXTFIELD INPUTS LOSE FOCUS HENCE MAKING THEM UNUSABLE
        // const intervalGetCustomer = setInterval(() => {
        //     window.parent.postMessage('getCustomer', '*');
        //     // console.log('Updating customer!');
        // }, 5000);

        // BELOW IS FOR FOLLOWING UPDATE
        // window.parent.postMessage({
        //     type: 'getCustomer',
        //     data: {
        //         frameId : designFrameId,
        //         height : designContainerRef.current.offsetHeight
        //     }
        // }, '*');

        // Listen for messages from the parent window
        window.addEventListener('message', function(event) {
            if (event.data.type === 'customerData') {
                var customerData = event.data.data;
                // Do something with the customer data
                setCustomer(customerData)
                // console.log("Got Customer", customerData)
            }
        }, false);

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                // console.log('Height:', entry.contentRect.height,
                //     designContainerRef.current.clientHeight,
                //     designContainerRef.current.offsetHeight
                // );
                setIsTabDevice(prevIsTabDevice => {
                    if (entry.contentRect.width < 500 && !prevIsTabDevice) {
                        // console.log("Switching to tab device mode");
                        return true;
                    } else if (entry.contentRect.width >= 500 && prevIsTabDevice) {
                        // console.log("Switching to non-tab device mode");
                        return false;
                    }
                    return prevIsTabDevice;
                });
                // console.log('Width:', entry.contentRect.width );
                // if(designFrameId){
                //     window.parent.postMessage({
                //         type: 'setDesignHeight',
                //         data: {
                //             frameId : designFrameId,
                //             height : designContainerRef.current.offsetHeight
                //         }
                //     }, '*');
                // }


            }

        });

        if (designContainerRef.current) {
            resizeObserver.observe(designContainerRef.current);
        }

        return () => {
            if (designContainerRef.current) {
                resizeObserver.unobserve(designContainerRef.current);
            }
            // clearInterval(intervalGetCustomer);
        };

    }, []);

    //STRIPE PAYMENT INTEGRATION
    const handleStripePaymentIntegration = async (products, sessionType) => {

        // console.log(customer)

        if (requiresLoggedInUsers && !customer) {
            if (!loginRedirectLink) {
                alert("Please login");
                return;
            }
            window.top.location.href = loginRedirectLink;
            return;
        }


        if (products[0].payment_integration?.gateway_type === 'Stripe') {
            setLoading(true)

            //
            //
            // console.log(stripePublishableApiKey)
            //
            // const connectId = product.payment_integration.stripe_properties.connect_id
            // const stripePromise = loadStripe(stripePublishableApiKey, {
            //     stripeAccount: connectId
            // });

            const sessionDetails = {
                // "secret_api_key": product.payment_integration.stripe_secret_api_key,
                // "card_title": product.header_name,
                // "description": product.description,
                // "total_billing_amount": totalBillingAmount,
                // "currency_code": currencyCode,
                // "product_id": product.id,
                // "client_id": product.client_id,
                // "scenario_id": product.scenario_id,
                "billing_period": selectedBillingPeriod,
                "final_products": products,
                // "stripe_connect_id": connectId,
                // "success_url": product.payment_integration.stripe_properties.success_url,
                // "cancel_url": product.payment_integration.stripe_properties.cancel_url,
                // "tax_behaviour": product.tax_integration.tax_behaviour,
                // "tax_code": product.tax_integration.tax_code,
                // "card_prices": currCardPrices,
                // "stripe_price_ids": product.payment_integration.stripe_properties.price_ids,
                // "stripe_product_id": product.payment_integration.stripe_properties.product_id,
                "quantity_values": values || {},
                "session_type": sessionType,
                // "usage_type": product.usage_type,
                // "free_trial_integration": product.free_trial_integration,
                "customer_id": customer?.id?.toString(),
                "customer_email": customer?.email?.toString()
            }
            // const stripe = await stripePromise;

            // console.log(sessionDetails)

            createStripeCheckoutSession(sessionDetails, (data) => {
                window.open(data.url, '_blank', 'noopener,noreferrer');
                setLoading(false)
            }, (err) => {
                // console.log(err,"MATA")
                setLoading(false)
                loadErrorDialog({
                    title: "Error", message: err?.error_message ??
                        "Unable to redirect to checkout session at the moment"
                });
            })

            // const session = await response.json();
            //
            // const result = await stripe.redirectToCheckout({
            //     sessionId: session.id,
            // });
            //
            // if (result.error) {
            //     alert(result.error.message);
            //     setLoading(false)
            // }
            //
            // setLoading(false)
        }

    };

    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
        products[0].prices[0].billing_period
    );
    const [billingPeriods, setBillingPeriods] = useState(
        Array.from(
            new Set(
                products.flatMap((product) =>
                    product.prices.map((price) => price.billing_period)
                )
            )
        )
    );

    //SETTING EQUAL HEIGHTS USING REFS
    //DESCRIPTION HEIGHT
    const descriptionsRef = useRef([]);
    descriptionsRef.current = [];

    const addToDescriptionsRefs = (el) => {
        if (el && !descriptionsRef.current.includes(el)) {
            descriptionsRef.current.push(el);
        }
    };

    //PRICES HEIGHT
    const pricesRef = useRef([]);
    pricesRef.current = [];

    const addToPricesRefs = (el) => {
        if (el && !pricesRef.current.includes(el)) {
            pricesRef.current.push(el);
        }
    };

    //USAGE INPUTS HEIGHT
    const usagesRef = useRef([]);
    usagesRef.current = [];

    const addToUsagesRefs = (el) => {
        if (el && !usagesRef.current.includes(el)) {
            usagesRef.current.push(el);
        }
    };

    //PAYMENT BUTTON AND LINK AREA HEIGHT
    const buttonLinkRef = useRef([]);
    buttonLinkRef.current = [];

    const addToButtonLinkRefs = (el) => {
        if (el && !buttonLinkRef.current.includes(el)) {
            buttonLinkRef.current.push(el);
        }
    };

    //FEATURES HEIGHT
    const featuresRef = useRef([]);
    featuresRef.current = [];

    const addToFeaturesRefs = (el) => {
        if (el && !featuresRef.current.includes(el)) {
            featuresRef.current.push(el);
        }
    };

    const resetHeights = () =>{
        // Resetting all heights to auto
        descriptionsRef.current.forEach((desc) => (desc.style.height = 'auto'));
        pricesRef.current.forEach((desc) => (desc.style.height = 'auto'));
        usagesRef.current.forEach((desc) => (desc.style.height = 'auto'));
        buttonLinkRef.current.forEach((desc) => (desc.style.height = 'auto'));
        featuresRef.current.forEach((desc) => (desc.style.height = 'auto'));
        // Recalculating max height
        const maxDescriptionHeight = Math.max(...descriptionsRef.current.map((desc) => desc.clientHeight));
        const maxPriceHeight = Math.max(...pricesRef.current.map((desc) => desc.clientHeight));
        const maxUsageHeight = Math.max(...usagesRef.current.map((desc) => desc.clientHeight));
        const maxButtonLinkHeight = Math.max(...buttonLinkRef.current.map((desc) => desc.clientHeight));
        const maxFeatureHeight = Math.max(...featuresRef.current.map((desc) => desc.clientHeight));

        // Apply max height to all elements
        descriptionsRef.current.forEach((desc) => (desc.style.height = `${maxDescriptionHeight}px`));
        pricesRef.current.forEach((desc) => (desc.style.height = `${maxPriceHeight}px`));
        usagesRef.current.forEach((desc) => (desc.style.height = `${maxUsageHeight}px`));
        buttonLinkRef.current.forEach((desc) => (desc.style.height = `${maxButtonLinkHeight}px`));
        featuresRef.current.forEach((desc) => (desc.style.height = `${maxFeatureHeight}px`));

        // console.log(maxFeatureHeight,featuresRef.current[0].clientHeight,featuresRef.current[0])
    }

    const [count, setCount] = useState(0);

    const designContainerRef = useRef();

    useEffect(() => {
        resetHeights();

        // console.log(designContainerRef, "Hello This is big container yeah")

        // const currDesignHeight = designContainerRef.current.offsetHeight
        //
        // window.parent.postMessage({
        //     type: 'iframeHeight',
        //     data: height
        // }, '*');

    }, [products, notification, designCtaDescription, selectedBillingPeriod, designCardWidth, designCtaFeatures]);

    // console.log(products)

    // Update the state variables whenever the products prop changes
    useEffect(() => {
        products.forEach((product) => {
            product.usages.forEach((usage) => {
                setValues(prevValues => ({
                    ...prevValues,
                    [product.name]: {
                        ...(prevValues[product.name] || {}),
                        [usage.unit]: usage.default
                    }
                }));
            });
        });
        setSelectedBillingPeriod(products[0].prices[0].billing_period);
        setBillingPeriods(
            Array.from(
                new Set(
                    products.flatMap((product) =>
                        product.prices.map((price) => price.billing_period)
                    )
                )
            )
        );
    }, [products]);

    const handleBillingPeriodChange = (billingPeriod) => {
        if (billingPeriod !== null) {
            setSelectedBillingPeriod(billingPeriod);
        }
        // console.log(billingPeriod)
    };

    const [values, setValues] = useState({});
    const handleDefaultValueChange = (newValue, productName, usage) => {
        if (newValue < usage.min) {
            newValue = usage.min;
        } else if (newValue > usage.max) {
            newValue = usage.max;
        }
        setValues(prevValues => ({
            ...prevValues,
            [productName]: {
                ...(prevValues[productName] || {}),
                [usage.unit]: newValue
            }
        }));
        // console.log("VALUES OF USAGE METRICS", values)
    };

    // Compute the filteredProducts array using the useMemo hook
    const filteredProducts = useMemo(() => {
        const filtered = products.filter((product) =>
            product.prices.some((price) => price.billing_period === selectedBillingPeriod)
        );

        // filtered.sort((a, b) => {
        //     const priceA = a.prices.find((price) => price.billing_period === selectedBillingPeriod);
        //     const priceB = b.prices.find((price) => price.billing_period === selectedBillingPeriod);
        //     return priceA.price_value - priceB.price_value;
        // });
        return filtered;
    }, [products, selectedBillingPeriod]);

    function CustomTextField(props) {
        const [isFocused, setIsFocused] = useState(false);
        const [val, setVal] = useState(props.value)
        const handleFocus = () => setIsFocused(true);
        const handleBlur = () => setIsFocused(false);

        return (
            <TextField
                {...props}
                onChange={(event) => {
                    setVal(event.target.value)
                }}
                onFocus={(event) => {
                    handleFocus();
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        // handleBlur();
                        if (!isNaN(val)) {
                            handleDefaultValueChange(Number(val), props.product.name, props.usage)
                        } else {
                            setVal(props.value)
                        }
                    }
                }}
                onBlur={(event) => {
                    handleBlur();
                    if (!isNaN(val)) {
                        handleDefaultValueChange(Number(val), props.product.name, props.usage)
                    } else {
                        setVal(props.value)
                    }
                }}
                value={isFocused ? val : props.renderValue(props.value)}
            />
        );
    }

    const styles = {
        usagePlusMinus: {
            // borderRadius={1}
            p: 0.1,
            width: '14%',
            '&:hover': {
                opacity: 0.8
            },
            opacity: 0.3,
            cursor: 'pointer',
        }
    }

    const verifyAddOnSection = (filteredProducts) =>{

        return addOnSection &&
            addOnProducts.length &&
            addOnProducts.some(addOnProduct => {
                return addOnProduct.selected_products_ids?.some(productId => {
                    return filteredProducts.some(product => product.id === productId);
                })
            })
    }

    const [anchorElPricingPreview, setAnchorElPricingPreview] = React.useState(null);

    const handlePopoverOpenPricingPreview = (event) => {
        setAnchorElPricingPreview(event.currentTarget);
    };

    const handlePopoverClosePricingPreview = () => {
        setAnchorElPricingPreview(null);
    };

    const openPricingPreview = Boolean(anchorElPricingPreview);

    return (
        <Grid
            // key={count}
            ref={designContainerRef}
            container
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                zoom: (scale ? scale : 0.5),
                // transform: 'scale(' + String(scale ?? 0.7) + ')',
                // transformOrigin: 'top left',
                bgcolor: designBgColor,
                // backgroundImage: 'linear-gradient(45deg, #c11ceb, #6f19b5, #25007a)',
                p: 3,
                minHeight: '100vh'
            }}
            display='column'
            gap={2}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {headerSection &&
                <Grid
                    container
                    item
                    width={'100%'}
                    justifyContent="center"
                    alignItems="center"
                    gap={3}
                    pt={isTabDevice ? 3 : 8}
                    pb={2}
                    px={isTabDevice ? 3 : 8}
                    textAlign={isTabDevice? 'left' : 'center'}
                >
                    <Typography
                        sx={{width:'100%'}}
                        fontWeight={900}
                        fontFamily={designFontStyle}
                        fontSize={isTabDevice? 40 : 50}
                        color={getContrastYIQ(designBgColor)}
                    >
                        {designHeaderText}
                    </Typography>
                    <Typography
                        width={isTabDevice? '100%' : '70%'}
                        fontWeight={400}
                        fontFamily={designFontStyle}
                        fontSize={isTabDevice? 16 : 18}
                        color={getContrastYIQ(designBgColor)}
                    >
                        {designSubheaderText}
                    </Typography>
                </Grid>
            }

            <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    border: `1.5px solid ${designCardBorderColor}`,
                    mt: 3,
                    mb: 4,
                    p: 0.3,
                    borderRadius: 4,
                    boxShadow: designCardBoxShadow,
                    bgcolor: (designHighlightedProduct === 'None' || designHighlightedButtonVariant === 'Outlined') ?
                        designCardColor : designHighlightedCardColor
                }}
            >
                {billingPeriods.map((billingPeriod, idx) => (
                    <Grid key={idx}
                        onClick={() => { handleBillingPeriodChange(billingPeriod) }}
                        sx={
                            billingPeriod === selectedBillingPeriod
                                ? {
                                    cursor: 'pointer',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    borderRadius: 4,
                                    p: '0.5em 0.8em 0.5em 0.8em',
                                    bgcolor: (designHighlightedProduct === 'None') ?
                                        designButtonColor : designHighlightedButtonColor,
                                    color: (designHighlightedProduct === 'None') ?
                                        getContrastYIQ(designButtonColor) :
                                        getContrastYIQ(designHighlightedButtonColor),
                                    fontFamily: designFontStyle,
                                }
                                : {
                                    cursor: 'pointer',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    borderRadius: 4,
                                    p: '0.5em 1em 0.5em 1em',
                                    color: 'primary.greyText',
                                    fontFamily: designFontStyle,
                                }
                        }
                    >
                        {billingPeriod === "N/A" ? "One Time" : billingPeriod}
                    </Grid>
                ))}
            </Grid>
            {/* Display a grid of product information */}
            <Grid container item columnGap={'3vw'} rowGap={'5vh'} justifyContent={'center'} height={'fit-content'}>
                {filteredProducts.map((product) => {
                    // Find the list of prices with a billing period equal to the selected billing period
                    const prices = product.prices.filter(
                        (price) => price.billing_period === selectedBillingPeriod
                    );

                    let tieredPricing = product.price_model.type === 'TIERED_PRICING';
                    let tiersMode = tieredPricing ? product.tiers_mode : null;

                    const calculateTieredPriceTotal = (tiers, quantity) => {
                        let graduatedTotal = 0;
                        let cumulativeQuantity = 0;

                        for(let tier of tiers){
                            if(tiersMode === 'volume'){
                                if(quantity <= tier.up_to || tier.up_to === -1){
                                    return ((quantity*tier.unit_price_value) + tier.flat_price_value)
                                }
                            }else{
                                if(quantity <= tier.up_to || tier.up_to === -1){
                                    graduatedTotal += (((quantity-cumulativeQuantity)*tier.unit_price_value) + tier.flat_price_value)
                                    return graduatedTotal
                                } else {
                                    graduatedTotal += (((tier.up_to-cumulativeQuantity)*tier.unit_price_value) + tier.flat_price_value)
                                    cumulativeQuantity = tier.up_to
                                }
                            }
                        }

                    }

                    // HOW TO CALCULATE SAVINGS
                    // Savings = (z-x)/z

                    // Find max value y lets call it z
                    // groupedPrices is all product.prices grouped by same price.billing_period
                    // y is sum of all price.price_value multiplied with its respective usage metric for all prices in a groupedPrice
                    // price.price_metric.price_units where it has price_unit and each price_unit has unit

                    // Now lets find x
                    // x is the sum of all price.price_value multiplied with its respective usage metric for all prices in prices

                    // usage metric is multiplication of all values[product.name][price_unit.unit]
                    // make sure price_unit.unit first letter is capital
                    // Find this max value y amongst all prices

                    let groupedPrices = product.prices.reduce((grouped, price) => {
                        // Find the group for this billing period
                        let group = grouped.find(g => g.billing_period === price.billing_period);
                        if (!group) {
                            // If the group doesn't exist, create it
                            group = { billing_period: price.billing_period, prices: [] };
                            grouped.push(group);
                        }
                        // Add the price to the group
                        group.prices.push(price);
                        return grouped;
                    }, []);

                    // console.log(groupedPrices,values)

                    let z = 0; // Initialize z
                    // Iterate over each group in groupedPrices
                    for (let group of groupedPrices) {
                        // Iterate over each price in the current group
                        // Calculate y for each group
                        let y = 0; // Initialize y
                        for (let price of group.prices) {
                            let total_usage = 1
                            for (let price_unit of price.price_metric.price_units) {
                                // Make sure price_unit.unit first letter is capital
                                let unit = price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1);
                                // Check if values[product.name][unit] exists, if not use product.usages
                                let usageValue;
                                if (values[product.name] && values[product.name][unit]) {
                                    usageValue = values[product.name][unit];
                                } else {
                                    // Find the usage with the same unit in product.usages
                                    let usage = product.usages.find(u => u.unit === unit);
                                    usageValue = usage ? usage.default : 1;
                                }
                                total_usage *= usageValue;
                            }
                            if(tieredPricing){
                                y += calculateTieredPriceTotal(price.tiers, total_usage)
                            }else{
                                y += (price.price_value * total_usage)
                            }
                        }
                        // console.log(y,z)
                        z = Math.max(y, z)
                    }

                    // console.log(z)

                    let units = new Set();

                    let x = 0; // Initialize x
                    // Iterate over each price in prices
                    for (let price of prices) {
                        let total_usage = 1
                        for (let price_unit of price.price_metric.price_units) {
                            // Make sure price_unit.unit first letter is capital
                            let unit = price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1);
                            // Add unit to set and make sure only units which are in price are shown in usage inputs
                            units.add(unit);
                            // Check if values[product.name][unit] exists, if not use product.usages
                            let usageValue;
                            if (values[product.name] && values[product.name][unit]) {
                                usageValue = values[product.name][unit];
                            } else {
                                // Find the usage with the same unit in product.usages
                                let usage = product.usages.find(u => u.unit === unit);
                                usageValue = usage ? usage.default : 1;
                            }
                            // Add to x
                            total_usage *= usageValue;
                        }
                        if(tieredPricing){
                            x += calculateTieredPriceTotal(price.tiers, total_usage)
                        }else{
                            x += (price.discount_price_value * total_usage)
                        }
                    }

                    // console.log(x)

                    let savings = Math.round(((z - x) * 100) / z);
                    // console.log(groupedPrices,prices)

                    // Finding multiplier in billing
                    let priceMultiplier = 0; // setting it to 0 incase of any error
                    let timePeriodUnit = 'month';

                    if (!groupedPrices.some(group => group.billing_period.includes('month')) &&
                        !product.prices.some(price => price.price_metric.price_units.some(price_unit => price_unit.unit.includes('month'))) &&
                        product.prices.some(price => price.price_metric.price_units.some(price_unit => price_unit.unit.includes('year')))) {
                        timePeriodUnit = 'year';
                    }

                    // Check selectedBillingPeriod
                    let match = selectedBillingPeriod.match(/Every (\d+) (months|years)/);
                    if (match) {
                        let x = parseInt(match[1]);
                        let unit = match[2];
                        if (unit === 'years') {
                            priceMultiplier = timePeriodUnit === 'month' ? x * 12 : x;
                        } else {
                            priceMultiplier = x;
                        }
                    } else if (selectedBillingPeriod === 'Yearly') {
                        priceMultiplier = timePeriodUnit === 'month' ? 12 : 1;
                    } else if (selectedBillingPeriod === 'Monthly' || selectedBillingPeriod === 'N/A') {
                        priceMultiplier = 1;
                    }

                    let currCardColor = (designHighlightedProduct === product.name)
                        ? designHighlightedCardColor : designCardColor;

                    let currButtonColor = (designHighlightedProduct === product.name)
                        ? designHighlightedButtonColor : designButtonColor;

                    let currButtonVariant = (designHighlightedProduct === product.name)
                        ? designHighlightedButtonVariant : designButtonVariant;

                    let isFreeTrialActive = product.free_trial_integration?.active && selectedBillingPeriod !== 'N/A';

                    return (
                        // <Paper elevation={6}>
                        //     <Grid container direction={'column'} sx={{ height:'100%' }}>
                        <Grid container item key={product.id} direction={'column'}
                            sx={{
                                width: designCardWidth,
                                height: 'auto',
                                p: 4,
                                rowGap: 0.5,
                                borderRadius: designCardBorderRadius,
                                boxShadow: designCardBoxShadow,
                                bgcolor: currCardColor,
                                border: (designHighlightedProduct === product.name) ?
                                    `2px solid ${designHighlightedButtonColor}` : `2px solid ${designCardBorderColor}`,
                            }}
                        >

                            {savings > 0 &&
                                <Grid container justifyContent={'center'} alignItems={'center'}
                                    sx={{
                                        pb: 2.3, mt: -5.65,
                                    }}
                                >
                                    <Typography
                                        fontFamily={designFontStyle}
                                        px={1.1} py={0.5}
                                        borderRadius={4} fontSize={10}
                                        fontWeight={800} color={'black'}
                                        sx={{
                                            // GOLD GRADIENT HIGHLIGHTING
                                            backgroundImage: 'linear-gradient(to right, #d4af37, #f1e5bc, #d4af37)',
                                        }}
                                    >
                                        {savings}% Savings
                                    </Typography>
                                </Grid>
                            }
                            <Grid container justifyContent={'space-between'} gap={2} mt={0} >
                                <Typography
                                    fontSize={22}
                                    fontWeight={800}
                                    color={(designHighlightedProduct === product.name) ?
                                        designHighlightedPlanNameColor : getContrastYIQ(currCardColor)}
                                    fontFamily={designFontStyle}
                                >
                                    {product.header_name}
                                </Typography>
                                {(designHighlightedProduct === product.name && designHighlightedText !== 'None') && (
                                    <Grid
                                        alignItems={"center"}
                                        container
                                        width={"fit-content"}
                                    >
                                        <Typography
                                            fontSize={10}
                                            fontWeight={800}
                                            borderRadius={4}
                                            fontFamily={designFontStyle}
                                            sx={{
                                                p: '0.5em 0.8em 0.5em 0.8em',
                                                border: designHighlightedButtonVariant === 'Contained' ?
                                                    'none' : `1px solid ${designHighlightedButtonColor}`,
                                                bgcolor: designHighlightedButtonVariant === 'Contained' ? currButtonColor : '#ffffff',
                                                color: designHighlightedButtonVariant === 'Contained' ? getContrastYIQ(currButtonColor) :
                                                    designHighlightedButtonColor,
                                                // Choose any one of the below metallic gradients for highlighting
                                                // SILVER GRADIENT HIGHLIGHTING
                                                // backgroundImage: "linear-gradient(to right, #bcbcbc 0%," +
                                                //     " #e5e5e5 20%, #f3f4f6 50%, #e5e5e5 80%, #bcbcbc 100%)",
                                                // GOLD GRADIENT HIGHLIGHTING
                                                // backgroundImage: 'linear-gradient(to right, #d4af37, #f1e5bc, #d4af37)',
                                                // animation: 'gradient 15s ease infinite',
                                            }}
                                        >
                                            {designHighlightedText}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Typography
                                fontSize={12}
                                pt={1}
                                pb={0}
                                color={getContrastYIQ(currCardColor)}
                                fontFamily={designFontStyle}
                                ref={addToDescriptionsRefs}
                                key={product.description}
                            >
                                {product.description}
                            </Typography>
                            <Grid ref={addToPricesRefs}>
                                {prices.map((price, index) => {
                                    return (
                                        <Grid key={index} container direction={'column'} gap={0}>
                                            <Grid container item
                                                key={index}
                                            >
                                                {price.discount_price_value !== price.price_value && <Typography
                                                    fontSize={36}
                                                    fontFamily={designFontStyle}
                                                    fontWeight={600}
                                                    color={'darkgray'}
                                                    sx={{
                                                        mr: 1,
                                                        display: "inline-block",
                                                        position: "relative",
                                                        "&::before": {
                                                            content: '""',
                                                            position: "absolute",
                                                            left: "-0.1em",
                                                            right: "-0.1em",
                                                            top: "0.38em",
                                                            bottom: "0.30em",
                                                            background:
                                                                "linear-gradient(to left top, transparent 46.5%, currentColor 48.0%, currentColor 52.0%, transparent 53.5%)",
                                                            pointerEvents: "none"
                                                        }
                                                    }}
                                                >
                                                    {price.currency}{price.price_value.toLocaleString()}
                                                </Typography>}

                                                {product.price_model.type === 'TIERED_PRICING' && product.usage_type === 'metered' &&
                                                    <Grid container width={'100%'} mb={-1} pt={1}>
                                                        <Typography
                                                            fontSize={12}
                                                            fontWeight={400}
                                                            color={getContrastYIQ(currCardColor)}
                                                            fontFamily={designFontStyle}
                                                        >
                                                            Starting at
                                                        </Typography>
                                                    </Grid>}

                                                <Typography
                                                    fontSize={36}
                                                    fontWeight={600}
                                                    color={getContrastYIQ(currCardColor)}
                                                    fontFamily={designFontStyle}
                                                >
                                                    {product.price_model.type !== 'TIERED_PRICING' ?
                                                        (x === 0 ? 'Free' : `${price.currency}${price.discount_price_value > 1 ?
                                                            price.discount_price_value.toLocaleString() : price.discount_price_value}`)
                                                        :
                                                        (product.usage_type !== 'metered' ?
                                                                (x === 0 ? 'Free' : `${price.currency}${x > 1 ?
                                                                    x.toLocaleString() : x}`) :
                                                                ((price.tiers[0].unit_price_value + price.tiers[0].flat_price_value) === 0
                                                                    ? 'Free' : `${price.currency}${price.tiers[0].unit_price_value > 1 ?
                                                                    price.tiers[0].unit_price_value.toLocaleString() : price.tiers[0].unit_price_value}`)
                                                        )

                                                    }
                                                </Typography>
                                                <Grid direction={'column'} container xs pl={0.2} pb={1.2} justifyContent={'flex-end'}>
                                                    {!(product.price_model.type === 'TIERED_PRICING' && product.usage_type !== 'metered') ?
                                                        (price.price_metric.price_units.map((price_unit, i) => {
                                                                if ((!(((price_unit.unit === 'month' || price_unit.unit === 'year')
                                                                    && selectedBillingPeriod === 'N/A') || x === 0))) {
                                                                    return (
                                                                        <Typography
                                                                            mt={-0.5}
                                                                            fontSize={12}
                                                                            fontWeight={600}
                                                                            color={getContrastYIQ(currCardColor)}
                                                                            fontFamily={designFontStyle}
                                                                            key={i}
                                                                        >
                                                                            /{price_unit.unit}
                                                                        </Typography>
                                                                    )
                                                                }
                                                            }))
                                                        :
                                                        <Typography
                                                            mt={-0.5}
                                                            fontSize={12}
                                                            fontWeight={600}
                                                            color={getContrastYIQ(currCardColor)}
                                                            fontFamily={designFontStyle}
                                                        >
                                                            {x > 0 && selectedBillingPeriod !== 'N/A'
                                                                ?
                                                                    `/${timePeriodUnit}`
                                                                :
                                                                    ''
                                                            }
                                                        </Typography>
                                                    }
                                                </Grid>
                                                {product.price_model.type === 'TIERED_PRICING' && product.usage_type === 'metered' &&
                                                    <Grid container width={'100%'} mt={-1}>
                                                        <Typography
                                                            fontSize={12}
                                                            fontWeight={400}
                                                            color={getContrastYIQ(currCardColor)}
                                                            fontFamily={designFontStyle}
                                                        >
                                                            plus
                                                        </Typography>
                                                        {price.tiers[0].flat_price_value > 0 &&
                                                            <>
                                                                    <Typography
                                                                        sx={{ml:0.5, pb:1.5}}
                                                                        fontSize={12}
                                                                        fontWeight={600}
                                                                        color={getContrastYIQ(currCardColor)}
                                                                        fontFamily={designFontStyle}
                                                                    >
                                                                        {`${price.currency}${price.tiers[0].flat_price_value > 1 ?
                                                                            price.tiers[0].flat_price_value.toLocaleString() : price.tiers[0].flat_price_value}`}
                                                                        {selectedBillingPeriod !== 'N/A'
                                                                            ?
                                                                            ` /${timePeriodUnit}`
                                                                            :
                                                                            ''
                                                                        } fee
                                                                    </Typography>
                                                            </>
                                                        }
                                                        {/*{price.tiers[0].flat_price_value > 0 &&*/}
                                                        {/*    <>*/}
                                                        {/*        <Grid container width={'100%'} mt={-1}>*/}
                                                        {/*            <Typography*/}
                                                        {/*                fontSize={36}*/}
                                                        {/*                fontWeight={600}*/}
                                                        {/*                color={getContrastYIQ(currCardColor)}*/}
                                                        {/*                fontFamily={designFontStyle}*/}
                                                        {/*            >*/}
                                                        {/*                {`${price.currency}${price.tiers[0].flat_price_value > 1 ?*/}
                                                        {/*                    price.tiers[0].flat_price_value.toLocaleString() : price.tiers[0].flat_price_value}`}*/}
                                                        {/*            </Typography>*/}
                                                        {/*            <Typography*/}
                                                        {/*                mt={3.2}*/}
                                                        {/*                ml={0.2}*/}
                                                        {/*                fontSize={12}*/}
                                                        {/*                fontWeight={600}*/}
                                                        {/*                color={getContrastYIQ(currCardColor)}*/}
                                                        {/*                fontFamily={designFontStyle}*/}
                                                        {/*            >*/}
                                                        {/*                {selectedBillingPeriod !== 'N/A'*/}
                                                        {/*                    ?*/}
                                                        {/*                    `/${timePeriodUnit}`*/}
                                                        {/*                    :*/}
                                                        {/*                    ''*/}
                                                        {/*                }*/}
                                                        {/*            </Typography>*/}
                                                        {/*        </Grid>*/}
                                                        {/*    </>*/}
                                                        {/*}*/}
                                                    </Grid>}
                                            </Grid>
                                            {/*{*/}
                                            {/*    index !== prices.length-1 &&*/}
                                            {/*    <Grid py={0.5}>*/}
                                            {/*        <Divider/>*/}
                                            {/*    </Grid>*/}
                                            {/*}*/}
                                        </Grid>
                                    )
                                })}
                            </Grid>

                            {product?.usage_type !=='metered' ?
                                <Typography
                                    fontSize={12}
                                    fontFamily={designFontStyle}
                                    pt={1}
                                    color={getContrastYIQ(currCardColor)}
                                >
                                    Billed at {z !== x && <s>{prices[0].currency}{(Math.round(z * priceMultiplier).toLocaleString())}</s>}
                                    <b> {prices[0].currency}{(Math.round(x * priceMultiplier).toLocaleString())} </b>
                                    <b> {selectedBillingPeriod === 'N/A' ? 'One Time' : selectedBillingPeriod}</b>
                                </Typography>
                                :
                                <Typography
                                    fontSize={12}
                                    fontFamily={designFontStyle}
                                    pt={1}
                                    color={getContrastYIQ(currCardColor)}
                                >
                                    Billed <b>{selectedBillingPeriod}</b> based on usage
                                </Typography>
                            }

                            <Grid container ref={addToUsagesRefs}
                                  alignItems={product.price_model.type === 'TIERED_PRICING' ? 'flex-end' : 'stretch'}
                            >
                                {product?.usage_type !=='metered' && product?.usages?.length > 0 &&
                                    <Grid container pt={2}>
                                        <Grid container item direction={'column'} gap={1.5}
                                            // py={1}
                                            width={'100%'}
                                            // mt={2}
                                            // p={2}
                                            // bgcolor={'orange'}
                                            // boxShadow={1}
                                            // borderRadius={2} border={"1px solid slategray"}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            {/*<Typography*/}
                                            {/*    fontFamily={designFontStyle}*/}
                                            {/*    fontWeight={800}*/}
                                            {/*    color={getContrastYIQ(currCardColor)}*/}
                                            {/*>*/}
                                            {/*    Usage Metrics*/}
                                            {/*</Typography>*/}

                                            {product?.usages.map((usage, idx) => {
                                                if (units.has(usage.unit)) {
                                                    return (usage.type === 'Text Field' ?
                                                        <Grid container item
                                                            alignItems={'center'}
                                                            justifyContent={'space-between'}
                                                            width={'75%'}
                                                        // bgcolor={'green'}
                                                        >
                                                            <Grid
                                                                justifyContent={'center'}
                                                                container
                                                                sx={styles.usagePlusMinus}
                                                                color={getContrastYIQ(currCardColor)}
                                                                borderColor={currCardColor}
                                                                onClick={(event) =>
                                                                    handleDefaultValueChange((values?.[product?.name]?.[usage?.unit]) - usage.step, product.name, usage)
                                                                }
                                                            >
                                                                <RemoveIcon sx={{ fontSize: 16 }} />
                                                            </Grid>
                                                            <CustomTextField
                                                                product={product}
                                                                usage={usage}
                                                                key={idx}
                                                                // sx={{zoom:0.70}}
                                                                // label={price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1) + ' Count'}
                                                                // label={usage.unit + 's'}
                                                                size='small'
                                                                value={Object.keys(values).length > 0 ?
                                                                    (values?.[product?.name]?.[usage?.unit]) :
                                                                    (usage?.default)}
                                                                renderValue={(value) => {
                                                                    if (value > 1) {
                                                                        return `${Number(value).toLocaleString()} ${usage?.plural?.toLowerCase()}`;
                                                                    } else {
                                                                        return `${Number(value).toLocaleString()} ${usage?.unit.toLowerCase()}`;
                                                                    }
                                                                }}

                                                                // value={values[price_unit.unit]}
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: getContrastYIQ(currCardColor, 0.2),
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: getContrastYIQ(currCardColor, 0.2),
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: getContrastYIQ(currCardColor, 0.6),
                                                                        },
                                                                        borderRadius: 1
                                                                    },
                                                                    width: '67%'
                                                                }}
                                                                // type="number"
                                                                // InputLabelProps={{
                                                                //     shrink: true,
                                                                //     style: {
                                                                //         fontFamily: designFontStyle,
                                                                //         fontWeight: 800,
                                                                //         color: getContrastYIQ(currCardColor),
                                                                //     },
                                                                // }}
                                                                inputProps={{
                                                                    style: {
                                                                        fontFamily: designFontStyle,
                                                                        height: 10,
                                                                        fontSize: 12,
                                                                        // fontWeight: 800,
                                                                        textAlign: 'center',
                                                                        color: getContrastYIQ(currCardColor),
                                                                        backgroundColor:currCardColor,
                                                                        borderRadius: 1,
                                                                        p: 2,
                                                                    },
                                                                    min: usage.min,
                                                                    max: usage.max,
                                                                    step: usage.step
                                                                }}
                                                            // onWheel={event => event.target.blur()}
                                                            />
                                                            <Grid justifyContent="center" container
                                                                sx={styles.usagePlusMinus}
                                                                color={getContrastYIQ(currCardColor)}
                                                                borderColor={currCardColor}
                                                                onClick={(event) =>
                                                                    handleDefaultValueChange((values?.[product?.name]?.[usage?.unit]) + usage.step, product.name, usage)
                                                                }
                                                            >
                                                                <AddIcon sx={{ fontSize: 16 }} />
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid container py={0} key={idx}>
                                                            <Grid container item justifyContent={
                                                                ((Object.keys(values).length > 0
                                                                    ? values?.[product?.name]?.[usage?.unit] : usage?.default) / usage?.max)
                                                                    > 0.4
                                                                    ? 'flex-start' : 'flex-end'
                                                            }
                                                            // onMouseEnter={()=>{console.log(values)}}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: designFontStyle,
                                                                        fontWeight: 800,
                                                                        color: getContrastYIQ(currCardColor),
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {usage.unit + ' Count'}
                                                                </Typography>
                                                            </Grid>
                                                            <Slider
                                                                sx={{
                                                                    color: getContrastYIQ(currCardColor),
                                                                    '& .MuiSlider-valueLabel': {
                                                                        fontSize: 10,
                                                                    }
                                                                }}
                                                                defaultValue={usage.default}
                                                                // value={values[product.name][usage.unit]}
                                                                step={usage.step}
                                                                min={usage.min}
                                                                valueLabelDisplay="on"
                                                                max={usage.max}
                                                                onChange={(event) =>
                                                                    handleDefaultValueChange(event, product.name, usage)
                                                                }
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            })}

                                        </Grid>
                                    </Grid>
                                }
                                {product.price_model.type === 'TIERED_PRICING' &&
                                    <Grid container width={'100%'} justifyContent={'center'} pt={2}>
                                        <PricingPreviewTooltip
                                            // open={true}
                                            title={
                                                <TieredPricingPreview
                                                    designCardWidth={designCardWidth}
                                                    designFontStyle={designFontStyle}
                                                    tiers={prices[0].tiers}
                                                    price={prices[0]}
                                                    mode={product.tiers_mode}
                                                    usage={product.usages[0]}
                                                    usageType={product.usage_type}
                                                    quantity={values?.[product?.name]?.[product?.usages[0]?.unit]}
                                                />
                                            }
                                        >
                                            <Grid
                                                container
                                                width={'60%'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                mb={-0.5}
                                                sx={{
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        filter: 'brightness(90%)'
                                                    }
                                                }}
                                            >
                                                <VisibilityIcon
                                                    sx={{
                                                        fontSize: 14,
                                                        color: getContrastYIQ(currCardColor),
                                                        pr:0.5,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontFamily: designFontStyle,
                                                        fontWeight: 800,
                                                        color: getContrastYIQ(currCardColor),
                                                        fontSize: 12,
                                                        // borderBottom: `1px dashed ${getContrastYIQ(currCardColor)}`
                                                    }}
                                                >
                                                    Pricing Preview
                                                </Typography>
                                            </Grid>
                                        </PricingPreviewTooltip>
                                    </Grid>}
                            </Grid>

                            {/*<div>Price Model: {product.priceModel}</div>*/}
                            <Grid ref={addToButtonLinkRefs}>
                                <Link href={product.payment_integration?.gateway_type === 'Custom' ?
                                    product.payment_integration?.custom_button_links[selectedBillingPeriod] : null}
                                    underline={'none'}
                                    target={'_blank'}
                                >
                                    <Button
                                        variant={currButtonVariant?.toLowerCase()}
                                        disableElevation
                                        onClick={(e) => handleStripePaymentIntegration(
                                            [product],
                                            isFreeTrialActive ? "freeTrial" : "regular"
                                        )}
                                        sx={{
                                            height: 40,
                                            mt: 2,
                                            width: '100%',
                                            fontFamily: designFontStyle,
                                            bgcolor: (currButtonVariant === 'Contained') ? currButtonColor : '#ffffff',
                                            color: (currButtonVariant === 'Contained') ? getContrastYIQ(currButtonColor) : currButtonColor,
                                            border: (currButtonVariant === 'Outlined') ? `1px solid ${currButtonColor}` : 'none',
                                            textTransform: 'none',
                                            fontSize: 12,
                                            '&:hover': {
                                                bgcolor: (currButtonVariant === 'Contained') ? currButtonColor : '#ffffff',
                                                border: (currButtonVariant === 'Outlined') ? `1px solid ${currButtonColor}` : 'none',
                                                filter: 'brightness(90%)'
                                            },
                                            fontWeight: 600,
                                            p: 0.8,
                                            borderRadius: designButtonBorderRadius
                                        }}
                                    >
                                        {isFreeTrialActive ?
                                            `Free ${product.free_trial_integration.days}-Day Trial`
                                            : designButtonText
                                        }
                                    </Button>
                                </Link>

                                {
                                    (isFreeTrialActive && product.free_trial_integration.skip_trial_option
                                    && !(product.free_trial_integration.coupon.redeem_by < Math.floor(Date.now()/1000))
                                    ) &&
                                    <Grid container width={'100%'} justifyContent={'center'} pt={1}>
                                        <Typography
                                            fontSize={12}
                                            fontFamily={designFontStyle}
                                        >
                                            or
                                        </Typography>
                                        <Typography
                                            pl={0.5}
                                            fontFamily={designFontStyle}
                                            fontSize={12}
                                            fontWeight={600}
                                            style={{ textDecoration: 'underline', color: currButtonColor }}
                                            onClick={(e) => handleStripePaymentIntegration(
                                                [product],
                                                "skipTrial"
                                            )}
                                            sx={{
                                                textTransform: 'none',
                                                fontSize: 12,
                                                '&:hover': {
                                                    filter: 'brightness(130%)',
                                                    cursor: 'pointer'
                                                },
                                            }}
                                        >
                                            Skip trial, get {product.free_trial_integration.coupon.discount_percentage}% off
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>

                            <Grid ref={addToFeaturesRefs}>
                                <Grid container width={'100%'}>
                                    {product.features?.length > 0  &&
                                        <Grid pt={2} container width={'100%'} direction={'column'}>
                                            <Typography
                                                fontSize={12}
                                                fontWeight={600}
                                                pb={0.5}
                                                color={getContrastYIQ(currCardColor)}
                                                fontFamily={designFontStyle}
                                            >
                                                {product.header_name} includes
                                            </Typography>
                                            {product.features.map((feature, index) => {
                                                return (
                                                    <Grid key={index} container pt={1.15} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                        <Grid container item width={'5%'}>
                                                            <DoneIcon
                                                                sx={{
                                                                    fontSize: 14,
                                                                    color: (designHighlightedProduct === product.name) ?
                                                                        designHighlightedTickColor :
                                                                        designTickColor
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid container item width={'92%'} mt={-0.25}>
                                                            <Typography
                                                                fontSize={12}
                                                                color={getContrastYIQ(currCardColor)}
                                                                fontFamily={designFontStyle}
                                                            >
                                                                {feature}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        // {/*</Grid>*/}
                        // </Paper>
                    );
                })}
                {
                    designCta &&
                    <Grid container item direction={'column'}
                        sx={{
                            // border: '1px solid slategray',
                            width: designCardWidth,
                            // width: '25vw',
                            height: 'auto-fit',
                            p: 4,
                            rowGap: 0.5,
                            borderRadius: designCardBorderRadius,
                            boxShadow: designCardBoxShadow,
                            bgcolor: designCtaCardColor,
                            border: `2px solid ${designCtaBorderColor}`
                        }}
                    >
                        <Grid container justifyContent={'space-between'} gap={2} mt={0} >
                            <Typography
                                fontSize={22}
                                fontWeight={800}
                                color={getContrastYIQ(designCtaCardColor)}
                                fontFamily={designFontStyle}
                            >
                                {designCtaName}
                            </Typography>
                        </Grid>
                        <Typography
                            fontSize={12}
                            pt={1}
                            color={getContrastYIQ(designCtaCardColor)}
                            fontFamily={designFontStyle}
                            ref={addToDescriptionsRefs}
                            key={designCtaDescription}
                        >
                            {designCtaDescription}
                        </Typography>
                        <Grid ref={addToPricesRefs}>
                            <Grid container direction={'column'} gap={0}>
                                <Grid container item
                                // sx={{ zoom: 0.9 }}
                                >
                                    <Typography
                                        fontSize={36}
                                        fontWeight={600}
                                        color={getContrastYIQ(designCtaCardColor)}
                                        fontFamily={designFontStyle}
                                    >
                                        Custom
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography
                            fontSize={12}
                            pt={1}
                            fontFamily={designFontStyle}
                            color={getContrastYIQ(designCtaCardColor)}
                        >
                            Billed<b> {selectedBillingPeriod === 'N/A' ? 'One Time' : selectedBillingPeriod}</b>
                        </Typography>

                        <Grid ref={addToUsagesRefs}></Grid>

                        <Grid ref={addToButtonLinkRefs}>
                            <Link href={designCtaButtonLink} target="_blank" underline="none">
                                <Button
                                    // variant={designButtonVariant.toLowerCase()}
                                    disableElevation
                                    sx={{
                                        height: 40,
                                        width: '100%',
                                        mt: 2,
                                        fontFamily: designFontStyle,
                                        bgcolor: (designButtonVariant === 'Contained') ?
                                            designCtaButtonColor : '#ffffff',
                                        border: (designButtonVariant === 'Outlined') ?
                                            `1px solid ${designCtaButtonColor}` : 'none',
                                        color: (designButtonVariant === 'Contained') ?
                                            getContrastYIQ(designCtaButtonColor) : designCtaButtonColor,
                                        textTransform: 'none',
                                        fontSize: 12,
                                        '&:hover': {
                                            bgcolor: (designButtonVariant === 'Contained') ?
                                                designCtaButtonColor : '#ffffff',
                                            filter: 'brightness(95%)'
                                        },
                                        fontWeight: 600,
                                        p: 0.8,
                                        borderRadius: designButtonBorderRadius
                                    }}
                                >
                                    {designCtaButtonText}
                                </Button>
                            </Link>
                        </Grid>

                        <Grid ref={addToFeaturesRefs}>
                            <Grid container width={'100%'}>
                                {designCtaFeatures?.length > 0 &&
                                    <Grid pt={2} container width={'100%'} direction={'column'}>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={600}
                                            pb={0.5}
                                            color={getContrastYIQ(designCtaCardColor)}
                                            fontFamily={designFontStyle}
                                        >
                                            {designCtaName} includes
                                        </Typography>
                                        {designCtaFeatures.map((feature, index) => {
                                            return (
                                                <Grid key={index} container pt={1.15} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                    <Grid container item width={'5%'}>
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize: 14,
                                                                color: designCtaTickColor
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid container item width={'92%'} mt={-0.25}>
                                                        <Typography
                                                            fontSize={12}
                                                            color={getContrastYIQ(designCtaCardColor)}
                                                            fontFamily={designFontStyle}
                                                        >
                                                            {feature}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {verifyAddOnSection(filteredProducts) ? <AddOnSection
                    designFontStyle={designFontStyle}
                    designButtonBorderRadius={designButtonBorderRadius}
                    designCardBorderRadius={designCardBorderRadius}
                    products={filteredProducts}
                    addOnProducts={addOnProducts}
                    selectedBillingPeriod={selectedBillingPeriod}
                    designCardColor={designCardColor}
                    designCardWidth={designCardWidth}
                    designCardBoxShadow={designCardBoxShadow}
                    designCardBorderColor={designCardBorderColor}
                    designHighlightedCardColor={designHighlightedCardColor}
                    designHighlightedProduct={designHighlightedProduct}
                    designHighlightedButtonColor={designHighlightedButtonColor}
                    designHighlightedPlanNameColor={designHighlightedPlanNameColor}
                    designHighlightedButtonVariant={designHighlightedButtonVariant}
                    designButtonColor={designButtonColor}
                    designButtonVariant={designButtonVariant}
                    designButtonText={designButtonText}
                    designBgColor={designBgColor}
                    handleStripePaymentIntegration={handleStripePaymentIntegration}
                    handleDefaultValueChange={handleDefaultValueChange}
                    values={values}
                    setValues={setValues}
                    isTabDevice={isTabDevice}
                /> : null}

            </Grid>

            {removeWatermark !== "Yes" &&
                <Grid container justifyContent={'flex-end'} pt={2} pr={2} alignItems={'center'}>
                    <Typography
                        color={getContrastYIQ(designBgColor)}
                        fontFamily={'Open Sans'}
                        fontSize={12}
                    >
                        <Grid container alignItems={'center'}>
                            {/*Copyright © 2023 by PriceOps |*/}
                            Powered by <Link
                            // color={designHighlightedButtonColor}
                            href="https://www.priceops.net/" target="_blank"
                            sx={{ textDecoration: 'none' }}>
                            <Grid item container alignItems={'center'}>
                                <Grid mb={-0.5} pl={0.7} pr={0.3}>
                                    <img className="logo" src={process.env.PUBLIC_URL + '/favicon.ico'} width="12" height="12" />
                                </Grid>
                                {' '} <b>PriceOps</b>
                            </Grid>
                        </Link>
                        </Grid>
                    </Typography>
                </Grid>
            }

        </Grid>
    );
}
import React from "react";
import DataIntegrations from "./DataIntegrations";
import SchemaIcon from '@mui/icons-material/Schema';
import CardContentContainer from "../../components/CardContentContainer";

export default function DataIntegrationsPage(props) {
    return (
        <CardContentContainer
            {...props}
            cardIcon={<SchemaIcon />}
            title="Data integrations"
            content={
                <DataIntegrations></DataIntegrations>} />)
}
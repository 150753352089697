import { Grid, Typography } from "@mui/material";
import { currencyFormatter, pricePointFormatter } from "utils/Utils";

export default function PricePointTotals(props) {
    const pricePoint = props.pricePoint;
    const optimum_revenue = pricePoint["revenue"]["annual_revenue"];
    const optimum_profit = pricePoint["profit"]["value"];

    const styles = {
        optValues: {
            // border: 7,
            bgcolor: "primary.bg2",
            borderColor: "primary.background",
            borderRadius: 2,
            p: 1,
            boxShadow: 1,
            display: "inline"
        },
        optValuesText: {
            color: "primary.text",
            fontSize: props.variant === "xs" ? 12 : 20,
            fontWeight: "bold",
            width: '100%',
            textAlign: 'center'
        },
        optValuesHeader: {
            color: "primary.sub",
            fontSize: props.variant === "xs" ? 10 : 12,
            display: "block",
            width: '100%',
            textAlign: 'center'
        },
        optValuesMini: {
            color: "primary.main",
            fontSize: 10,
            width: '100%',
            textAlign: 'center'
        }
    }

    return (
        <Grid container item xs={12} justifyContent={'space-between'}>
            <Grid container item width={'49.5%'} alignContent="center" justifyContent="center" sx={styles.optValues}>
                <Typography sx={styles.optValuesHeader}>Total revenue</Typography>
                <Typography sx={styles.optValuesText}>{pricePointFormatter(optimum_revenue)}</Typography>
                <Typography sx={styles.optValuesMini}>{currencyFormatter(optimum_revenue)}</Typography>
            </Grid>
            <Grid container item width={'49.5%'} alignContent="center" justifyContent="center" sx={styles.optValues}>
                <Typography sx={styles.optValuesHeader}>Total profit</Typography>
                <Typography sx={styles.optValuesText}>{pricePointFormatter(optimum_profit)}</Typography>
                <Typography sx={styles.optValuesMini}>{currencyFormatter(optimum_profit)}</Typography>
            </Grid>
        </Grid>
    )
}
import AddIcon from "@mui/icons-material/Add";
import { Drawer, Dialog, Fab, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import CustDialogTitle from "../../components/CustDialogTitle";
import CreatePriceModelDefinition from "./price-modelling/components/CreatePriceModelDefinition";
import PriceModelDefinitions from "./price-modelling/components/PriceModelDefinitions";
import {listPriceModels} from "../../apis/ClientDataService";

export default function PriceModelDefintionsPage() {
    const [openAddPriceModelDialog, setOpenAddPriceModelDialog] = React.useState(false);
    const [priceModels, setPriceModels] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        if(!openAddPriceModelDialog){
            setLoading(true);
            // fetch price models
            listPriceModels((models) => {
                setPriceModels(models);
                setLoading(false);
            }, err => {
                setLoading(false);
            });
        }

    }, [openAddPriceModelDialog]);

    return (
        <Grid sx={{ border: "1px solid lightgray", p: 3, bgcolor: 'primary.background' }} minHeight={'89vh'}>
            <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                <Typography fontSize={30}>
                    Price Models And Metric Definitions
                </Typography>
                <Grid item>
                    <Tooltip title="Add Price Model">
                        <Fab color="primary" aria-label="add" size="small" >
                            <AddIcon
                                onClick={() => setOpenAddPriceModelDialog(true)}
                            />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
            {!loading &&
                <PriceModelDefinitions priceModels={priceModels}></PriceModelDefinitions>
            }
            {/*<Button startIcon={<AddIcon />} onClick={() => setOpenAddPriceModelDialog(true)}>New price model</Button>*/}
            <Drawer anchor="right" open={openAddPriceModelDialog} onClose={() => setOpenAddPriceModelDialog(false)}>
                <CustDialogTitle onClose={() => setOpenAddPriceModelDialog(false)}>
                    <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>
                        Define New Price Model
                    </Typography>
                </CustDialogTitle>
                <CreatePriceModelDefinition
                    onSuccess={(priceModel) => { setOpenAddPriceModelDialog(false); }}
                    onClose={() => setOpenAddPriceModelDialog(false)}
                />
            </Drawer>
        </Grid>
    );
}
import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


export default function FeatureBox(props) {
    const index = props.index;
    const feature = props.feature;
    const deleteFeatureFn = props.deletefeature ? props.deletefeature : {}

    const styles = {
        feature: {
            borderRadius: 1,
            boxShadow: 2,
            backgroundColor: "primary.sub",
            color: "primary.text",
            height: 'auto',
            textAlign: "center",
            position: 'relative',
            mb: 1,
            pb: 1
        },
        label: {
            postion: 'absolute',
            top: 0,
            left: 0,
            height: 20,
            backgroundColor: "primary.text",
            color: "primary.main",
            width: 120,
            borderBottomRightRadius: 100
        },
        delete: {
            position: 'absolute',
            right: 0,
            bottom: 0,
            pr: 1,
            color: "primary.groupBorder",
            transform: "scale(0.8)",
            opacity: 0.7,
            cursor: "pointer",
            "&:hover": {
                opacity: 1.0,
            }
        }
    }

    return (<Box key={feature} sx={styles.feature}>
        <Box sx={styles.label}>
            <Typography sx={{ fontSize: 12 }}>{"Feature " + (index + 1)}</Typography>
        </Box>
        {props.deletefeature && <Box sx={styles.delete} onClick={() => deleteFeatureFn(feature)}>
            <DeleteIcon />
        </Box>}
        <Typography sx={{ p: 1, fontWeight: 'bold' }}>{feature}</Typography>
    </Box>)
}
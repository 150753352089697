import React from "react";
import { validateEmail } from "../../utils/Utils";
import { getUser } from "../../state/state";
import { grantOrgAccessToUser } from "../../apis/ClientDataService";
import { Typography, Grid, Alert, TextField, FormControl, InputLabel, Select, MenuItem, ListItemText, Button, InputAdornment, CircularProgress } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';

export default function GrantAccess(props) {
    const [email, setEmail] = React.useState("");
    const [role, setRole] = React.useState("VIEWER");
    const [error, setError] = React.useState(null);
    const [activeOrg] = React.useState(props.org);
    const [loading, setLoading] = React.useState(false);

    const grantAccessToUser = () => {
        setLoading(true);
        grantOrgAccessToUser(activeOrg.id, email, role, (user) => {
            setLoading(false);
            props.onCancel();
            props.setSnackNotif("grantAccess");
        }, err => {
            setError(err?.["error_message"] ?? "Unable to grant access to this user");
            setLoading(false);
        });
    }

    return (<Grid container item sx={{ width: 550, minHeight: 280, borderRadius: 2, p: 4 }} direction="column">
        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Add user to {activeOrg.name}</Typography>
        <Typography sx={{ mb: 3, fontSize: 14 }}>You are about to grant access to other users.</Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}
        <Grid container item xs={12} justifyContent="center" alignItems="center">
            {loading && <CircularProgress />}
        </Grid>
        <TextField
            variant="standard"
            size="small"
            label="Email"
            type="text"
            value={email}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                         <EmailIcon />
                    </InputAdornment>
                )
            }}
            onChange={(e) => { setEmail(e.target.value); }}
        />
        <FormControl variant="outlined" sx={{ mt: 3, minWidth: 120 }}>
            <InputLabel id="role-select">Role</InputLabel>
            <Select labelId="role-select" value={role} onChange={e => setRole(e.target.value)} label="Role">
                <MenuItem value="VIEWER">
                    <ListItemText>Viewer</ListItemText>
                </MenuItem>
                <MenuItem value="EDITOR">
                    <ListItemText>Editor</ListItemText>
                </MenuItem>
                <MenuItem value="ADMIN">
                    <ListItemText>Admin</ListItemText>
                </MenuItem>
            </Select>
        </FormControl>
        <Grid item container justifyContent='flex-end'>
            <Button variant="outlined" size="medium" sx={{ mt: 4 }} onClick={props.onCancel}>Cancel</Button>
            <Button variant="outlined" size="medium"
                onClick={grantAccessToUser} sx={{ ml: 1, mt: 4 }}
                disabled={email === getUser()["email"] || !validateEmail(email) || loading}>Grant Access</Button>
        </Grid>
    </Grid>)
}
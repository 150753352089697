import { Box, Typography } from "@mui/material";
import { ensureEndsWithS } from "utils/Utils";

export default function PriceUnits(props) {
    const units = props.metric.price_units;
    const count = props.count;
    const variant = props.variant;

    const fontSize = variant === "xs" ? 10 : variant === "normal" ? 9 : 12;
    const lineHeight = variant === "xs" ? "12px" : variant === "normal" ? "10px" : "14px"
    const fontWeight = variant === "normal" ? "500" : "bold"

    const getUnitName = (unit) => {
        if (count == 1) return unit.name;
        return unit.plural ?? ensureEndsWithS(unit.name);
    }

    return (<Box sx={{ display: "inline-block", textAlign: "left", ml: 1 }}>
        {units.map((unit, index) => {
            return <Typography key={index} sx={{
                fontSize: { fontSize }, p: 0,
                lineHeight: { lineHeight },
                fontWeight: { fontWeight },
                textTransform: "capitalize"
            }}>
                {count == 1 ? "" : count} {getUnitName(unit)}
            </Typography>
        })}
    </Box>)
}
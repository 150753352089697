import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { Box, CircularProgress, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import { Container } from '@mui/system';
import * as React from "react";
import { Autosave } from "react-autosave";

export default function CardContentContainer(props) {
  const getStateIcon = () => {
    return !isDataPresent() ? null : props.formState === 'loading' ? <CircularProgress size="1rem" />
      : props.formState === 'success' ? <CloudDoneIcon sx={{ color: "primary.main" }} />
        : props.formState === 'yet_to_save' ? <SyncProblemIcon sx={{ color: "primary.warning" }} />
          : <ErrorIcon sx={{ color: "primary.error" }} />
  }

  const isDataPresent = () => {
    if (!props.data) return false;
    if (Array.isArray(props.data) && props.data.length === 0) return false;
    if (typeof props.data === 'object' && Object.keys(props.data).length === 0) return false;
    return true;
  }

  return (
    <Card sx={{ width: "100%" }}>
      {isDataPresent() && props.save &&
        <Autosave key={props.data} data={props.data} onSave={() => props.save()} />}
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', pt: 2, pb: 2, position: 'relative' }}>
        <Avatar sx={{ bgcolor: "primary.main" }} aria-label="recipe">
          {props.cardIcon}
        </Avatar>
        <Typography sx={{ ml: 2, fontSize: 20 }}>{props.title}</Typography>
        <Box sx={{ position: 'absolute', right: 3 }}>
          {getStateIcon()}
        </Box>
      </Container>
      {/* <CardHeader
        // avatar={

        // }
        titleTypographyProps={{ variant: "h5" }}
        title={props.title}
        action={getStateIcon()}
      >

       
      </CardHeader> */}
      <CardContent sx={{ textAlign: "left", borderTop: 1, borderColor: grey[200] }}>{props.content}</CardContent>
    </Card>
  );
}

import React from "react";

import { Box, Button } from "@mui/material";

export default function SelectElement(props) {
  return (
    <Box style={{ width: "60%", margin: 10 }} className="select-box">
      <Button
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 150,
          width: 220,
          maxHeight: 220,
          color: "primary.sub",
          cursor: "pointer",
          boxShadow: 2,
          backgroundColor: "primary.background",
          "&:hover": {
            opacity: [0.9, 0.9, 0.9],
          },
        }}
      >
        {props.text}
      </Button>
    </Box>
  );
}

import CategoryIcon from '@mui/icons-material/Category';
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { getProductFeatures, saveData } from '../../apis/ClientDataService';
import CardContentContainer from "../../components/CardContentContainer";
import CustField from "../../components/CustField";
import FeatureBox from '../../components/FeatureBox';

export default function ProductFeatures() {
    const [features, setFeatures] = React.useState(null);
    const [formState, setFormState] = React.useState(null);
    const [newFeatureName, setNewFeatureName] = React.useState("");

    React.useEffect(() => {
        setFormState('loading');
        getProductFeatures((data) => {
            setFeatures(data);
            setFormState('success');
        }, (err) => {
            setFormState('error');
        });
    }, []);

    const handleAddNewFeature = () => {
        if (newFeatureName && !features.includes(newFeatureName)) {
            setFeatures([...features, newFeatureName]);
        }
    }

    const deleteFeature = (feature) => {
        setFeatures(features.slice().filter(x => x !== feature));
    }

    React.useEffect(() => {
        save();
    }, [features]);

    const save = () => {
        if (features) {
            setFormState('loading');
            saveData("product_features", features, () => { setFormState('success'); }, () => { setFormState('error') });
        }
    }

    return (
        <CardContentContainer
            cardIcon={<CategoryIcon />}
            title="Product features"
            data={features}
            formState={formState}
            save={() => save()}
            content={<Grid container item xs={12} justifyContent="center" alignItems="center" direction="column">
                <Box sx={{ p: 2, width: 400 }}>
                    {features && features.length > 0 &&
                        features.map((feature, index) => {
                            return (<FeatureBox
                                feature={feature}
                                index={index}
                                hide
                                deletefeature={deleteFeature}>
                            </FeatureBox>)
                        })
                    }
                    {features && features.length === 0 && <Typography sx={{ fontSize: 12 }}>Please add your product features...</Typography>}
                </Box>
                <Box sx={{ width: 400 }}>
                    <CustField sx={{ width: 250, pr: 2 }} label="Feature name" value={newFeatureName} onChange={(e) => setNewFeatureName(e.target.value)} />
                    <Button sx={{ mt: 2 }} variant="outlined" onClick={handleAddNewFeature}>Add</Button>
                </Box>
            </Grid>
            }></CardContentContainer>
    )
}
import { createContext } from 'react';
import React from 'react';

const ErrorDialogContext = createContext();

export function useErrorContext() {
    return React.useContext(ErrorDialogContext);
}

export function ErrorProvider({ children }) {
    const [error, setError] = React.useState(null);

    const loadErrorDialog = (error) => {
        setError({
            title: error?.title ?? "Error",
            message: error?.message ?? "Unknown error, please contact priceops!",
            reload: error?.reload ?? false
        });
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <ErrorDialogContext.Provider value={{ error, loadErrorDialog, clearError }}>
            {children}
        </ErrorDialogContext.Provider>
    );
}

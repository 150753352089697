import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import PaymentIcon from '@mui/icons-material/Payment';
import SchemaIcon from "@mui/icons-material/Schema";
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaidIcon from '@mui/icons-material/Paid';

import AddCardIcon from '@mui/icons-material/AddCard';
import {
  Dialog, Divider,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from 'react';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PaymentIntegrationFlow from "../pages/packaging/PaymentIntegrationFlow";
import OrganizationContext from "../state/OrganizationContext";
import { getUser } from "../state/state";
import PeopleIcon from '@mui/icons-material/People';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SegmentIcon from '@mui/icons-material/Segment';
import { getAuthorizationDetails } from 'apis/ClientDataService';

const pages = [
  // { name: "Scenarios", link: "/scenarios" },
  { name: "Analytics", link: "/analytics" },
  { name: "Market Segments", link: "/marketsegments" },
  { name: "Pricing", link: "/pricing" },
]

export default function HeaderMenuBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { globalOrganization, limitationsRef } = React.useContext(OrganizationContext);

  const [authDetails, setAuthDetails] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openSettings = () => {
    navigate('/settings');
    setAnchorElUser(null);
  }

  const logout = () => {
    setAnchorElUser(null);
    props.onLogout();
  }

  const openPayments = () => {
    setAnchorElUser(null);
    navigate('/payments');
  }

  const openAppSettings = () => {
    setAnchorElUser(null);
    navigate('/app-settings');
  }

  const openPriceopsUsers = () => {
    setAnchorElUser(null);
    navigate('/users');
  }

  const openClientPlans = () => {
    setAnchorElUser(null);
    navigate('/client-plans');
  }

  const openCustomerPlans = () => {
    setAnchorElUser(null);
    navigate('/customer-plans');
  }

  const openPriceOpsPlans = () => {
    setAnchorElUser(null);
    navigate('/priceops-plans');
  }

  const openDataIntegrations = () => {
    setAnchorElUser(null);
    navigate('/data');
  }

  const [openPaymentIntegrationDialog, setOpenPaymentIntegrationDialog] = useState(false)
  const openPaymentIntegration = () => {
    setOpenPaymentIntegrationDialog(true);
  }

  const openFinancialData = () => {
    setAnchorElUser(null);
    navigate('/finance_metrics');
  }

  const openPriceModels = () => {
    setAnchorElUser(null);
    navigate('/pricemodels');
  }

  const navigate = useNavigate();
  const onSelectPage = (pageUrl) => {
    navigate(pageUrl);
  }

  const user = getUser();

  React.useEffect(() => {
    const user = getUser();
    if (user) {
      getAuthorizationDetails(user.id, (details) => {
        setAuthDetails(details);
        setIsAdmin(details.find(auth => auth?.role?.permissions?.find(x => x?.permission == "super_user")));
      }, err => { console.log(err) });
    }
  }, []);


  const goToOrganization = () => {
    navigate('/organization-details');
    setAnchorElUser(null);
  }

  function stringAvatar(name) {
    const words = name.split(' ');
    let initials;
    if (words.length >= 2) {
      initials = words[0][0] + words[words.length - 1][0];
    } else {
      initials = words[0][0];
    }
    return {
      sx: {
        bgcolor: "primary.main",
        fontSize: 12,
        width: 32,
        height: 32
      },
      children: initials,
    };
  }

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: "primary.sub", borderBottom: '1px solid lightgray' }}>
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <img
            src={require("./just_logo.png")} width={50} height={26} alt="logo" />
          <Typography
            noWrap
            component="a"
            href="/"
            sx={{
              fontSize: 30,
              fontWeight: 400,
              display: { xs: "none", md: "flex" },
              color: "primary.textOnSub",
              fontFamily: "Open Sans",
              textDecoration: "none",
              cursor: "pointer"
            }}
          >
            PriceOps
          </Typography>
          {/*<Typography fontSize={8} mb={2} fontWeight={800}>BETA</Typography>*/}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            PriceOps
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: 'flex-end', mr: 10 }}>
            {pages.map((page) => (
              <MenuItem
                key={page.name}
                component={Link}
                to={page.link}
                onClick={() => onSelectPage(page.link)}
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  pr: 2, pl: 2,
                  fontSize: 14,
                  color: "primary.textOnSub",
                  '&:hover': {
                    color: 'primary.background'
                  }
                }}
              >
                {page.name}
              </MenuItem>
            ))}
            <MenuItem
              component={Link}
              to="https://priceops.net"
              key="blog"
              onClick={(e) => {
                e.preventDefault(); e.stopPropagation(); window.open("https://priceops.net", '_blank')
              }}
              sx={{
                textTransform: "none",
                fontWeight: 600,
                fontSize: 14,
                color: "primary.textOnSub",
                '&:hover': {
                  color: 'primary.background'
                }
              }}
            >
              Blog
            </MenuItem>
          </Box>

          {user && <Box sx={{ flexGrow: 0 }}>
            {/* <Typography sx={{ color: "primary.textOnMain", display: "inline-block", mr: 1 }}>{user["name"]}</Typography> */}
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, display: "inline-block" }}>
              <Avatar {...stringAvatar(user["name"])} />
            </IconButton>
            <Menu
              sx={{ mt: "45px", }}

              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem key="Account" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Account</Typography>
              </MenuItem> */}
              <Typography sx={{ width:240, color: 'primary.greyText', fontSize: 11, fontWeight: 700, py: 0.5, pl: 2 }}>
                ACCOUNT
              </Typography>
              {user && <MenuItem key="Username">
                <ListItemText primary={
                  <React.Fragment>

                    <Grid container spacing={2} sx={{ mb: 0 }}>
                      <Grid item>
                        <Avatar {...stringAvatar(user['name'])} />
                      </Grid>
                      <Grid item>
                        <Typography sx={{ fontSize: 14 }}>{user['name']}</Typography>
                        <Typography sx={{ fontSize: 9, color: 'gray' }}>{user['email']}</Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                } />
              </MenuItem>}
              <Divider />

              {globalOrganization &&
                <>
                  <Typography sx={{ color: 'primary.greyText', fontSize: 11, fontWeight: 700, py: 0.5, pl: 2 }}>
                    ORGANIZATION
                  </Typography>
                  <MenuItem key="Company" onClick={goToOrganization} component={Link} to={'/organization-details'}>
                    <ListItemText primary={
                      <React.Fragment>
                        <Grid container alignItems={'center'}>
                          <ListItemIcon>
                            <GroupsIcon />
                          </ListItemIcon>
                          <Typography fontSize={14}>{globalOrganization.name}</Typography>
                        </Grid>
                      </React.Fragment>}
                    />
                  </MenuItem>

                  <MenuItem key="Customer Plans" onClick={openCustomerPlans} component={Link} to={'/customer-plans'}>
                    <ListItemText primary={
                      <React.Fragment>
                        <Grid container alignItems={'center'}>
                          <ListItemIcon>
                            <CreditScoreIcon />
                          </ListItemIcon>
                          <Typography fontSize={14}>Customer Plans</Typography>
                        </Grid>
                      </React.Fragment>}
                    />
                  </MenuItem>

                  {/* <MenuItem key="Customer Portal" sx={{ py: 1.2 }} component={Link} to={'/customer-portal'} >
                     <ListItemIcon>
                        <PaidIcon />
                     </ListItemIcon>
                     <ListItemText primary="Customer Portal" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
                  </MenuItem> */}

                  <Divider />
                </>
              }

              <Typography sx={{ color: 'primary.greyText', fontSize: 11, fontWeight: 700, py: 0.5, pl: 2 }}>
                SETTINGS
              </Typography>

              <MenuItem key="Manage Billing" sx={{ py: 1.2 }} component={Link}
                        to="https://billing.stripe.com/p/login/cN24hkbZiefPbAc9AA" target={'_blank'}>
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Billing" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem> 

              {/* <MenuItem key="Setup" onClick={openSettings}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Setup" />
              </MenuItem> */}

              <MenuItem key="Price models" onClick={openPriceModels} sx={{ py: 1.2 }} component={Link} to="/pricemodels">
                <ListItemIcon>
                  <SegmentIcon />
                </ListItemIcon>
                <ListItemText primary="Price Models (Metrics)" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem>
              {globalOrganization &&
                  limitationsRef.current.stripe &&
                <MenuItem
                  sx={{ py: 1.2, pr: 7 }}
                  key="Stripe integration" onClick={(e) => {
                    openPaymentIntegration();
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                  <ListItemIcon>
                    <AddCardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontSize: 14 } }}
                    primary=
                    {`${globalOrganization.payment_integration?.stripe_properties?.activated === true ?
                      'Manage' : 'Add'} Stripe Integration`}
                  />
                </MenuItem>
              }
              <MenuItem key="Data integrations" onClick={openDataIntegrations} sx={{ py: 1.2 }} component={Link} to="/data">
                <ListItemIcon>
                  <SchemaIcon />
                </ListItemIcon>
                <ListItemText primary="Data Integrations" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem>
              {/*<MenuItem key="Financial data" onClick={openFinancialData} sx={{ py: 1.2 }}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <SchemaIcon />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="Financial Data" primaryTypographyProps={{ sx: { fontSize: 14 } }} />*/}
              {/*</MenuItem>*/}
              {isAdmin && <Divider />}
              {isAdmin && <Typography sx={{ color: 'primary.greyText', fontSize: 11, fontWeight: 700, py: 0.5, pl: 2 }}>
                ADMIN
              </Typography>}
              {isAdmin && <MenuItem key="App settings (Super user)" onClick={openAppSettings} sx={{ py: 1.2 }} component={Link} to="/app-settings">
                <ListItemIcon>
                  <SettingsApplicationsIcon />
                </ListItemIcon>
                <ListItemText primary="App Settings" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem>}
              {isAdmin && <MenuItem key="PriceOps Users (Super user)" onClick={openPriceopsUsers} sx={{ py: 1.2 }} component={Link} to="/users">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="PriceOps Users" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem>}
              {isAdmin && <MenuItem key="All Client Plans" onClick={openClientPlans} sx={{ py: 1.2 }} component={Link} to="/client-plans">
                <ListItemIcon>
                  <CreditScoreIcon />
                </ListItemIcon>
                <ListItemText primary="All Client Plans" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem>}
              <Divider />
              <MenuItem key="Logout" onClick={logout} sx={{ py: 1.2 }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" primaryTypographyProps={{ sx: { fontSize: 14 } }} />
              </MenuItem>
            </Menu>

            {/*ADD PAYMENT INTEGRATION DIALOG*/}
            <Dialog
              open={openPaymentIntegrationDialog}
              onClose={(e) => {
                setOpenPaymentIntegrationDialog(false);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <PaymentIntegrationFlow
                handlePaymentIntegrationDialog={() => setOpenPaymentIntegrationDialog(false)}
              />
            </Dialog>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar >
  );
}

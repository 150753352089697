import "./PricingResultTabs.css";

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import PricingResultDataContext from "pages/scenarios/price-modelling/PricingResultDataContext";
import MarketBackTab from './MarketBackTab';
import StrategyTab from "./StrategyTab";
import CompetitorTab from "./CompetitorTab";
import ScenarioUIStateContext from "pages/scenarios/ScenarioUIStateContext";


export default function PricingResultTabs(props) {
    const { pricingResult, } = React.useContext(PricingResultDataContext);
    const { scenarioUIState, updateScenarioUIState } = React.useContext(ScenarioUIStateContext);

    const handleChange = (event, newValue) => {
        const state = { ...scenarioUIState };
        state.tab = newValue;
        updateScenarioUIState(state);
    };

    return (
        <Box sx={{ width: '100%', }}>
            {pricingResult &&
                <TabContext value={scenarioUIState.tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'white' }}>
                        <TabList onChange={handleChange}>
                            <Tab sx={{
                                fontWeight: 600,
                                fontSize: 14
                            }}
                                label="Market Back" value="market_back"
                            />
                            <Tab sx={{
                                fontWeight: 600,
                                fontSize: 14
                            }}
                                label="Cost Plus" value="cost_plus"
                            />
                            <Tab sx={{
                                fontWeight: 600,
                                fontSize: 14
                            }}
                                label="Value Based" value="value_based"
                            />
                            <Tab sx={{
                                fontWeight: 600,
                                fontSize: 14
                            }}
                                label="Competitor Based" value="competitor_based"
                            />
                            {/* <Tab sx={{
                                fontWeight: 600,
                                fontSize: 14
                            }}
                                label="Finalized Price" value="finalized_price"
                            /> */}
                        </TabList>
                    </Box>
                    <TabPanel value="market_back">
                        <MarketBackTab></MarketBackTab>
                    </TabPanel>
                    <TabPanel value="cost_plus">
                        <StrategyTab strategy="COST_PLUS_STRATEGY"></StrategyTab>
                    </TabPanel>
                    <TabPanel value="value_based">
                        <StrategyTab strategy="VALUE_BASED_STRATEGY"></StrategyTab>
                    </TabPanel>
                    <TabPanel value="competitor_based">
                        <CompetitorTab></CompetitorTab>
                    </TabPanel>
                    {/* <TabPanel value="finalized_price">
                        <StrategyTab strategy="FINALIZE_PRICE"></StrategyTab>
                    </TabPanel> */}
                </TabContext>}
        </Box>
    );
}
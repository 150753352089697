import { Box, Grid, Typography } from "@mui/material";
import { currencyFractionFormatter } from "utils/Utils";
import PriceUnits from "./PriceUnits";

export default function PriceLabel(props) {
    const pricePoint = props.pricePoint;

    const variant = props.variant;
    const fontSize = variant === "xs" ? 20 : variant === "normal" ? 12 : 26;
    const fontWeight = variant === "normal" ? "bold" : "bold";

    const styles = {
        centered: {
            width: "100%",
            textAlign: "center"
        }
    }

    return <Box textAlign="center">
        {pricePoint["price"]?.["prices_by_metrics"]?.map((priceByMetric, idx) => {
            const metric = priceByMetric["price_metric"];
            const price = priceByMetric["price"];
            const formattedPrice = currencyFractionFormatter(price.value);

            return <Grid key={idx}>
                <Typography sx={[styles.centered, { fontSize: { fontSize }, fontWeight: { fontWeight }, pb: 4, pt: 4, display: "inline", color: "primary.sub" }]}>
                    {formattedPrice[0]} /
                </Typography>
                <PriceUnits metric={metric} count={formattedPrice[1]} variant={variant} />
            </Grid>
        }
        )
        }
    </Box>
}
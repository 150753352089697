import CloudIcon from '@mui/icons-material/Cloud';
import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {getOrganization, updateOrganizationApiKey} from "../../apis/ClientDataService";
import GroupsIcon from '@mui/icons-material/Groups';
import CardContentContainer from "../../components/CardContentContainer";
import OrganizationContext from "../../state/OrganizationContext";

export default function OrganizationDetails() {
    const { globalOrganization, updateOrganization , limitationsRef } = React.useContext(OrganizationContext);
    const  organizationId  = globalOrganization.id;
    const [organization, setOrganization] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [snackNotif, setSnackNotif] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("");
    const [currPriceOpsPlan, setCurrPriceOpsPlan] = React.useState("No Active Plan");
    const [billingPeriod, setBillingPeriod] = React.useState("");
    const [planStartDate, setPlanStartDate] = React.useState("");

    React.useEffect(() => {
        getOrganization(organizationId, (org) => {
            setOrganization(org);
            // updateOrganization(org);

            if(limitationsRef.current.launch_plan){
                setCurrPriceOpsPlan("Launch")
                setBillingPeriod(limitationsRef.current.billing_period)
                setPlanStartDate(limitationsRef.current.created_date)
            }

            if(limitationsRef.current.starter_plan){
                if(limitationsRef.current.stripe_integration_plan){
                    setCurrPriceOpsPlan("Starter + Stripe Integration")
                }else{
                    setCurrPriceOpsPlan("Starter")
                }
                setBillingPeriod(limitationsRef.current.billing_period)
                setPlanStartDate(limitationsRef.current.created_date)
            }


            setLoading(false);
            // console.log(org)
        }, err => {
            setLoading(false)
            console.log(err)
        });

    }, []);

    const handleUpdateOrgApiKey = () =>{
        setLoading(true)
        updateOrganizationApiKey(organization.id,(org)=>{
            setTimeout(() => {
                setOrganization(org)
                setLoading(false)
                setSnackMessage("New API Secret Key has been generated")
                setSnackNotif(true)
            }, 2000);
            // console.log(org)
        }, err=>{
            setLoading(false)
            console.log(err)
        })
    }

    return (

        <Grid container minHeight={'90vh'} bgcolor={'primary.background'} px={3} py={2}
            justifyContent={'flex-start'}
            direction={'column'}
        >
            {loading ?
                <Grid container height={'90vh'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress/>
                </Grid>
                    :
                <>
                    <Grid container item width={'100%'} p={1}>
                        <Typography fontSize={30} >
                            {organization.name} Organization
                        </Typography>
                    </Grid>
                    <Grid container height={'auto'} width={'100%'} bgcolor={'white'} borderRadius={2}>
                        <Grid container width={'50%'} p={3} direction={'column'} gap={1}>
                            <Typography fontSize={16} fontWeight={600} sx={{pb:1}}>
                                Organization Info
                            </Typography>
                            <Grid container border={'1px solid lightgray'} borderRadius={1}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>ID</TableCell>
                                            <TableCell>{organization.id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>Name</TableCell>
                                            <TableCell>{organization.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>Stripe Connect ID</TableCell>
                                            <TableCell>{organization.payment_integration?.stripe_properties?.activated ?
                                                organization.payment_integration?.stripe_properties?.connect_id
                                                : 'N/A'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>Created by</TableCell>
                                            <TableCell>{organization.created_by_user}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>Created on</TableCell>
                                            <TableCell>{new Date(organization.created_date).toLocaleString('en-US',
                                                {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>Last Updated on</TableCell>
                                            <TableCell>{new Date(organization.modified_date).toLocaleString('en-US',
                                                {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid container width={'50%'} p={3} direction={'column'} gap={1}>
                            <Typography fontSize={16} fontWeight={600} sx={{pb:1}}>
                                Active PriceOps Plan
                            </Typography>

                            <Grid container width={'100%'}
                                  border={'2px solid black'}
                                  borderRadius={1}
                                  height={55}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                  bgcolor={billingPeriod.length>0 ? 'palegreen' : 'white'}
                            >
                                <Typography fontWeight={800}>
                                    {currPriceOpsPlan}
                                </Typography>
                            </Grid>

                            {billingPeriod.length > 0 &&
                                <Grid container width={'100%'} justifyContent={'flex-end'} pr={2} my={-0.5}>
                                    <Typography fontSize={10} fontWeight={800}>
                                        Billed {billingPeriod} starting {new Date(planStartDate).toLocaleString('en-US',
                                        {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                        })}
                                    </Typography>
                                </Grid>
                            }

                            <Typography fontSize={16} fontWeight={600} sx={{py:1}}>
                                API Integration
                            </Typography>
                            <Grid container border={'1px solid lightgray'} borderRadius={1}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3, pr: 10 }}>API Secret Key</TableCell>
                                            <TableCell>{organization.api_key || 'N/A'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid container justifyContent={'space-between'} py={1} gap={2}>
                                <Button variant={'outlined'} sx={{height: 40, width:'48%'}}
                                    onClick={handleUpdateOrgApiKey}
                                >
                                    Generate New API Key
                                </Button>

                                <Button variant={'outlined'} sx={{height: 40, width:'48%'}}
                                    onClick={(e)=>{
                                        navigator.clipboard.writeText(organization.api_key || '');
                                        setSnackMessage("API Secret copied to clipboard")
                                        setSnackNotif(true)
                                    }}
                                >
                                    Copy API Key to clipboard
                                </Button>
                            </Grid>

                            <Alert severity={'info'}>
                                PriceOps allows you to communicate with us through APIs.
                                For more information regarding setting up APIs please refer to our API docs.
                            </Alert>
                        </Grid>
                    </Grid>

                    <Snackbar open={snackNotif} autoHideDuration={6000}
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              onClose={() => setSnackNotif(false)}>
                        <Alert variant="filled" severity="success" onClose={() => setSnackNotif(false)}>
                            {snackMessage}
                        </Alert>
                    </Snackbar>
                </>
            }
        </Grid>
    )
}
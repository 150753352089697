import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { resetClientData } from "../../apis/ClientDataService";
import FileImportComponent from "./FileImportComponent";

export default function DataIntegrations(props) {
    const [key, setKey] = React.useState(1);
    const [customerCsv] = React.useState(props.disablecustomer ? false : true);
    const [financeCsv] = React.useState(props.disablefinances ? false : true);
    const noDataMessage = props.nodatamessage;
    const styles = {
        importBox: {
            p: 2,
            width: '100%'
        }
    }

    const resetClient = () => {
        resetClientData((resp) => {
            setKey(key + 1);
            alert("Reset client data successful");
        }, (err) => {
            setKey(key + 1);
            alert("Reset client failed");
        })
    }

    const notifyDataLoad = (datatype) => {
        if (props.notify) {
            props.notify(datatype);
        }
    }

    return (<React.Fragment>

        <Grid xs={12} item container alignItems="center" justifyContent="center">
            <Grid container item sx={{ maxWidth: 1000, p: 4, mt: 2, boxShadow: 2, borderRadius: 2 }}>
                {noDataMessage && <Typography fontSize={14}>{noDataMessage}</Typography>}
                {customerCsv && <Box sx={styles.importBox}>
                    <FileImportComponent
                        key={key}
                        datatype="customer"
                        datatitle="User subscription data"
                        notify={() => notifyDataLoad("customer")}
                    ></FileImportComponent>
                    <Typography sx={{ fontSize: 12, p: 2, pt: 1 }}>User subscription data is a collection of information related to customers who have subscribed to a particular service or product. This data can be used to gain insights into customer behavior, preferences, and demographics, which can then be used to tailor marketing strategies and pricing models to increase revenue.</Typography>
                </Box>}
                {financeCsv && <Box sx={styles.importBox}>
                    <FileImportComponent key={key}
                        datatype="expense"
                        datatitle="Financial data"
                        notify={() => notifyDataLoad("expense")}
                    ></FileImportComponent>
                    <Typography sx={{ fontSize: 12, p: 2, pt: 1 }}>Financial data is crucial for gaining analytics to understand its performance, growth, and overall financial health. In the software as a service (SaaS) industry, several key metrics are used to gain insights, including ARR, MRR, Churn rate, CAC, LTV, etc</Typography>
                </Box>}
                <Button onClick={resetClient} sx={{ height: 30, float: "right", textTransform: "none" }}>Reset client</Button>
            </Grid>
        </Grid>
        {/* } /> */}
    </React.Fragment >)
}
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CurrencyField from "components/CurrencyField";
import { copy, textNum } from "utils/Utils";

export const DEFAULT_EXPENSES = [
    { "value": 0.0, "name": "Marketing expense", "cost_type": "MARKETING" },
    { "value": 0.0, "name": "Sales", "cost_type": "SALES" },
    { "value": 0.0, "name": "Fixed costs", "cost_type": "FIXED" },
    { "value": 0.0, "name": "Variable costs", "cost_type": "VARIABLE" },
    { "value": 0.0, "name": "Personnel expenses", "cost_type": "PERSONNEL" }
]

export const getExpenseLabel = (expense) => {
    return expense["title"] ? expense["title"] : expense["name"].replace("_", " ")
        .replace(/^\w/, c => c.toUpperCase());;
}

const styles = {
    textField: {
        mt: 0,
        fontSize: 12,
        height: 23,
        padding: "3px",
        paddingLeft: "5px",
        paddingTop: "0px"
    },
    label: {
        fontSize: 12,
        mr: "15px"
    }
}

const inputProps = {
    style: styles.textField
}

export const ExpenseField = (props) => {
    const [expense] = React.useState(props?.expense);
    return <React.Fragment>
        <CurrencyField
            value={expense.value}
            size="small"
            inputProps={inputProps}
            onChange={(e) => { props?.onChange(expense, e.target.value) }} />
    </React.Fragment>
}

export default function Expenses(props) {
    const [expenses, setExpenses] = React.useState(props.expenses ? props.expenses : DEFAULT_EXPENSES);

    const setExpense = (name, value) => {
        const exp = copy(expenses);
        exp.find(x => x["name"] === name)["value"] = textNum(value);
        setExpenses(exp);
        console.log("exp", exp);
        props.updateExpenses(exp);
    }

    return (
        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", width: "100%", p: 1 }}>
            <Typography sx={{ fontSize: 12, color: "primary.main", mr: 3 }}>Expenses</Typography>
            {expenses.map((expense, idx) => {
                return <Box key={idx} sx={{ mr: 4 }}>
                    <Box>
                        <Typography sx={[styles.label, { mt: 0 }]}>{getExpenseLabel(expense)}</Typography>
                        <Box sx={{ width: "150px" }}>
                            <ExpenseField expense={expense} onChange={(e, value) => setExpense(e["name"], value)}></ExpenseField>
                        </Box>
                    </Box>
                </Box>
            })}
        </Grid >
    )
}
import { Grid } from "@mui/material";
import React from "react";
import CurrencyField from "components/CurrencyField";
import { currencyFormatter, textNum } from "utils/Utils";
import ScenarioDataContext from "pages/scenarios/ScenarioDataContext";
import EditableInputsForm from "./EditableInputsForm";
import InputLabelGroup from "./InputLabelGroup";

export default function CompetitorStrategyInput(props) {
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const segmentId = props.segmentId;

    const getCompetitorPriceFromScenario = () => {
        const segment = scenario["params"]["segment_params"]
            .find(x => String(x["id"]) === String(segmentId))
        const inputs = segment["strategy_inputs"]
        return inputs["competitor_price"] ? inputs["competitor_price"]["value"] :
            inputs["benchmark_price"] ? inputs["benchmark_price"]["value"] : 0;
    }

    const [competitorPrice, setCompetitorPrice] = React.useState(getCompetitorPriceFromScenario());

    const applyCompetitorPrice = () => {
        const sc = { ...scenario };
        const segment = sc["params"]["segment_params"].find(x => String(x["id"]) === String(segmentId));
        segment["strategy_inputs"]["competitor_price"] = {
            "value": textNum(competitorPrice)
        }
        sc["fragment"] = "COMPETITOR";
        updateScenario(sc, true, () => {
            if (props.onChange) {
                props.onChange();
            }
        }, () => { });
    }
    const refresh = () => {
        setCompetitorPrice(getCompetitorPriceFromScenario());
    }

    return (
        <EditableInputsForm
            miniView={
                <InputLabelGroup labels={
                    [
                        { name: "Competitor Price", value: currencyFormatter(competitorPrice) }
                    ]
                } />
            }
            editView={
                <React.Fragment>
                    <Grid container item sx={{ width: 200 }}>
                        <CurrencyField
                            size="small"
                            value={competitorPrice}
                            label="Competitor price"
                            onChange={(e) => setCompetitorPrice(e.target.value)} />

                    </Grid>
                </React.Fragment>
            }
            onSubmit={applyCompetitorPrice}
            onCancel={refresh}>
        </EditableInputsForm>
    )
}
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Alert,
    Autocomplete,
    Backdrop,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    Drawer,
    FormControl, FormControlLabel, Grid,
    IconButton, InputAdornment,
    MenuItem,
    Paper, Slider,
    Snackbar, Switch, TextField,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { MuiColorInput } from 'mui-color-input';
import React, { useContext, useEffect, useState } from "react";
import { useErrorContext } from "state/ErrorDialogContext";
import { v4 as uuidv4 } from "uuid";
// import { addEntitlement, updateEntitlement } from "../../apis/ClientDataService";
import {getCurrentOrgId, getOrgFromLocalStore} from "../../state/state";
import ScenarioDataContext from "../scenarios/ScenarioDataContext";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import DoneIcon from "@mui/icons-material/Done";
import ProductActions from "../packaging/ProductActions";
import {styled} from "@mui/material/styles";

const fonts = [
    'Open Sans',
    'Roboto',
    'IBM Plex Sans',
    'Inter',
    'Lexend Deca',
    'system-ui',
    'Montserrat',
    'Chakra Petch',
    'Helvetica',
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Gill Sans',
    'Times New Roman',
    'Georgia',
    'Palatino',
    'Baskerville',
    'Andalé Mono',
    'Courier',
    'Lucida',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS'
];


const shapes = ['Rounded', 'Rectangular', 'Capsule'];

const highlightTexts = ['None', 'Recommended', 'Best Deal', 'Most Popular'];

export default function EntitlementActions(props) {

    // console.log(props)

    const  products = [];

    const { loadErrorDialog } = useErrorContext();

    const [name, setName] = useState(props.mode === "Edit" ? props.entitlement.name : '');
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const [addOnSection, setAddOnSection] = useState(props.mode === "Edit" ? (props.entitlement.add_on_section ?? false) : false);
    const [headerSection, setHeaderSection] = useState(props.mode === "Edit" ? (props.entitlement.header_section ?? false) : false);
    const [headerText, setHeaderText] = useState(props.mode === "Edit" ? (props.entitlement.properties.header_text ?? '') : '');
    const [subheaderText, setSubheaderText] = useState(props.mode === "Edit" ? (props.entitlement.properties.subheader_text ?? '') : '');

    const [requiresLoggedInUsers, setRequiresLoggedInUsers] = useState(props.mode === "Edit" ? (props.entitlement.requires_logged_in_users ?? false) : false);
    const handleRequireLoggedInUsersToggleChange = (event) => {
        setRequiresLoggedInUsers(event.target.checked);
    };

    const [loginRedirectLink, setLoginRedirectLink] = useState(props.mode === "Edit" ? props.entitlement.login_redirect_link : '');
    const handleLoginRedirectLinkChange = (event) => {
        setLoginRedirectLink(event.target.value);
    };

    const [bgColor, setBgColor] = useState(props.mode === "Edit" ? props.entitlement.properties.bg_color : '#ffffff')
    const handleBgColorChange = (color) => {
        setBgColor(color)
    }

    const [buttonColor, setButtonColor] = useState(props.mode === "Edit" ? props.entitlement.properties.button_color : '#4f46e5')
    const handleButtonColorChange = (color) => {
        setButtonColor(color)
    }

    const [cardColor, setCardColor] = useState(props.mode === "Edit" ? props.entitlement.properties.card_color : '#ffffff')
    const handleCardColorChange = (color) => {
        setCardColor(color)
    }

    const [highlightedButtonColor, setHighlightedButtonColor] = useState(props.mode === "Edit" ? props.entitlement.properties.highlighted_button_color : '#4f46e5')
    const handleHighlightedButtonColorChange = (color) => {
        setHighlightedButtonColor(color)
    }

    const [highlightedCardColor, setHighlightedCardColor] = useState(props.mode === "Edit" ? props.entitlement.properties.highlighted_card_color : '#ffffff')
    const handleHighlightedCardColorChange = (color) => {
        setHighlightedCardColor(color)
    }

    const [ctaButtonColor, setCtaButtonColor] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_button_color : '#29303d')
    const handleCtaButtonColorChange = (color) => {
        setCtaButtonColor(color)
    }

    const [ctaCardColor, setCtaCardColor] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_card_color : '#111827')
    const handleCtaCardColorChange = (color) => {
        setCtaCardColor(color)
    }

    const [cardBoxShadow, setCardBoxShadow] = useState(props.mode === "Edit" ? props.entitlement.properties.card_box_shadow : 0)
    const handleCardBoxShadowChange = (shadow) => {
        setCardBoxShadow(shadow)
    }

    const [buttonBorderRadius, setButtonBorderRadius] = useState(props.mode === "Edit" ? props.entitlement.properties.button_border_radius : 1)
    const handleButtonBorderRadiusChange = (radius) => {
        setButtonBorderRadius(radius)
    }

    const [cardBorderRadius, setCardBorderRadius] = useState(props.mode === "Edit" ? props.entitlement.properties.card_border_radius : 5)
    const handleCardBorderRadiusChange = (radius) => {
        setCardBorderRadius(radius)
    }

    const [cardBorderColor, setCardBorderColor] = useState(props.mode === "Edit" ? props.entitlement.properties.card_border_color : '#e5e7eb')
    const handleCardBorderColorChange = (color) => {
        setCardBorderColor(color)
    }

    const [ctaBorderColor, setCtaBorderColor] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_border_color : '#111827')
    const handleCtaBorderColorChange = (color) => {
        setCtaBorderColor(color)
    }

    const [highlightedPlanNameColor, setHighlightedPlanNameColor] = useState(props.mode === "Edit" ? props.entitlement.properties.highlighted_plan_name_color : '#4f46e5')
    const handleHighlightedPlanNameColorChange = (color) => {
        setHighlightedPlanNameColor(color)
    }

    const [cardWidth, setCardWidth] = useState(props.mode === "Edit" ? props.entitlement.properties.card_width : 350)
    const handleCardWidthChange = (width) => {
        setCardWidth(width)
    }

    const [ctaButtonLink, setCtaButtonLink] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_button_link : '')
    const handleCtaButtonLinkChange = (link) => {
        setCtaButtonLink(link)
    }

    const [tickColor, setTickColor] = useState(props.mode === "Edit" ? props.entitlement.properties.tick_color : '#4f46e5')
    const handleTickColorChange = (color) => {
        setTickColor(color)
    }

    const [highlightedTickColor, setHighlightedTickColor] = useState(props.mode === "Edit" ? props.entitlement.properties.highlighted_tick_color : '#4f46e5')
    const handleHighlightedTickColorChange = (color) => {
        setHighlightedTickColor(color)
    }

    const [ctaTickColor, setCtaTickColor] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_tick_color : '#ffffff')
    const handleCtaTickColorChange = (color) => {
        setCtaTickColor(color)
    }

    const [buttonVariant, setButtonVariant] = useState(props.mode === "Edit" ? props.entitlement.properties.button_variant : 'Contained')
    const handleButtonVariantChange = (event) => {
        setButtonVariant(event.target.value)
    }

    const [highlightedButtonVariant, setHighlightedButtonVariant] = useState(props.mode === "Edit" ? props.entitlement.properties.highlighted_button_variant : 'Contained')
    const handleHighlightedButtonVariantChange = (event) => {
        setHighlightedButtonVariant(event.target.value)
    }

    const [entitlementStyleMode, setEntitlementStyleMode] = useState(props.mode === "Edit" ? props.entitlement.properties.entitlement_style_mode : 'Custom')
    const handleEntitlementStyleModeChange = (mode) => {
        setEntitlementStyleMode(mode)
    }

    const [fontStyle, setFontStyle] = useState(props.mode === "Edit" ? props.entitlement.properties.font_style : fonts[3]);
    const handleFontStyleChange = (event) => {
        setFontStyle(event.target.value);
    };

    const [shape, setShape] = useState(props.mode === "Edit" ? props.entitlement.properties.shape : shapes[0]);
    const handleShapeChange = (event) => {
        setShape(event.target.value);
    };

    const [highlightedProduct, setHighlightedProduct] = React.useState(() => {
        if (props.mode === 'Edit') {
            const highlightedProductObj = props.entitlement.selected_products
                .find(product => product.id === props.entitlement.properties.highlighted_product_id);
            return highlightedProductObj ? highlightedProductObj.name : 'None';
        } else {
            return 'None';
        }
    });

    const [highlightedText, setHighlightedText] = React.useState(
        props.mode === "Edit" ? props.entitlement.properties.highlighted_text :
            'Most Popular'
    );

    const handleHighlightedProductChange = (event) => {
        setHighlightedProduct(event.target.value);
    };

    const handleHighlightedTextChange = (event) => {
        setHighlightedText(event.target.value);
    };

    const [buttonText, setButtonText] = React.useState('Subscribe');
    const handleButtonTextChange = (event) => {
        setButtonText(event.target.value);
    };

    const [currSelectedProduct, setCurrSelectedProduct] = React.useState("");

    const [selectedProducts, setSelectedProducts] = React.useState(
        props.mode === 'Edit' ? props.entitlement.selected_products : []
    );
    const [searchResults, setSearchResults] = React.useState(
        props.mode === 'Edit'
            ? products.filter(product => !props.entitlement.selected_products.some(selectedProduct => selectedProduct.id === product.id))
            : products
    );

    const handleAddProduct = (result) => {
        // console.log(result)
        let value = result
        if (typeof value !== 'string') {
            if (value) {
                setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, value]);
                setSearchResults((prevSearchResults) =>
                    prevSearchResults.filter((product) => product.name !== value.name)
                );
                setCurrSelectedProduct(value.name)
            }
        }
    };

    const handleDeleteProduct = (productToDelete) => () => {
        setSelectedProducts((prevSelectedProducts) =>
            prevSelectedProducts.filter((product) => product.name !== productToDelete.name)
        );
        if (productToDelete.name === highlightedProduct) {
            setHighlightedProduct("None")
        }
        setSearchResults((prevSearchResults) => [...prevSearchResults, productToDelete]);
    };

    const [ctaCheckValue, setCtaCheckValue] = React.useState(props.mode === "Edit" ? props.entitlement.properties.cta : false);
    const handleCtaCheckChange = (event) => {
        setCtaCheckValue(event.target.checked)
        setCtaDescription("")
        setCtaName("")
        setCtaButtonText("")
        setChipData([])
    };

    const [ctaName, setCtaName] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_name : '');

    const [ctaDescription, setCtaDescription] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_description : '');

    const [ctaButtonText, setCtaButtonText] = useState(props.mode === "Edit" ? props.entitlement.properties.cta_button_text : '');

    const [chipData, setChipData] = useState(
        props.mode === "Edit"
            ? props.entitlement.properties.cta_features
                .map((feature) => ({ key: uuidv4(), label: feature }))
            : []
    );

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const [inputValue, setInputValue] = useState('');
    const handleAdd = () => {
        if (inputValue.trim() !== '') {
            setChipData([...chipData, { key: uuidv4(), label: inputValue }]);
            setInputValue('');
        }
    };

    const [geoLocationCountriesAllowed, setGeoLocationCountriesAllowed] =
        useState(props.mode === "Edit" ?
            props.entitlement.properties.geo_location_countries_allowed : ["Global"]);


    const [loading, setLoading] = useState(false);

    const handleAddEntitlement = () => {
        // setLoading(true)
        // const properties = {
        //     "bg_color": bgColor,
        //     "button_color": buttonColor,
        //     "card_color": cardColor,
        //     "highlighted_button_color": highlightedButtonColor,
        //     "highlighted_card_color": highlightedCardColor,
        //     "cta_button_color": ctaButtonColor,
        //     "cta_card_color": ctaCardColor,
        //     "card_box_shadow": cardBoxShadow,
        //     "card_border_radius": cardBorderRadius,
        //     "button_border_radius": buttonBorderRadius,
        //     "card_border_color": cardBorderColor,
        //     "cta_border_color": ctaBorderColor,
        //     "highlighted_plan_name_color": highlightedPlanNameColor,
        //     "card_width": cardWidth,
        //     "cta_button_link": ctaButtonLink,
        //     "tick_color": tickColor,
        //     "highlighted_tick_color": highlightedTickColor,
        //     "cta_tick_color": ctaTickColor,
        //     "button_variant": buttonVariant,
        //     "highlighted_button_variant": highlightedButtonVariant,
        //     "entitlement_style_mode": entitlementStyleMode,
        //     "font_style": fontStyle,
        //     "shape": shape,
        //     "highlighted_product_id": selectedProducts
        //         .find((product) => product.name === highlightedProduct)?.id,
        //     "highlighted_text": highlightedText,
        //     "button_text": buttonText.trim(),
        //     "cta": ctaCheckValue,
        //     "cta_name": ctaName.trim(),
        //     "cta_description": ctaDescription.trim(),
        //     "cta_features": chipData.map((chip) => chip.label.trim()),
        //     "cta_button_text": ctaButtonText.trim(),
        //     "geo_location_countries_allowed": geoLocationCountriesAllowed,
        //     "header_text": headerText,
        //     "subheader_text": subheaderText
        // }
        // const newEntitlement = {
        //     "name": name.trim(),
        //     "client_id": getCurrentOrgId(),
        //     "scenario_id": scenario.id,
        //     "selected_products": selectedProducts,
        //     "properties": properties,
        //     "login_redirect_link": loginRedirectLink,
        //     "requires_logged_in_users": requiresLoggedInUsers,
        //     "add_on_section": addOnSection,
        //     "header_section": headerSection,
        // }

        // addEntitlement(JSON.parse(JSON.stringify(newEntitlement)), (data) => {
        //     props.updateEntitlements(data)
        //     props.handleSnackNotif("addEntitlement")
        //     props.closeActionsEntitlement()
        //     setLoading(false)
        // }, (err) => {
        //     setLoading(false)
        //     loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to add entitlement at the moment" });
        // })
    }

    const handleEditEntitlementSaveClick = () => {

        // setLoading(true);
        //
        // const properties = {
        //     "bg_color": bgColor,
        //     "button_color": buttonColor,
        //     "card_color": cardColor,
        //     "highlighted_button_color": highlightedButtonColor,
        //     "highlighted_card_color": highlightedCardColor,
        //     "cta_button_color": ctaButtonColor,
        //     "cta_card_color": ctaCardColor,
        //     "card_box_shadow": cardBoxShadow,
        //     "card_border_radius": cardBorderRadius,
        //     "button_border_radius": buttonBorderRadius,
        //     "card_border_color": cardBorderColor,
        //     "cta_border_color": ctaBorderColor,
        //     "highlighted_plan_name_color": highlightedPlanNameColor,
        //     "card_width": cardWidth,
        //     "cta_button_link": ctaButtonLink,
        //     "tick_color": tickColor,
        //     "highlighted_tick_color": highlightedTickColor,
        //     "cta_tick_color": ctaTickColor,
        //     "button_variant": buttonVariant,
        //     "highlighted_button_variant": highlightedButtonVariant,
        //     "entitlement_style_mode": entitlementStyleMode,
        //     "font_style": fontStyle,
        //     "shape": shape,
        //     "highlighted_product_id": selectedProducts
        //         .find((product) => product.name === highlightedProduct)?.id,
        //     "highlighted_text": highlightedText,
        //     "button_text": buttonText.trim(),
        //     "cta": ctaCheckValue,
        //     "cta_name": ctaName.trim(),
        //     "cta_description": ctaDescription.trim(),
        //     "cta_features": chipData.map((chip) => chip.label.trim()),
        //     "cta_button_text": ctaButtonText.trim(),
        //     "geo_location_countries_allowed": geoLocationCountriesAllowed,
        //     "header_text": headerText,
        //     "subheader_text": subheaderText
        // }
        // const newEntitlement = Object.assign({}, props.entitlement, {
        //     "name": name.trim(),
        //     "selected_products": selectedProducts,
        //     "properties": properties,
        //     "login_redirect_link": loginRedirectLink,
        //     "requires_logged_in_users": requiresLoggedInUsers,
        //     "add_on_section": addOnSection,
        //     "header_section": headerSection,
        // });
        //
        // // console.log(newEntitlement)
        // updateEntitlement(JSON.parse(JSON.stringify(newEntitlement)), (data) => {
        //     props.updateEntitlements(props.entitlements.map(entitlement => entitlement.id === data.id ? data : entitlement));
        //     props.handleSnackNotif("updateEntitlement")
        //     props.closeActionsEntitlement()
        //     setLoading(false);
        // }, (err) => {
        //     setLoading(false);
        //     loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to update entitlement at the moment" });
        // })
    }

    const validateForm = () => {
        return (
            name &&
            selectedProducts?.length &&
            buttonText?.trim() &&
            (
                !ctaCheckValue ||
                (
                    ctaName?.trim() &&
                    ctaDescription?.trim() &&
                    // chipData?.length &&
                    ctaButtonText?.trim() &&
                    ctaButtonLink?.trim()
                )
            ) &&
            (
                !requiresLoggedInUsers || loginRedirectLink?.length
            )

        );
    }

    const [activeProduct, setActiveProduct] = useState();
    const [snackNotif, setSnackNotif] = useState("");

    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }

    const [openAdd, setOpenAdd] = useState(false);
    const handleCreateProductClick = () => {
        setOpenAdd(!openAdd);
    };

    const [openEdit, setOpenEdit] = useState(false);
    const handleUpdateProductClick = () => {
        setOpenEdit(!openEdit);
    };

    const steps = ['General Settings', 'Style Settings'];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (snackNotif === "addProduct") {
            // Find the new product
            const newProduct = products.find(product =>
                !selectedProducts.some(selectedProduct => selectedProduct.id === product.id) &&
                !searchResults.some(searchResult => searchResult.id === product.id)
            );

            // Add the new product to selectedProducts
            if (newProduct) {
                setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, newProduct]);
            }
        }
        if (snackNotif === "updateProduct") {
            // Update selectedProducts
            setSelectedProducts(prevSelectedProducts => {
                return prevSelectedProducts
                    .map(selectedProduct => {
                        return products.find(product => product.id === selectedProduct.id) || null;
                    })
                    .filter(product => product !== null);
            });

            // Update searchResults
            setSearchResults(products.filter(product => !selectedProducts.some(selectedProduct => selectedProduct.id === product.id)));
        }
    }, [snackNotif]);


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <Grid container sx={{ width: '43vw', px:3, pt: 2 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container justifyContent='space-between' alignItems='center' pb={1}>
                <Grid container item width={'40%'} gap={0.5}>
                    <Typography sx={{
                        color: "primary.main",
                        fontSize: 24,
                        fontWeight: "bold",
                        pl: 1
                    }}>
                        {props.mode} Entitlement
                    </Typography>
                </Grid>

                <Grid item container width={'50%'} justifyContent={'flex-end'}>

                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{ mt: 0 }}
                        onClick={props.closeActionsEntitlement}
                    >
                        {props.mode === "Add" && 'Cancel'}
                        {props.mode === "View" && 'Close'}
                    </Button>

                    {props.mode === "Add" &&
                        <Button
                            disabled={!validateForm()}
                            variant="outlined"
                            size="medium"
                            // onClick={handleAddEntitlement}
                            sx={{ ml: 1, mt: 0 }}
                        >
                            Add Entitlement
                        </Button>
                    }
                </Grid>
            </Grid>
            <Grid container item sx={{ borderTop: '1px solid lightgray', p: 2 }}
                  justifyContent='space-between'>
                <Grid container direction='column'
                      wrap="nowrap"
                      gap={2}
                      sx={{
                          width: '100%',
                          maxHeight: '83vh',
                          overflowY: 'scroll',
                          pr: 2,
                          "&::-webkit-scrollbar": {
                              width: 7,
                              // bgcolor: ''
                          },
                          "&::-webkit-scrollbar-thumb": {
                              backgroundColor: 'lightgray',
                              outline: "1px solid lightgray",
                              borderRadius: 4
                          }
                      }}
                >
                    <Typography sx={{ fontWeight: 600, fontSize: 14, }}>
                        Entitlement Details
                    </Typography>
                    <TextField
                        size='small'
                        label="Entitlement Name"
                        variant="outlined"
                        // value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        // helperText="Some important text"
                    />

                    <TextField
                        size='small'
                        label="Product ID"
                        variant="outlined"
                        // value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        // helperText="Some important text"
                    />



                    {/*<TextField*/}
                    {/*    size='small'*/}
                    {/*    label="Discount Percentage"*/}
                    {/*    variant="outlined"*/}
                    {/*    value={props.mode === 'View' ? props.coupon.discount_percentage : discountPercentage}*/}
                    {/*    readOnly={props.mode === 'View'}*/}
                    {/*    onChange={handleDiscountPercentageChange}*/}
                    {/*    onFocus={() => setIsFocused({ ...isFocused, percentage : true })}*/}
                    {/*    onBlur={() => setIsFocused({ ...isFocused, percentage : false })}*/}
                    {/*    InputProps={{*/}
                    {/*        endAdornment: (isFocused["percentage"] || discountPercentage) ? (*/}
                    {/*            <InputAdornment position="end"> % </InputAdornment>*/}
                    {/*        ) : null*/}
                    {/*    }}*/}
                    {/*    sx={{mt:1}}*/}
                    {/*/>*/}

                    <TextField
                        select
                        size={'small'}
                        label="Entitlement Type"
                        // value={props.mode === 'View' ? props.coupon.duration : duration}
                        // readOnly={props.mode === 'View'}
                        // onChange={(e)=>setDuration(e.target.value)}
                    >
                        {['Feature','Configuration','Usage'].map((dur) => (
                            <MenuItem key={dur} value={dur}>
                                {dur}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        size='small'
                        label="Parent Resource Type"
                        variant="outlined"
                        // value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        // helperText="Some important text"
                    />

                    <TextField
                        size='small'
                        label="Parent Resource ID"
                        variant="outlined"
                        // value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        // helperText="Some important text"
                    />

                    <TextField
                        size='small'
                        label="Child Resource Type"
                        variant="outlined"
                        // value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        // helperText="Some important text"
                    />

                    <TextField
                        size='small'
                        label="Child Resource ID"
                        variant="outlined"
                        // value={props.mode === 'View' ? props.coupon.name : name}
                        readOnly={props.mode === 'View'}
                        onChange={(e) => setName(e.target.value)}
                        // helperText="Some important text"
                    />

                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Typography fontSize={16}>
                            Feature Gate:
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        // checked={freeTrialActive}
                                        // onChange={(e)=>setFreeTrialActive(!freeTrialActive)}
                                        sx={{
                                            ml:3,
                                            size:'small'
                                        }}
                                    />
                                }
                            />
                        </Typography>
                        <TextField
                            size='small'
                            label="Quota"
                            variant="outlined"
                            // value={props.mode === 'View' ? props.coupon.name : name}
                            readOnly={props.mode === 'View'}
                            onChange={(e) => setName(e.target.value)}
                            // helperText="Some important text"
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/*CREATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openAdd} onClose={() => setOpenAdd(false)}>
                <ProductActions
                    mode={'Add'}
                    closeActionsProduct={handleCreateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*UPDATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
                <ProductActions
                    mode={'Edit'}
                    product={activeProduct}
                    closeActionsProduct={handleUpdateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*ADD PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "addProduct"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product added successfully!
                </Alert>
            </Snackbar>

            {/*UPDATE PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "updateProduct"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product updated successfully!
                </Alert>
            </Snackbar>
        </Grid>
    )
}

import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { currencyFormatter, fractionToPercentFormatter } from "../../utils/Utils";
import { getMonth } from "./Utils";

export default function DataView(props) {
    const [metric] = React.useState(props.metric);
    const [period] = React.useState(props.period);
    const [data] = React.useState(props.data);

    const calc = (row, key) => {
        return key === "revenue_delta" ?
            currencyFormatter(row["revenue"] - row["prev_revenue"]) :
            key === "subscribers" ? ("subscribers" in row ? row["subscribers"] : "N/A") :
                key === "churned_subscribers" ? ("churned_subscribers" in row ? row["churned_subscribers"] : "N/A") :
                    key === "new_subscribers" ? ("new_subscribers" in row ? row["new_subscribers"] : "N/A") :
                        key === "ndr" ? ("ndr" in row ? fractionToPercentFormatter(row["ndr"]) : "N/A") :
                            key in row ? currencyFormatter(row[key]) : "N/A";
    }

    const period_data = period === "month" ? data["monthly_metrics"] : data["yearly_metrics"];
    const columns = period_data.map(x => {
        const header = period === "month" ? getMonth(x['month']) + " " + x["year"] : x["year"];
        return { 'field': header, 'headerName': header, 'sortable': false }
    });
    columns.unshift({ 'field': 'type', 'headerName': '', 'position': 'sticky', width: 200 })
    const r = {
        'revenue': { id: 'revenue', 'type': 'Revenue' },
        'revenue_delta': { id: 'revenue_delta', 'type': 'Revenue change' },
        'churn': { id: 'churn', 'type': 'Churn' },
        'win': { id: 'win', 'type': 'Acquire' },
        'keep': { id: 'keep', 'type': 'Retain' },
        'churned_subscribers': { id: 'churned_subscribers', 'type': 'Subscribers churned' },
        'new_subscribers': { id: 'new_subscribers', 'type': 'New subscribers' },
        'subscribers': { id: 'subscribers', 'type': 'Total subscribers' },
        'cac': { id: 'cac', 'type': 'Customer acquisition cost' },
        'ndr': { id: 'ndr', 'type': 'Net dollar retention' },
        'ltv': { id: 'ltv', 'type': 'LTV' },

        // 'grow': { id: 'grow', 'type': 'Expand', formatter: currencyFormatter }
    }
    const rows = [];

    if (period_data) {
        period_data.forEach(x => {
            const column = period === "month" ? getMonth(x['month']) + " " + x["year"] : x["year"];

            for (const key of Object.keys(r)) {
                r[key][column] = calc(x, key);
            }
        });
        for (const key of Object.keys(r)) {
            rows.push(r[key]);
        }
    }

    return (
        <Grid key={metric + period} container item xs={12} flex="flex-start" justifyContent="center" sx={{ mt: 2, pt: 2, borderTop: 1, borderColor: "primary.groupBorder" }}>
            <Grid item xs={12}>
                <DataGrid
                    initialState={{ pinnedColumns: { left: ['type'] } }}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    rowsPerPageOptions={[]}
                />
            </Grid>
        </Grid>
    )
}
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup, Slider,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {getReadablePriceMetric} from "../scenarios/price-modelling/components/PriceModelDefinitions";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import {TieredPricingPreview} from "./TieredPricingPreview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {styled} from "@mui/material/styles";
import {tooltipClasses} from "@mui/material/Tooltip";

function getContrastYIQ(hexcolor, opacity) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "rgba(17,24,39," + (opacity ?? "1.0") + ")" : "rgba(255,255,255," + (opacity ?? "1.0") + ")";
}

export default function AddOnSection({
    designFontStyle,
    designButtonBorderRadius,
    designCardBorderRadius,
    products,
    addOnProducts,
    selectedBillingPeriod,
    designCardWidth,
    designCardBoxShadow,
    designCardBorderColor,
    designCardColor,
    designHighlightedProduct,
    designHighlightedButtonColor,
    designHighlightedButtonVariant,
    designButtonColor,
    designButtonVariant,
    designHighlightedCardColor,
    designHighlightedPlanNameColor,
    designButtonText,
    designBgColor,
    handleStripePaymentIntegration,
    handleDefaultValueChange,
    values,
    setValues,
    isTabDevice
    }) {

    const PricingPreviewTooltip = styled(({ className, ...props }) => (
        <Tooltip arrow {...props} classes={{ popper: className }} placement="top"/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'black',
            boxShadow: theme.shadows[10]
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#f5f5f9',
        },
    }));

    const [selectedProduct,setSelectedProduct] = useState(null)

    const findProductWithMaxValidAddOns = () => {
        let maxValidAddOns = 0;
        let productWithMaxValidAddOns = null;

        products.forEach(product => {
            const validAddOnsCount = validAddOnsOnCurrentProduct(product).length;
            if (validAddOnsCount > maxValidAddOns) {
                maxValidAddOns = validAddOnsCount;
                productWithMaxValidAddOns = product;
            }
        });

        return productWithMaxValidAddOns;
    };

    const [finalProducts,setFinalProducts] = useState([])

    const validAddOnsOnCurrentProduct = (currProduct) =>{
        return addOnProducts.filter(addOnProduct => {

            const isInSelectedProductIds = addOnProduct.selected_products_ids?.some(productId => currProduct.id === productId);
            const hasValidPrice = addOnProduct.prices.some(price => (price.billing_period === selectedBillingPeriod || price.billing_period === 'N/A'));

            // console.log(currProduct,addOnProduct,isSelected,hasValidPrice)

            return isInSelectedProductIds && hasValidPrice;
        });
    }

    // Compute the filteredProducts array using the useMemo hook
    const filteredAddOnProducts = useMemo(() => {
        return selectedProduct ? validAddOnsOnCurrentProduct(selectedProduct) : []
    }, [selectedProduct, selectedBillingPeriod]);

    useEffect(()=>{
        const maxValidAddOnsProduct = findProductWithMaxValidAddOns()
        setSelectedProduct(maxValidAddOnsProduct)
        if(maxValidAddOnsProduct){
            setFinalProducts([maxValidAddOnsProduct])
        }else{
            setFinalProducts([])
        }
    },[selectedBillingPeriod])


    const [recurringPriceTotal,setRecurringPriceTotal] = useState(0)
    const [oneTimePriceTotal,setOneTimePriceTotal] = useState(0)

    const [isFreeTrialActive, setIsFreeTrialActive] = useState(false)

    const [usageMetered, setUsageMetered] = useState(false);

    useEffect(() => {
        // This will run after every render
        // Need to run this to rerender on usageMetered updates
        // console.log(usageMetered,finalProducts);
    }, [usageMetered]);

    useEffect(()=>{
        setRecurringPriceTotal(0)
        setOneTimePriceTotal(0)
        setUsageMetered(false)
        let isAllRecurringPrices = true
        let otpt = 0
        let rpt = 0

        finalProducts.map((product)=>{
            product.prices.map((price)=>{
                if(product.add_on_product && price.billing_period === 'N/A'){
                    isAllRecurringPrices = false
                }
            })
            if(product.usage_type === 'metered'){
                setUsageMetered(true)
            }

            // SAME CODE AS BELOW INSIDE COMPONENT BUT WITH SLIGHT CHANGE TO FIX RENDER ISSUE
            let prices = []

            // if(product.usage_type === 'metered'){
            //     usageMetered.current = true
            // }

            if(product.add_on_product && product.prices[0].payment_type === 'One Time'){
                prices = product.prices.filter(
                    (price) => price.billing_period === 'N/A'
                );
            }
            else{
                prices = product.prices.filter(
                    (price) => price.billing_period === selectedBillingPeriod
                );
            }

            let tieredPricing = product.price_model.type === 'TIERED_PRICING' ? true : false;

            let units = new Set();

            let x = 0; // Initialize x
            // Iterate over each price in prices
            for (let price of prices) {
                let total_usage = 1
                for (let price_unit of price.price_metric.price_units) {
                    // Make sure price_unit.unit first letter is capital
                    let unit = price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1);
                    // Add unit to set and make sure only units which are in price are shown in usage inputs
                    units.add(unit);
                    // Check if values[product.name][unit] exists, if not use product.usages
                    let usageValue;
                    if (values[product.name] && values[product.name][unit]) {
                        usageValue = values[product.name][unit];
                    } else {
                        // Find the usage with the same unit in product.usages
                        let usage = product.usages.find(u => u.unit === unit);
                        usageValue = usage ? usage.default : 1;
                    }
                    // Add to x
                    total_usage *= usageValue;
                }
                if(tieredPricing){
                    x += calculateTieredPriceTotal(price, product.tiers_mode, product.name)
                }else{
                    x += (price.discount_price_value * total_usage)
                }
            }


            // Finding multiplier in billing
            let priceMultiplier = 0; // setting it to 0 incase of any error
            let timePeriodUnit = 'month';

            if (
                !product.prices.some(price => price.price_metric.price_units.some(price_unit => price_unit.unit.includes('month'))) &&
                product.prices.some(price => price.price_metric.price_units.some(price_unit => price_unit.unit.includes('year')))) {
                timePeriodUnit = 'year';
            }

            // Check selectedBillingPeriod
            let match = selectedBillingPeriod.match(/Every (\d+) (months|years)/);
            if (match) {
                let x = parseInt(match[1]);
                let unit = match[2];
                if (unit === 'years') {
                    priceMultiplier = timePeriodUnit === 'month' ? x * 12 : x;
                } else {
                    priceMultiplier = x;
                }
            } else if (selectedBillingPeriod === 'Yearly') {
                priceMultiplier = timePeriodUnit === 'month' ? 12 : 1;
            } else if (selectedBillingPeriod === 'Monthly' || selectedBillingPeriod === 'N/A') {
                priceMultiplier = 1;
            }

            if (selectedBillingPeriod === 'N/A' ||
                (product.add_on_product && product.prices[0].billing_period === 'N/A')) {
                // console.log(x,priceMultiplier)
                priceMultiplier = 1;
                otpt += x;
            } else {
                // console.log(x,priceMultiplier)
                rpt += (x * priceMultiplier);
            }

        })

        setOneTimePriceTotal(otpt)
        setRecurringPriceTotal(rpt)
        setIsFreeTrialActive(selectedProduct?.free_trial_integration?.active && selectedBillingPeriod !== 'N/A' && isAllRecurringPrices)

    },[finalProducts,values])

    const toggleAddOnProduct = (addOnProduct) => {
        const isPresent = finalProducts.some(product => product.id === addOnProduct.id);

        if (isPresent) {
            // Remove the addOnProduct from finalProducts
            const updatedProducts = finalProducts.filter(product => product.id !== addOnProduct.id);
            setFinalProducts(updatedProducts);
        } else {
            // Add the addOnProduct to finalProducts
            setFinalProducts([...finalProducts, addOnProduct]);
        }
    };

    // const [values, setValues] = useState({});
    // const handleDefaultValueChange = (newValue, productName, usage) => {
    //     // console.log(newValue, productName, usage)
    //
    //     if (newValue < usage.min) {
    //         newValue = usage.min;
    //     } else if (newValue > usage.max) {
    //         newValue = usage.max;
    //     }
    //     setValues(prevValues => ({
    //         ...prevValues,
    //         [productName]: {
    //             ...(prevValues[productName] || {}),
    //             [usage.unit]: newValue
    //         }
    //     }));
    //     // console.log("VALUES OF USAGE METRICS", values)
    // };

    useEffect(()=>{
        products.forEach((product) => {
            product.usages.forEach((usage) => {
                setValues(prevValues => ({
                    ...prevValues,
                    [product.name]: {
                        ...(prevValues[product.name] || {}),
                        [usage.unit]: usage.default
                    }
                }));
            });
        });

        addOnProducts.forEach((product) => {
            product.usages.forEach((usage) => {
                setValues(prevValues => ({
                    ...prevValues,
                    [product.name]: {
                        ...(prevValues[product.name] || {}),
                        [usage.unit]: usage.default
                    }
                }));
            });
        });
    },[])

    function CustomTextField(props) {
        const [isFocused, setIsFocused] = useState(false);
        const [val, setVal] = useState(props.value)
        const handleFocus = () => setIsFocused(true);
        const handleBlur = () => setIsFocused(false);

        return (
            <TextField
                {...props}
                onChange={(event) => {
                    setVal(event.target.value)
                }}
                onFocus={(event) => {
                    handleFocus();
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        // handleBlur();
                        if (!isNaN(val)) {
                            handleDefaultValueChange(Number(val), props.product.name, props.usage)
                        } else {
                            setVal(props.value)
                        }
                    }
                }}
                onBlur={(event) => {
                    handleBlur();
                    if (!isNaN(val)) {
                        handleDefaultValueChange(Number(val), props.product.name, props.usage)
                    } else {
                        setVal(props.value)
                    }
                }}
                value={isFocused ? val : props.renderValue(props.value)}
            />
        );
    }

    const styles = {
        usagePlusMinus: {
            // borderRadius={1}
            p: 0.1,
            width: '14%',
            '&:hover': {
                opacity: 0.8
            },
            opacity: 0.3,
            cursor: 'pointer',
        }
    }

    const processPriceMetric = (paymentType, metric) => {
        let readablePriceMetric = getReadablePriceMetric(metric);
        if (paymentType === 'One Time') {
            readablePriceMetric = readablePriceMetric.replace('/month', '').replace('/year', '');
            if (readablePriceMetric === '') {
                readablePriceMetric = '/';
            }
        }
        return readablePriceMetric;
    }

    const selectionColor = (designHighlightedProduct === 'None') ?
        designButtonColor : designHighlightedButtonColor

    const calculateTieredPriceTotal = (price,tiersMode,productName) => {
        let graduatedTotal = 0;
        let cumulativeQuantity = 0;

        let mode = tiersMode
        let tiers = price.tiers

        // console.log(price,price.price_metric.price_units[0].unit)

        let priceUnit = price.price_metric.price_units[0].unit

        let unit = priceUnit.charAt(0).toUpperCase() + priceUnit.slice(1)

        let quantity = values[productName][unit]

        for(let tier of tiers){
            if(mode === 'volume'){
                if(quantity <= tier.up_to || tier.up_to === -1){
                    return ((quantity*tier.unit_price_value) + tier.flat_price_value)
                }
            }else{
                if(quantity <= tier.up_to || tier.up_to === -1){
                    graduatedTotal += (((quantity-cumulativeQuantity)*tier.unit_price_value) + tier.flat_price_value)
                    return graduatedTotal
                } else {
                    graduatedTotal += (((tier.up_to-cumulativeQuantity)*tier.unit_price_value) + tier.flat_price_value)
                    cumulativeQuantity = tier.up_to
                }
            }
        }
    }

    return (selectedProduct &&
        <>
            <Grid
                container
                alignItems={'center'}
                mt={5}
                width={'100%'}
                direction={'column'}
            >
                <Typography
                    fontSize={24}
                    fontWeight={800}
                    fontFamily={designFontStyle}
                    color={getContrastYIQ(designBgColor)}
                >
                    Enhance Your Plan
                </Typography>

                <Grid container mt={5} justifyContent={'center'}
                    // border={'1px solid lightgray'}
                    gap={5}
                >
                    <Grid
                        container
                        direction={'column'}
                        width={isTabDevice ? '100%' : '60%'}
                        minWidth={300}
                        p={4}
                        gap={3}
                    >
                        <Grid container direction={'column'}>
                            <Typography
                                fontSize={16}
                                fontWeight={800}
                                fontFamily={designFontStyle}
                                color={getContrastYIQ(designBgColor)}
                            >
                                Select a plan:
                            </Typography>

                            <Grid container columnGap={1.5} >
                                {products.map((product)=>{

                                    // console.log(product.name)

                                    const check = validAddOnsOnCurrentProduct(product).length > 0

                                    const prices = product.prices.filter(
                                        (price) => (price.billing_period === selectedBillingPeriod)
                                    );

                                    // let currCardColor = (designHighlightedProduct === product.name)
                                    //     ? designHighlightedCardColor : designCardColor;
                                    //
                                    // let currButtonColor = (designHighlightedProduct === product.name)
                                    //     ? designHighlightedButtonColor : designButtonColor;
                                    //
                                    // let currButtonVariant = (designHighlightedProduct === product.name)
                                    //     ? designHighlightedButtonVariant : designButtonVariant;

                                    return (check &&
                                        <Grid
                                            container
                                            minWidth={250}
                                            width={'32%'}
                                            maxWidth={'100%'}
                                            pl={2.2}
                                            pr={1}
                                            py={1}
                                            mt={2}
                                            border={`2px solid ${designCardBorderColor}`}
                                            direction={'column'}
                                            sx={{
                                                boxShadow: designCardBoxShadow,
                                                '&:hover':{
                                                    boxShadow:3,
                                                    border:`2px solid ${selectionColor}`,
                                                    cursor: 'pointer',
                                                    filter: 'brightness(105%)'
                                                },
                                                borderColor: product.id === selectedProduct.id ? selectionColor : designCardBorderColor,
                                                borderRadius: designCardBorderRadius,
                                                bgcolor: designCardColor,
                                            }}
                                            onClick={(e)=>{
                                                if(selectedProduct.id !== product.id){
                                                    setFinalProducts([product]);
                                                    setSelectedProduct(product);
                                                }
                                            }}
                                        >
                                            <Grid
                                                container
                                                alignItems={'flex-start'}
                                                justifyContent={'space-between'}
                                            >
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    fontFamily={designFontStyle}
                                                    color={getContrastYIQ(designCardColor)}
                                                    sx={{width:170,pt:1.3,pb:1.5}}
                                                >
                                                    {product.header_name}
                                                </Typography>
                                                <Checkbox
                                                    checked={product.id === selectedProduct.id}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: selectionColor,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            {prices.map((price)=>{
                                                return <>
                                                    {product.price_model.type !== 'TIERED_PRICING' ?
                                                        <Typography
                                                            mt={-1}
                                                            pb={1}
                                                            fontSize={12}
                                                            fontWeight={600}
                                                            fontFamily={designFontStyle}
                                                            color={getContrastYIQ(designCardColor)}
                                                        >
                                                            {price.currency}{price.discount_price_value.toLocaleString()}{' '}{price.billing_period !== 'N/A' ?
                                                            getReadablePriceMetric(price.price_metric) : ''}
                                                        </Typography>
                                                        :
                                                        (product.usage_type !== 'metered' ?
                                                                <Typography
                                                                    mt={-1}
                                                                    pb={1}
                                                                    fontSize={12}
                                                                    fontWeight={600}
                                                                    fontFamily={designFontStyle}
                                                                    color={getContrastYIQ(designCardColor)}
                                                                >
                                                                    {price.currency}{calculateTieredPriceTotal(price,product.tiers_mode,product.name)}{' '}{price.billing_period !== 'N/A' ?
                                                                    '/ month' : ''}
                                                                </Typography>
                                                                :
                                                                <Typography
                                                                    mt={-1}
                                                                    pb={1}
                                                                    fontSize={12}
                                                                    fontWeight={600}
                                                                    fontFamily={designFontStyle}
                                                                    color={getContrastYIQ(designCardColor)}
                                                                >
                                                                    Starting at <br/>
                                                                    {price.currency}{price.tiers[0].unit_price_value}{' '}{price.billing_period !== 'N/A' ?
                                                                    getReadablePriceMetric(price.price_metric) : ''} <br/>
                                                                    plus {price.currency}{price.tiers[0].unit_price_value}{' '}{price.billing_period !== 'N/A' ?
                                                                    '/month' : ''}
                                                                </Typography>
                                                        )
                                                    }
                                                </>
                                            })}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>

                        {/*<Divider variant="middle" flexItem />*/}

                        <Grid container direction={'column'} gap={1}>
                            <Typography
                                fontSize={16}
                                fontWeight={800}
                                fontFamily={designFontStyle}
                                color={getContrastYIQ(designBgColor)}
                            >
                                Add-ons:
                            </Typography>
                            <Typography
                                fontSize={14}
                                fontFamily={designFontStyle}
                                color={getContrastYIQ(designBgColor)}
                            >
                                Enhance your plan with additional features.
                            </Typography>

                            {filteredAddOnProducts.map((addOnProduct)=>{

                                const addOnPrices = addOnProduct.prices.filter(
                                    (price) => price.billing_period === selectedBillingPeriod || price.billing_period === 'N/A'
                                );

                                return (
                                    <Grid
                                        container
                                        width={'100%'}
                                        my={1}
                                        pl={3}
                                        pr={2}
                                        py={1.5}
                                        border={`2px solid ${designCardBorderColor}`}
                                        direction={'column'}
                                        sx={{
                                            boxShadow: designCardBoxShadow,
                                            '&:hover':{
                                                boxShadow:3,
                                                border:`2px solid ${selectionColor}`,
                                                cursor: 'pointer',
                                                filter: 'brightness(105%)',
                                            },
                                            borderColor: finalProducts.some((prod) => addOnProduct.id === prod.id) ? selectionColor : designCardBorderColor,
                                            borderRadius: designCardBorderRadius,
                                            bgcolor: designCardColor,
                                        }}
                                        onClick={(e)=>{
                                            toggleAddOnProduct(addOnProduct);
                                        }}
                                    >
                                        <Grid
                                            container
                                            alignItems={'flex-start'}
                                            justifyContent={'space-between'}
                                        >
                                            <Typography
                                                fontSize={14}
                                                fontWeight={600}
                                                fontFamily={designFontStyle}
                                                color={getContrastYIQ(designCardColor)}
                                                sx={{width:'70%',pt:1.3,pb:1.5}}
                                            >
                                                {addOnProduct.header_name}
                                            </Typography>
                                            <Checkbox
                                                checked={finalProducts.some(product => product.id === addOnProduct.id)}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: selectionColor,
                                                    },
                                                }}
                                                // onChange={(e)=>setAddOnSection(e.target.checked)}
                                                // inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Grid>
                                        {addOnPrices.map((price)=>{
                                            return <>
                                                {addOnProduct.price_model.type !== 'TIERED_PRICING' ?
                                                    <Typography
                                                        mt={-1}
                                                        pb={1}
                                                        fontSize={12}
                                                        fontWeight={600}
                                                        fontFamily={designFontStyle}
                                                        color={getContrastYIQ(designCardColor)}
                                                    >
                                                        {price.currency}{price.discount_price_value.toLocaleString()}{' '}{price.billing_period !== 'N/A' ?
                                                        getReadablePriceMetric(price.price_metric) : ''}
                                                    </Typography>
                                                    :
                                                    (addOnProduct.usage_type !== 'metered' ?
                                                            <Typography
                                                                mt={-1}
                                                                pb={1}
                                                                fontSize={12}
                                                                fontWeight={600}
                                                                fontFamily={designFontStyle}
                                                                color={getContrastYIQ(designCardColor)}
                                                            >
                                                                {price.currency}{calculateTieredPriceTotal(price,addOnProduct.tiers_mode,addOnProduct.name)}{' '}{price.billing_period !== 'N/A' ?
                                                                '/month' : ''}
                                                            </Typography>
                                                            :
                                                            <Typography
                                                                mt={-1}
                                                                pb={1}
                                                                fontSize={12}
                                                                fontWeight={600}
                                                                fontFamily={designFontStyle}
                                                                color={getContrastYIQ(designCardColor)}
                                                            >
                                                                Starting at <br/>
                                                                {price.currency}{price.tiers[0].unit_price_value}{' '}{price.billing_period !== 'N/A' ?
                                                                getReadablePriceMetric(price.price_metric) : ''} <br/>
                                                                plus {price.currency}{price.tiers[0].unit_price_value}{' '}{price.billing_period !== 'N/A' ?
                                                                '/ month' : ''}
                                                            </Typography>
                                                    )
                                                }
                                            </>
                                        })}
                                        <Typography
                                            // mt={-1}
                                            pb={1}
                                            mr={1}
                                            fontSize={12}
                                            // fontWeight={600}
                                            fontFamily={designFontStyle}
                                            color={getContrastYIQ(designCardColor)}
                                        >
                                            {addOnProduct.description}
                                        </Typography>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                    {/*<Divider orientation={'vertical'} flexItem />*/}

                    <Grid container item direction={'column'}
                          width={designCardWidth}
                    >
                        <Grid container item direction={'column'}
                              sx={{
                                  // border: '1px solid slategray',
                                  width: designCardWidth,
                                  // textAlign:'center',
                                  // width: '25vw',
                                  height: 'auto-fit',
                                  p: 4,
                                  rowGap: 0.5,
                                  borderRadius: designCardBorderRadius,
                                  boxShadow: designCardBoxShadow,
                                  bgcolor: (designHighlightedProduct === selectedProduct.name) ?
                                      designHighlightedCardColor : designCardColor,
                                  border: (designHighlightedProduct === selectedProduct.name) ?
                                      `2px solid ${designHighlightedButtonColor}` : `2px solid ${designCardBorderColor}`,
                              }}
                        >
                            <Typography
                                fontSize={22}
                                fontWeight={800}
                                fontFamily={designFontStyle}
                                textAlign={'center'}
                                pb={1}
                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                    ? designHighlightedCardColor : designCardColor)}
                            >
                                Final Price Total
                            </Typography>

                            <Grid container direction={'column'}>
                                {usageMetered ?
                                    <Typography
                                        fontSize={16}
                                        fontWeight={800}
                                        fontFamily={designFontStyle}
                                        textAlign={'center'}
                                        pb={2}
                                        color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                            ? designHighlightedCardColor : designCardColor)}
                                    >
                                        Billed based on usage
                                    </Typography>
                                    :
                                    <>
                                        {recurringPriceTotal > 0 &&
                                            <>
                                                <Typography
                                                    fontSize={36}
                                                    fontWeight={800}
                                                    fontFamily={designFontStyle}
                                                    textAlign={'center'}
                                                    // pb={2}
                                                    color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                        ? designHighlightedCardColor : designCardColor)}
                                                >
                                                    {selectedProduct.prices[0].currency}{recurringPriceTotal.toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={800}
                                                    fontFamily={designFontStyle}
                                                    textAlign={'center'}
                                                    pb={2}
                                                    color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                        ? designHighlightedCardColor : designCardColor)}
                                                >
                                                    Billed {selectedBillingPeriod}
                                                </Typography>
                                            </>
                                        }
                                        {oneTimePriceTotal > 0 &&
                                            <>
                                                <Typography
                                                    fontSize={24}
                                                    fontWeight={800}
                                                    fontFamily={designFontStyle}
                                                    textAlign={'center'}
                                                    // pb={2}
                                                    color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                        ? designHighlightedCardColor : designCardColor)}
                                                >
                                                    {selectedProduct.prices[0].currency}{(recurringPriceTotal+oneTimePriceTotal).toLocaleString()}
                                                </Typography>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={800}
                                                    fontFamily={designFontStyle}
                                                    textAlign={'center'}
                                                    pb={2}
                                                    color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                        ? designHighlightedCardColor : designCardColor)}
                                                >
                                                    {selectedBillingPeriod !== 'N/A' ? 'Estimated total to get started' : 'Billed One Time'}
                                                </Typography>
                                            </>
                                        }
                                        {oneTimePriceTotal+recurringPriceTotal === 0 &&
                                            <Typography
                                                fontSize={36}
                                                fontWeight={800}
                                                fontFamily={designFontStyle}
                                                textAlign={'center'}
                                                // pb={2}
                                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                    ? designHighlightedCardColor : designCardColor)}
                                            >
                                                Free
                                            </Typography>
                                        }
                                    </>
                                }
                                {/*Recurring Total = {recurringPriceTotal.current}*/}
                                {/*<br/>*/}
                                {/*One Time Total = {oneTimePriceTotal.current}*/}
                                {/*<br/>*/}
                                {/*Usage Metered ? = {usageMetered.current.toString()}*/}
                            </Grid>

                            <Typography
                                fontSize={16}
                                fontWeight={800}
                                fontFamily={designFontStyle}
                                pb={2}
                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                    ? designHighlightedCardColor : designCardColor)}
                            >
                                Product Selections
                            </Typography>

                            {finalProducts.map((product)=>{

                                let prices = []

                                // if(product.usage_type === 'metered'){
                                //     usageMetered.current = true
                                // }

                                if(product.add_on_product && product.prices[0].payment_type === 'One Time'){
                                    prices = product.prices.filter(
                                        (price) => price.billing_period === 'N/A'
                                    );
                                }
                                else{
                                    prices = product.prices.filter(
                                        (price) => price.billing_period === selectedBillingPeriod
                                    );
                                }

                                let tieredPricing = product.price_model.type === 'TIERED_PRICING';

                                let groupedPrices = product.prices.reduce((grouped, price) => {
                                    // Find the group for this billing period
                                    let group = grouped.find(g => g.billing_period === price.billing_period);
                                    if (!group) {
                                        // If the group doesn't exist, create it
                                        group = { billing_period: price.billing_period, prices: [] };
                                        grouped.push(group);
                                    }
                                    // Add the price to the group
                                    group.prices.push(price);
                                    return grouped;
                                }, []);

                                // console.log(groupedPrices,values)

                                let z = 0; // Initialize z
                                // Iterate over each group in groupedPrices
                                for (let group of groupedPrices) {
                                    // Iterate over each price in the current group
                                    // Calculate y for each group
                                    let y = 0; // Initialize y
                                    for (let price of group.prices) {
                                        let total_usage = 1
                                        for (let price_unit of price.price_metric.price_units) {
                                            // Make sure price_unit.unit first letter is capital
                                            let unit = price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1);
                                            // Check if values[product.name][unit] exists, if not use product.usages
                                            let usageValue;
                                            if (values[product.name] && values[product.name][unit]) {
                                                usageValue = values[product.name][unit];
                                            } else {
                                                // Find the usage with the same unit in product.usages
                                                let usage = product.usages.find(u => u.unit === unit);
                                                usageValue = usage ? usage.default : 1;
                                            }
                                            total_usage *= usageValue;
                                        }
                                        if(tieredPricing){
                                            y += calculateTieredPriceTotal(price,product.tiers_mode,product.name)
                                        }else{
                                            y += (price.price_value * total_usage)
                                        }
                                    }
                                    // console.log(y,z)
                                    z = Math.max(y, z)
                                }

                                // console.log(z)

                                let units = new Set();

                                let x = 0; // Initialize x
                                // Iterate over each price in prices
                                for (let price of prices) {
                                    let total_usage = 1
                                    for (let price_unit of price.price_metric.price_units) {
                                        // Make sure price_unit.unit first letter is capital
                                        let unit = price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1);
                                        // Add unit to set and make sure only units which are in price are shown in usage inputs
                                        units.add(unit);
                                        // Check if values[product.name][unit] exists, if not use product.usages
                                        let usageValue;
                                        if (values[product.name] && values[product.name][unit]) {
                                            usageValue = values[product.name][unit];
                                        } else {
                                            // Find the usage with the same unit in product.usages
                                            let usage = product.usages.find(u => u.unit === unit);
                                            usageValue = usage ? usage.default : 1;
                                        }
                                        // Add to x
                                        total_usage *= usageValue;
                                    }
                                    if(tieredPricing){
                                        x += calculateTieredPriceTotal(price, product.tiers_mode, product.name)
                                    }else{
                                        x += (price.discount_price_value * total_usage)
                                    }
                                }

                                // console.log(x)

                                let savings = Math.round(((z - x) * 100) / z);
                                // console.log(groupedPrices,prices)

                                // Finding multiplier in billing
                                let priceMultiplier = 0; // setting it to 0 incase of any error
                                let timePeriodUnit = 'month';

                                if (!groupedPrices.some(group => group.billing_period.includes('month')) &&
                                    !product.prices.some(price => price.price_metric.price_units.some(price_unit => price_unit.unit.includes('month'))) &&
                                    product.prices.some(price => price.price_metric.price_units.some(price_unit => price_unit.unit.includes('year')))) {
                                    timePeriodUnit = 'year';
                                }

                                // Check selectedBillingPeriod
                                let match = selectedBillingPeriod.match(/Every (\d+) (months|years)/);
                                if (match) {
                                    let x = parseInt(match[1]);
                                    let unit = match[2];
                                    if (unit === 'years') {
                                        priceMultiplier = timePeriodUnit === 'month' ? x * 12 : x;
                                    } else {
                                        priceMultiplier = x;
                                    }
                                } else if (selectedBillingPeriod === 'Yearly') {
                                    priceMultiplier = timePeriodUnit === 'month' ? 12 : 1;
                                } else if (selectedBillingPeriod === 'Monthly' || selectedBillingPeriod === 'N/A') {
                                    priceMultiplier = 1;
                                }

                                // if (selectedBillingPeriod === 'N/A' ||
                                //     (product.add_on_product && product.prices[0].billing_period === 'N/A')) {
                                //     // console.log(x,priceMultiplier)
                                //     priceMultiplier = 1;
                                //     setOneTimePriceTotal(oneTimePriceTotal + x);
                                // } else {
                                //     // console.log(x,priceMultiplier)
                                //     setRecurringPriceTotal(recurringPriceTotal + (x * priceMultiplier));
                                // }

                                return (
                                    <Grid
                                        container
                                        width={'100%'}
                                        pl={1.5}
                                        pr={0.5}
                                        pb={2}
                                        // border={'2px solid lightgray'}
                                        direction={'column'}
                                    >
                                        <Grid
                                            container
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            pb={product.add_on_product ? 1 : 1.5}
                                        >
                                            <Typography
                                                sx={{width:'75%'}}
                                                fontSize={14}
                                                fontWeight={600}
                                                fontFamily={designFontStyle}
                                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedCardColor : designCardColor)}
                                            >
                                                {product.header_name}
                                            </Typography>
                                            {product.add_on_product &&
                                                <IconButton
                                                    sx={{
                                                        '&:hover': {
                                                            filter: 'brightness(80%)',
                                                        },
                                                        color: selectionColor
                                                    }}
                                                    onClick={(e)=>{
                                                        toggleAddOnProduct(product);
                                                    }}
                                                >
                                                    <ClearIcon fontSize="small"/>
                                                </IconButton>
                                            }
                                        </Grid>
                                        <Grid container
                                            direction={'column'}
                                        >
                                            {prices.map((price)=>{
                                                return (
                                                    <>
                                                        {product.price_model.type !== 'TIERED_PRICING' ?
                                                            <Typography
                                                                mt={-1}
                                                                pb={1}
                                                                fontSize={12}
                                                                fontWeight={600}
                                                                fontFamily={designFontStyle}
                                                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                    ? designHighlightedCardColor : designCardColor)}
                                                            >
                                                                {price.currency}{price.discount_price_value.toLocaleString()}{' '}{price.billing_period !== 'N/A' ?
                                                                getReadablePriceMetric(price.price_metric) : ''}
                                                            </Typography>
                                                            :
                                                            (product.usage_type !== 'metered' ?
                                                                    <Typography
                                                                        mt={-1}
                                                                        pb={1}
                                                                        fontSize={12}
                                                                        fontWeight={600}
                                                                        fontFamily={designFontStyle}
                                                                        color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                            ? designHighlightedCardColor : designCardColor)}
                                                                    >
                                                                        {price.currency}{calculateTieredPriceTotal(price,product.tiers_mode,product.name)}{' '}{price.billing_period !== 'N/A' ?
                                                                        '/month' : ''}
                                                                    </Typography>
                                                                    :
                                                                    <Typography
                                                                        mt={-1}
                                                                        pb={1}
                                                                        fontSize={12}
                                                                        fontWeight={600}
                                                                        fontFamily={designFontStyle}
                                                                        color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                            ? designHighlightedCardColor : designCardColor)}
                                                                    >
                                                                        Starting at <br/>
                                                                        {price.currency}{price.tiers[0].unit_price_value}{' '}{price.billing_period !== 'N/A' ?
                                                                        getReadablePriceMetric(price.price_metric) : ''} <br/>
                                                                        plus {price.currency}{price.tiers[0].unit_price_value}{' '}{price.billing_period !== 'N/A' ?
                                                                        '/ month' : ''}
                                                                    </Typography>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                        {product?.usage_type !=='metered' ?
                                            <Typography
                                                fontSize={12}
                                                fontFamily={designFontStyle}
                                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedCardColor : designCardColor)}
                                                pt={1}
                                                // color={getContrastYIQ(currCardColor)}
                                            >
                                                Billed at {z !== x && <s>{product.prices[0].currency}{(Math.round(z * priceMultiplier).toLocaleString())}</s>}
                                                <b> {product.prices[0].currency}{(Math.round(x * priceMultiplier).toLocaleString())} </b>
                                                <b>
                                                    {(selectedBillingPeriod === 'N/A' || (product.add_on_product && product.prices[0].payment_type === 'One Time')) ?
                                                        'One Time' : selectedBillingPeriod}
                                                </b>
                                            </Typography>
                                            :
                                            <Typography
                                                fontSize={12}
                                                fontFamily={designFontStyle}
                                                color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedCardColor : designCardColor)}
                                                pt={1}
                                                // color={getContrastYIQ(currCardColor)}
                                            >
                                                Billed <b>{selectedBillingPeriod}</b> based on usage
                                            </Typography>
                                        }
                                        {product?.usage_type !=='metered' && product?.usages?.length > 0 &&
                                            <Grid container pt={2}>
                                                <Grid container item direction={'column'} gap={1.5}
                                                    // py={1}
                                                      width={'100%'}
                                                    // mt={2}
                                                    // p={2}
                                                    // bgcolor={'orange'}
                                                    // boxShadow={1}
                                                    // borderRadius={2} border={"1px solid slategray"}
                                                      justifyContent={'center'}
                                                      alignItems={'center'}
                                                >
                                                    {/*<Typography*/}
                                                    {/*    fontFamily={designFontStyle}*/}
                                                    {/*    fontWeight={800}*/}
                                                    {/*    color={getContrastYIQ(currCardColor)}*/}
                                                    {/*>*/}
                                                    {/*    Usage Metrics*/}
                                                    {/*</Typography>*/}

                                                    {product?.usages.map((usage, idx) => {
                                                        if (units.has(usage.unit)) {
                                                            return (usage.type === 'Text Field' ?
                                                                    <Grid container item
                                                                          alignItems={'center'}
                                                                          justifyContent={'space-between'}
                                                                          width={'75%'}
                                                                        // bgcolor={'green'}
                                                                    >
                                                                        <Grid
                                                                            justifyContent={'center'}
                                                                            container
                                                                            sx={styles.usagePlusMinus}
                                                                            color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor)}
                                                                            borderColor={(designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor}
                                                                            onClick={(event) =>
                                                                                handleDefaultValueChange((values?.[product?.name]?.[usage?.unit]) - usage.step, product.name, usage)
                                                                            }
                                                                        >
                                                                            <RemoveIcon sx={{ fontSize: 16 }} />
                                                                        </Grid>
                                                                        <CustomTextField
                                                                            product={product}
                                                                            usage={usage}
                                                                            key={idx}
                                                                            // sx={{zoom:0.70}}
                                                                            // label={price_unit.unit.charAt(0).toUpperCase() + price_unit.unit.slice(1) + ' Count'}
                                                                            // label={usage.unit + 's'}
                                                                            size='small'
                                                                            value={Object.keys(values).length > 0 ?
                                                                                (values?.[product?.name]?.[usage?.unit]) :
                                                                                (usage?.default)}
                                                                            renderValue={(value) => {
                                                                                if (value > 1) {
                                                                                    return `${Number(value).toLocaleString()} ${usage?.plural?.toLowerCase()}`;
                                                                                } else {
                                                                                    return `${Number(value).toLocaleString()} ${usage?.unit.toLowerCase()}`;
                                                                                }
                                                                            }}

                                                                            // value={values[price_unit.unit]}
                                                                            sx={{
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                        borderColor: getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor, 0.2),
                                                                                    },
                                                                                    '&:hover fieldset': {
                                                                                        borderColor: getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor, 0.2),
                                                                                    },
                                                                                    '&.Mui-focused fieldset': {
                                                                                        borderColor: getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor, 0.6),
                                                                                    },
                                                                                    borderRadius: 1
                                                                                },
                                                                                width: '67%'
                                                                            }}
                                                                            // type="number"
                                                                            // InputLabelProps={{
                                                                            //     shrink: true,
                                                                            //     style: {
                                                                            //         fontFamily: designFontStyle,
                                                                            //         fontWeight: 800,
                                                                            //         color: getContrastYIQ(currCardColor),
                                                                            //     },
                                                                            // }}
                                                                            inputProps={{
                                                                                style: {
                                                                                    fontFamily: designFontStyle,
                                                                                    height: 10,
                                                                                    fontSize: 12,
                                                                                    // fontWeight: 800,
                                                                                    textAlign: 'center',
                                                                                    color: getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                                        ? designHighlightedCardColor : designCardColor),
                                                                                    backgroundColor:(designHighlightedProduct === selectedProduct.name)
                                                                                        ? designHighlightedCardColor : designCardColor,
                                                                                    borderRadius: 1,
                                                                                    p: 2,
                                                                                },
                                                                                min: usage.min,
                                                                                max: usage.max,
                                                                                step: usage.step
                                                                            }}
                                                                            // onWheel={event => event.target.blur()}
                                                                        />
                                                                        <Grid justifyContent="center" container
                                                                              sx={styles.usagePlusMinus}
                                                                              color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor)}
                                                                              borderColor={(designHighlightedProduct === selectedProduct.name)
                                                                                  ? designHighlightedCardColor : designCardColor}
                                                                              onClick={(event) =>
                                                                                  handleDefaultValueChange((values?.[product?.name]?.[usage?.unit]) + usage.step, product.name, usage)
                                                                              }
                                                                        >
                                                                            <AddIcon sx={{ fontSize: 16 }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container py={0} key={idx}>
                                                                        {/*<Grid container item */}
                                                                        {/*//       justifyContent={*/}
                                                                        {/*//     ((Object.keys(values).length > 0*/}
                                                                        {/*//         ? values?.[product?.name]?.[usage?.unit] : usage?.default) / usage?.max)*/}
                                                                        {/*//     > 0.4*/}
                                                                        {/*//         ? 'flex-start' : 'flex-end'*/}
                                                                        {/*// }*/}
                                                                        {/*//     // onMouseEnter={()=>{console.log(values)}}*/}
                                                                        {/*>*/}
                                                                        {/*    <Typography*/}
                                                                        {/*        sx={{*/}
                                                                        {/*            fontFamily: designFontStyle,*/}
                                                                        {/*            fontWeight: 800,*/}
                                                                        {/*            // color: getContrastYIQ(currCardColor),*/}
                                                                        {/*            fontSize: 12,*/}
                                                                        {/*        }}*/}
                                                                        {/*    >*/}
                                                                        {/*        {usage.unit + ' Count'}*/}
                                                                        {/*    </Typography>*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Slider*/}
                                                                        {/*    sx={{*/}
                                                                        {/*        // color: getContrastYIQ(currCardColor),*/}
                                                                        {/*        '& .MuiSlider-valueLabel': {*/}
                                                                        {/*            fontSize: 10,*/}
                                                                        {/*        }*/}
                                                                        {/*    }}*/}
                                                                        {/*    defaultValue={usage.default}*/}
                                                                        {/*    // value={values[product.name][usage.unit]}*/}
                                                                        {/*    step={usage.step}*/}
                                                                        {/*    min={usage.min}*/}
                                                                        {/*    valueLabelDisplay="on"*/}
                                                                        {/*    max={usage.max}*/}
                                                                        {/*    // onChange={(event) =>*/}
                                                                        {/*    //     handleDefaultValueChange(event, product.name, usage)*/}
                                                                        {/*    // }*/}
                                                                        {/*/>*/}
                                                                    </Grid>
                                                            )
                                                        }
                                                    })}
                                                </Grid>
                                            </Grid>
                                        }
                                        {product.price_model.type === 'TIERED_PRICING' &&
                                            <Grid container width={'100%'} justifyContent={'center'} pt={2}>
                                                <PricingPreviewTooltip
                                                    // open={true}
                                                    title={
                                                        <TieredPricingPreview
                                                            designCardWidth={designCardWidth}
                                                            designFontStyle={designFontStyle}
                                                            tiers={prices[0]?.tiers}
                                                            price={prices[0]}
                                                            mode={product.tiers_mode}
                                                            usage={product.usages[0]}
                                                            usageType={product.usage_type}
                                                            quantity={values?.[product?.name]?.[product?.usages[0]?.unit]}
                                                        />
                                                    }
                                                >
                                                    <Grid
                                                        container
                                                        width={'60%'}
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        mb={-0.5}
                                                        sx={{
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                filter: 'brightness(90%)'
                                                            }
                                                        }}
                                                    >
                                                        <VisibilityIcon
                                                            sx={{
                                                                fontSize: 14,
                                                                color: getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                    ? designHighlightedCardColor : designCardColor),
                                                                pr:0.5,
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontFamily: designFontStyle,
                                                                fontWeight: 800,
                                                                color: getContrastYIQ((designHighlightedProduct === selectedProduct.name)
                                                                    ? designHighlightedCardColor : designCardColor),
                                                                fontSize: 12,
                                                                // borderBottom: `1px dashed ${getContrastYIQ(currCardColor)}`
                                                            }}
                                                        >
                                                            Pricing Preview
                                                        </Typography>
                                                    </Grid>
                                                </PricingPreviewTooltip>
                                            </Grid>}
                                    </Grid>
                                )
                            })}
                            {/*<Typography*/}
                            {/*    // mt={-1}*/}
                            {/*    py={1}*/}
                            {/*    fontSize={12}*/}
                            {/*    fontFamily={designFontStyle}*/}
                            {/*    color={getContrastYIQ((designHighlightedProduct === selectedProduct.name)*/}
                            {/*            ? designHighlightedCardColor : designCardColor)}*/}
                            {/*>*/}
                            {/*    Billed at <strong>TBD</strong>*/}
                            {/*</Typography>*/}
                            <Link
                                // href={product.payment_integration?.gateway_type === 'Custom' ?
                                // product.payment_integration?.custom_button_links[selectedBillingPeriod] : null}
                                  underline={'none'}
                                  target={'_blank'}
                            >
                                <Button
                                    variant={((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedButtonVariant : designButtonVariant).toLowerCase()}
                                    disableElevation
                                    onClick={(e) => handleStripePaymentIntegration(
                                        finalProducts,
                                        (isFreeTrialActive) ? "freeTrial" : "regular"
                                    )}
                                    sx={{
                                        height: 40,
                                        mt: 2,
                                        width: '100%',
                                        fontFamily: designFontStyle,
                                        bgcolor: (((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedButtonVariant : designButtonVariant) === 'Contained') ? ((designHighlightedProduct === selectedProduct.name)
                        ? designHighlightedButtonColor : designButtonColor) : '#ffffff',
                                        color: (((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedButtonVariant : designButtonVariant) === 'Contained') ? getContrastYIQ(((designHighlightedProduct === selectedProduct.name)
                        ? designHighlightedButtonColor : designButtonColor)) : ((designHighlightedProduct === selectedProduct.name)
                        ? designHighlightedButtonColor : designButtonColor),
                                        border: (((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedButtonVariant : designButtonVariant) === 'Outlined') ? `1px solid ${((designHighlightedProduct === selectedProduct.name)
                        ? designHighlightedButtonColor : designButtonColor)}` : 'none',
                                        textTransform: 'none',
                                        fontSize: 12,
                                        '&:hover': {
                                            border: (((designHighlightedProduct === selectedProduct.name)
                                        ? designHighlightedButtonVariant : designButtonVariant) === 'Outlined') ? `1px solid ${((designHighlightedProduct === selectedProduct.name)
                        ? designHighlightedButtonColor : designButtonColor)}` : 'none',
                                            bgcolor: (((designHighlightedProduct === selectedProduct.name)
                                                ? designHighlightedButtonVariant : designButtonVariant) === 'Contained') ? ((designHighlightedProduct === selectedProduct.name)
                                                ? designHighlightedButtonColor : designButtonColor) : '#ffffff',
                                            filter: 'brightness(90%)'
                                        },
                                        fontWeight: 600,
                                        p: 0.8,
                                        borderRadius: designButtonBorderRadius
                                    }}
                                >
                                    {(isFreeTrialActive) ?
                                        `Free ${selectedProduct.free_trial_integration.days}-Day Trial`
                                        : designButtonText
                                    }
                                </Button>
                            </Link>

                            {
                                (isFreeTrialActive && selectedProduct.free_trial_integration.skip_trial_option
                                    && !(selectedProduct.free_trial_integration.coupon.redeem_by < Math.floor(Date.now()/1000))
                                ) &&
                                <Grid container width={'100%'} justifyContent={'center'} pt={1}>
                                    <Typography
                                        fontSize={12}
                                        fontFamily={designFontStyle}
                                    >
                                        or
                                    </Typography>
                                    <Typography
                                        pl={0.5}
                                        fontFamily={designFontStyle}
                                        fontSize={12}
                                        fontWeight={600}
                                        style={{
                                            textDecoration: 'underline',
                                            color: (designHighlightedProduct === selectedProduct.name)
                                                ? designHighlightedButtonColor : designButtonColor
                                            }}
                                        onClick={(e) => handleStripePaymentIntegration(
                                            finalProducts,
                                            "skipTrial"
                                        )}
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: 12,
                                            '&:hover': {
                                                filter: 'brightness(130%)',
                                                cursor: 'pointer'
                                            },
                                        }}
                                    >
                                        Skip trial, get {selectedProduct.free_trial_integration.coupon.discount_percentage}% off
                                    </Typography>
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Alert,
    Autocomplete,
    Backdrop,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    Drawer,
    FormControl, Grid,
    IconButton,
    MenuItem,
    Paper, Slider,
    Snackbar, TextField,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { MuiColorInput } from 'mui-color-input';
import React, { useContext, useEffect, useState } from "react";
import { useErrorContext } from "state/ErrorDialogContext";
import { v4 as uuidv4 } from "uuid";
import { addDesign, updateDesign } from "../../apis/ClientDataService";
import { getCurrentOrgId } from "../../state/state";
import ScenarioDataContext from "../scenarios/ScenarioDataContext";
import { countries } from './CountryList';
import DesignPreview from "./DesignPreview";
import { ProductContext } from "./PackagingFlow";
import ProductActions from "./ProductActions";
import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {SortableFeatureItem} from "./SortableFeatureItem";
import OrganizationContext from "../../state/OrganizationContext";

const fonts = [
    'Open Sans',
    'Roboto',
    'IBM Plex Sans',
    'Inter',
    'Lexend Deca',
    'system-ui',
    'Montserrat',
    'Chakra Petch',
    'Helvetica',
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Gill Sans',
    'Times New Roman',
    'Georgia',
    'Palatino',
    'Baskerville',
    'Andalé Mono',
    'Courier',
    'Lucida',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS'
];


const shapes = ['Rounded', 'Rectangular', 'Capsule'];

const highlightTexts = ['None', 'Recommended', 'Best Deal', 'Most Popular'];

export default function DesignActions(props) {

    // console.log(props)

    const { scenario, updateScenario } = useContext(ScenarioDataContext);
    const { products, updateProducts } = useContext(ProductContext)

    const {limitationsRef} = useContext(OrganizationContext)
    const [limitationAddOn,setLimitationAddOn] = useState(true)
    const [limitationHideWatermark,setLimitationHideWatermark] = useState(true)
    useEffect(()=>{
        setLimitationAddOn(limitationsRef.current.add_on)
        setLimitationHideWatermark(limitationsRef.current.hide_watermark)
    },[])

    const { loadErrorDialog } = useErrorContext();

    const [name, setName] = useState(props.mode === "Edit" ? props.design.name : '');
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const [addOnSection, setAddOnSection] = useState(props.mode === "Edit" ? (props.design.add_on_section ?? false) : false);
    const [headerSection, setHeaderSection] = useState(props.mode === "Edit" ? (props.design.header_section ?? false) : false);
    const [headerText, setHeaderText] = useState(props.mode === "Edit" ? (props.design.properties.header_text ?? '') : '');
    const [subheaderText, setSubheaderText] = useState(props.mode === "Edit" ? (props.design.properties.subheader_text ?? '') : '');

    const [requiresLoggedInUsers, setRequiresLoggedInUsers] = useState(props.mode === "Edit" ? (props.design.requires_logged_in_users ?? false) : false);
    const handleRequireLoggedInUsersToggleChange = (event) => {
        setRequiresLoggedInUsers(event.target.checked);
    };

    const [loginRedirectLink, setLoginRedirectLink] = useState(props.mode === "Edit" ? props.design.login_redirect_link : '');
    const handleLoginRedirectLinkChange = (event) => {
        setLoginRedirectLink(event.target.value);
    };

    const [bgColor, setBgColor] = useState(props.mode === "Edit" ? props.design.properties.bg_color : '#ffffff')
    const handleBgColorChange = (color) => {
        setBgColor(color)
    }

    const [buttonColor, setButtonColor] = useState(props.mode === "Edit" ? props.design.properties.button_color : '#4f46e5')
    const handleButtonColorChange = (color) => {
        setButtonColor(color)
    }

    const [cardColor, setCardColor] = useState(props.mode === "Edit" ? props.design.properties.card_color : '#ffffff')
    const handleCardColorChange = (color) => {
        setCardColor(color)
    }

    const [highlightedButtonColor, setHighlightedButtonColor] = useState(props.mode === "Edit" ? props.design.properties.highlighted_button_color : '#4f46e5')
    const handleHighlightedButtonColorChange = (color) => {
        setHighlightedButtonColor(color)
    }

    const [highlightedCardColor, setHighlightedCardColor] = useState(props.mode === "Edit" ? props.design.properties.highlighted_card_color : '#ffffff')
    const handleHighlightedCardColorChange = (color) => {
        setHighlightedCardColor(color)
    }

    const [ctaButtonColor, setCtaButtonColor] = useState(props.mode === "Edit" ? props.design.properties.cta_button_color : '#29303d')
    const handleCtaButtonColorChange = (color) => {
        setCtaButtonColor(color)
    }

    const [ctaCardColor, setCtaCardColor] = useState(props.mode === "Edit" ? props.design.properties.cta_card_color : '#111827')
    const handleCtaCardColorChange = (color) => {
        setCtaCardColor(color)
    }

    const [cardBoxShadow, setCardBoxShadow] = useState(props.mode === "Edit" ? props.design.properties.card_box_shadow : 0)
    const handleCardBoxShadowChange = (shadow) => {
        setCardBoxShadow(shadow)
    }

    const [buttonBorderRadius, setButtonBorderRadius] = useState(props.mode === "Edit" ? props.design.properties.button_border_radius : 1)
    const handleButtonBorderRadiusChange = (radius) => {
        setButtonBorderRadius(radius)
    }

    const [cardBorderRadius, setCardBorderRadius] = useState(props.mode === "Edit" ? props.design.properties.card_border_radius : 5)
    const handleCardBorderRadiusChange = (radius) => {
        setCardBorderRadius(radius)
    }

    const [cardBorderColor, setCardBorderColor] = useState(props.mode === "Edit" ? props.design.properties.card_border_color : '#e5e7eb')
    const handleCardBorderColorChange = (color) => {
        setCardBorderColor(color)
    }

    const [ctaBorderColor, setCtaBorderColor] = useState(props.mode === "Edit" ? props.design.properties.cta_border_color : '#111827')
    const handleCtaBorderColorChange = (color) => {
        setCtaBorderColor(color)
    }

    const [highlightedPlanNameColor, setHighlightedPlanNameColor] = useState(props.mode === "Edit" ? props.design.properties.highlighted_plan_name_color : '#4f46e5')
    const handleHighlightedPlanNameColorChange = (color) => {
        setHighlightedPlanNameColor(color)
    }

    const [cardWidth, setCardWidth] = useState(props.mode === "Edit" ? props.design.properties.card_width : 350)
    const handleCardWidthChange = (width) => {
        setCardWidth(width)
    }

    const [ctaButtonLink, setCtaButtonLink] = useState(props.mode === "Edit" ? props.design.properties.cta_button_link : '')
    const handleCtaButtonLinkChange = (link) => {
        setCtaButtonLink(link)
    }

    const [tickColor, setTickColor] = useState(props.mode === "Edit" ? props.design.properties.tick_color : '#4f46e5')
    const handleTickColorChange = (color) => {
        setTickColor(color)
    }

    const [highlightedTickColor, setHighlightedTickColor] = useState(props.mode === "Edit" ? props.design.properties.highlighted_tick_color : '#4f46e5')
    const handleHighlightedTickColorChange = (color) => {
        setHighlightedTickColor(color)
    }

    const [ctaTickColor, setCtaTickColor] = useState(props.mode === "Edit" ? props.design.properties.cta_tick_color : '#ffffff')
    const handleCtaTickColorChange = (color) => {
        setCtaTickColor(color)
    }

    const [buttonVariant, setButtonVariant] = useState(props.mode === "Edit" ? props.design.properties.button_variant : 'Contained')
    const handleButtonVariantChange = (event) => {
        setButtonVariant(event.target.value)
    }

    const [highlightedButtonVariant, setHighlightedButtonVariant] = useState(props.mode === "Edit" ? props.design.properties.highlighted_button_variant : 'Contained')
    const handleHighlightedButtonVariantChange = (event) => {
        setHighlightedButtonVariant(event.target.value)
    }

    const [designStyleMode, setDesignStyleMode] = useState(props.mode === "Edit" ? props.design.properties.design_style_mode : 'Custom')
    const handleDesignStyleModeChange = (mode) => {
        setDesignStyleMode(mode)
    }

    const [fontStyle, setFontStyle] = useState(props.mode === "Edit" ? props.design.properties.font_style : fonts[3]);
    const handleFontStyleChange = (event) => {
        setFontStyle(event.target.value);
    };

    const [shape, setShape] = useState(props.mode === "Edit" ? props.design.properties.shape : shapes[0]);
    const handleShapeChange = (event) => {
        setShape(event.target.value);
    };

    const [highlightedProduct, setHighlightedProduct] = React.useState(() => {
        if (props.mode === 'Edit') {
            const highlightedProductObj = props.design.selected_products
                .find(product => product.id === props.design.properties.highlighted_product_id);
            return highlightedProductObj ? highlightedProductObj.name : 'None';
        } else {
            return 'None';
        }
    });

    const [highlightedText, setHighlightedText] = React.useState(
        props.mode === "Edit" ? props.design.properties.highlighted_text :
            'Most Popular'
    );

    const handleHighlightedProductChange = (event) => {
        setHighlightedProduct(event.target.value);
    };

    const handleHighlightedTextChange = (event) => {
        setHighlightedText(event.target.value);
    };

    const [buttonText, setButtonText] = React.useState('Subscribe');
    const handleButtonTextChange = (event) => {
        setButtonText(event.target.value);
    };

    const [currSelectedProduct, setCurrSelectedProduct] = React.useState("");

    const [selectedProducts, setSelectedProducts] = React.useState(
        props.mode === 'Edit' ? props.design.selected_products : []
    );
    const [searchResults, setSearchResults] = React.useState(
        props.mode === 'Edit'
            ? products.filter(product => !props.design.selected_products.some(selectedProduct => selectedProduct.id === product.id))
            : products
    );

    const handleAddProduct = (result) => {
        // console.log(result)
        let value = result
        if (typeof value !== 'string') {
            if (value) {
                setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, value]);
                setSearchResults((prevSearchResults) =>
                    prevSearchResults.filter((product) => product.name !== value.name)
                );
                setCurrSelectedProduct(value.name)
            }
        }
    };

    const handleDeleteProduct = (productToDelete) => () => {
        setSelectedProducts((prevSelectedProducts) =>
            prevSelectedProducts.filter((product) => product.name !== productToDelete.name)
        );
        if (productToDelete.name === highlightedProduct) {
            setHighlightedProduct("None")
        }
        setSearchResults((prevSearchResults) => [...prevSearchResults, productToDelete]);
    };

    const [ctaCheckValue, setCtaCheckValue] = React.useState(props.mode === "Edit" ? props.design.properties.cta : false);
    const handleCtaCheckChange = (event) => {
        setCtaCheckValue(event.target.checked)
        setCtaDescription("")
        setCtaName("")
        setCtaButtonText("")
        setChipData([])
    };

    const [ctaName, setCtaName] = useState(props.mode === "Edit" ? props.design.properties.cta_name : '');

    const [ctaDescription, setCtaDescription] = useState(props.mode === "Edit" ? props.design.properties.cta_description : '');

    const [ctaButtonText, setCtaButtonText] = useState(props.mode === "Edit" ? props.design.properties.cta_button_text : '');

    const [removeWatermark, setRemoveWatermark] = useState(props.mode === "Edit" ? props.design.properties.remove_watermark : 'No');


    const [chipData, setChipData] = useState(
        props.mode === "Edit"
            ? props.design.properties.cta_features
                .map((feature) => ({ id: uuidv4(), label: feature }))
            : []
    );

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const [inputValue, setInputValue] = useState('');
    const handleAdd = () => {
        if (inputValue.trim() !== '') {
            setChipData([...chipData, { id: uuidv4(), label: inputValue }]);
            setInputValue('');
        }
    };

    const [geoLocationCountriesAllowed, setGeoLocationCountriesAllowed] =
        useState(props.mode === "Edit" ?
            props.design.properties.geo_location_countries_allowed : ["Global"]);

    const CountrySelect = () => {

        const continents = Array.from(new Set(countries.map((c) => c.continent)));

        const handleChange = (event, newValue) => {
            setGeoLocationCountriesAllowed(newValue);
        };

        const options = ["Global", ...continents].concat(countries.map(c => c.country));

        return (
            <Autocomplete
                multiple
                id="country-select"
                options={options}
                value={geoLocationCountriesAllowed}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" size='small' label="Select Countries" />
                )}
            />
        );
    };


    const [loading, setLoading] = useState(false);

    const handleAddDesign = () => {
        setLoading(true)
        const properties = {
            "bg_color": bgColor,
            "button_color": buttonColor,
            "card_color": cardColor,
            "highlighted_button_color": highlightedButtonColor,
            "highlighted_card_color": highlightedCardColor,
            "cta_button_color": ctaButtonColor,
            "cta_card_color": ctaCardColor,
            "card_box_shadow": cardBoxShadow,
            "card_border_radius": cardBorderRadius,
            "button_border_radius": buttonBorderRadius,
            "card_border_color": cardBorderColor,
            "cta_border_color": ctaBorderColor,
            "highlighted_plan_name_color": highlightedPlanNameColor,
            "card_width": cardWidth,
            "cta_button_link": ctaButtonLink,
            "tick_color": tickColor,
            "highlighted_tick_color": highlightedTickColor,
            "cta_tick_color": ctaTickColor,
            "button_variant": buttonVariant,
            "highlighted_button_variant": highlightedButtonVariant,
            "design_style_mode": designStyleMode,
            "font_style": fontStyle,
            "shape": shape,
            "highlighted_product_id": selectedProducts
                .find((product) => product.name === highlightedProduct)?.id,
            "highlighted_text": highlightedText,
            "button_text": buttonText.trim(),
            "cta": ctaCheckValue,
            "cta_name": ctaName.trim(),
            "cta_description": ctaDescription.trim(),
            "cta_features": chipData.map((chip) => chip.label.trim()),
            "cta_button_text": ctaButtonText.trim(),
            "geo_location_countries_allowed": geoLocationCountriesAllowed,
            "header_text": headerText,
            "subheader_text": subheaderText,
            "remove_watermark": removeWatermark
        }
        const newDesign = {
            "name": name.trim(),
            "client_id": getCurrentOrgId(),
            "scenario_id": scenario.id,
            "selected_products": selectedProducts,
            "properties": properties,
            "login_redirect_link": loginRedirectLink,
            "requires_logged_in_users": requiresLoggedInUsers,
            "add_on_section": addOnSection,
            "header_section": headerSection,
        }

        addDesign(JSON.parse(JSON.stringify(newDesign)), (data) => {
            props.updateDesigns(data)
            props.handleSnackNotif("addDesign")
            props.closeActionsDesign()
            setLoading(false)
        }, (err) => {
            setLoading(false)
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to add design at the moment" });
        })
    }

    const handleEditDesignSaveClick = () => {

        setLoading(true);

        const properties = {
            "bg_color": bgColor,
            "button_color": buttonColor,
            "card_color": cardColor,
            "highlighted_button_color": highlightedButtonColor,
            "highlighted_card_color": highlightedCardColor,
            "cta_button_color": ctaButtonColor,
            "cta_card_color": ctaCardColor,
            "card_box_shadow": cardBoxShadow,
            "card_border_radius": cardBorderRadius,
            "button_border_radius": buttonBorderRadius,
            "card_border_color": cardBorderColor,
            "cta_border_color": ctaBorderColor,
            "highlighted_plan_name_color": highlightedPlanNameColor,
            "card_width": cardWidth,
            "cta_button_link": ctaButtonLink,
            "tick_color": tickColor,
            "highlighted_tick_color": highlightedTickColor,
            "cta_tick_color": ctaTickColor,
            "button_variant": buttonVariant,
            "highlighted_button_variant": highlightedButtonVariant,
            "design_style_mode": designStyleMode,
            "font_style": fontStyle,
            "shape": shape,
            "highlighted_product_id": selectedProducts
                .find((product) => product.name === highlightedProduct)?.id,
            "highlighted_text": highlightedText,
            "button_text": buttonText.trim(),
            "cta": ctaCheckValue,
            "cta_name": ctaName.trim(),
            "cta_description": ctaDescription.trim(),
            "cta_features": chipData.map((chip) => chip.label.trim()),
            "cta_button_text": ctaButtonText.trim(),
            "geo_location_countries_allowed": geoLocationCountriesAllowed,
            "header_text": headerText,
            "subheader_text": subheaderText,
            "remove_watermark": removeWatermark
        }
        const newDesign = Object.assign({}, props.design, {
            "name": name.trim(),
            "selected_products": selectedProducts,
            "properties": properties,
            "login_redirect_link": loginRedirectLink,
            "requires_logged_in_users": requiresLoggedInUsers,
            "add_on_section": addOnSection,
            "header_section": headerSection,
        });

        // console.log(newDesign)
        updateDesign(JSON.parse(JSON.stringify(newDesign)), (data) => {
            props.updateDesigns(props.designs.map(design => design.id === data.id ? data : design));
            props.handleSnackNotif("updateDesign")
            props.closeActionsDesign()
            setLoading(false);
        }, (err) => {
            setLoading(false);
            loadErrorDialog({ title: "Error", message: err?.error_message ?? "Unable to update design at the moment" });
        })
    }

    const validateForm = () => {
        return (
            name &&
            selectedProducts?.length &&
            buttonText?.trim() &&
            (
                !ctaCheckValue ||
                (
                    ctaName?.trim() &&
                    ctaDescription?.trim() &&
                    // chipData?.length &&
                    ctaButtonText?.trim() &&
                    ctaButtonLink?.trim()
                )
            ) &&
            (
                !requiresLoggedInUsers || loginRedirectLink?.length
            )

        );
    }

    const [activeProduct, setActiveProduct] = useState();
    const [snackNotif, setSnackNotif] = useState("");

    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }

    const [openAdd, setOpenAdd] = useState(false);
    const handleCreateProductClick = () => {
        setOpenAdd(!openAdd);
    };

    const [openEdit, setOpenEdit] = useState(false);
    const handleUpdateProductClick = () => {
        setOpenEdit(!openEdit);
    };

    const steps = ['General Settings', 'Style Settings'];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (snackNotif === "addProduct") {
            // Find the new product
            const newProduct = products.find(product =>
                !selectedProducts.some(selectedProduct => selectedProduct.id === product.id) &&
                !searchResults.some(searchResult => searchResult.id === product.id)
            );

            // Add the new product to selectedProducts
            if (newProduct) {
                setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, newProduct]);
            }
        }
        if (snackNotif === "updateProduct") {
            // Update selectedProducts
            setSelectedProducts(prevSelectedProducts => {
                return prevSelectedProducts
                    .map(selectedProduct => {
                        return products.find(product => product.id === selectedProduct.id) || null;
                    })
                    .filter(product => product !== null);
            });

            // Update searchResults
            setSearchResults(products.filter(product => !selectedProducts.some(selectedProduct => selectedProduct.id === product.id)));
        }
    }, [snackNotif]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    )

    return (
        <Grid container sx={{ width: '100vw', pl: 4, pr: 4, pt: 2 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container justifyContent='space-between' alignItems='center' pb={1}>
                <Grid container item width={'40%'} gap={0.5}>
                    <IconButton
                        onClick={props.closeActionsDesign}
                        sx={{
                            '&:hover': {
                                bgcolor: 'primary.background',
                            },
                        }}
                    >
                        <ClearIcon fontSize="medium" />
                    </IconButton>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Typography sx={{
                        color: "primary.main",
                        fontSize: 24,
                        fontWeight: "bold",
                        pl: 1
                    }}>
                        {props.mode} Design
                    </Typography>
                </Grid>

                <Grid item container width={'50%'} justifyContent={'flex-end'}>
                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{ mt: 0 }}
                        onClick={props.closeActionsDesign}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!validateForm()}
                        variant="outlined"
                        size="medium"
                        onClick={props.mode === "Add" ? handleAddDesign : handleEditDesignSaveClick}
                        sx={{ ml: 1, mt: 0 }}
                    >
                        Save {props.mode === "Add" && 'and Add Design'}
                    </Button>
                </Grid>
            </Grid>
            <Grid container item sx={{ borderTop: '1px solid lightgray', py: 2, pl: 2 }}
                justifyContent='space-between' rowGap={3}>
                <Grid container direction='column' width='60%' gap={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                        Design Preview
                    </Typography>

                    <Paper elevation={6}>
                        {selectedProducts.length ?
                            <Grid container item sx={{
                                height: '75vh',
                                borderRadius: 1,
                                overflow: 'auto',
                            }}>
                                <DesignPreview
                                    products={selectedProducts}
                                    notification={snackNotif}
                                    designBgColor={bgColor}
                                    designButtonColor={buttonColor}
                                    designHighlightedButtonColor={highlightedButtonColor}
                                    designHighlightedCardColor={highlightedCardColor}
                                    designCtaButtonColor={ctaButtonColor}
                                    designCtaCardColor={ctaCardColor}
                                    designButtonText={buttonText}
                                    designCardColor={cardColor}
                                    designCardBoxShadow={cardBoxShadow}
                                    designButtonBorderRadius={buttonBorderRadius}
                                    designCardBorderRadius={cardBorderRadius}
                                    designCardBorderColor={cardBorderColor}
                                    designCtaBorderColor={ctaBorderColor}
                                    designHighlightedPlanNameColor={highlightedPlanNameColor}
                                    designCardWidth={cardWidth}
                                    designCtaButtonLink={ctaButtonLink}
                                    designTickColor={tickColor}
                                    designHighlightedTickColor={highlightedTickColor}
                                    designCtaTickColor={ctaTickColor}
                                    designButtonVariant={buttonVariant}
                                    designHighlightedButtonVariant={highlightedButtonVariant}
                                    designStyleMode={designStyleMode}
                                    designFontStyle={fontStyle}
                                    designShape={shape}
                                    designHighlightedProduct={highlightedProduct}
                                    designHighlightedText={highlightedText}
                                    designCta={ctaCheckValue}
                                    designCtaName={ctaName}
                                    designCtaDescription={ctaDescription}
                                    designCtaButtonText={ctaButtonText}
                                    designCtaFeatures={chipData.map((chip) => chip.label.trim())}
                                    loginRedirectLink={loginRedirectLink}
                                    requiresLoggedInUsers={requiresLoggedInUsers}
                                    addOnSection={addOnSection}
                                    headerSection={headerSection}
                                    designHeaderText={headerText}
                                    designSubheaderText={subheaderText}
                                    removeWatermark={removeWatermark}
                                    addOnProducts={products.filter(product => {return product.add_on_product})}
                                />

                            </Grid>
                            :
                            <Grid container justifyContent={'center'} alignItems={'center'}
                                sx={{
                                    height: '75vh',
                                    borderRadius: 1,
                                    overflow: 'auto',
                                }}
                            >
                                <Typography color={'primary.greyText'}>
                                    Select some products to display over here.
                                </Typography>
                            </Grid>
                        }
                    </Paper>

                </Grid>
                <Grid container direction={'column'}
                    sx={{
                        width: '38%',
                        borderLeft: "1px solid lightgray",
                        pl: 2
                    }}
                >
                    {/*<Typography sx={{ fontWeight: 600, fontSize: 14, width: '100%' }}>*/}
                    {/*    Design Settings*/}
                    {/*</Typography>*/}
                    <Grid container direction='column'
                        gap={2}
                    >
                        {/*<Stepper activeStep={activeStep}>*/}
                        {/*    {steps.map((label, index) => {*/}
                        {/*        return (*/}
                        {/*            <Step key={label} >*/}
                        {/*                <StepLabel>*/}
                        {/*                    <Typography fontSize={14} fontWeight={600}*/}
                        {/*                                pr={label === 'Style Settings' ? 2 : 0 }*/}
                        {/*                    >*/}
                        {/*                        {label}*/}
                        {/*                    </Typography>*/}
                        {/*                </StepLabel>*/}
                        {/*            </Step>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</Stepper>*/}
                        {activeStep === steps.length ? (
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - Click on Save above
                                </Typography>
                            </Grid>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 ?
                                    <Grid container direction={'column'}
                                        wrap="nowrap"
                                        sx={{
                                            pr: 2,
                                            pb: 2,
                                            height: '75vh',
                                            borderBottom: '1px solid lightgray',
                                            overflowY: 'scroll',
                                            "&::-webkit-scrollbar": {
                                                width: 7,
                                                // bgcolor: ''
                                            },
                                            "&::-webkit-scrollbar-thumb": {
                                                backgroundColor: 'lightgray',
                                                outline: "1px solid lightgray",
                                                borderRadius: 4
                                            }
                                        }}
                                    >
                                        <Grid container direction='column' gap={2} >
                                            <Typography sx={{ fontWeight: 600, fontSize: 14, width: '100%' }}>
                                                General Settings
                                            </Typography>
                                            <TextField
                                                sx={{
                                                    width: '100%'
                                                }}
                                                size={'small'}
                                                label="Design Name"
                                                value={name}
                                                onChange={handleNameChange}
                                            />
                                            {/*<Grid container alignItems={'center'} width={'100%'}>*/}
                                            {/*    /!*<LocationOnIcon/>*!/*/}
                                            {/*    /!*<Typography sx={{ fontWeight: 600, fontSize: 14,*!/*/}
                                            {/*    /!*    mt:0.5*!/*/}
                                            {/*    /!*}}>*!/*/}
                                            {/*    /!*    Location :*!/*/}
                                            {/*    /!*</Typography>*!/*/}
                                            {/*    /!*<Typography sx={{ fontWeight: 600, fontSize: 14,*!/*/}
                                            {/*    /!*    mt:0.5*!/*/}
                                            {/*    /!*}}>*!/*/}
                                            {/*    /!*    &nbsp;*!/*/}
                                            {/*    /!*    <GeoLocator/>*!/*/}
                                            {/*    /!*</Typography>*!/*/}


                                            {/*</Grid>*/}
                                            {/*<Typography sx={{ fontWeight: 600, fontSize: 14, width: '100%' }}>*/}
                                            {/*    Countries Allowed*/}
                                            {/*</Typography>*/}
                                            {/*<CountrySelect/>*/}
                                            {/*<Typography sx={{ fontWeight: 600, fontSize: 14, width: '100%' }}>*/}
                                            {/*    Payment Integration*/}
                                            {/*</Typography>*/}
                                            {/*<Grid container justifyContent={'space-between'}>*/}
                                            {/*    <TextField*/}
                                            {/*        select*/}
                                            {/*        sx={{*/}
                                            {/*            width: '48%'*/}
                                            {/*        }}*/}
                                            {/*        size={'small'}*/}
                                            {/*        label="Payment Gateway"*/}
                                            {/*        // value={name}*/}
                                            {/*        // onChange={handleNameChange}*/}
                                            {/*    >*/}
                                            {/*        {gateways.map((gateway) => (*/}
                                            {/*            <MenuItem key={gateway} value={gateway}>*/}
                                            {/*                {gateway}*/}
                                            {/*            </MenuItem>*/}
                                            {/*        ))}*/}
                                            {/*    </TextField>*/}
                                            {/*    <TextField*/}
                                            {/*        sx={{*/}
                                            {/*            width: '48%'*/}
                                            {/*        }}*/}
                                            {/*        size={'small'}*/}
                                            {/*        label="API Key"*/}
                                            {/*        // value={name}*/}
                                            {/*        // onChange={handleNameChange}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                Product Selection
                                            </Typography>
                                            <TextField
                                                width={'100%'}
                                                select
                                                size='small'
                                                labelId="product-select-label"
                                                id="product-select"
                                                label="Select Product"
                                                value={currSelectedProduct}
                                            >
                                                <MenuItem
                                                    onClick={handleCreateProductClick}
                                                    value={'ADD_PRODUCT'}
                                                    sx={{
                                                        '&.MuiMenuItem-root': {
                                                            backgroundColor: 'primary.main', color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <Grid container >
                                                        <AddIcon mr={1} />
                                                        <Typography pl={1}>Add Product</Typography>
                                                    </Grid>
                                                </MenuItem>
                                                {searchResults.map((result) => (
                                                    <MenuItem
                                                        value={result}
                                                        onClick={() => handleAddProduct(result)}
                                                    >
                                                        {result.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {selectedProducts.length ?
                                                <Grid container item gap={1} mt={-1}>
                                                    {selectedProducts.map((product) => (
                                                        <Chip
                                                            key={product.name}
                                                            label={
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography pr={2}>
                                                                        {product.name}
                                                                    </Typography>
                                                                    <EditIcon fontSize="small"
                                                                        onClick={(e) => {
                                                                            setActiveProduct(product)
                                                                            handleUpdateProductClick()
                                                                        }}
                                                                        sx={{
                                                                            color: '#aeaeae',
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                color: '#818589'
                                                                            }
                                                                        }}
                                                                    />
                                                                </Box>
                                                            }
                                                            deleteIcon={<DeleteIcon sx={{
                                                                '&:hover': {
                                                                    color: '#818589'
                                                                }
                                                            }} />}
                                                            onDelete={handleDeleteProduct(product)}
                                                        />
                                                    ))}
                                                </Grid>
                                                :
                                                <Alert severity="info" sx={{ fontSize: 12, mt: -1 }}>
                                                    Select your first product from the above dropdown menu.
                                                </Alert>
                                            }
                                            <Grid container item alignItems={'center'} ml={0} mt={-1}>
                                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                    Add header section
                                                </Typography>
                                                <Checkbox
                                                    checked={headerSection}
                                                    onChange={(e)=>setHeaderSection(e.target.checked)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                {headerSection &&
                                                    <Grid container direction='column' gap={2} pt={1}>
                                                        <TextField
                                                            size='small'
                                                            label="Header"
                                                            variant="outlined"
                                                            multiline
                                                            rows={2}
                                                            value={headerText}
                                                            onChange={(e) => setHeaderText(e.target.value)}
                                                        />

                                                        <TextField
                                                            size='small'
                                                            label="Subheader"
                                                            variant="outlined"
                                                            multiline
                                                            rows={2}
                                                            value={subheaderText}
                                                            onChange={(e) => setSubheaderText(e.target.value)}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container item alignItems={'center'} ml={0} mt={-1}>
                                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                    Add add-on section
                                                </Typography>
                                                <Checkbox

                                                    // LIMITATION ADD-ON FROM DESIGN
                                                    disabled={!limitationAddOn}

                                                    checked={addOnSection}
                                                    onChange={(e)=>setAddOnSection(e.target.checked)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Grid>

                                            <Grid container item alignItems={'center'} ml={0} mt={-1}>
                                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                    Require logged in users
                                                </Typography>
                                                <Checkbox
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    checked={requiresLoggedInUsers}
                                                    onChange={handleRequireLoggedInUsersToggleChange}
                                                />
                                            </Grid>
                                            {/*<Typography sx={{ fontWeight: 600, fontSize: 14 }}>*/}
                                            {/*    User login page*/}
                                            {/*</Typography>*/}
                                            {requiresLoggedInUsers &&
                                                <Grid container gap={1} mt={-1}>
                                                    <TextField
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                        size={'small'}
                                                        label="User Login Page URL"
                                                        value={loginRedirectLink}
                                                        onChange={handleLoginRedirectLinkChange}
                                                    />
                                                    <Alert severity={'info'} sx={{width:'100%'}}>
                                                        Customer will be redirected to this page if not logged in.
                                                    </Alert>
                                                </Grid>
                                            }
                                        </Grid>

                                        <Grid item container mt={0} gap={1} py={2}>

                                            {/*<Grid container item alignItems={'center'} ml={0}>*/}
                                            {/*    <Typography sx={{ fontWeight: 600, fontSize: 14 }}>*/}
                                            {/*        Enable "Start Free Trial" Button Text*/}
                                            {/*    </Typography>*/}
                                            {/*    <Checkbox*/}
                                            {/*        // checked={ctaCheckValue}*/}
                                            {/*        // onChange={handleCtaCheckChange}*/}
                                            {/*        inputProps={{ 'aria-label': 'controlled' }}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}

                                            <Grid container item alignItems={'center'} ml={0} mt={-1}>
                                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                    Add custom plan with call-to-actions (CTA) button
                                                </Typography>
                                                <Checkbox
                                                    checked={ctaCheckValue}
                                                    onChange={handleCtaCheckChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Grid>

                                            {ctaCheckValue &&
                                                <Grid container direction='column' gap={2}>
                                                    <Grid container justifyContent={'space-between'}>
                                                        <TextField
                                                            sx={{ width: '48%' }}
                                                            size='small'
                                                            label="Plan Name"
                                                            variant="outlined"
                                                            value={ctaName}
                                                            onChange={(e) => setCtaName(e.target.value)}
                                                        />
                                                        <TextField
                                                            sx={{ width: '48%' }}
                                                            size='small'
                                                            label="Button Text"
                                                            variant="outlined"
                                                            value={ctaButtonText}
                                                            onChange={(e) => setCtaButtonText(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <TextField
                                                        size='small'
                                                        label="Button Link"
                                                        variant="outlined"
                                                        value={ctaButtonLink}
                                                        onChange={(e) => setCtaButtonLink(e.target.value)}
                                                    />
                                                    <TextField
                                                        size='small'
                                                        label="Description"
                                                        variant="outlined"
                                                        multiline
                                                        rows={2}
                                                        value={ctaDescription}
                                                        onChange={(e) => setCtaDescription(e.target.value)}
                                                    />
                                                    <Grid container justifyContent='space-between' alignItems='center'>
                                                        <TextField
                                                            sx={{ width: '100%' }}
                                                            size='small'
                                                            label="Feature"
                                                            placeholder={'Type and press enter'}
                                                            value={inputValue}
                                                            onChange={(e) => setInputValue(e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    handleAdd()
                                                                }
                                                            }}
                                                        />
                                                        {chipData.length > 0 ?
                                                            // <Grid container item gap={1} mt={1}>
                                                            //     {chipData.map((data) => (
                                                            //         <Chip
                                                            //             key={data.key}
                                                            //             label={data.label}
                                                            //             onDelete={handleDelete(data)}
                                                            //         />
                                                            //     ))}
                                                            // </Grid>
                                                            <>
                                                                <DndContext
                                                                    collisionDetection={closestCenter}
                                                                    sensors={sensors}
                                                                    onDragEnd={(event)=>{

                                                                        // console.log("Drag end called");
                                                                        const {active, over} = event;
                                                                        // console.log("ACTIVE: " + active.id);
                                                                        // console.log("OVER :" + over.id);

                                                                        if(active.id !== over.id) {
                                                                            setChipData((items) => {
                                                                                const activeIndex = items.findIndex((item) => item.id === active.id);
                                                                                const overIndex = items.findIndex((item) => item.id === over.id);
                                                                                // console.log(arrayMove(items, activeIndex, overIndex));
                                                                                return arrayMove(items, activeIndex, overIndex);
                                                                            });

                                                                        }
                                                                    }}
                                                                >
                                                                    <Grid container direction={'column'} mt={2}>
                                                                        <SortableContext
                                                                            items={chipData}
                                                                            strategy={verticalListSortingStrategy}
                                                                        >
                                                                            {chipData.map(data =>
                                                                                <SortableFeatureItem
                                                                                    features={chipData}
                                                                                    setFeatures={setChipData}
                                                                                    label={data.label}
                                                                                    id={data.id}
                                                                                />
                                                                                )
                                                                            }
                                                                        </SortableContext>
                                                                    </Grid>
                                                                </DndContext>
                                                                <Alert severity="info" sx={{ fontSize: 12, mt: 1 }}>
                                                                    Drag and drop to change order of the features.
                                                                    You can also edit/delete a feature. Press enter to save after editing.
                                                                </Alert>
                                                            </>
                                                            :
                                                            <Alert severity="info" sx={{ fontSize: 12, mt: 1, width: '100%' }}>
                                                                Please add your first feature.
                                                            </Alert>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item container gap={2}
                                            direction={'column'}
                                            wrap="nowrap"
                                            sx={{
                                                pr: 2,
                                                pb: 2,
                                                height: '75vh',
                                                borderBottom: '1px solid lightgray',
                                                overflowY: 'scroll',
                                                "&::-webkit-scrollbar": {
                                                    width: 7,
                                                    // bgcolor: ''
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    backgroundColor: 'lightgray',
                                                    outline: "1px solid lightgray",
                                                    borderRadius: 4
                                                }
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: 600, fontSize: 14, width: '100%' }}>
                                                Style Settings
                                            </Typography>
                                            <Grid container justifyContent={'space-between'} gap={2}>
                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    value={bgColor}
                                                    label="Background Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleBgColorChange}
                                                />
                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    value={buttonColor}
                                                    label="Button Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleButtonColorChange}
                                                />
                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    value={cardColor}
                                                    label="Card Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleCardColorChange}
                                                />
                                                <MuiColorInput
                                                    sx={{ width: '45%' }}
                                                    value={cardBorderColor}
                                                    label="Border Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#000000"
                                                    isAlphaHidden
                                                    onChange={handleCardBorderColorChange}
                                                />
                                                {/*<FormControl*/}
                                                {/*    sx={{*/}
                                                {/*        width: '45%'*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    /!*<InputLabel id="shape-select-label">Button Shape</InputLabel>*!/*/}
                                                {/*    <TextField*/}
                                                {/*        select*/}
                                                {/*        size={'small'}*/}
                                                {/*        labelId="shape-select-label"*/}
                                                {/*        id="shape-select"*/}
                                                {/*        label="Button Shape"*/}
                                                {/*        value={shape}*/}
                                                {/*        onChange={handleShapeChange}*/}
                                                {/*    >*/}
                                                {/*        {shapes.map((shape) => (*/}
                                                {/*            <MenuItem key={shape} value={shape}>*/}
                                                {/*                {shape}*/}
                                                {/*            </MenuItem>*/}
                                                {/*        ))}*/}
                                                {/*    </TextField>*/}
                                                {/*</FormControl>*/}

                                                <Grid item container sx={{ width: '45%', mt: -1 }}>
                                                    <Typography id="button-border-radius-slider" fontSize={12} fontWeight={600}>
                                                        Button Border Radius
                                                    </Typography>
                                                    <Slider
                                                        aria-labelledby="button-border-radius-slider"
                                                        step={1}
                                                        min={0}
                                                        max={7}
                                                        defaultValue={props.mode === "Edit" ? props.design.properties.button_border_radius : 1}
                                                        onChange={(event, newValue) => handleButtonBorderRadiusChange(newValue)}
                                                    />
                                                </Grid>

                                                <FormControl
                                                    sx={{ width: '45%' }}
                                                >
                                                    <TextField
                                                        select
                                                        size={'small'}
                                                        labelId="buttonVariant-select-label"
                                                        id="buttonVariant-select"
                                                        label="Button Variant"
                                                        value={buttonVariant}
                                                        onChange={handleButtonVariantChange}
                                                    >
                                                        {['Contained', 'Outlined'].map((variant) => (
                                                            <MenuItem key={variant} value={variant}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>

                                                <FormControl
                                                    sx={{
                                                        width: '45%'
                                                    }}>
                                                    {/*<InputLabel id="font-select-label">Font Style</InputLabel>*/}
                                                    <TextField
                                                        select
                                                        size={'small'}
                                                        labelId="font-select-label"
                                                        id="font-select"
                                                        label="Font Style"
                                                        value={fontStyle}
                                                        onChange={handleFontStyleChange}
                                                    >
                                                        {fonts.map((font) => (
                                                            <MenuItem key={font} value={font}>
                                                                {font}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                                <TextField
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    size={'small'}
                                                    label="Button Text"
                                                    value={buttonText}
                                                    onChange={handleButtonTextChange}
                                                />

                                                <Grid item container sx={{ width: '45%', mt: -1 }}
                                                >
                                                    <Typography id="card-width-slider" fontSize={12} fontWeight={600}>
                                                        Card Width
                                                    </Typography>
                                                    <Slider
                                                        aria-labelledby="card-width-slider"
                                                        // valueLabelDisplay="auto"
                                                        step={10}
                                                        min={250}
                                                        max={500}
                                                        defaultValue={props.mode === "Edit" ? props.design.properties.card_width : 350}
                                                        onChange={(event, newValue) => handleCardWidthChange(newValue)}
                                                    />
                                                </Grid>

                                                <Grid item container sx={{ width: '45%', mt: -1 }}>
                                                    <Typography id="card-box-shadow-slider" fontSize={12} fontWeight={600}>
                                                        Card Box Shadow
                                                    </Typography>
                                                    <Slider
                                                        aria-labelledby="card-box-shadow-slider"
                                                        step={1}
                                                        min={0}
                                                        max={10}
                                                        defaultValue={props.mode === "Edit" ? props.design.properties.card_box_shadow : 0}
                                                        onChange={(event, newValue) => handleCardBoxShadowChange(newValue)}
                                                    />
                                                </Grid>

                                                <Grid item container sx={{ width: '45%', mt: -1 }}>
                                                    <Typography id="card-border-radius-slider" fontSize={12} fontWeight={600}>
                                                        Card Border Radius
                                                    </Typography>
                                                    <Slider
                                                        aria-labelledby="card-border-radius-slider"
                                                        step={1}
                                                        min={0}
                                                        max={20}
                                                        defaultValue={props.mode === "Edit" ? props.design.properties.card_border_radius : 5}
                                                        onChange={(event, newValue) => handleCardBorderRadiusChange(newValue)}
                                                    />
                                                </Grid>

                                                <MuiColorInput
                                                    sx={{ width: '45%' }}
                                                    value={tickColor}
                                                    label="Tick Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#000000"
                                                    isAlphaHidden
                                                    onChange={handleTickColorChange}
                                                />

                                                <FormControl
                                                    sx={{
                                                        width: '45%'
                                                    }}>
                                                    {/*<InputLabel id="product-select-label">Highlighted Product</InputLabel>*/}
                                                    <TextField
                                                        disabled={selectedProducts.length === 0}
                                                        select
                                                        size={'small'}
                                                        labelId="product-select-label"
                                                        id="product-select"
                                                        label="Highlighted Product"
                                                        value={highlightedProduct}
                                                        onChange={handleHighlightedProductChange}
                                                    >
                                                        <MenuItem value="None">None</MenuItem>
                                                        {selectedProducts.map((product) => (
                                                            <MenuItem key={product.name} value={product.name}>
                                                                {product.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                                <FormControl
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                >
                                                    {/*<InputLabel id="text-select-label">Highlighted Text</InputLabel>*/}
                                                    <TextField
                                                        disabled={highlightedProduct === 'None'}
                                                        select
                                                        size={'small'}
                                                        labelId="text-select-label"
                                                        id="text-select"
                                                        label="Highlighted Text"
                                                        value={highlightedText}
                                                        onChange={handleHighlightedTextChange}
                                                    >
                                                        {highlightTexts.map((text) => (
                                                            <MenuItem key={text} value={text}>
                                                                {text}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    disabled={highlightedProduct === 'None'}
                                                    value={highlightedCardColor}
                                                    label="Highlighted Card Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleHighlightedCardColorChange}
                                                />

                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    disabled={highlightedProduct === 'None'}
                                                    value={highlightedButtonColor}
                                                    label="Highlighted Button Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleHighlightedButtonColorChange}
                                                />

                                                <FormControl
                                                    sx={{ width: '45%' }}
                                                >
                                                    <TextField
                                                        select
                                                        size={'small'}
                                                        labelId="highlighted-buttonVariant-select-label"
                                                        id="highlighted-buttonVariant-select"
                                                        label="Highlighted Button Variant"
                                                        value={highlightedButtonVariant}
                                                        onChange={handleHighlightedButtonVariantChange}
                                                    >
                                                        {['Contained', 'Outlined'].map((variant) => (
                                                            <MenuItem key={variant} value={variant}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>

                                                <MuiColorInput
                                                    sx={{ width: '45%' }}
                                                    disabled={highlightedProduct === 'None'}
                                                    value={highlightedTickColor}
                                                    label="Highlighted Tick Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#000000"
                                                    isAlphaHidden
                                                    onChange={handleHighlightedTickColorChange}
                                                />

                                                <MuiColorInput
                                                    sx={{ width: '45%' }}
                                                    disabled={highlightedProduct === 'None'}
                                                    value={highlightedPlanNameColor}
                                                    label="Highlighted Name Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#000000"
                                                    isAlphaHidden
                                                    onChange={handleHighlightedPlanNameColorChange}
                                                />

                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    disabled={!ctaCheckValue}
                                                    value={ctaCardColor}
                                                    label="CTA Card Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleCtaCardColorChange}
                                                />
                                                <MuiColorInput
                                                    sx={{
                                                        width: '45%'
                                                    }}
                                                    disabled={!ctaCheckValue}
                                                    value={ctaButtonColor}
                                                    label="CTA Button Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#ffffff"
                                                    isAlphaHidden
                                                    onChange={handleCtaButtonColorChange}
                                                />

                                                <MuiColorInput
                                                    sx={{ width: '45%' }}
                                                    disabled={!ctaCheckValue}
                                                    value={ctaBorderColor}
                                                    label="CTA Border Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#000000"
                                                    isAlphaHidden
                                                    onChange={handleCtaBorderColorChange}
                                                />

                                                <MuiColorInput
                                                    sx={{ width: '45%' }}
                                                    disabled={!ctaCheckValue}
                                                    value={ctaTickColor}
                                                    label="CTA Tick Color"
                                                    size='small'
                                                    format="hex"
                                                    fallbackValue="#000000"
                                                    isAlphaHidden
                                                    onChange={handleCtaTickColorChange}
                                                />

                                                <FormControl
                                                    sx={{ width: '45%' }}
                                                >
                                                    <TextField
                                                        select
                                                        size={'small'}
                                                        labelId="Remove Watermark-select-label"
                                                        id="Remove Watermark-select"
                                                        label="Hide PriceOps Watermark"
                                                        value={removeWatermark}
                                                        onChange={(e)=>{
                                                            let type = e.target.value
                                                            if(type === "Yes"){

                                                                // LIMITATION PRICEOPS WATERMARK
                                                                if(limitationHideWatermark){
                                                                    setRemoveWatermark(e.target.value)
                                                                }
                                                            }else{
                                                                setRemoveWatermark(e.target.value)
                                                            }
                                                        }}
                                                    >
                                                        {['No', 'Yes'].map((variant) => (
                                                            <MenuItem key={variant} value={variant}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>
                                }

                                <Box sx={{ display: 'flex', flexDirection: 'row', }}>

                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {activeStep === 1 ?
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            // sx={{ mr: 1 }}
                                            variant="outlined"
                                        >
                                            Back
                                        </Button>
                                    :
                                        <Button
                                            onClick={handleNext}
                                            variant="outlined"
                                            disabled={activeStep === 1}
                                        >
                                            Next
                                        </Button>
                                    }
                                </Box>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {/*CREATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openAdd} onClose={() => setOpenAdd(false)}>
                <ProductActions
                    mode={'Add'}
                    closeActionsProduct={handleCreateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*UPDATE PRODUCT DRAWER*/}
            <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
                <ProductActions
                    mode={'Edit'}
                    product={activeProduct}
                    closeActionsProduct={handleUpdateProductClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*ADD PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "addProduct"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product added successfully!
                </Alert>
            </Snackbar>

            {/*UPDATE PRODUCT NOTIFICATION*/}
            <Snackbar open={snackNotif === "updateProduct"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Product updated successfully!
                </Alert>
            </Snackbar>
        </Grid>
    )
}

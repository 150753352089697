import React from "react";

import { Grid, InputAdornment, Button, Alert, Typography, Box, CircularProgress, TextField, Backdrop } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { forgotPassword } from "../../apis/ClientDataService";
import { validateEmail } from "../../utils/Utils";
import EmailIcon from '@mui/icons-material/Email';

export default function ForgotPasswordForm(props) {
    const [email, setEmail] = React.useState(props?.email);
    const [emailSent, setEmailSent] = React.useState(null);
    const [loading, setLoading] = React.useState(null);

    function validateForm() {
        return validateEmail(email);
    }

    function handleForgotPassword(event) {
        event.preventDefault();

        setEmailSent(null);
        setLoading(true);
        forgotPassword(email, () => {
            setLoading(false);
            setEmailSent("sent");
        }, err => {
            setLoading(false);
            setEmailSent("notSent");
        });
    }

    return (
        // <form onSubmit={handleForgotPassword}>
            <Grid container item direction="column" pt={3} gap={1}>
                {
                    loading && <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }

                <React.Fragment>
                    <Typography sx={{ fontSize: 12 }}>Please enter your email address.</Typography>
                    <TextField
                        sx={{ color: "white" }}
                        variant="outlined"
                        size={'small'}
                        fullWidth
                        margin="dense"
                        label="Email"
                        id="login-id"
                        aria-describedby="email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {emailSent === 'sent' &&
                        <Alert sx={{my: 1}} onClose={() => setEmailSent(null)} severity="success">
                            Password reset email sent to {email}
                        </Alert>
                    }

                    {emailSent === 'notSent' &&
                        <Alert sx={{ my: 1 }} onClose={() => setEmailSent(null)} severity="error">
                            Unable to send reset email to {email}. Check your email address or try again later.
                        </Alert>
                    }

                    <Button
                        block
                        sx={{ mt: 1, py:2.7,
                            '&.Mui-disabled': {
                                color: 'white',
                                backgroundColor: 'primary.main',
                            },
                        }}
                        type="submit"
                        variant="contained"
                        autoFocus
                        disabled={!validateForm()}
                        onClick={(e) => handleForgotPassword(e)}
                    >
                        Send Password Reset Email
                    </Button>

                    <Grid container justifyContent={'center'} pt={1}>
                        <Typography fontSize={12}>Go back to </Typography>
                        <Typography
                            pl={0.5}
                            fontWeight={800}
                            fontSize={12}
                            sx={{ color: "primary.main", textTransform: "none", cursor: 'pointer' }}
                            onClick={() => props.backToLogin()}
                        >Log in</Typography>
                    </Grid>
                </React.Fragment>
            </Grid>
        // </form>
    )
}
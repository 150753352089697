import React from "react";
import ConstructionIcon from '@mui/icons-material/Construction';
import { Grid, Typography } from "@mui/material";

export default function UnderConstruction() {

    return <Grid container item justifyContent="center" alignItems="center" sx={{ p: 5 }}>
        <ConstructionIcon sx={{ width: 50, height: 50, mr: 2 }}></ConstructionIcon>
        <Typography>Under Construction...</Typography>
    </Grid>
}
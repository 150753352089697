import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Chip, Grid, Typography } from "@mui/material";
import ScenarioDataContext from 'pages/scenarios/ScenarioDataContext';
import React from "react";
import { currencyFormatter } from "utils/Utils";
import DeltaLabel from './DeltaLabel';
import PercentageDeltaLabel from './PercentageDeltaLabel';
import PriceLabel from './PriceLabel';

export default function PricePoint(props) {
    const { scenario } = React.useContext(ScenarioDataContext);
    const pricePoint = props.pricePoint;
    const w = props.width;

    const styles = {
        keys: {
            width: "100%",
            fontSize: 14,
            color: "primary.main",
            m: 0,
            pr: 2,
            textAlign: "center"
        },
        values: {
            width: "100%",
            fontSize: 16,
            color: "primary.sub",
            p: 0,
            m: 0,
            textAlign: "left"
        },
        centered: {
            width: "100%",
            textAlign: "center"
        }
    }

    const getSegmentNameById = (segmentId) => {
        const segment = scenario["params"]["segment_params"].find(x => String(x.id) === String(segmentId));
        return segment["segment_name"];
    }

    const formatOtherProperty = (property) => {
        if (property.type === "CURRENCY") return currencyFormatter(property.value);
        else return property.vaulue;
    }

    const getBoxStyle = () => {
        const style = {
            width: w,
            boxShadow: 3,
            borderRadius: 2,
            pt: 2,
            backgroundColor: "primary.bg2"
        }
        if (pricePoint["error"]) {
            style.opacity = 0.6;
            style.backgroundColor = "primary.disabled";
        }
        return style;
    }


    return (<React.Fragment>
        {pricePoint &&
            <Grid sx={getBoxStyle()}>
                <Grid container justifyContent="flex-end" sx={{ pr: 2 }}>
                    <Chip label={getSegmentNameById(pricePoint["segment_id"])} />
                </Grid>
                {pricePoint["error"] && <Alert sx={{ mt: 1 }} severity="error">{pricePoint["error"]["message"]}</Alert>}
                <Grid>
                    <Typography sx={[styles.centered, { fontSize: 14, pt: 2 }]}>Price</Typography>
                    <PriceLabel pricePoint={pricePoint} />
                    <Typography sx={[styles.centered, { fontSize: 30, fontWeight: "bold", pb: 2 }]}>


                        {/* {pricePointFormatter(pricePoint["price"]["value"])} */}
                    </Typography>
                </Grid>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td>
                                <Typography sx={styles.keys}>Revenue</Typography>
                            </td>
                            <td>
                                <Typography sx={[styles.values]}>{currencyFormatter(pricePoint["revenue"]["annual_revenue"])}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={styles.keys}>Customers</Typography>
                            </td>
                            <td style={{ textAlign: "left" }}>
                                <DeltaLabel value={pricePoint["updated_customers"]} oldValue={pricePoint["num_customers"] ?? 0} formatter={(x) => Math.ceil(x)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={styles.keys}>Profit</Typography>
                            </td>
                            <td>
                                <Typography sx={[styles.values]}>{currencyFormatter(pricePoint["profit"]?.["value"] ?? 0)}</Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Box sx={{ borderBottom: '1px solid lightgray', mt: 2 }} />
                <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ fontSize: 14 }}>Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography sx={styles.keys}>Price change</Typography>
                                    </td>
                                    <td>
                                        <PercentageDeltaLabel value={pricePoint["price_change"] * 100}></PercentageDeltaLabel>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography sx={styles.keys}>Demand change</Typography>
                                    </td>
                                    <td>
                                        <PercentageDeltaLabel value={pricePoint["change_in_demand"] * 100}></PercentageDeltaLabel>
                                    </td>
                                </tr>
                                {pricePoint["other_properties"].map((p, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Typography sx={styles.keys}>
                                                {p["name"]}</Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ ...styles.values, fontSize: 13 }}>
                                                {formatOtherProperty(p)}
                                            </Typography>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
            </Grid>}
    </React.Fragment >)
}


import { Alert, CircularProgress, Grid, Link, MenuItem, MenuList, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { fetchAllUsers } from "../../apis/ClientDataService";

export default function UserListPage() {
    const dateComparator = (v1, v2) => new Date(v1) - new Date(v2);

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'rowIndex',
            headerName: '#',
            disableColumnMenu: true,
            sortable: false,
            width: 5
        },
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'email', headerName: 'Email', flex: 2 },
        // {
        //     field: 'price_model',
        //     headerName: 'Price Model',
        //     renderCell: (params) => {
        //         if (params.row.price_model === "Standard Price Model - Flat Rate") {
        //             return "Flat Rate";
        //         } else {
        //             return params.row.price_model;
        //         }
        //     },
        //     flex:2
        // },
        {
            field: 'organizations',
            headerName: 'Organizations',
            renderCell: (params) => {
                // map the organizations array to an array of organization names
                const orgs = params.row.organizations;
                // join the organization names with a comma and a space
                return <MenuList>
                    {orgs?.map((org, idx) => {
                        return <MenuItem key={idx} sx={{
                            m: 0, p: "3px", '&:hover': {
                                background: 'none',
                            },
                        }}>
                            <Typography sx={{ fontSize: 12, p: 0, m: 0 }}>
                                {/*<Link href={"/organization/" + org.id}>*/}
                                    {org.name}
                                {/*</Link>*/}
                            </Typography>
                        </MenuItem>
                    })
                    }
                </MenuList>
            },
            flex: 5
        },
        // { field: 'created_by_user', headerName: 'Created By', flex:3 },
        // { field: 'modified_by_user', headerName: 'Updated By', flex:3 },
        {
            field: 'created_date',
            headerName: 'Registered On',
            flex: 2,
            sortComparator: dateComparator,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString('en-US',
                    {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });
            }
        },
        // {
        //     field: 'modified_date',
        //     headerName: 'Updated On',
        //     flex:3,
        //     valueFormatter: (params) => {
        //         const date = new Date(params.value);
        //         return date.toLocaleString('en-US',
        //             {
        //                 day: 'numeric',
        //                 month: 'short',
        //                 year: 'numeric',
        //                 hour: '2-digit',
        //                 minute:'2-digit',
        //                 hour12:true,
        //             });
        //     }
        // },
    ];

    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setError(null);
        fetchAllUsers((data) => {
            const newData = data.map((user, index) => ({
                ...user,
                rowIndex: index + 1,
            }));
            setUsers(newData);
            setLoading(false);
        }, (err) => {
            if (err.error === 403) {
                setError("You are not authorized to access this data");
            } else {
                setError("Users could not be fetched!");
            }
            setLoading(false);
        });
    }, []);

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                    <Typography fontSize={30}>
                        Users
                    </Typography>
                </Grid>
                <Grid container item
                    sx={{
                        height: '75vh',
                        bgcolor: 'white',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {loading ?
                        <CircularProgress />
                        :
                        <>
                            {error ?
                                <Alert sx={{ width: "80%", maxWidth: 600 }} severity="error">{error}</Alert>
                                :
                                users.length
                                    ?
                                    <DataGrid
                                        sx={{
                                            overflowX: 'hidden',
                                            // disable cell selection style
                                            '.MuiDataGrid-cell:focus': {
                                                outline: 'none'
                                            },
                                            // pointer cursor on ALL rows
                                            '& .MuiDataGrid-row:hover': {
                                                cursor: 'pointer'
                                            },
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1, fontSize: 12,
                                            }
                                        }}
                                        getRowHeight={() => 'auto'}
                                        rows={users}
                                        // loading={loading}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 100 },
                                            },
                                        }}
                                        // onRowClick={handleRowClick}
                                        pageSizeOptions={[10, 50, 100]}
                                    // onCellClick={handleRowHover}
                                    // checkboxSelection
                                    />
                                    :
                                    <Typography color={'primary.greyText'}>
                                        No User present in the database!
                                    </Typography>
                            }
                        </>
                    }
                </Grid>
            </Grid >
        </>
    );
}


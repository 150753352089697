import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    IconButton,
    Grid,
    LinearProgress,
    Alert,
    Dialog,
    CircularProgress,
    Button,
    Typography,
    Tooltip, Fab
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import React from "react";
import { deletePriceModel, listPriceModels } from "apis/ClientDataService";
import AddIcon from "@mui/icons-material/Add";


export const getReadablePriceMetric = (priceMetric) => {
    return getReadableUnits(priceMetric["price_units"]);
}

export const getReadableUnits = (units) => {
    return units.length > 0 ? "/" + units.map(x => x["unit"]).join(" /") : "";
}

export default function PriceModelDefinitions(props) {
    const [priceModels, setPriceModels] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [deletePendingModel, setDeletePendingModel] = React.useState(null);

    React.useEffect(() => {
        if (!props.priceModels) {
            setLoading(true);
            // fetch price models
            listPriceModels((models) => {
                setPriceModels(models);
                setLoading(false);
            }, err => {
                setError("Unable to fetch price models, please try again later");
                setLoading(false);
            });
        } else {
            setPriceModels(props.priceModels);
        }
    }, []);

    const handleDeletePriceModel = () => {
        const priceModel = deletePendingModel;
        setLoading(true);
        deletePriceModel(priceModel.id, () => {
            setPriceModels([...priceModels.filter(x => x.id !== priceModel.id)]);
            setLoading(false);
            setDeletePendingModel(null);
            setOpenDeleteDialog(false);
        }, () => {
            setError("Unable to delete price models, please try again later");
            setLoading(false);
            setDeletePendingModel(null);
        });
    }

    const handleOpenDeleteDialog = (e, priceModel) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenDeleteDialog(true);
        setDeletePendingModel(priceModel);
    }

    const priceModelsGridColumns = [
        {
            field: 'name',
            headerName: 'Price Model Name',
            flex: 1,
        },
        {
            field: 'type',
            headerName: 'Price Model Type',
            flex: 1,
        },
        {
            field: 'metrics',
            headerName: 'Price Metrics',
            flex: 3,
            renderCell: (params) => {
                const row = params.row;
                return <Grid display="flex" flexWrap="wrap">
                    {row["price_metrics"].map((metric, index) => {
                        return <Box key={index} sx={{ borderRadius: 20, pr: 1, mr: 1, pl: 1, boxShadow: 2, fontSize: 14 }}
                            width="auto">
                            {getReadablePriceMetric(metric)}
                        </Box>
                    })}
                </Grid>
            }
        }
    ];

    if (!props.hideDelete) {
        priceModelsGridColumns.push({
            field: 'Delete',
            headerName: '',
            width: 5,
            renderCell: (params) => {
                const row = params.row;
                return (row["type"] === "CUSTOM_PRICING" || row["type"] === "TIERED_PRICING") ?
                    <Tooltip title='Delete Price Model'>
                        <IconButton size="small" onClick={(e) => handleOpenDeleteDialog(e, row)}>
                            <DeleteIcon fontSize="12" />
                        </IconButton>
                    </Tooltip>
                    : <React.Fragment />
            }
        });
    }

    return (
        <Grid container item
            sx={{
                bgcolor: 'white',
                borderRadius: 2,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {loading && <LinearProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            <DataGrid
                getRowHeight={() => 'auto'}

                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: "6px",
                    },
                    width:'50vw'
                }}
                rows={priceModels}
                columns={priceModelsGridColumns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}>
            </DataGrid>

            {/*DELETE PRICE MODEL DIALOG*/}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Remove Price Model</Typography>

                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Are you sure you want to delete this price model? This operation cannot be undone.
                        </Alert>

                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                            <Button variant="contained" color="error" size="medium" onClick={handleDeletePriceModel} sx={{ ml: 1 }}>Remove</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>
        </Grid>
    )
}
import axios from "axios";
import { getCurrentOrgId, getProjectFromLocalStore, getUser } from "../state/state";
import { apiGateway, apiGatewayJava } from "./Router";
import { apiCall } from "./utils";

const loginApi = apiGatewayJava + "/api/v1/auth/login"
const registerUserApi = apiGatewayJava + "/api/v1/user/register"
const verifyUserApi = apiGatewayJava + "/api/v1/user/verify"
const renewOtpApi = apiGatewayJava + "/api/v1/user/{user_id}/renewOtp"
const forgotPasswordApi = apiGatewayJava + "/api/v1/user/forgotPassword"
const resetPasswordApi = apiGatewayJava + "/api/v1/user/resetPassword"
const fetchAllUsersApi = apiGatewayJava + "/api/v1/user/getAll"
const fetchAllAppPropertiesApi = apiGatewayJava + "/api/v1/appProperties"
const addOrUpdateAppPropertiesApi = apiGatewayJava + "/api/v1/appProperties"
const deletePropertyApi = apiGatewayJava + "/api/v1/appProperties"

const registerOrgApi = apiGatewayJava + "/api/v1/organization"
const fetchAllOrgsApi = apiGatewayJava + "/api/v1/organizations"
const fetchAllOrgsOfUserApi = apiGatewayJava + "/api/v1/user/{user_id}/organizations"
const removeOrgFromUserApi = apiGatewayJava + "/api/v1/user/{user_id}/organization/{organization_id}"
const fetchOrgApi = apiGatewayJava + "/api/v1/organization/{organization_id}"
const updateOrgApi = apiGatewayJava + "/api/v1/organization"
const updateOrgSecretKeyApi = apiGatewayJava + "/api/v1/organization/{organization_id}/apiKey"
const fetchAllProjectsApi = apiGatewayJava + "/api/v1/organization/{organization_id}/project"
const addProjectApi = apiGatewayJava + "/api/v1/organization/{organization_id}/project"
const updateProjectApi = apiGatewayJava + "/api/v1/organization/{organization_id}/project"
const fetchWorkspacesApi = apiGatewayJava + "/api/v1/organization/{organization_id}/project/{project_id}/workspace"
const grantOrgAccessToUserApi = apiGatewayJava + "/client/{client_id}/authorization/grantAccess"
const revokeOrgAccessFromUserApi = apiGatewayJava + "/client/{client_id}/authorization/revokeAccess"
const getAuthorizationApi = apiGatewayJava + "/client/{client_id}/authorization/user/{user_id}"

const crunchbase = apiGateway + "/company";
const getClientDataApi = apiGateway + "/client/{client_id}/{data}"
const saveClientDataApi = apiGateway + "/client/{client_id}/data"
const resetClientDataApi = apiGateway + "/client/{client_id}/data"

const getCustomerDatasetApi = apiGateway + "/client/{client_id}/data_sets/{data_set_id}"
const getCustomerSegmentApi = apiGateway + "/client/{client_id}/segments"
const getDataLoadStatusApi = apiGateway + "/client/{client_id}/{datatype}/dataload_status"

const getInsightsApi = apiGateway + "/client/{client_id}/insights"

const uploadCustomerDataApi = apiGateway + "/client/{client_id}/{datatype}/upload_customer_data"

const runScenarioApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios/run"
const listScenariosApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios"
const updateScenarioApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios"
const addScenarioApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios"
const deleteScenarioApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios/{scenario_id}"
const importDataScenarioApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios/{scenario_id}/importData?year={year}"
const getScenarioByIdApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios/{scenario_id}"
const getScenarioResultApi = apiGatewayJava + "/client/{client_id}/project/{project_id}/scenarios/{scenario_id}/result"

const getPriceMetricsApi = apiGatewayJava + "/client/{client_id}/priceMetrics"
const getPriceModelsApi = apiGatewayJava + "/client/{client_id}/priceModels"
const getPriceUnitsApi = apiGatewayJava + "/client/{client_id}/priceUnits"

const addPriceModelApi = apiGatewayJava + "/client/{client_id}/priceModel"
const deletePriceModelApi = apiGatewayJava + "/client/{client_id}/priceModel/{id}"
const addPriceUnitApi = apiGatewayJava + "/client/{client_id}/priceUnit"
const deletePriceUnitApi = apiGatewayJava + "/client/{client_id}/priceUnit"

const listProductsApi = apiGatewayJava + "/client/{client_id}/products/scenario/{scenario_id}"
const addProductApi = apiGatewayJava + "/client/{client_id}/products"
const updateProductApi = apiGatewayJava + "/client/{client_id}/products"
const deleteProductApi = apiGatewayJava + "/client/{client_id}/products/{product_id}"
const listDesignsApi = apiGatewayJava + "/client/{client_id}/designs/scenario/{scenario_id}"
const addDesignApi = apiGatewayJava + "/client/{client_id}/designs"
const updateDesignApi = apiGatewayJava + "/client/{client_id}/designs"
const deleteDesignApi = apiGatewayJava + "/client/{client_id}/designs/{design_id}"
const defaultDesignUrlApi = apiGatewayJava + "/client/{client_id}/designs/defaultUrl"
const listCouponsApi = apiGatewayJava + "/client/{client_id}/coupons/scenario/{scenario_id}"
const addCouponApi = apiGatewayJava + "/client/{client_id}/coupons"
const deleteCouponApi = apiGatewayJava + "/client/{client_id}/coupons/{coupon_id}"

const designApi = apiGatewayJava + "/designs/{design_id}"

const stripeOnboardingApi = apiGatewayJava + "/api/v1/organization/{organization_id}/stripePaymentIntegration/{country_code}"
const stripeDeauthorizeApi = apiGatewayJava + "/api/v1/organization/{organization_id}/deauthorizeStripe"

const stripeCheckoutApi = apiGatewayJava + "/designs/stripe/create-checkout-session"
const stripePublishableApiKey = apiGatewayJava + "/designs/stripe/publishableApiKey"

const listAllClientPlansApi = apiGatewayJava + "/client-plans" // All client plans together. Admin API
const listCustomerPlansApi = apiGatewayJava + "/client-plans/{client_id}/customer-plans" // Customer plans for a client.
const listClientPlansApi = apiGatewayJava + "/client-plans/{client_id}" // Client's priceops plans

export const login = (userName, password, onSuccess, onFailure) => {
    return apiCall(loginApi, {
        method: "POST",
        body: JSON.stringify({
            "email": userName,
            "password": password
        }),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure);
};

export const registerUser = (registrationDetails, onSuccess, onFailure) => {
    return apiCall(registerUserApi, {
        method: "POST",
        body: JSON.stringify(registrationDetails),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure);
}

export const verifyUser = (userId, otpValue, onSuccess, onFailure) => {
    return apiCall(verifyUserApi, {
        method: "POST",
        body: JSON.stringify({
            "user_id": userId,
            "otp_value": otpValue
        }),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure);
};

export const renewOtp = (userId, onSuccess, onFailure) => {
    return apiCall(renewOtpApi.replace('{user_id}', userId), {
        method: "POST"
    }, onSuccess, onFailure);
};

export const fetchAllUsers = (onSuccess, onFailure) => {
    return apiCall(fetchAllUsersApi, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const fetchAllAppProperties = (onSuccess, onFailure) => {
    return apiCall(fetchAllAppPropertiesApi, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const addOrUpdateProperty = (property, onSuccess, onFailure) => {
    return apiCall(addOrUpdateAppPropertiesApi, {
        method: "POST",
        body: JSON.stringify(property),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const deleteProperty = (property, onSuccess, onFailure) => {
    return apiCall(deletePropertyApi, {
        method: "DELETE",
        body: JSON.stringify(property),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const fetchAllOrgs = (onSuccess, onFailure) => {
    return apiCall(fetchAllOrgsApi, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const fetchAllOrgsOfUser = (userId, onSuccess, onFailure) => {
    return apiCall(fetchAllOrgsOfUserApi.replace('{user_id}', userId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const removeOrgFromUser = (orgId, userId, onSuccess, onFailure) => {
    return apiCall(removeOrgFromUserApi
        .replace('{organization_id}', orgId)
        .replace('{user_id}', userId), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const grantOrgAccessToUser = (orgId, email, role, onSuccess, onFailure) => {
    return apiCall(grantOrgAccessToUserApi
        .replace('{client_id}', orgId), {
        method: "POST",
        body: JSON.stringify({
            email_id: email,
            requested_org_role: role
        }),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const revokeOrgAccessFromUser = (orgId, email, role, onSuccess, onFailure) => {
    return apiCall(revokeOrgAccessFromUserApi
        .replace('{client_id}', orgId), {
        method: "POST",
        body: JSON.stringify({
            email_id: email,
            requested_org_role: role
        }),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const getAuthorizationDetails = (userId, onSuccess, onFailure) => {
    return apiCall(getAuthorizationApi
        .replace('{client_id}', 0)
        .replace('{user_id}', userId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const registerOrganization = (organizationDetails, onSuccess, onFailure) => {
    return apiCall(registerOrgApi, {
        method: "POST",
        body: JSON.stringify(organizationDetails),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const updateOrganization = (organization, onSuccess, onFailure) => {
    return apiCall(updateOrgApi, {
        method: "PUT",
        body: JSON.stringify(organization),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const updateOrganizationApiKey = (orgId, onSuccess, onFailure) => {
    return apiCall(
        updateOrgSecretKeyApi.replace('{organization_id}', orgId), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const getOrganization = (organizationId, onSuccess, onFailure) => {
    return apiCall(fetchOrgApi.replace('{organization_id}', organizationId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const listProjects = (orgId, onSuccess, onFailure) => {
    return apiCall(fetchAllProjectsApi
        .replace('{organization_id}', orgId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const addProject = (orgId, project, onSuccess, onFailure) => {
    return apiCall(addProjectApi
        .replace('{organization_id}', orgId), {
        method: "POST",
        body: JSON.stringify(project),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const updateProject = (orgId, project, onSuccess, onFailure) => {
    return apiCall(updateProjectApi
        .replace('{organization_id}', orgId), {
        method: "PUT",
        body: JSON.stringify(project),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const listWorkspaces = (orgId, projectId, onSuccess, onFailure) => {
    return apiCall(fetchWorkspacesApi
        .replace('{organization_id}', orgId)
        .replace('{project_id}', projectId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const forgotPassword = (userName, onSuccess, onFailure) => {
    return apiCall(forgotPasswordApi, {
        method: "PUT",
        body: JSON.stringify({
            "email": userName
        }),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure);
};

export const resetPassword = (userName, token, password, onSuccess, onFailure) => {
    return apiCall(resetPasswordApi, {
        method: "PUT",
        body: JSON.stringify({
            "email": userName,
            "reset_password_token": token,
            "password": password
        }),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure);
};

export const getBearerToken = () => {
    return 'Bearer ' + getUser()["token"];
}

export const uploadData = (formData, datatype, setProgress, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    axios.post(uploadCustomerDataApi
        .replace('{client_id}', clientId)
        .replace('{datatype}', datatype),
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': getBearerToken()
            },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.progress);
                setProgress(progress);
            }
        })
        .then((resp) => {
            const status = resp.status;
            const data = resp.data;
            if (status !== 200) onFailure(data);
            else return onSuccess(data);
        })
        .catch((err) => {
            console.log(err);
            if (err.response && err.response.data) {
                onFailure(err.response.data);
            } else {
                onFailure({ "error_message": "Server error" });
            }
        });
}

export const getCompany = (company, onSuccess, onFailure) => {
    return apiCall(crunchbase + "?company=" + company, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure);
};

export const getFinancialMetrics = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getClientDataApi
        .replace('{client_id}', clientId)
        .replace('{data}', 'financial_metrics'), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}


export const saveData = (property, client_data, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const data = {};
    data[property] = client_data;
    return apiCall(saveClientDataApi.replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const getCompetitors = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getClientDataApi
        .replace('{client_id}', clientId)
        .replace('{data}', 'competitors'), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getBasicProfile = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getClientDataApi
        .replace('{client_id}', clientId)
        .replace('{data}', 'basic_profile'), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getCustomerData = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getCustomerDatasetApi
        .replace('{client_id}', clientId)
        .replace('{data_set_id}', 1), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getCustomerSegments = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getCustomerSegmentApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const addCustomerSegment = (segment, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getCustomerSegmentApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(segment),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getDatasetStatus = (datatype, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getDataLoadStatusApi
        .replace('{client_id}', clientId)
        .replace('{datatype}', datatype), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure, true)
}


export const getInsights = (filters, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getInsightsApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(filters),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getStrategies = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getClientDataApi
        .replace('{client_id}', clientId)
        .replace('{data}', 'strategies'), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getProductFeatures = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(getClientDataApi
        .replace('{client_id}', clientId)
        .replace('{data}', 'product_features'), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const resetClientData = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(resetClientDataApi
        .replace('{client_id}', clientId), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getScenarios = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore()?.id;

    if (!projectId) {
        return;
    }

    return apiCall(listScenariosApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getScenariosOfProject = (projectId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    if (!projectId) {
        return;
    }

    return apiCall(listScenariosApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const addScenario = (scenario, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore().id;
    return apiCall(addScenarioApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId), {
        method: "POST",
        body: JSON.stringify(scenario),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const importData = (scenarioId, year, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore().id;
    return apiCall(importDataScenarioApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId)
        .replace('{scenario_id}', scenarioId)
        .replace('{year}', year), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getScenario = (scenarioId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore()?.id ?? "-";
    return apiCall(getScenarioByIdApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId)
        .replace('{scenario_id}', scenarioId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const updateScenario = (scenario, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore().id;
    return apiCall(updateScenarioApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId), {
        method: "PUT",
        body: JSON.stringify(scenario),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deleteScenario = (scenarioId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore().id;
    return apiCall(deleteScenarioApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId)
        .replace('{scenario_id}', scenarioId), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}


export const runWorkBenchFlatPricing = (scenario, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore().id;
    return apiCall(runScenarioApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId), {
        method: "POST",
        body: JSON.stringify(scenario),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getScenarioResult = (scenarioId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    const projectId = getProjectFromLocalStore().id;
    return apiCall(getScenarioResultApi
        .replace('{client_id}', clientId)
        .replace('{project_id}', projectId)
        .replace('{scenario_id}', scenarioId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const listPriceModels = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(getPriceModelsApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const addPriceModel = (priceModel, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(addPriceModelApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(priceModel),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deletePriceModel = (id, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(deletePriceModelApi
        .replace('{client_id}', clientId)
        .replace('{id}', id), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const listPriceMetrics = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(getPriceMetricsApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const listPriceUnits = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(getPriceUnitsApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const addPriceUnit = (priceUnit, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(addPriceUnitApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(priceUnit),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deletePriceUnit = (priceUnit, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(deletePriceUnitApi
        .replace('{client_id}', clientId), {
        method: "DELETE",
        body: JSON.stringify(priceUnit),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getPackagingProducts = (scenarioId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    // console.log(listProductsApi,clientId,scenarioId)
    return apiCall(listProductsApi
        .replace('{client_id}', clientId)
        .replace('{scenario_id}', scenarioId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const addProduct = (product, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(addProductApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(product),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const updateProduct = (product, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(updateProductApi
        .replace('{client_id}', clientId), {
        method: "PUT",
        body: JSON.stringify(product),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deleteProduct = (productId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(deleteProductApi
        .replace('{client_id}', clientId)
        .replace('{product_id}', productId), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getDesigns = (scenarioId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(listDesignsApi
        .replace('{client_id}', clientId)
        .replace('{scenario_id}', scenarioId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getDesign = (designId, onSuccess, onFailure) => {
    return apiCall(designApi
        .replace('{design_id}', designId), {
        method: "GET"
    }, onSuccess, onFailure)
}

export const addDesign = (design, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(addDesignApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(design),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const updateDesign = (design, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(updateDesignApi
        .replace('{client_id}', clientId), {
        method: "PUT",
        body: JSON.stringify(design),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deleteDesign = (designId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(deleteDesignApi
        .replace('{client_id}', clientId)
        .replace('{design_id}', designId), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getDesignUrl = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(defaultDesignUrlApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getCoupons = (scenarioId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(listCouponsApi
        .replace('{client_id}', clientId)
        .replace('{scenario_id}', scenarioId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const addCoupon = (coupon, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(addCouponApi
        .replace('{client_id}', clientId), {
        method: "POST",
        body: JSON.stringify(coupon),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deleteCoupon = (couponId, onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(deleteCouponApi
        .replace('{client_id}', clientId)
        .replace('{coupon_id}', couponId), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}


export const getStripeOnboardingUrl = (countryCode, onSuccess, onFailure) => {

    return apiCall(stripeOnboardingApi
        .replace('{organization_id}', getCurrentOrgId())
        .replace('{country_code}', countryCode), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const deauthorizeStripe = (onSuccess, onFailure) => {

    return apiCall(stripeDeauthorizeApi
        .replace('{organization_id}', getCurrentOrgId()), {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const getStripePublishableApiKey = (onSuccess, onFailure) => {
    return apiCall(stripePublishableApiKey, {
        method: "GET"
    }, onSuccess, onFailure)
}

export const createStripeCheckoutSession = (sessionDetails, onSuccess, onFailure) => {

    return apiCall(stripeCheckoutApi, {
        method: "POST",
        body: JSON.stringify(sessionDetails),
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
    }, onSuccess, onFailure)
}

// Admin api, will show all data irrespective of clients.
export const fetchAllClientPlans = (onSuccess, onFailure) => {
    return apiCall(listAllClientPlansApi, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure);
}

export const fetchCustomerPlansForClient = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();
    return apiCall(listCustomerPlansApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}

export const fetchPriceOpsPlansOfClient = (onSuccess, onFailure) => {
    const clientId = getCurrentOrgId();

    return apiCall(listClientPlansApi
        .replace('{client_id}', clientId), {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            'Authorization': getBearerToken()
        },
    }, onSuccess, onFailure)
}
import PaymentIcon from '@mui/icons-material/Payment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { fetchPriceopsSubscriptionPlansForClient as fetchPriceopsSubscriptionPlanForClient, getDesignUrl } from 'apis/ClientDataService';
import React, {useState} from 'react';
import CardContentContainer from '../../components/CardContentContainer';
import Paypal from './Paypal';

export default function PricingPage() {
    const [value, setValue] = React.useState('paypal');
    const [designUrl, setDesignUrl] = React.useState(null);
    const [ready, setReady] = React.useState(true);
    const [currentPlan, setCurrentPlan] = React.useState(null);
    const [currentPlanError, setCurrentPlanError] = React.useState(null);
    const [designFrameId, setDesignFrameId] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        getDesignUrl((data) => {
            if (data.value && data.value.length > 0){
                setDesignUrl(data.value);
                const designId = (data.value).split('/').pop();
                setDesignFrameId(`priceops-design-${designId}`);
            }
            else setReady(false);
        }, err => {
            setReady(false);
        });
    }, []);

    // React.useEffect(() => {
    //     fetchPriceopsSubscriptionPlanForClient((data) => {
    //         setCurrentPlan(data);
    //     }, err => {
    //         console.log(err);
    //         setCurrentPlanError(err?.error_message ?? "No active plan");
    //     });
    // }, [])

    return (
        // <Grid alignItems="center">
        //     <CardContentContainer
        //         cardIcon={<PaymentIcon />}
        //         title="Payments"
        //         content={
        //             <React.Fragment sx={{ width: "100%", height: "50vh" }}>
        //                 <Typography sx={{ color: "primary.sub", fontSize: 25, textAlign: "center", fontWeight: "bold", mt: 4 }}>One stop solution for all your pricing needs</Typography>
        //                 <Typography sx={{ color: "primary.sub", fontSize: 18, textAlign: "center", fontWeight: "bold", mb: 4 }}>Choose your plan today</Typography>
        <>
            {ready ?
                // <iframe
                //     id={designFrameId}
                //     src={designUrl}
                //     style={{minWidth: "100%",minHeight: "100vh", background: "transparent", border: "0"}}
                // >
                // </iframe>
                <object data={designUrl}
                        style={{ width: "100%", height: 1750, background: "transparent" }}
                        type="text/html">

                </object>
                :
            <Grid>
                <Alert severity="error">Design page is not yet configured</Alert>
            </Grid>}
        </>
        //                 <Box sx={{ width: '100%', typography: 'body1', backgroundColor: "primary.background", display: "none" }}>
        //                     <TabContext value={value}>
        //                         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        //                             <TabList onChange={handleChange} aria-label="lab API tabs example">
        //                                 <Tab label="Paypal" value="paypal" />
        //                                 <Tab label="Razor pay" value="razorpay" />
        //                             </TabList>
        //                         </Box>
        //                         <TabPanel value="paypal"><Paypal /></TabPanel>
        //                         <TabPanel value="razorpay">Item Two</TabPanel>
        //                     </TabContext>
        //                 </Box>
        //             </React.Fragment>} />
        // </Grid>
    );
}

import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {getReadablePriceMetric} from "../scenarios/price-modelling/components/PriceModelDefinitions";

export function TieredPricingPreview({
    tiers ,mode ,usage ,quantity ,designFontStyle ,price, usageType
}) {

    useEffect(()=>{
       console.log(tiers ,mode ,usage ,quantity ,designFontStyle, price, usageType)
    },[])

    const processPriceMetric = (paymentType, metric) => {
        let readablePriceMetric = getReadablePriceMetric(metric);
        if (paymentType === 'One Time') {
            readablePriceMetric = readablePriceMetric.replace('/month', '').replace('/year', '');
            if (readablePriceMetric === '') {
                readablePriceMetric = '/';
            }
        }
        return readablePriceMetric;
    }

    const pluralUnit = usage.plural.toLowerCase();
    const singularUnit = usage.unit.toLowerCase();

    const isTierActivated = (index) =>{

        if(usageType === 'metered') return false;

        let upTo = tiers[index].up_to
        let from = 0

        if(index > 0){
            from = tiers[index-1].up_to +1
        }

        if(mode === 'volume'){
            if(quantity >= from && (quantity <= upTo || upTo === -1) ){
                return true
            }
        }else{
            if(quantity >= from){
                return true
            }
        }
        return false
    }

    const correctUnit = (num) => (num > 1) ? pluralUnit : singularUnit;
    const correctBorder = (index) => (isTierActivated(index)) ? '2px solid green' : '1px solid gray';

    const calculateTieredPriceTotal = () => {
        let graduatedTotal = 0;
        let cumulativeQuantity = 0;

        for(let tier of tiers){
            if(mode === 'volume'){
                if(quantity <= tier.up_to || tier.up_to === -1){
                    return ((quantity*tier.unit_price_value) + tier.flat_price_value)
                }
            }else{
                if(quantity <= tier.up_to || tier.up_to === -1){
                    graduatedTotal += (((quantity-cumulativeQuantity)*tier.unit_price_value) + tier.flat_price_value)
                    return graduatedTotal
                } else {
                    graduatedTotal += (((tier.up_to-cumulativeQuantity)*tier.unit_price_value) + tier.flat_price_value)
                    cumulativeQuantity = tier.up_to
                }
            }
        }
    }

    const finalPriceTotal = calculateTieredPriceTotal()

    return (
        <Grid container p={1} width={300} direction={'column'} gap={1}>
            <Typography fontFamily={designFontStyle} fontWeight={800} fontSize={12}>
                Price Tiers
            </Typography>

            <Grid container direction={'column'} gap={0.7}>
                {(tiers.map((tier,index) => {
                    return(
                        <Grid container border={correctBorder(index)} borderRadius={1} py={0.7} px={0.8} justifyContent={"space-between"}>
                            <Grid container width={'55%'} alignItems={'center'}>
                                <Typography fontSize={10} fontWeight={400} fontFamily={designFontStyle}>
                                    {tiers.length > 1
                                        ? (index === 0
                                            ? (mode === 'volume'
                                                ? `Up to ${tier.up_to} ${correctUnit(tier.up_to)}`
                                                : `For first ${tier.up_to} ${correctUnit(tier.up_to)}`)
                                            : (index !== (tiers.length-1)
                                                ? (mode === 'volume'
                                                    ? `For ${tiers[index-1].up_to + 1}-${tier.up_to} ${pluralUnit}`
                                                    : `For next ${tiers[index-1].up_to + 1}-${tier.up_to} ${pluralUnit}`)
                                                : (mode === 'volume'
                                                    ? `For ${tiers[index-1].up_to + 1} or more ${pluralUnit}`
                                                    : `For the rest ${pluralUnit}`)))
                                        : `For 1 or more ${pluralUnit}`}
                                </Typography>
                            </Grid>
                            <Grid container width={'45%'} direction={'column'} alignItems={'flex-end'} textAlign={'right'}>
                                <Typography fontSize={10} fontWeight={800} fontFamily={designFontStyle}>
                                    {price.currency}{tier['unit_price_value']} {processPriceMetric(price.payment_type, price.price_metric)}
                                </Typography>

                                {tier.flat_price_value > 0 &&
                                    <Typography fontSize={10} fontWeight={800} fontFamily={designFontStyle}>
                                        plus {price.currency + tier['flat_price_value']} {price.payment_type === 'Recurring'
                                        ? ' /month' : ''} fee
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    )
                }))}
            </Grid>

            {usageType !== 'metered' &&
                <>
                    <Typography fontFamily={designFontStyle} fontWeight={800} fontSize={12} mt={1}>
                        Price Breakdown (For {quantity} {correctUnit(quantity)})
                    </Typography>

                    <Grid container direction={'column'} gap={0.7}>
                        {(tiers.map((tier,index) => {

                            let upTo = tier.up_to
                            // from = 0 will give incorrect calculation
                            let from = 1
                            if(index > 0){
                                from = tiers[index-1].up_to + 1
                            }
                            let currMultiplier = quantity
                            if(mode !== 'volume'){
                                if(quantity >= upTo && upTo !== -1){
                                    currMultiplier = upTo - from + 1
                                }else{
                                    currMultiplier = quantity - from + 1
                                }

                            }

                            let tierTotal = tier.unit_price_value*currMultiplier + tier.flat_price_value

                            return(
                                <>
                                    {isTierActivated(index) &&
                                        <Grid container alignItems={'center'} gap={0.5} >
                                            <Typography fontSize={10} fontWeight={400} fontFamily={designFontStyle}>
                                                {mode === 'volume'
                                                    ? `For ${quantity} ${correctUnit(quantity)} =`
                                                    : `For ${index > 0 ? 'next' : 'first'} ${currMultiplier} ${correctUnit(quantity)} =`
                                                }
                                            </Typography>

                                            <Typography fontSize={10} fontFamily={designFontStyle}>
                                                {price.currency}{tier['unit_price_value']} x {currMultiplier}
                                            </Typography>

                                            {tier.flat_price_value > 0 &&
                                                <Typography fontSize={10} fontFamily={designFontStyle}>
                                                    + {price.currency + tier['flat_price_value']}
                                                </Typography>
                                            }

                                            <Typography fontSize={10} fontFamily={designFontStyle}>
                                                = {price.currency}{tierTotal}
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                            )
                        }))}
                    </Grid>
                    <Grid container gap={0.5}>
                        <Typography fontSize={10} fontFamily={designFontStyle}>
                            Final Price is
                        </Typography>
                        <Typography fontSize={10} fontWeight={800} fontFamily={designFontStyle}>
                            {price.currency}{finalPriceTotal > 1 ?
                            finalPriceTotal.toLocaleString() : finalPriceTotal} {price.payment_type === 'Recurring'
                            ? ' /month' : ''}
                        </Typography>
                    </Grid>
                </>
            }

        </Grid>)
}
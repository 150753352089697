import React from "react";
import { Typography, Tooltip } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function DeltaLabel(props) {
    const value = props.value;
    const oldValue = props.oldValue;
    const formatter = props.formatter ?? (() => { });

    const getTitle = () => {
        return value > oldValue ? "Increase by " + formatter(value - oldValue)
            : value < oldValue ? "Decrease by " + formatter(oldValue - value)
                : "No change";
    }

    return (<React.Fragment><Typography sx={[{ display: "inline", mr: 1, fontSize: 13 }]}> {formatter(value)}
    </Typography>
        <Tooltip title={getTitle()}>
            {value === oldValue ?
                <React.Fragment></React.Fragment>
                : value > oldValue ?
                    <ArrowUpwardIcon color="success" sx={{ display: "inline", fontSize: 13, fontWeight: "bold", cursor: "pointer" }} />
                    : <ArrowDownwardIcon color="error" sx={{ display: "inline", fontSize: 13, fontWeight: "bold", cursor: "pointer" }} />}
        </Tooltip></React.Fragment>)
}
import React, { useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Backdrop,
  IconButton, Typography
} from "@mui/material";
// import { uiStyles } from "../../themes/themes";

import PasswordIcon from '@mui/icons-material/Password';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import { login } from "../../apis/ClientDataService";
import { Dialog } from '@mui/material';

import { useContext } from "react";
import { validateEmail } from "../../utils/Utils";
import { MicrosoftAuthContext } from "./microsoft/MicrosoftAuthContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OtpVerificationFlow from "./OtpVerificationForm";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';

export default function LoginForm(props) {
  const [username, setUsername] = useState(props?.email ?? "");
  const [password, setPassword] = useState("");
  const [status, setStatus] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [verifyUserId, setVerifyUserId] = useState();

  const navigate = useNavigate();

  const { account, login: microsoftLogin, logout } = useContext(MicrosoftAuthContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const redirect = queryParams.get('redirect');

  function validateForm() {
    return username.length > 0 && password.length > 0 && validateEmail(username);
  }

  function handleLogin(event) {
    event.preventDefault();

    setStatus(null);
    setLoading(true);
    login(username, password, (data) => {
      const user = data["user"];
      user["token"] = data["token"];

      if (props?.onLogin) props?.onLogin(user);
      navigate(redirect ?? "/");
    }, (err) => {

        console.log(err)

      if (props?.onFailure) props?.onFailure(username);
      setLoading(false);
      if (err.error === "PENDING_VERIFICATION") {
        // setStatus("Your verification status is still pending please verify with the new OTP that has been sent to your email.");
        // setState("info");
        setOtpDialogOpen(true);
        setVerifyUserId(Number(err?.error_message?.split(' ')?.pop() ?? 0));
      } else {
        setState("error");
        setStatus("Unable to login. " + (err?.error_message ?? "Server error") + ".");
      }
    })
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container direction="column" pt={3}>
      {otpDialogOpen && <Dialog open={true} disableScrollLock={true}>
        <OtpVerificationFlow
            verifyUserId={verifyUserId}
            updateLoading={setLoading}
            updateStatus={setStatus}
            updateState={setState}
            updateOtpDialogOpen={setOtpDialogOpen}
        />
        {/*<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>*/}
        {/*  <Button*/}
        {/*    variant="text"*/}
        {/*    sx={{ fontSize: 12, textTransform: 'none' }}*/}
        {/*    onClick={() => {*/}
        {/*      setLoading(false);*/}
        {/*      setStatus(null);*/}
        {/*      setState(null);*/}
        {/*      setOtpDialogOpen(false);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Back To Login*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Dialog>}
      {
        loading && <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <React.Fragment>
        <form onSubmit={handleLogin} >
          <Grid container direction="column" gap={1}>
            {/*<Grid container item justifyContent="center" align="center">*/}
            {/*  /!*<AccountCircleIcon sx={{ fontSize: 60, opacity: 0.6 }} />*!/*/}
            {/*  <Typography fontWeight={800} fontSize={20}>Login</Typography>*/}
            {/*</Grid>*/}
            <TextField
              variant="outlined"
              size={'small'}
              margin="dense"
              label="Email"
              id="login-id"
              aria-describedby="email address"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              size={'small'}
              margin="dense"
              label="Password"
              id="login-password"
              aria-describedby="Login password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                ),
              }}
            />

            <Grid container width={'100%'} justifyContent={'flex-end'}>
              <Typography
                  type="button"
                  sx={{
                    color: "primary.main",
                    fontSize: 12,
                    fontWeight:800,
                    textTransform: "none",
                    cursor: 'pointer'
                  }}
                  onClick={() => { props.onForgotPassword() }}
              >
                  Forgot Password?
              </Typography>
            </Grid>

            {status &&
                <Grid container item sx={{ my: 1 }}>
                  <Alert onClose={() => setStatus(null)} severity={state} sx={{ width: "100%" }}>
                    {status}
                  </Alert>
                </Grid>
            }

            <Button
              sx={{ mt:1, py: 2.7,
                '&.Mui-disabled': {
                  color: 'white',
                  backgroundColor: 'primary.main',
                },
              }}
              type="submit"
              disabled={!validateForm()}
              autoFocus
              variant="contained"
              onClick={(e) => handleLogin(e)}
            >
              Log in
            </Button>

            <Grid container width={'100%'} gap={0.5} mt={1} justifyContent={'center'}>
              <Typography fontSize={12}>Don't have an account?</Typography>
              <Typography
                  fontSize={12}
                  fontWeight={800}
                  type="button"
                  onClick={() => props.setRegisterPage(true)}
                  sx={{ color: "primary.main", cursor: 'pointer' }}
              >
                Sign up
              </Typography>
            </Grid>
            {/* <Button onClick={microsoftLogin}>Microsoft login</Button> */}
          </Grid>
        </form>
      </React.Fragment>
    </Grid>
  );
}

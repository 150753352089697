import React from "react";

import { Box, Typography, Button } from "@mui/material";
import { getDatasetStatus, uploadData } from "../../apis/ClientDataService";
import RichFileUpload from "../../components/RichFileUpload";
import { LinearProgress } from "@mui/material";

export default function FileImportComponent(props) {
    const [fileTypes] = React.useState(['csv', 'xls', 'xlsx']);
    const [processingStatus, setProcessingStatus] = React.useState(null);
    const datatype = props.datatype;
    const dataTitle = props.datatitle;
    const [showUpload, setShowUpload] = React.useState(false);

    const pollForStatus = () => {
        setShowUpload(false);
        var errorCount = 0;
        const fn = () => getDatasetStatus(datatype, resp => {
            console.log(resp);
            if (resp != null) {
                setProcessingStatus(resp.status);
                if (resp.status.status === 'done') {
                    clearInterval(myInterval);

                    if (props.notify) {
                        props.notify();
                    }
                }
                else if (resp.status.status === 'failed') {
                    setShowUpload(true);
                    clearInterval(myInterval);
                }
            }
        }, (err) => {
            console.log("Error while fetching status for " + datatype);
            errorCount++;
            if (errorCount > 3) clearInterval(myInterval);
        });
        fn();
        const myInterval = setInterval(fn, 2000);
    }
    React.useEffect(() => {
        pollForStatus();
    }, []);

    const handleUploadSuccess = (req) => {
        pollForStatus();
    }

    const fileUploadFailureMessage = (err) => {
        return err.error_message;
    }

    return (<React.Fragment>
        <Box sx={{ boxShadow: 1, borderRadius: 2, overflow: "hidden" }}>
            <Box sx={{
                display: 'table-cell', width: 200, height: 100,
                verticalAlign: 'middle',
                textAlign: "center",
                border: 0,
                p: 1,
                backgroundColor: "primary.sub",
                borderColor: "primary.groupBorder",
            }}>
                <img src={require('./spreadsheet.png')} style={{ width: 40, height: 40, padding: '0 20px' }} alt="spreadsheet" />
                <Typography sx={{ color: "primary.textOnMain", fontSize: 12 }}>{dataTitle}</Typography>
            </Box>
            {!showUpload && <React.Fragment>
                <Box sx={{ display: 'table-cell', width: '100%', p: 2, pt: 2 }} >
                    {processingStatus && processingStatus.status === "done" &&
                        <React.Fragment>
                            <Typography sx={{ fontSize: 14 }}>Data is ready</Typography>
                            <Button variant="outlined" onClick={() => setShowUpload(true)} sx={{ float: "right", height: 30, textTransform: "None" }}>Upload again</Button>
                        </React.Fragment>}
                    {processingStatus && processingStatus.status === "in_progress" &&
                        <React.Fragment>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>{processingStatus.statusMessage}</Typography>
                            <LinearProgress variant="indeterminate"></LinearProgress>
                        </React.Fragment>
                    }
                    {!processingStatus &&
                        <React.Fragment>
                            <Button variant="outlined" onClick={() => setShowUpload(true)} sx={{ float: "right", height: 30, textTransform: "None" }}>Upload</Button>
                        </React.Fragment>
                    }
                </Box>
            </React.Fragment>}
            {showUpload && <Box sx={{ display: 'table-cell', width: '100%' }} >
                <RichFileUpload
                    fileUploadFailureMessage={(err) => fileUploadFailureMessage(err)}
                    datastatus="Click or drag to upload file"
                    datatype={datatype}
                    onUploadStart={() => { setProcessingStatus({ "status": "in_progress", "statusMessage": "Processing kicked off" }); }}
                    onSuccess={(resp) => { handleUploadSuccess(resp); }}
                    onFailure={(err) => { setProcessingStatus(null); }}
                    onReset={() => { }}
                    uploadFn={uploadData}
                    height={100}
                    // title="Load customer data"
                    fileTypes={fileTypes}
                />
            </Box>}
        </Box>
    </React.Fragment >)
}
import { Grid, InputLabel } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { COLORS } from '../themes/themes';

const RADIAN = Math.PI / 180;

export default function PricingPieChart(props) {
    const data = props.data;
    const dataKey = props.dataKey;
    const formatter = props.formatter ? props.formatter : (value) => value;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={12}>
                {/* {data[index].name + ' (' + `${(percent * 100).toFixed(0)}%)`} */}
                {(percent * 100).toFixed(0) + '%'}
            </text>
        );
    };

    return (
        <Grid container item xs={5} justifyContent="center" direction="column" height="auto">
            <Grid item align="center">
                <InputLabel>Distribution</InputLabel>
            </Grid>
            <Grid item align="center">
                <PieChart width={250} height={250}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey={dataKey}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip formatter={formatter} />
                </PieChart>
            </Grid>
        </Grid>
    );
}

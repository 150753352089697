import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from "@mui/icons-material/Person";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Alert,
    Button, Chip,
    CircularProgress,
    Dialog,
    Drawer,
    Fab,
    Grid,
    ListItemIcon, ListItemText,
    Snackbar,
    Tooltip,
    Typography
} from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from '@mui/x-data-grid';
import {useContext, useEffect, useState} from 'react';
import {deleteCoupon, deleteProduct, getCoupons, getDesigns} from "../../apis/ClientDataService";
import { dateFormatterLocal } from "../../utils/Utils";
import IconContextMenu from "../scenarios/price-modelling/components/IconContextMenu";
import {CouponContext, ProductContext} from "./PackagingFlow";
import ProductActions from "./ProductActions";
import CouponActions from "./CouponActions";
import ScenarioDataContext from "../scenarios/ScenarioDataContext";
import {getOrgFromLocalStore} from "../../state/state";
import Link from "@mui/material/Link";
import OrganizationContext from "../../state/OrganizationContext";
import * as React from "react";
import DesignActions from "./DesignActions";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Extend dayjs to use the relativeTime plugin
dayjs.extend(relativeTime);

export default function CouponFlow(props) {

    const { globalOrganization, updateGlobalOrganization } = useContext(OrganizationContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMoreActions = Boolean(anchorEl);

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    }

    const getWhoColumns = (row) => {
        const styles = {
            label: {
                fontSize: 12,
                pr: 5
            },
            value: {
                fontSize: 14
            },
        }

        return <table>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.created_by_user ?? "System"}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.created_date)}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Last updated by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.modified_by_user ?? 'System'}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Updated on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.modified_date)}</Typography>
                </td>
            </tr>
        </table>
    }

    const [openView, setOpenView] = useState(false);
    const handleViewCouponClick = () => {
        setOpenView(!openView);
    };

    const StatusCell = (params) => {
        const redeemBy = params.row.redeem_by;
        const [now, setNow] = useState(dayjs());

        useEffect(() => {
            const timer = setInterval(() => {
                setNow(dayjs());
            }, 30000); // Update every 30 seconds

            return () => {
                clearInterval(timer); // Clean up on unmount
            };
        }, []);

        const redeemByDate = dayjs.unix(redeemBy);
        const timeLeft = redeemByDate.from(now);

        if (!redeemBy || now.isBefore(redeemByDate)) {
            return (
                <Tooltip title={redeemBy ? `Expires ${timeLeft}` : 'Expires at Coupon deletion'}>
                    <Chip label="ACTIVE" color="success" variant="contained"
                          sx={{
                              '&:hover':{
                                  cursor:'pointer'
                              }
                          }}
                    />
                </Tooltip>
            );
        } else {
            return <Tooltip title={`Expired ${timeLeft}`}>
                <Chip label="EXPIRED" color="error" variant="contained"
                      sx={{
                          '&:hover':{
                              cursor:'pointer'
                          }
                      }}
                />
            </Tooltip>;
        }
    };

    const columns = [
        { field: 'name', headerName: 'Coupon Name', flex: 2 },
        { field: 'code', headerName: 'Coupon Code', flex: 2 },
        // {
        //     field: 'discount', headerName: 'Discount', flex: 2, renderCell: (params) => {
        //         const row = params.row;
        //         return <>
        //             {`${row.discount_percentage} %`}
        //         </>
        //     }
        // },
        // {
        //     field: 'duration', headerName: 'Duration', flex: 2, renderCell: (params) => {
        //         const row = params.row;
        //         return <>
        //             {row.duration !== 'Multiple Months' ? row.duration : `${row.duration_in_months} months`}
        //         </>
        //     }
        // },
        {
            field: 'terms', headerName: 'Terms', flex: 3, renderCell: (params) => {
                const currDuration = params.row.duration;
                const currDiscountPercentage = params.row.discount_percentage
                return <>
                    {currDiscountPercentage}% off {currDuration !== 'Multiple Months' ?
                        `${currDuration.toLowerCase()}` :
                        `for ${params.row.duration_in_months} months`
                    }
                </>
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: StatusCell,
        },
        {
            field: 'expire_at', headerName: 'Expires At', flex: 3, renderCell: (params) => {
                return <>
                    {params.row.redeem_by ?
                    `${dayjs.unix(params.row.redeem_by).format('lll')}` :
                    'Until Coupon deletion'}
                </>
            }
        },
        // {
        //     field: 'dashboard', headerName: 'Dashboard Link', flex: 2, renderCell: (params) => {
        //         const row = params.row;
        //         return <Link
        //             href={`https://dashboard.stripe.com/${getOrgFromLocalStore().payment_integration.stripe_properties.connect_id}${isTestModeLink()}/coupons/${row.stripe_coupon_id}`}
        //             target="_blank"
        //             underline='none'
        //             color={'inherit'}
        //         >
        //             <OpenInNewIcon/>
        //         </Link>
        //     }
        // },
        {
            field: 'details', headerName: 'Who?', flex: 1, renderCell: (params) => {
                const row = params.row;
                return <IconContextMenu Icon={PersonIcon} Content={
                    <Grid sx={{ p: 2, zoom: 0.9 }}>
                        {getWhoColumns(row)}
                    </Grid>
                }></IconContextMenu>
            }
        },
        // {
        //     flex: 1,
        //     disableColumnMenu: true,
        //     sortable: false,
        //     align: "center",
        //     renderCell: (params) => {
        //         return <DeleteIcon
        //                     onClick={(event) => {
        //                         event.stopPropagation();
        //                         event.preventDefault();
        //                         setActiveCoupon(params.row)
        //                         setIsDialogOpen("removeCoupon")
        //                     }}
        //                     sx={{
        //                         color: "lightgray",
        //                         "&:hover": {
        //                             color: "black",
        //                             cursor: "pointer"
        //                         },
        //                     }}
        //             />
        //     },
        // },
        {
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            align: "center",
            renderCell: (params) => {
                const onClickFix = (event) => {
                    // console.log(params.row);
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                    setActiveCoupon(params.row)
                };
                return (
                    <>
                        <>
                            <Tooltip title="More Actions">
                                <MoreHorizIcon
                                    id="more-actions"
                                    aria-controls={openMoreActions ? 'more-actions-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMoreActions ? 'true' : undefined}
                                    onClick={(event) => {
                                        onClickFix(event)
                                    }}
                                    sx={{
                                        fontSize: 24,
                                        cursor: 'pointer',
                                        '&:hover': { bgcolor: 'primary.background', borderRadius: 1 },
                                    }}
                                />
                            </Tooltip>
                            <Menu
                                id="more-actions-menu"
                                aria-labelledby="more-actions"
                                anchorEl={anchorEl}
                                open={openMoreActions}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                onClose={(event) => {
                                    handleMenuClose(event);
                                }}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                elevation={2}
                                sx={{
                                    '& .MuiMenuItem-root': {
                                        '&:hover': {
                                            borderLeft: '2px solid',
                                            borderLeftColor: 'primary.main',
                                            backgroundColor: 'primary.background'
                                        },
                                        borderLeft: '2px solid white',
                                    },
                                    '& .MuiTypography-body1': {
                                        fontSize: 14, pr: 1
                                    }
                                }}
                            >
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleMenuClose(event);
                                        handleViewCouponClick()
                                    }}
                                >
                                    <ListItemIcon>
                                        <VisibilityIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>View</ListItemText>

                                </MenuItem>

                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleMenuClose(event);
                                        setIsDialogOpen("removeCoupon")
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    </>
                )
            }
        },
    ];

    const { scenario, updateScenario } = useContext(ScenarioDataContext);
    const { coupons, updateCoupons } = useContext(CouponContext);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [activeCoupon, setActiveCoupon] = useState();
    const [snackNotif, setSnackNotif] = useState("");

    const handleDialogClose = () => {
        setIsDialogOpen("")
        setError(null)
    }

    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }

    const [open, setOpen] = useState(false);
    const handleCreateCouponClick = () => {
        setOpen(!open);
    };

    // useEffect(() => {
    //     setLoading(true);
    //     setError(null);
    //     getCoupons(scenario.id, (data) => {
    //         updateCoupons(data);
    //         setLoading(false);
    //     }, err => {
    //         setLoading(false);
    //         setError("Organization coupons could not be fetched!");
    //         console.log(err);
    //     });
    // }, []);

    const removeCoupon= () => {
        const couponId = activeCoupon.id
        console.log(couponId)
        setLoading(true);
        setError(null);
        deleteCoupon(couponId, () => {
            updateCoupons(coupons.filter(coupon => coupon.id !== couponId));
            setLoading(false);
            handleSnackNotif("removeCoupon")
            handleDialogClose();
        }, err => {
            setLoading(false);
            setError(err?.error_message ?? "Coupons could not be removed!");
        });
    }

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                    <Typography fontSize={30}>
                        Coupons
                    </Typography>
                    <Grid item>
                        <Tooltip title="Add Coupon">
                            <Fab color="primary" aria-label="add" size="small"
                                 onClick={handleCreateCouponClick}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item
                      sx={{
                          height: '60vh',
                          bgcolor: 'white',
                          borderRadius: 2,
                          justifyContent: 'center',
                          alignItems: 'center'
                      }}
                >
                    {props.loading ?
                        <CircularProgress />
                        :
                        <>
                            {coupons.length
                                ?
                                <DataGrid
                                    sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                            outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiDataGrid-row:hover': {
                                            cursor: 'pointer'
                                        },
                                        width: '50vw',
                                        '& .MuiDataGrid-cell': {
                                            fontSize: 12,
                                        },
                                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                            display: 'none'
                                        }
                                    }}
                                    rowHeight={40}
                                    rows={coupons}
                                    // loading={loading}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 100 },
                                        },
                                    }}
                                    // onRowClick={handleRowClick}
                                    pageSizeOptions={[10, 50, 100]}
                                    // onCellClick={handleRowHover}
                                    // checkboxSelection
                                />
                                :
                                <Typography color={'primary.greyText'}>
                                    Add your first coupon by clicking on the + icon above.
                                </Typography>
                            }
                        </>
                    }
                </Grid>
            </Grid >

            {/*ADD COUPON DRAWER*/}
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <CouponActions
                    mode={'Add'}
                    closeActionsCoupon={handleCreateCouponClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*VIEW COUPON DRAWER*/}
            <Drawer anchor="right" open={openView} onClose={() => setOpenView(false)}>
                <CouponActions
                    mode={'View'}
                    coupon={activeCoupon}
                    closeActionsCoupon={handleViewCouponClick}
                />
            </Drawer>

            {/*REMOVE COUPON DIALOG*/}
            <Dialog open={isDialogOpen === "removeCoupon"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Remove Coupon From User</Typography>

                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Are you sure you want to remove this coupon from your coupons list?
                        </Alert>

                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>
                            <Button variant="contained" color="error" size="medium" onClick={removeCoupon} sx={{ ml: 1 }}>Remove</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*ADD COUPON NOTIFICATION*/}
            <Snackbar open={snackNotif === "addCoupon"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Coupon added successfully!
                </Alert>
            </Snackbar>

            {/*REMOVE COUPON NOTIFICATION*/}
            <Snackbar open={snackNotif === "removeCoupon"} autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Coupon removed successfully!
                </Alert>
            </Snackbar>

        </>
    );
}


import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, LinearProgress, Tooltip } from "@mui/material";
import React from "react";
import ScenarioUIStateContext from 'pages/scenarios/ScenarioUIStateContext';

export default function EditableInputsForm(props) {
    const [editMode, setEditMode] = React.useState(false);
    const { scenarioUIState } = React.useContext(ScenarioUIStateContext);

    const MiniView = props.miniView;
    const EditView = props.editView;

    return (
        <Grid container xs={12} item justifyContent="center" alignItems="center" direction="column">
            {!editMode &&
                <Tooltip title="Click to edit">
                    <Grid sx={{ textAlign: "center", cursor: "pointer", boxShadow: 2, borderRadius: 3, p: 2 }} container direction="row" justifyContent="flex-end" onClick={() => setEditMode(true)}>
                        {MiniView}
                        <EditIcon sx={{ ml: 3, color: "primary.sub", width: 20 }}></EditIcon>
                    </Grid>
                </Tooltip>
            }
            {editMode && EditView}
            {editMode &&
                <Grid key={scenarioUIState}>
                    {scenarioUIState.loading === true && <LinearProgress sx={{ mt: 1 }} />}
                    <Button sx={{ textTransform: "none" }} onClick={() => { setEditMode(false); if (props.onCancel) props.onCancel(); }} disabled={scenarioUIState.loading}>Cancel</Button>
                    <Button sx={{ textTransform: "none" }} onClick={() => { if (props.onSubmit) props.onSubmit() }} disabled={scenarioUIState.loading}>Apply</Button>
                </Grid>
            }
        </Grid>
    )
}
import { Typography } from "@mui/material";
import React from "react";

export default function InputLabelGroup(props) {
    const labels = props.labels;
    return (
        <table>
            <tbody>

                {labels.map((label, idx) => {
                    return <tr key={idx}>
                        <td>
                            <Typography sx={{ fontSize: 13, color: "primary.text", textAlign: "left" }}>{label.name}</Typography>
                        </td>
                        <td>
                            <Typography sx={{ ml: 3, fontSize: 13 }}>{label.value}</Typography>
                        </td>
                    </tr>
                })
                }
            </tbody>
        </table>
    )
}
import { getUser, setUser } from "../state/state";
import mixpanel from "mixpanel-browser";
import { _ } from "ag-grid-community";

const debug = false;

export const apiCall = async (api, content, onSuccess, onFailure, noAlert) => {
    await fetch(api, { ...content })
        .then((response) => {

            if (debug) console.log("Raw response", api, response);

            if (response.status === 403) {
                onFailure({
                    "error": 403,
                    "error_message": "You are not authorized to peform this action"
                })
            } else if (response.status === 401) {
                if (debug) console.log("User in not authenticated, hence logging out", api);
                //Checking if any user is authenticated in current session only then logging out
                //Needed in case of otp verification going wrong
                if (getUser() !== null) {
                    localStorage.clear();
                    mixpanel.track("User Logged Out");
                    mixpanel.reset();
                    window.location = '/login';
                }
                else {
                    response.json().then((data) => {
                        onFailure(data);
                    });
                }
            } else if (response.status === 402) {
                console.log("Payment required, no active plan");
                window.location = '/pricing';
            } else if (!response.ok) {
                if (debug) console.log("Failure response", api, response);
                response.json().then((data) => {
                    onFailure(data);
                });
            } else if (response.ok && response.status === 204) {
                if (debug) console.log("No content received, meaning we cannot convert it to json", api);
                onSuccess();
            }
            else return response.json().then((data) => {
                if (debug) console.log("Response is parsed", api, data);
                onSuccess(data);
            });
        })
        .catch((err) => {
            console.log("Error", api, err);
            onFailure(err);
        });
};
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    TextField,
    Typography,
    Alert,
    InputAdornment,
    Checkbox,
    CircularProgress
} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import { addScenario } from "../../apis/ClientDataService";
import OrganizationContext from "../../state/OrganizationContext";
import WorkspaceDropSelector, { selectionList } from './WorkspaceDropSelector';

export default function ScenarioWorkspaceSelection({ handleAddClick,handleDialogClose }) {
    const { globalOrganization, limitationsRef } = useContext(OrganizationContext);
    const [newScenarioName, setNewScenarioName] = useState("");
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [limitationEngine,setLimitationEngine] = useState(true)
    useEffect(()=>{
       setLimitationEngine(limitationsRef.current.engine)
    },[])

    const inputsValid = () => {
        return newScenarioName.trim() && newScenarioName.length > 0 && selectedWorkspaces.length > 0 && !loading;
    }

    const addSelectedWorkspacesAndCreateScenario = () => {
        setSelectedWorkspaces((selectedWorkspaces) => {
            const orderedSelectedWorkspaces = selectedWorkspaces
                .sort((a, b) => a.id - b.id)
                .map((item) => (item.name));
            if (globalOrganization) {
                setLoading(true);
                setError(null);
                addScenario({
                    scenario_name: newScenarioName.trim(),
                    workspaces: orderedSelectedWorkspaces
                }, (scenario) => {
                    setLoading(false);
                    handleAddClick(scenario);
                }, (err) => {
                    setLoading(false);
                    setError(err?.error_message ?? "Error. Please contact priceops!");
                });
            }
            return selectedWorkspaces;
        });
    };

    const addWorkspace = (id) => {
        const option = selectionList.find((item) => item.id === id);
        setSelectedWorkspaces((selectedWorkspaces) => {
            const update = (selectedWorkspaces.length < 4 && !selectedWorkspaces.some((item) => item.id === id)) ?
                [...selectedWorkspaces, option] : selectedWorkspaces;

            return update;
        });
    };

    const removeWorkspace = (id) => {
        const updated = selectedWorkspaces.filter((item) => item.id !== id);
        setSelectedWorkspaces(updated);
    };

    const isPresentInSelectedWorkpaces = (workspaceId) => {
        if(selectedWorkspaces.find(selectedWorkspace => workspaceId === selectedWorkspace.id)){
            return true
        }
        return false
    };

    return (loading ?
            <Grid container justifyContent="center" alignItems="center"
                  sx={{width: 500, minHeight: 420, borderRadius: 2, p: 4}}>
                <CircularProgress/>
            </Grid>
            :
            <Grid container item sx={{ width: 500, minHeight: 420, borderRadius: 2, p: 4 }} direction="column" gap={2}>
                <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>Add Scenario</Typography>
                {/*<Typography sx={{ mb: 3, fontSize: 14 }}>You can create a new scenario by entering the scenario name and selecting workspaces as per your requirement.</Typography>*/}

                <TextField
                    variant="outlined"
                    size="small"
                    label="Scenario Name"
                    type="text"
                    value={newScenarioName}
                    onChange={(e) => { setNewScenarioName(e.target.value) }}
                />

                <Typography sx={{fontSize: 14, fontWeight: "bold",py:1 }} textAlign={'center'}>Select Your Workspaces</Typography>

                <Grid
                    container
                    item
                    gap={6}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {selectionList.map((workspace) => (
                        <Grid
                            container
                            alignItems={'center'}
                            borderRadius={2}
                            border={'2px solid'}
                            borderColor={isPresentInSelectedWorkpaces(workspace.id) ? 'primary.main' : 'white'}
                            width={140}
                            height={140}
                            direction={'column'}
                            textAlign={'center'}
                            sx={{
                                '&:hover':{
                                    cursor: 'pointer',
                                    borderColor: 'primary.main',
                                    filter: 'brightness(140%)'
                                },
                                boxShadow:3,
                            }}
                            onClick={(e)=>{
                                if(isPresentInSelectedWorkpaces(workspace.id)){
                                    removeWorkspace(workspace.id)
                                }else{

                                    // LIMITATION PRICING ENGINE CHECK
                                    if(workspace.name === "Price Modelling"
                                        && limitationEngine
                                    ){
                                        addWorkspace(workspace.id)
                                    }

                                    if(workspace.name === "Packaging"){
                                        addWorkspace(workspace.id)
                                    }

                                }
                            }}
                        >
                            <Grid container justifyContent={'flex-end'} p={0.3}>
                                <Checkbox
                                    checked={isPresentInSelectedWorkpaces(workspace.id)}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: 'primary.main',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid container textAlign={'center'} width={'100%'}
                                justifyContent={'center'}
                            >
                                <Typography
                                    fontSize={14}
                                    fontWeight={600}
                                    color={'primary.main'}
                                    pt={1.5}
                                    pl={workspace.name === "Price Modelling" ? 1 : 0}
                                >
                                    {workspace.name === "Price Modelling" ?
                                        "Pricing Engine"
                                        :
                                        workspace.name
                                    }
                                </Typography>
                                {
                                    workspace.name === "Price Modelling" &&
                                    <Typography
                                        fontSize={8}
                                        fontWeight={600}
                                        color={'darkgoldenrod'}
                                        pt={1}
                                    >
                                        BETA
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                {error && <Alert severity="error" onClose={() => setError(false)}>{error}</Alert>}

                <Grid item container justifyContent='flex-end' mt={2}>
                    <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>
                    <Button disabled={!inputsValid()} variant="outlined" size="medium" onClick={addSelectedWorkspacesAndCreateScenario} sx={{ ml: 1}}>Create Scenario</Button>
                </Grid>
            </Grid>
    )
}

import AddIcon from '@mui/icons-material/Add';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    Alert, Button,
    CircularProgress,
    Dialog,
    Drawer,
    Fab,
    Grid,
    IconButton,
    ListItemIcon, ListItemText,
    Snackbar, Tooltip,
    Typography
} from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { deleteDesign, getDesigns } from "../../apis/ClientDataService";
import ScenarioDataContext from "../scenarios/ScenarioDataContext";
import { ProductContext } from "./PackagingFlow";
import IconContextMenu from "../scenarios/price-modelling/components/IconContextMenu";
import PersonIcon from "@mui/icons-material/Person";
import { dateFormatterLocal } from "../../utils/Utils";
import PreviewIcon from '@mui/icons-material/Preview';
import Link from "@mui/material/Link";
import DesignActions from "./DesignActions";

export default function DesignFlow() {

    const { products, updateProducts } = useContext(ProductContext)

    const [anchorEl, setAnchorEl] = useState(null);
    const openMoreActions = Boolean(anchorEl);

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    }

    const [selectedClipboard, setSelectedClipboard] = React.useState(null);

    const ClipBoard = ({ text, id }) => {
        return <IconButton onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(text);
            setSelectedClipboard(id);
        }}>
            {selectedClipboard !== id ?
                <Tooltip title="Click to copy script tags">
                    <ContentCopyIcon sx={{ color: 'black' }} />
                </Tooltip> :
                <Tooltip title="Copied!">
                    <AssignmentTurnedInIcon sx={{ color: 'black' }} />
                </Tooltip>}
        </IconButton>
    }

    const getWhoColumns = (row) => {
        const styles = {
            label: {
                fontSize: 12,
                pr: 5
            },
            value: {
                fontSize: 14
            },
        }

        return <table>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.created_by_user ?? "System"}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.created_date)}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Last updated by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.modified_by_user ?? 'System'}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Updated on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.modified_date)}</Typography>
                </td>
            </tr>
        </table>
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 2 },
        {
            field: 'script', headerName: 'Script', flex: 4, renderCell: (params) => {
                const id = params.row.id;
                const text = '<object data="https://app.priceops.net/designs/' + id + '"' +
                    '   style="width:100%;height:100%;"' +
                    '   type="text/html">' +
                    '</object>';
                return <Grid display="flex" alignItems="center">
                    <Tooltip title={<React.Fragment>
                        <div>{text}</div>
                        <p>You can use this tags to embed in your website</p>
                    </React.Fragment>}>
                        <Grid sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                            whiteSpace: "nowrap",
                            maxWidth: '20vw',
                            mr: 1
                        }}>{text}</Grid>
                    </Tooltip>
                    <ClipBoard text={text} id={id} />
                </Grid>
            }
        },
        {
            field: 'preview', headerName: 'Preview', flex: 2, renderCell: (params) => {
                const row = params.row;
                return <Tooltip title="Click to view this design">
                    <Link href={`/designs/${params.id}`} target="_blank" rel="noopener noreferrer"
                        underline="none" sx={{ color: 'inherit' }}>
                        <PreviewIcon />
                    </Link>
                </Tooltip>
            }
        },
        // { field: 'payment_gateway', headerName: 'Payment Gateway', flex: 2 },
        // { field: 'api_key', headerName: 'API Key', flex: 2 },
        {
            field: 'details', headerName: 'Who?', flex: 2, renderCell: (params) => {
                const row = params.row;
                return <IconContextMenu Icon={PersonIcon} Content={
                    <Grid sx={{ p: 2, zoom: 0.9 }}>
                        {getWhoColumns(row)}
                    </Grid>
                }></IconContextMenu>
            }
        },
        // { field: 'numberOfProducts', headerName: 'Number Of Products', flex:2 },
        // { field: 'created_by_user', headerName: 'Created By', flex: 3 },
        // { field: 'modified_by_user', headerName: 'Updated By', flex: 3 },
        // {
        //     field: 'created_date',
        //     headerName: 'Created On',
        //     flex: 3,
        //     valueFormatter: (params) => {
        //         const date = new Date(params.value);
        //         return date.toLocaleString('en-US',
        //             {
        //                 day: 'numeric',
        //                 month: 'short',
        //                 year: 'numeric',
        //                 hour: '2-digit',
        //                 minute: '2-digit',
        //                 hour12: true,
        //             });
        //     }
        // },
        // {
        //     field: 'modified_date',
        //     headerName: 'Updated On',
        //     flex: 3,
        //     valueFormatter: (params) => {
        //         const date = new Date(params.value);
        //         return date.toLocaleString('en-US',
        //             {
        //                 day: 'numeric',
        //                 month: 'short',
        //                 year: 'numeric',
        //                 hour: '2-digit',
        //                 minute: '2-digit',
        //                 hour12: true,
        //             });
        //     }
        // },
        {
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            align: "center",
            renderCell: (params) => {
                const onClickFix = (event) => {
                    // console.log(params.row);
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                    setActiveDesign(params.row)
                };
                return (
                    <>
                        <>
                            <Tooltip title="More Actions">
                                <MoreHorizIcon
                                    id="more-actions"
                                    aria-controls={openMoreActions ? 'more-actions-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMoreActions ? 'true' : undefined}
                                    onClick={(event) => {
                                        onClickFix(event)
                                    }}
                                    sx={{
                                        fontSize: 24,
                                        cursor: 'pointer',
                                        '&:hover': { bgcolor: 'primary.background', borderRadius: 1 },
                                    }}
                                />
                            </Tooltip>
                            <Menu
                                id="more-actions-menu"
                                aria-labelledby="more-actions"
                                anchorEl={anchorEl}
                                open={openMoreActions}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                onClose={(event) => {
                                    handleMenuClose(event);
                                }}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                elevation={2}
                                sx={{
                                    '& .MuiMenuItem-root': {
                                        '&:hover': {
                                            borderLeft: '2px solid',
                                            borderLeftColor: 'primary.main',
                                            backgroundColor: 'primary.background'
                                        },
                                        borderLeft: '2px solid white',
                                    },
                                    '& .MuiTypography-body1': {
                                        fontSize: 14, pr: 1
                                    }
                                }}
                            >
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleMenuClose(event);
                                        handleUpdateDesignClick()
                                    }}
                                >
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Edit</ListItemText>

                                </MenuItem>

                                {/*<MenuItem*/}
                                {/*    onClick={(event) => {*/}
                                {/*        event.stopPropagation();*/}
                                {/*        event.preventDefault();*/}
                                {/*        handleMenuClose(event);*/}
                                {/*        // fn to open details display here*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <ListItemIcon>*/}
                                {/*        <InfoIcon fontSize="small" />*/}
                                {/*    </ListItemIcon>*/}
                                {/*    <ListItemText>Details</ListItemText>*/}
                                {/*</MenuItem>*/}

                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleMenuClose(event);
                                        setIsDialogOpen("removeDesign")
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                        {/*<Tooltip title="Delete Design">*/}
                        {/*    <DeleteIcon*/}
                        {/*        onClick={(event) => {*/}
                        {/*            event.preventDefault();*/}
                        {/*            event.stopPropagation();*/}
                        {/*            setIsDialogOpen("removeDesign")*/}
                        {/*            setActiveDesign(params.row)*/}
                        {/*        }}*/}
                        {/*        sx={{*/}
                        {/*            color: "lightgray",*/}
                        {/*            "&:hover": {*/}
                        {/*                color: "black",*/}
                        {/*                cursor: "pointer"*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Tooltip>*/}
                        {/*<Tooltip title="Edit Design">*/}
                        {/*    <EditIcon*/}
                        {/*        onClick={(event) => {*/}
                        {/*            event.preventDefault();*/}
                        {/*            event.stopPropagation();*/}
                        {/*            setActiveDesign(params.row);*/}
                        {/*            handleUpdateDesignClick();*/}
                        {/*        }}*/}
                        {/*        sx={{*/}
                        {/*            color: "lightgray",*/}
                        {/*            "&:hover": {*/}
                        {/*                color: "black",*/}
                        {/*                cursor: "pointer"*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Tooltip>*/}
                    </>

                )
            }
        },
    ];

    const { scenario, updateScenario } = useContext(ScenarioDataContext);
    const [designs, setDesigns] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [activeDesign, setActiveDesign] = useState();
    const [snackNotif, setSnackNotif] = useState("");

    const handleDialogClose = () => {
        setIsDialogOpen("")
        setError(null)
    }

    const handleSnackNotif = (notif) => {
        setSnackNotif(notif)
    }


    const [open, setOpen] = useState(false);
    const handleCreateDesignClick = () => {
        setOpen(!open);
    };

    const [openEdit, setOpenEdit] = useState(false);
    const handleUpdateDesignClick = () => {
        setOpenEdit(!openEdit);
    };

    useEffect(() => {
        setLoading(true);
        setError(null);
        getDesigns(scenario.id, (data) => {
            setDesigns(data);
            setLoading(false);
        }, err => {
            setLoading(false);
            setError("Organization designs could not be fetched!");
            console.log(err);
        });
    }, []);

    const handleDesignAdd = (design) => {
        setDesigns([...designs, design])
    }

    const handleDesignUpdate = (updatedDesigns) => {
        setDesigns(updatedDesigns)
    }

    const removeDesign = () => {
        const designId = activeDesign.id
        setLoading(true);
        setError(null);
        deleteDesign(designId, () => {
            setDesigns(designs.filter(design => design.id !== designId));
            setLoading(false);
            handleSnackNotif("removeDesign")
            handleDialogClose();
        }, err => {
            setLoading(false);
            setError(err?.error_message ?? "Designs could not be removed!");
        });
    }

    return (
        <>
            <Grid container item sx={{ border: "1px solid lightgray", p: 2, bgcolor: 'primary.background' }}>
                <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                    <Typography fontSize={30}>
                        Designs
                    </Typography>
                    <Grid item>
                        <Tooltip title="Add Design">
                            <Fab color="primary" aria-label="add" size="small"
                                onClick={handleCreateDesignClick}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item
                    sx={{
                        height: '60vh',
                        bgcolor: 'white',
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {loading ?
                        <CircularProgress />
                        :
                        <>
                            {designs.length
                                ?
                                <DataGrid
                                    sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                            outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiDataGrid-row:hover': {
                                            cursor: 'pointer'
                                        },
                                        width: '50vw',
                                        '& .MuiDataGrid-cell': {
                                            fontSize: 12,
                                        },
                                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                            display: 'none'
                                        }
                                    }}
                                    getRowHeight={() => 'auto'}
                                    rows={designs}
                                    // loading={loading}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 100 },
                                        },
                                    }}
                                    // onRowClick={handleRowClick}
                                    pageSizeOptions={[10, 50, 100]}
                                // onCellClick={handleRowHover}
                                // checkboxSelection
                                />
                                :
                                <Typography color={'primary.greyText'}>
                                    Add your first design by clicking on the + icon above.
                                </Typography>
                            }
                        </>
                    }
                </Grid>
            </Grid >

            {/*ADD DESIGN DRAWER*/}
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <DesignActions
                    mode={'Add'}
                    updateDesigns={handleDesignAdd}
                    closeActionsDesign={handleCreateDesignClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*UPDATE DESIGN DRAWER*/}
            <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
                <DesignActions
                    mode={'Edit'}
                    design={activeDesign}
                    designs={designs}
                    updateDesigns={handleDesignUpdate}
                    closeActionsDesign={handleUpdateDesignClick}
                    handleSnackNotif={handleSnackNotif}
                />
            </Drawer>

            {/*REMOVE PRODUCT DIALOG*/}
            <Dialog open={isDialogOpen === "removeDesign"} onClose={handleDialogClose}>
                {loading ?
                    <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                        <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Remove Design From User</Typography>

                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Are you sure you want to remove this design from your designs list?
                        </Alert>

                        {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                        <Grid item container justifyContent='flex-end'>
                            <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>
                            <Button variant="contained" color="error" size="medium" onClick={removeDesign} sx={{ ml: 1 }}>Remove</Button>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            {/*ADD DESIGN NOTIFICATION*/}
            <Snackbar open={snackNotif === "addDesign"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Design added successfully!
                </Alert>
            </Snackbar>

            {/*UPDATE DESIGN NOTIFICATION*/}
            <Snackbar open={snackNotif === "updateDesign"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Design updated successfully!
                </Alert>
            </Snackbar>

            {/*REMOVE DESIGN NOTIFICATION*/}
            <Snackbar open={snackNotif === "removeDesign"} autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setSnackNotif("")}>
                <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                    Design removed successfully!
                </Alert>
            </Snackbar>
        </>
    );
}


import Check from '@mui/icons-material/Check';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { Button, CircularProgress, Grid, ListItemIcon, MenuItem, MenuList, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from 'react-router-dom';
import { getInsights } from "../../apis/ClientDataService";
import CardContentContainer from "../../components/CardContentContainer";
import SegmentFilters from '../../components/SegmentFilters';
import { fractionToPercentFormatter } from '../../utils/Utils';
import DataIntegrations from '../settings/DataIntegrations';
import DataView from './DataView';
import DetailView from "./DetailView";
import MetricsGraph from './MetricsGraph';


const metrics = {
    'month': [
        { title: 'Monthly Recurring Revenue', metric: 'revenue', y: 'Revenue' },
        { title: 'Monthly Churn', metric: 'churn', y: 'Churn' },
        { title: 'Monthly Retain', metric: 'keep', y: 'Revenue' },
        { title: 'Monthly Acquire', metric: 'win', y: 'Revenue' },
        { title: 'Monthly Expand', metric: 'grow', y: 'Revenue' },
        { title: 'Monthly Net Dollar Retention', metric: 'ndr', y: 'NDR', formatter: fractionToPercentFormatter },
    ],
    'year': [
        { title: 'Annual Recurring Revenue', metric: 'revenue', y: 'Revenue' },
        { title: 'Annual Churn', metric: 'churn', y: 'Churn' },
        { title: 'Annual Retain', metric: 'keep', y: 'Revenue' },
        { title: 'Annual Acquire', metric: 'win', y: 'Revenue' },
        { title: 'Annual Expand', metric: 'grow', y: 'Revenue' },
        { title: 'Annual Customer Acquisition Cost', metric: 'cac', y: 'CAC' },
        { title: 'Annual Net Dollar Retention', metric: 'ndr', y: 'NDR', formatter: fractionToPercentFormatter },
        { title: 'Annual LTV', metric: 'ltv', y: 'LTV' }
    ]
}

export default function Insights(props) {
    const [searchParams] = useSearchParams();
    const [data, setData] = React.useState(null);
    const [period, setPeriod] = React.useState(searchParams.get('period') ? searchParams.get('period') : "month");
    const [viewMode, setViewMode] = React.useState("dashboard");
    const [selectedMetric, setSelectedMetric] = React.useState(null);
    const [key, setKey] = React.useState(1);
    const [customerInsightsAvailable, setCustomerInsightsAvailable] = React.useState(false);
    const [expenseInsightsAvailable, setExpenseInsightsAvailable] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [showFilters, setShowFilters] = React.useState(false);
    const [filters, setFilters] = React.useState({});

    const mergeYearlyMetrics = (data) => {
        if (data) {
            setCustomerInsightsAvailable(true);
            const yearly_metrics = data["yearly_metrics"];
            const expense_metrics = data["expense_metrics"];
            const expense_metrics_by_year = {};
            if (expense_metrics && yearly_metrics) {
                for (let i = 0; i < expense_metrics.length; i++) {
                    var metrics = expense_metrics[i];
                    var year = metrics['year'];
                    expense_metrics_by_year[year] = metrics;
                }
                for (let i = 0; i < yearly_metrics.length; i++) {
                    metrics = yearly_metrics[i];
                    year = metrics['year'];
                    var expense = expense_metrics_by_year[year];
                    metrics['cac'] = expense ? expense['cac'] : 0;
                    metrics['ltv'] = expense ? expense['ltv'] : 0;
                }
                setExpenseInsightsAvailable(true);
            }
        }
    }

    const getInsightsData = () => {
        setLoading(true);
        getInsights(filters, (data) => {
            console.log(data);
            mergeYearlyMetrics(data);
            setData(data);
            setLoading(false);
            setKey(key + 1);
        }, (err) => {
            setLoading(false);
            console.log(err.error);
        });
    }

    React.useEffect(() => {
        getInsightsData();
    }, []);

    const selectMetric = (metric, period) => {
        setViewMode("detail");
        setSelectedMetric(metrics[period].find(x => x['metric'] === metric));
        setPeriod(period);
        setKey(key + 1);
    }

    const styles = {
        menuItem: {
            borderRadius: 1
        },
        menuItemSelected: {
            backgroundColor: "primary.background",
            color: "primary.main",
        }
    }

    const notifyDataLoad = (datatype) => {
        if (datatype === "customer") {
            setCustomerInsightsAvailable(true);
            if (expenseInsightsAvailable) {
                getInsightsData();
            }
        }
        if (datatype === "expense") {
            setExpenseInsightsAvailable(true);
            if (customerInsightsAvailable) {
                getInsightsData();
            }
        }
    }

    const applyFilters = () => {
        getInsightsData();
    }

    return (
        <Grid container item xs={12} sx={{ backgroundColor: "primary.background", borderRadius: 2 }}>
            <CardContentContainer
                {...props}
                cardIcon={<StackedBarChartIcon />}
                title="Insights"
                content={
                    <React.Fragment>
                        <Grid container item xs={12} justifyContent="right">
                            {!showFilters && <Button onClick={() => setShowFilters(true)} sx={{ textTransform: "none" }}>Show filters</Button>}
                            {showFilters && <SegmentFilters
                                handleSegmentFilterChange={(filters) => setFilters(filters)}
                            >
                            </SegmentFilters>}
                            <Grid container item xs={12} justifyContent="right" sx={{ p: 1 }}>
                                {showFilters && <Button variant="outlined" onClick={() => applyFilters()}>Apply filters</Button>}
                                {showFilters && <Button onClick={() => setShowFilters(false)} sx={{ textTransform: "none" }}>Hide filters</Button>}
                            </Grid>
                        </Grid>
                        {loading &&
                            <Grid container item xs={12} alignItems="center" justifyContent="center">
                                <Typography sx={{ color: "primary.text", mt: 10, mb: 2 }}>Generating insights...</Typography>
                                <CircularProgress />
                            </Grid>}
                        {!(customerInsightsAvailable && expenseInsightsAvailable) && !loading && < DataIntegrations nodatamessage="No insights data found, please load data" notify={notifyDataLoad}></DataIntegrations>}
                        {customerInsightsAvailable && expenseInsightsAvailable && !loading &&
                            < Grid key={key} xs={12} container item justifyContent="center">
                                {viewMode === "dashboard" &&
                                    <React.Fragment>
                                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                                            <ToggleButtonGroup value={period} onChange={(e) => setPeriod(e.target.value)} sx={{ height: 30 }}>
                                                <ToggleButton value="month">Month</ToggleButton>
                                                <ToggleButton value="year">Year</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid xs={12} container item justifyContent="center">
                                            {metrics[period].map(entry => {
                                                const metric = entry['metric'];
                                                return (<MetricsGraph
                                                    key={data + period + metric}
                                                    metric={metric}
                                                    period={period}
                                                    title={entry['title']}
                                                    data={data}
                                                    xLabel={period}
                                                    yLabel={entry['y']}
                                                    yFormatter={entry['formatter']}
                                                    onClick={() => selectMetric(metric, period)}
                                                    chartType="simple"
                                                    hideDot
                                                    variant="mini">
                                                </MetricsGraph>)
                                            })}
                                        </Grid>
                                    </React.Fragment>
                                }
                                {viewMode === "detail" &&
                                    <Grid key={key} container item xs={12}>
                                        <Grid container item xs={12} flex="flex-end" sx={{ borderBottom: 1, pb: 2, borderColor: "primary.groupBorder" }}>
                                            <Button variant="outlined" onClick={() => setViewMode("dashboard")}>Back to dashboard</Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MenuList dense>
                                                {metrics[period].map(entry => {
                                                    const selected = entry['metric'] === selectedMetric['metric'];
                                                    const style = selected ? [styles.menuItem, styles.menuItemSelected] : [styles.menuItem];
                                                    return (<MenuItem key={entry['metric']}
                                                        onClick={() => selectMetric(entry['metric'], period)}
                                                        sx={style}>
                                                        <ListItemIcon>{selected && <Check />}</ListItemIcon>
                                                        {entry['title']}
                                                    </MenuItem>)
                                                })}
                                            </MenuList>
                                        </Grid>
                                        <Grid item xs={9} justifyContent="center">
                                            <DetailView period={period} metric={selectedMetric} data={data}></DetailView>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataView period={period} metric={selectedMetric} data={data}></DataView>
                                        </Grid>
                                    </Grid>}
                            </Grid>}
                    </React.Fragment>
                } />
        </Grid >
    )
}
import { Grid, Menu, Tooltip } from "@mui/material";
import React from "react";

export default function IconContextMenu(props) {
    const Icon = props.Icon;
    const MenuContent = props.Content;

    const [anchorEl, setAnchorEl] = React.useState(null);
    return <Grid>
        <Tooltip title= {props.TitleText ? props.TitleText : "Click to see details"}>
            <Icon
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); setAnchorEl(e.currentTarget) }}
                sx={{
                    pt:0.5,
                }}
            >
            </Icon>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {MenuContent}
        </Menu>
    </Grid>
}
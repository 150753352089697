const locales = {
  US: { symbol: "$", locale: "en-US" },
  IND: { symbol: "\u20B9", locale: "en-IN" },
};

var curr = "US";

export function isTestModeLink() {
  var testMode = false;
  return testMode ? '/test' : '';
}

export function fractionToPercentFormatter(val) {
  return val ? (val * 100).toFixed(2) + "%" : 0 + "%";
}

export function percentFormatter_2(val) {
  return val ? val.toFixed(2) + "%" : 0 + "%";
}

export function getCurrency() {
  return locales[curr];
}

export function textNum(txt) {
  if (String(txt) === String(0)) return 0;
  if (!txt || String(txt).length === 0) return '';
  var val = parseFloat(String(txt).replace(/,/g, ""));
  return val;
}

export function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export function isValidNumber(txt) {
  return !isNaN(textNum(txt));
}

function convertToProperFraction(decimalNumber) {
  var absNumber = Math.abs(decimalNumber);

  if (absNumber === 0) {
    return [0, 1];
  }

  var powers = ["", "k", "m", "bn", "e12", "e15"];
  let powerIndex = 0;

  while (absNumber < 0.001 && powerIndex < powers.length - 1) {
    absNumber *= 1000;
    powerIndex++;
  }

  return [Number(absNumber.toFixed(6)), `1${powers[powerIndex]}`];
}

function sigdig(val, dig) {
  let v = Math.abs(val)
  if (v === 0) {
    return 0.00
  }

  const f = 10 ** (-Math.ceil(Math.log10(v)) + dig);
  return Math.sign(val) * (Math.round(v * f) / f);
}

export function currencyFractionFormatter(txt) {
  var num = textNum(txt);

  if (num < 1) {
    var decimal = sigdig(num, 2);
    var properFraction = convertToProperFraction(decimal);
    return [getCurrency().symbol + properFraction[0], properFraction[1]];
  } else {
    return [getCurrency().symbol + commaCurrency(Math.ceil(num)), 1];
  }
}

export function currencyFractionFormatterMini(txt) {
  var num = textNum(txt);

  if (num < 1) {
    var decimal = sigdig(num, 2);
    var properFraction = convertToProperFraction(decimal);
    return [getCurrency().symbol + properFraction[0], properFraction[1]];
  } else {
    return [currencyFormatter(Math.ceil(num)), 1];
  }
}

export function pricePointFormatter(txt) {
  var num = textNum(txt);
  if (num > 1) {
    return getCurrency().symbol + commaCurrency(Math.ceil(num));
  } else {
    return getCurrency().symbol + sigdig(num, 2);
  }
}

export function commaCurrency(txt) {
  var num = textNum(txt);
  if (isNaN(num)) {
    return "-";
  }
  return num < 1 ? num : num.toLocaleString(getCurrency().locale);
}

export function currencyFormatter(txt, digits) {
  if (!isValidNumber(txt)) return "-";
  return getCurrency().symbol + nFormatter(txt, digits);
}

export function nFormatter(numText, fixed) {
  var txt = String(numText);
  if (txt.length === 0) return "";
  var num = textNum(txt);
  if (num === null || isNaN(num)) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "k", "m", "bn", "e12"][k]; // append power
  return e;
}

export function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function formattedLabel(txt) {
  var t = txt.replace("-", " ").replace("_", " ");
  return t[0].toUpperCase() + t.slice(1);
}

export const getRevRangeLabel = (revRange) => {
  var loHi = revRange.split("-")
  if (loHi[0] === String(0)) return `< ${currencyFormatter(loHi[1])}`
  if (loHi[1] === String(0)) return `> ${currencyFormatter(loHi[0])}`
  return currencyFormatter(loHi[0]) + "-" + currencyFormatter(loHi[1]);
}

export const dateFormatterLocal = (isoDateString) => {
  if (!isoDateString) return "";
  const localDate = new Date(isoDateString);
  return localDate.toLocaleString().replace(",", "");
}

export function adjustArrayValuesToSumTo100(a, i, newValue) {
  const n = a.length;

  // Calculate the current sum excluding the value at index i
  const currentSum = (a.reduce((sum, val, index) => index !== i ? sum + val : sum, 0)) + newValue;

  a[i] = newValue;

  // current value is increased, reduce other values.
  var adjustment, j;
  if (currentSum > 100) {
    adjustment = currentSum - 100;
    j = n - 1;
    while (j >= 0 && adjustment > 0) {
      if (j === i) {
        j--;
        continue;
      }

      if (a[j] > adjustment) {
        a[j] -= adjustment;
        break;
      } else {
        adjustment -= a[j];
        a[j] = 0;
      }
      j--;
    }
  } else {
    adjustment = 100 - currentSum;
    // current value is decreased, increase other values.
    j = n - 1;
    while (j >= 0 && adjustment > 0) {
      if (j === i) {
        j--;
        continue;
      }

      a[j] += adjustment;
      if (a[j] >= 100) {
        adjustment = a[j] - 100;
        a[j] = 100;
      } else {
        break;
      }
      j--;
    }
  }

  return a;
}

export function snakeCaseToTitleCase(str) {
  return str?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ');
}

export function groupByAndCollect(arr, key) {
  return arr.reduce((result, item) => {
    // Extract the value for the key
    const keyValue = item[key];

    // Check if there is already a list for this key
    if (!result[keyValue]) {
      result[keyValue] = [];
    }

    // Add the item to the list corresponding to the key
    result[keyValue].push(item);

    return result;
  }, {});
}


export function idString(inputString) {
  // Convert to lowercase and remove non-alphanumeric characters
  const cleanedString = inputString.toLowerCase().replace(/[^a-z0-9]/g, '');

  return cleanedString;
}

export function ensureEndsWithS(inputString) {
  return inputString.endsWith('s') ? inputString : inputString + 's';
} 


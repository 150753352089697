import React from "react";

import {Divider, Grid, Typography} from "@mui/material";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginForm from "./LoginForm";
import RegisterFlow from "./RegisterForm";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function LoginPage(props) {
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [registerPage, setRegisterPage] = React.useState(false);
  const [email, setEmail] = React.useState(null);

  return (
      <Grid
          container item xs={12} className="Login"
          sx={{
              minHeight: "100vh"
          }}
      >
          <Grid xs container  rowGap={8}
                sx={{
                    bgcolor:'primary.background'
                }}
                alignItems={'center'} justifyContent={'center'} direction={'column'} p={6}>

              {/*<Typography fontSize={20}>Welcome To*/}
              {/*    <img src={require("./../../menubar/just_logo.png")} width={40} height={22} alt="logo" />PriceOps*/}
              {/*</Typography>*/}

              <Grid container justifyContent={'center'} width={'100%'} direction={'column'} mt={-5}>
                  {/*<Typography fontSize={'8.8vw'} textAlign={'center'} fontWeight={800} color={'primary.main'}>PriceOps</Typography>*/}
                  <Typography fontSize={'3.1vw'} textAlign={'center'} fontWeight={800} color={'#00171F'}>SaaS Pricing & Packaging.</Typography>
                  <Typography fontSize={'3.5vw'} textAlign={'center'} fontWeight={800} color={'primary.main'}>Simplified.</Typography>
                  {/*<Typography fontSize={'1.2vw'} textAlign={'center'} pt={2} color={'black'}>*/}
                  {/*    Pricing and Packaging infrastructure for SaaS companies.*/}
                  {/*</Typography>*/}
              </Grid>
              {/* <Grid container justifyContent={'center'}>*/}
              {/*   <br/>*/}
              {/*  <Typography fontSize={30} pl={1}>*/}
              {/*    Increase*/}
              {/*  </Typography>*/}
              {/*  <Typography fontSize={30} fontWeight={800} color={'#6C63FF'} pl={1}>*/}
              {/*    revenue,*/}
              {/*  </Typography>*/}
              {/*  <Typography fontSize={30} pl={1}>*/}
              {/*    Reduce*/}
              {/*  </Typography>*/}
              {/*  <Typography fontSize={30} fontWeight={800} color={'#6C63FF'} pl={1}>*/}
              {/*    time-to-value*/}
              {/*  </Typography>*/}
              {/*  <Typography fontSize={30} pl={1}>*/}
              {/*    and Improve*/}
              {/*  </Typography>*/}
              {/*  <Typography fontSize={30} fontWeight={800} color={'#6C63FF'} pl={1}>*/}
              {/*    price governance*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid container width={500} p={4} minHeight={'100vh'}>
              <Typography fontSize={'2vw'} fontWeight={800} color={'primary.main'}>
                  PriceOps
              </Typography>
              <Grid
                  container
                  width={'100%'}
                  // minHeight={'100vh'}
                  direction={'column'}
                  justifyContent={'flex-start'}
                  sx={{backgroundColor: "white", px:'6vh'}}
              >
                  <Typography fontSize={20} fontWeight={800} pt={'2.5vh'}>
                      { forgotPassword ?
                        'Forgot your password?' :
                          registerPage ?
                              'Create your account'
                              :
                              'Welcome back!'
                      }
                  </Typography>

                  <Typography fontSize={12} pt={1}>
                      {registerPage ?
                          'Please enter your details and verify your account.'
                          :
                          forgotPassword ?
                              "Don't worry we will send an reset password email to help you get back to your account."
                              :
                              'Please enter your details below to login.'
                      }
                  </Typography>

                  <Grid
                      // xs height={'100%'}
                      container
                      width={'100%'}
                      // alignItems={'center'}
                  >
                      {registerPage ?
                          <RegisterFlow
                              width={'100%'}
                              setRegisterPage={setRegisterPage}
                              email={email}
                              onSuccess={(email) => setEmail(email)}
                          />
                          :
                          <React.Fragment>
                              {!forgotPassword && <LoginForm
                                  setRegisterPage={setRegisterPage}
                                  email={email}
                                  onFailure={(email) => setEmail(email)}
                                  onLogin={(user) => props.onSuccess(user)}
                                  onForgotPassword={() => setForgotPassword(true)}></LoginForm>}
                              {forgotPassword &&
                                  <ForgotPasswordForm email={email} backToLogin={() => setForgotPassword(false)} />}
                          </React.Fragment>
                      }
                  </Grid>
              </Grid>
          </Grid>

      </Grid >
    // <Grid
    //     container item xs={12} className="Login"
    //     sx={{
    //       minHeight: "100vh"
    //     }}
    // >
    //   <Grid xs container sx={{
    //       // bgcolor:'#110B11'
    //       // bgcolor: 'primary.background'
    //       backgroundImage: 'linear-gradient(100deg, #000000, #434343)'
    //       // background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,121,63,1) 35%, rgba(0,212,255,1) 100%)'
    //       // backgroundImage: 'url("https://images.ctfassets.net/9qa6g0dkae5p/40JbjZIWJXhMFjNm66nPVpmigrated/de1f02adc334232bc4ae41e19c1278a8/SaaS_Pricing_Page_Design.png")',
    //       // backgroundSize:'1200px 590px'
    //   }}
    //     p={7}
    //   >
    //       {/*<Grid direction={'column'}*/}
    //           {/*<Typography fontSize={20}>Welcome To*/}
    //           {/*    <img src={require("./../../menubar/just_logo.png")} width={40} height={22} alt="logo" />PriceOps*/}
    //           {/*</Typography>*/}
    //
    //           <Grid container direction={'column'}>
    //               <Typography fontSize={'2.5vw'} color={'#f8fcda'} fontWeight={800} fontFamily={'roboto'} mb={'8vw'}>
    //                   {/*<ChevronRightIcon sx={{color:'white'}}/>*/}
    //                   priceops
    //               </Typography>
    //               <Typography fontSize={'3.8vw'} color={'#f8fcda'}>SaaS Pricing.</Typography>
    //               <Typography fontSize={'3.8vw'} fontWeight={800}
    //                           color={'primary.main'}
    //                           className={'flow'}
    //                           sx={{
    //                               background: 'linear-gradient(45deg,#F97794,#ecc94b,#48bb78)',
    //                               backgroundSize: '200% 200%',
    //                               WebkitBackgroundClip: 'text',
    //                               WebkitTextFillColor: 'transparent',
    //                           }}
    //               >
    //                   Simplified. Optimized.
    //               </Typography>
    //               <Typography fontSize={'1.27vw'} pt={2} color={'#f8fcda'}>
    //                   End-to-end pricing solution your team needs.
    //                   {/*From pricing research to price optimization and price execution.*/}
    //               </Typography>
    //           </Grid>
    //           {/* <Grid container justifyContent={'center'}>*/}
    //           {/*   <br/>*/}
    //           {/*  <Typography fontSize={30} pl={1}>*/}
    //           {/*    Increase*/}
    //           {/*  </Typography>*/}
    //           {/*  <Typography fontSize={30} fontWeight={800} color={'#6C63FF'} pl={1}>*/}
    //           {/*    revenue,*/}
    //           {/*  </Typography>*/}
    //           {/*  <Typography fontSize={30} pl={1}>*/}
    //           {/*    Reduce*/}
    //           {/*  </Typography>*/}
    //           {/*  <Typography fontSize={30} fontWeight={800} color={'#6C63FF'} pl={1}>*/}
    //           {/*    time-to-value*/}
    //           {/*  </Typography>*/}
    //           {/*  <Typography fontSize={30} pl={1}>*/}
    //           {/*    and Improve*/}
    //           {/*  </Typography>*/}
    //           {/*  <Typography fontSize={30} fontWeight={800} color={'#6C63FF'} pl={1}>*/}
    //           {/*    price governance*/}
    //           {/*  </Typography>*/}
    //           {/*</Grid>*/}
    //
    //       {/*</Grid>*/}
    //   </Grid>
    //
    //   <Divider orientation="vertical" flexItem />
    //
    //   <Grid
    //       container
    //       width={550}
    //       minHeight={'100vh'}
    //       direction={'column'}
    //       justifyContent={'center'}
    //       sx={{backgroundColor: "white", px:'13vh'}}
    //   >
    //       <Typography fontSize={24} fontWeight={800}>
    //           {registerPage ?
    //               'Create your PriceOps Account'
    //               :
    //               'Welcome back to PriceOps!'
    //           }
    //       </Typography>
    //
    //       <Typography fontSize={12} pt={1}>
    //           {registerPage ?
    //               'Please enter your details and verify your account.'
    //               :
    //               forgotPassword ?
    //                   "Forgot your password? Don't worry we will send an reset password email to help you get back to your account."
    //                   :
    //                   'Please enter your details below to login.'
    //           }
    //       </Typography>
    //
    //       <Grid
    //           // xs height={'100%'}
    //           container
    //           width={'100%'}
    //           // alignItems={'center'}
    //       >
    //           {registerPage ?
    //               <RegisterFlow
    //                   width={'100%'}
    //                   setRegisterPage={setRegisterPage}
    //                   email={email}
    //                   onSuccess={(email) => setEmail(email)}
    //               />
    //               :
    //               <React.Fragment>
    //                   {!forgotPassword && <LoginForm
    //                       setRegisterPage={setRegisterPage}
    //                       email={email}
    //                       onFailure={(email) => setEmail(email)}
    //                       onLogin={(user) => props.onSuccess(user)}
    //                       onForgotPassword={() => setForgotPassword(true)}></LoginForm>}
    //                   {forgotPassword &&
    //                       <ForgotPasswordForm email={email} backToLogin={() => setForgotPassword(false)} />}
    //               </React.Fragment>
    //           }
    //       </Grid>
    //   </Grid>
    // </Grid >
  );
}

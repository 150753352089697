import PercentIcon from '@mui/icons-material/Percent';
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import EditableInputsForm from './EditableInputsForm';
import InputLabelGroup from './InputLabelGroup';
import ScenarioDataContext from 'pages/scenarios/ScenarioDataContext';
import { textNum } from 'utils/Utils';

export default function CostPlusStrategyInput(props) {
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const segmentId = props.segmentId;

    const getMarginFromScenario = () => {
        return scenario["params"]["segment_params"]
            .find(x => String(x["id"]) === String(segmentId))?.["strategy_inputs"]?.["cost_plus_margin_percentage"] ?? 0
    }

    const [margin, setMargin] = React.useState(getMarginFromScenario());

    const applyMargin = () => {
        const sc = { ...scenario };
        const segment = sc["params"]["segment_params"].find(x => String(x["id"]) === String(segmentId));
        segment["strategy_inputs"]["cost_plus_margin_percentage"] = textNum(margin);
        sc["fragment"] = "COST_PLUS";
        updateScenario(sc, true, () => {
            if (props.onChange) {
                props.onChange();
            }
        }, () => { });
    }

    const refresh = () => {
        setMargin(getMarginFromScenario());
    }

    return (
        <EditableInputsForm
            miniView={
                <InputLabelGroup labels={
                    [
                        { name: "Margin", value: margin + "%" }
                    ]
                } />
            }
            editView={
                <React.Fragment>
                    <TextField
                        size="small"
                        sx={{ width: 200, mt: 2 }}
                        value={margin}
                        InputProps={{
                            endAdornment: <InputAdornment position="start"><PercentIcon sx={{ width: 16 }} /></InputAdornment>
                        }}
                        label="Margin percent"
                        onChange={(e) => setMargin(e.target.value)}
                        type="number"
                        inputProps={{ min: 0, max: 100, step: 0.1 }} />
                </React.Fragment>
            }
            onSubmit={applyMargin}
            onCancel={refresh} />
    )
}
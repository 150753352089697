import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, MenuItem, MenuList, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import { getReadablePriceMetric } from "pages/scenarios/price-modelling/components/PriceModelDefinitions";
import PriceModelsDataContext from "pages/scenarios/PriceModelsDataContext";
import ScenarioDataContext from "pages/scenarios/ScenarioDataContext";
import React from "react";
import { currencyFormatter, snakeCaseToTitleCase } from "utils/Utils";
import "index.css";

export default function PricingResultComparisonView(props) {
    const { scenario, updateScenario } = React.useContext(ScenarioDataContext);
    const { priceModels, updatePriceModels } = React.useContext(PriceModelsDataContext);
    const scenarioResult = props.scenarioResult;

    const [selectedSegment, setSelectedSegment] = React.useState(null);

    const extractStrategy = (strategy) => {
        const strategyName = snakeCaseToTitleCase(strategy.split("-")[0].toLowerCase().replace("strategy", ""));
        const optName = snakeCaseToTitleCase(strategy.split("-")?.[1]?.toLowerCase());
        return {
            strategyName: strategyName,
            optName: optName
        }
    }

    const columns = [
        {
            field: 'strategy', headerName: 'Strategy', autoHeight: true, pinned: 'left',
            cellRenderer: (props) => {
                const p = props.value;
                return <Box>
                    <Typography sx={{ fontSize: 12 }}>{p.strategyName}</Typography>
                    {p.optName && <Typography sx={{ fontSize: 10, color: "primary.main" }}>{p.optName} Optimization</Typography>}
                </Box>;
            }
        }, {
            field: 'price_model', headerName: 'Price model'
        }, {
            field: 'num_customers', headerName: 'Customers'
        }, {
            field: 'revenue', headerName: 'Revenue', cellRenderer: (props) => {
                return <Typography sx={{ fontSize: 12, mt: 1 }}>{currencyFormatter(props.value)}</Typography>
            }
        }, {
            field: 'profit', headerName: 'Profit', cellRenderer: (props) => {
                return <Typography sx={{ fontSize: 12, mt: 1 }}>{currencyFormatter(props.value)}</Typography>
            }
        }, {
            field: 'price', headerName: 'Price', pinned: 'right', autoHeight: true, cellRenderer: (props) => {
                const pricesByMetrics = props.value?.prices_by_metrics;

                return <MenuList>
                    {pricesByMetrics?.map(priceByMetric => {
                        return <MenuItem sx={{ p: 0, background: "none", '&:hover': { background: "none" } }}>
                            <Typography sx={{ fontSize: 12, mr: 1, p: 0 }} display="inline">{currencyFormatter(priceByMetric["price"])}</Typography>
                            <Typography sx={{ fontSize: 12, p: 0 }} display="inline">{getReadablePriceMetric(priceByMetric["price_metric"])}</Typography>
                        </MenuItem>
                    })
                    }
                </MenuList >
            }
        }];

    const getPriceModelNameById = (priceModelId) => {
        return priceModels.find(x => String(x.id) === String(priceModelId))?.name ?? priceModelId;
    }

    const constructData = () => {
        const segmentData = {};
        for (const r in scenarioResult) {
            const pricingResult = scenarioResult[r];
            const pricePoints = { ...pricingResult?.["price_points"] ?? {} }

            for (const o in pricingResult["optimization_models"]) {
                const model = pricingResult["optimization_models"][o];
                const opts = model?.pricing_calc_options
                if (!opts) continue;
                const strategy = opts.compute_mode + "-" + opts.optimizer_type;

                pricePoints[strategy] = model["optimum_point"];
            }

            for (const strategy of Object.keys(pricePoints)) {
                const pricePoint = pricePoints[strategy];
                const seg_attrs = pricePoint?.["params"]?.["attributes"] ?? [];

                for (const s in seg_attrs) {
                    const seg = seg_attrs[s];
                    const segId = seg.segment_id;
                    const row = {};

                    if (strategy === "FINALIZE_PRICE") continue;

                    row["segment_id"] = seg.segment_id;
                    row["strategy"] = extractStrategy(strategy);
                    row["price_model"] = getPriceModelNameById(pricingResult.price_model_id);
                    row["price"] = seg?.price;
                    row["profit"] = seg?.profit?.value;
                    row["revenue"] = seg?.revenue?.annual_revenue;
                    row["num_customers"] = seg?.num_customers;

                    if (!segmentData[segId]) segmentData[segId] = {
                        maxRevenueRow: row,
                        maxProfitRow: row,
                        rows: []
                    };
                    const currSegData = segmentData[segId];
                    currSegData.rows.push(row);
                    if (row.revenue > currSegData.maxRevenueRow.revenue) {
                        currSegData.maxRevenueRow = row;
                    }
                    if (row.profit > currSegData.maxProfitRow.profit) {
                        currSegData.maxProfitRow = row;
                    }
                }
            }
        }
        return segmentData;
    }

    const allSegmentData = constructData();

    const getData = (segmentId) => allSegmentData[segmentId];

    const defaultColDef = {
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
    }

    // priceModels.forEach(model => {
    //     columns.push({
    //         field: model.name, headerName: 'Model name', width: 250, renderCell: (params) => {
    //             const segmentParams = params.row;
    //             return "";
    //         }
    //     });
    // })

    const handleChange = (panel) => (event, newExpanded) => {
        setSelectedSegment(newExpanded ? panel : false);
    };

    const summaryColumns = [
        {
            field: 'tag', headerName: "", width: 100
        }, {
            field: 'strategy', headerName: 'Strategy', autoHeight: true, width: 200,
            cellRenderer: (props) => {
                const p = props.value;
                return <Box>
                    <Typography sx={{ fontSize: 12, mt: 1, display: "inline", mr: 2 }}>{p.strategyName}</Typography>
                    {p.optName && <Typography sx={{ fontSize: 10, color: "primary.main", display: "inline" }}>{p.optName}</Typography>}
                </Box>;
            }
        }, {
            field: 'price_model', headerName: 'Price model', width: 120
        }, {
            field: 'num_customers', headerName: 'Customers', width: 100
        }, {
            field: 'revenue', headerName: 'Revenue', width: 120, cellRenderer: (props) => {
                return <Typography sx={{ fontSize: 12, mt: 1 }}>{currencyFormatter(props.value)}</Typography>
            }
        }, {
            field: 'profit', headerName: 'Profit', width: 120, cellRenderer: (props) => {
                return <Typography sx={{ fontSize: 12, mt: 1 }}>{currencyFormatter(props.value)}</Typography>
            }
        }, {
            field: 'price', headerName: 'Price', autoHeight: true, cellRenderer: (props) => {
                const pricesByMetrics = props.value?.prices_by_metrics;

                return <MenuList>
                    {pricesByMetrics?.map(priceByMetric => {
                        return <MenuItem sx={{ p: 0, background: "none", cursor: "default", '&:hover': { background: "none", cursor: "default" } }}>
                            <Typography sx={{ fontSize: 12, mr: 1, p: 0 }} display="inline">{currencyFormatter(priceByMetric["price"])}</Typography>
                            <Typography sx={{ fontSize: 12, p: 0 }} display="inline">{getReadablePriceMetric(priceByMetric["price_metric"])}</Typography>
                        </MenuItem>
                    })
                    }
                </MenuList >
            }
        }];

    return (
        <React.Fragment>
            {scenario?.["params"]?.["segment_params"]?.map((segment, idx) => {
                const data = getData(segment.id);
                const rows = data.rows;
                const summaryRows = [
                    { ...data.maxRevenueRow, tag: "Top revenue" },
                    { ...data.maxProfitRow, tag: "Top profit" },
                ]

                return <Accordion key={idx} sx={{ width: "100%" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid direction="column" item xs={12}>
                            <Grid container item xs={12}>
                                <Chip label={segment["segment_name"]}></Chip>
                                <Typography sx={{ ml: 2, mt: 1, color: "primary.main", fontSize: 12 }}>{rows.length} options</Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ boxShadow: 1, borderRadius: 2, mt: 1, p: 1 }}>
                                <div
                                    style={{ width: "100%" }}
                                    className="ag-theme-balham ag-theme-minimal"
                                >
                                    <AgGridReact
                                        rowData={summaryRows}
                                        suppressRowHoverHighlight={true}
                                        onGridReady={(params) => {
                                            params.api.setDomLayout("autoHeight");
                                        }}
                                        columnDefs={summaryColumns}></AgGridReact>

                                </div>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container item xs={12} sx={{ mb: 2 }}>
                            <Box width="100%" minHeight={400} alignItems="center" justifyContent="center">
                                <div
                                    style={{ height: "100%", width: "80%" }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        suppressBrowserResizeObserver={true}
                                        autoGroupColumnDef={{ headerName: "Segment data", minWidth: 200 }}
                                        rowData={rows}
                                        columnDefs={columns}
                                        groupDisplayType='singleColumn'
                                        defaultColDef={defaultColDef}></AgGridReact>

                                </div>
                            </Box>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            })
            }
        </React.Fragment>
    )
}
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessIcon from '@mui/icons-material/Business';
import FactoryIcon from '@mui/icons-material/Factory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PieChartIcon from '@mui/icons-material/PieChart';
import SegmentIcon from '@mui/icons-material/Segment';
import StorefrontIcon from "@mui/icons-material/Storefront";
import TableChartIcon from '@mui/icons-material/TableChart';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import * as React from "react";
import { addCustomerSegment, getCustomerSegments } from "../../apis/ClientDataService";
import CardContentContainer from "../../components/CardContentContainer";
import { currencyFormatter, formattedLabel, getRevRangeLabel } from '../../utils/Utils';
import DataIntegrations from '../settings/DataIntegrations';
import DefineMarketSegment from './DefineMarketSegment';
import MarketSegmentView from './MarketSegmentView';


export default function MarketSegments(props) {
  const [formState, setFormState] = React.useState('loading');
  const [segments, setSegments] = React.useState([]);
  const [segmentData, setSegmentData] = React.useState([]);
  const [selectedSegmentName, setSelectedSegmentName] = React.useState("");
  const [segmentTypes, setSegmentTypes] = React.useState(null);

  const [datagridView, setDatagridView] = React.useState(false);
  const [years, setYears] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState("");

  const [segmentationView, setSegmentationView] = React.useState(null);
  const [createSegmentForm, setCreateSegmentForm] = React.useState(false);
  const [segmentFilters, setSegmentFilters] = React.useState(null);
  const [viewKey, setViewKey] = React.useState(1);
  const [isDataAvailable, setIsDataAvailable] = React.useState(false);


  const handleSegmentViewChange = (event, segmentation) => {
    setSegmentationView(segmentation);
  };

  const init = (currSegName) => {
    setFormState('loading');
    getCustomerSegments((data) => {
      console.log(data);
      setSegments(data["segments"]);
      setSegmentData(data["data"]);
      setSegmentationView("revenue");

      if (data["segments"].length > 0) {
        setIsDataAvailable(true);
      }

      const years = new Set();
      const segTypes = new Set();
      for (const [segType, dataPoints] of Object.entries(data["data"])) {

        dataPoints.forEach(x => years.add(x.year));
        segTypes.add(segType);
      }
      setYears([...years].sort());
      setSelectedYear(Math.max(...years));
      setSegmentTypes([...segTypes]);

      if (data["segments"].length > 0) {
        setSelectedSegmentName(currSegName);
        const segment = data["segments"].find(x => x.segment_name === currSegName);
        setSegmentFilters(segment["segment_params"]);
      }

      setFormState('success');
    }, (err) => {
      console.log(err.error);
      setFormState('error');
    });
  }

  React.useEffect(() => {
    init('All');
  }, []);

  const createGraphData = () => {
    const graphPoints = segmentData;

    if (!segmentFilters) return [];

    if (!graphPoints[segmentationView]) { console.log("No data found for segment type " + segmentationView); return []; }

    return graphPoints[segmentationView]
      .filter(pt => pt.year === selectedYear)
      .filter(pt => segmentFilters[segmentationView].includes(pt['segment_type']))
      .map(pt => {
        return {
          "name": segmentationView !== 'revenue' ? pt['segment_type'] : getRevRangeLabel(pt['segment_type']),
          "num_customers": pt["num_customers"],
          "revenue": pt['revenue']
        }
      });
  }

  const styles = {
    toggleButton: {
      textTransform: "None",
      height: 30,
      color: "primary.sub"
    },
    toggleButtonIcon: {
      ml: 1,
      height: 18
    }
  }

  const columns = [
    { field: 'customer_id', headerName: 'Customer' },
    { field: 'year', headerName: 'Year' },
    {
      field: 'rev',
      headerName: 'Revenue',
      width: 150,
      valueFormatter: (params) => { return currencyFormatter(params.value, 1); }
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 150,
      editable: true,
    },
    {
      field: 'business_type',
      headerName: 'Business type',
      width: 150,
      editable: true,
    },
    {
      field: 'industry',
      headerName: 'Industry',
      width: 110,
      editable: true,
    }
  ];

  const createSegment = (segment) => {
    const customSegmentName = segment.segment_name;
    if (!customSegmentName || customSegmentName === 'All') {
      alert("Please provide a valid name");
      return;
    }
    setCreateSegmentForm(false);
    addCustomerSegment(segment, (data) => {
      init(customSegmentName);
    }, (err) => {
      setFormState('error');
      alert(err);
    })
  }

  const handleSegmentationChange = (e) => {
    const changedSegmentName = e.target.value;
    setSelectedSegmentName(changedSegmentName);
    setSegmentFilters(segments.find(x => x.segment_name === changedSegmentName)['segment_params']);
    // setSegmentTypes(segments.find(x => {
    //   return x['segment_name'] === e.target.value
    // }));
  }

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  }

  const getCustomerDataForGridView = () => {
    // const filterData = (data) => {
    //   if (!segmentTypes) return [];
    //   return data.filter(x => {
    //     if (x.year !== selectedYear) return false;
    //     for (const [segmentKey, values] of Object.entries(segmentTypes.segment_params)) {
    //       if (!values.includes(x[segmentKey])) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   });
    // }

    return [];
  }

  const getSegmentTypeIcon = (segmentType) => {
    return segmentType === "revenue" ? AccountBalanceIcon
      : segmentType === "industry" ? FactoryIcon
        : segmentType === "business_type" ? BusinessIcon
          : segmentType === "country" ? LocationOnIcon : SegmentIcon
  }

  const notifyDataLoad = (datatype) => {
    setIsDataAvailable(true);
    init('All');
  }

  return (
    <React.Fragment>
      <CardContentContainer
        {...props}
        cardIcon={<StorefrontIcon />}
        title="Market segments"
        formState={formState}
        data={segmentData}
        save={() => { }}
        content={
          <React.Fragment>
            {isDataAvailable && formState !== "loading" &&
              <Grid item xs={12} justifyContent="center">
                {!datagridView && segmentTypes && selectedSegmentName &&
                  <Grid container item xs={12} sx={{ height: 50, mt: 1 }} justifyContent="center">
                    <ToggleButtonGroup
                      value={segmentationView}
                      exclusive
                      onChange={handleSegmentViewChange}
                      sx={{ borderRadius: 20 }}
                      aria-label="text alignment"
                    >
                      {segmentTypes.map(segmentType => {
                        const SegmentIcon = getSegmentTypeIcon(segmentType);
                        return (<ToggleButton key={segmentType} value={segmentType} sx={styles.toggleButton}>
                          {formattedLabel(segmentType)}
                          <SegmentIcon sx={styles.toggleButtonIcon} />
                        </ToggleButton>)
                      })}
                    </ToggleButtonGroup>
                  </Grid>}

                <Grid container item xs={12} justifyContent="flex-end" sx={{ mb: 1 }}>
                  {!createSegmentForm && <React.Fragment>
                    <Button
                      onClick={() => setCreateSegmentForm(true)}
                      sx={{ mr: 1, height: 30, fontSize: 13 }}
                    >Filters</Button>
                    <FormControl sx={{ mr: 1, minWidth: 60 }}>
                      <InputLabel id="yearlabel" height="">Year</InputLabel>
                      <Select
                        sx={{ height: 30, width: 100 }}
                        labelId="year-label"
                        value={selectedYear}
                        label="Group"
                        onChange={handleYearChange}
                      >
                        {years.map(x => {
                          return <MenuItem key={x} value={x}>{x}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ mr: 1, minWidth: 60 }}>
                      <InputLabel id="segmentation-label" height="">Segment</InputLabel>
                      <Select
                        sx={{ height: 30, minWidth: 100 }}
                        labelId="segmentation-label"
                        value={selectedSegmentName}
                        label="Group"
                        onChange={handleSegmentationChange}
                      >
                        {segments.map(x => {
                          return <MenuItem key={x.segment_name} value={x.segment_name}>{x.segment_name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    <Tooltip title="Toggle charts" placement="bottom">
                      <ToggleButton
                        value={datagridView}
                        onClick={() => setDatagridView(!datagridView)}
                        sx={{ height: 30, width: 30 }}
                      >
                        {!datagridView && <TableChartIcon sx={{ color: "primary.sub" }} />}
                        {datagridView && <PieChartIcon sx={{ color: "primary.sub" }} />}
                      </ToggleButton>
                    </Tooltip>
                  </React.Fragment>}
                  {createSegmentForm && <DefineMarketSegment
                    allOptions={segments.find(x => x.segment_name === 'All')['segment_params']}
                    segmentFilters={segmentFilters}
                    selectedSegmentName={selectedSegmentName}
                    createSegment={(segment) => { createSegment(segment); }}
                    cancelSegment={() => setCreateSegmentForm(false)}
                    handleSegmentFilterChange={(filters, segmentType) => { setSegmentationView(segmentType); setViewKey(viewKey + 1); setSegmentFilters(filters); }}
                  ></DefineMarketSegment>}
                </Grid>

                {!datagridView && segmentTypes && selectedSegmentName &&
                  < React.Fragment key={selectedSegmentName}>
                    <Grid key={segmentationView + selectedYear + selectedSegmentName + viewKey} container item xs={12} justifyContent="center">
                      <MarketSegmentView
                        xLabel={formattedLabel(segmentationView)}
                        data={createGraphData()}
                      ></MarketSegmentView>
                    </Grid>
                  </React.Fragment>}
                {datagridView &&
                  <Grid container item xs={12} justifyContent="center" sx={{ mt: 5 }} height={600}>
                    <DataGrid key={segmentationView + selectedYear}
                      getRowId={(row) => (row.customer_id + "_" + row.year)}
                      rows={getCustomerDataForGridView()}
                      columns={columns}
                      headerHeight={30}
                      rowHeight={25}
                      pageSize={40}
                      rowsPerPageOptions={[40]}
                      // checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </Grid>}
              </Grid>
            }
            {!isDataAvailable && formState !== "loading" &&
              <DataIntegrations disablefinances="true" nodatamessage="Please import data to visualize market segments"
                notify={notifyDataLoad} />}
          </React.Fragment >
        }
      ></CardContentContainer >
    </React.Fragment >
  );
}

import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import {
    Alert, Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogContent, Fab,
    Grid, InputAdornment,
    LinearProgress,
    MenuItem,
    Select,
    Snackbar, TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteScenario, getScenarios, listProjects } from '../../apis/ClientDataService';
import CustDialogTitle from '../../components/CustDialogTitle';
import { useErrorContext } from '../../state/ErrorDialogContext';
import OrganizationContext from '../../state/OrganizationContext';
import ProjectContext from '../../state/ProjectContext';
import { dateFormatterLocal, idString } from '../../utils/Utils';
import HierarchyBreadCrumbs from './price-modelling/components/HierarchyBreadCrumbs';
import IconContextMenu from './price-modelling/components/IconContextMenu';
import { selectionList } from './WorkspaceDropSelector';
import WorkspaceSelection from './WorkspaceSelection';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import GroupsIcon from "@mui/icons-material/Groups";
import ScenarioWorkspaceSelection from "./ScenarioWorkspaceSelection";

export default function ScenarioListPage() {
    const { globalOrganization } = useContext(OrganizationContext);
    const { globalProject, updateGlobalProject } = useContext(ProjectContext);
    const { loadErrorDialog } = useErrorContext();

    const getIcon = (workspace) => {
        const workspaceObject = selectionList.find(x => x["name"].toLowerCase() === workspace.toLowerCase());
        return workspaceObject ? workspaceObject["Icon"] : RemoveCircleIcon;
    }

    const WorkspaceIcon = (props) => {
        const workspaces = props.workspaces;
        const scenarioId = props.scenarioId;
        return <Grid container direction="row">
            {workspaces.map((workspace, index) => {
                const Icon = getIcon(workspace);
                return <React.Fragment key={index}>
                    <Tooltip title={workspace === "Price Modelling" ? "Pricing Engine" : workspace}>
                        <Icon
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate("/scenarios/" + scenarioId + "/" + idString(workspace));
                            }}
                            sx={{
                                mr: 1,
                                color: "primary.sub",
                                borderRadius: 10, boxShadow: 1, p: "4px", width: 20, height: 20
                            }}></Icon>
                    </Tooltip>
                    {index !== workspaces.length - 1 && <ArrowRightAltIcon sx={{ mr: 1 }} />}
                </React.Fragment>
            }
            )}
        </Grid >
    }

    const getWhoColumns = (row) => {
        const styles = {
            label: {
                fontSize: 12,
                pr: 5
            },
            value: {
                fontSize: 14
            }
        }

        return <table>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.created_by_user ?? "System"}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Created on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.created_date)}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Last updated by</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{row.last_modified_by ?? 'System'}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography sx={styles.label}>Updated on</Typography>
                </td>
                <td>
                    <Typography sx={styles.value}>{dateFormatterLocal(row.last_modified_date)}</Typography>
                </td>
            </tr>
        </table>
    }

    const columns = [
        //{ field: 'id', headerName: 'ID', width: 70 },
        { field: 'scenario_name', headerName: 'Name', flex: 3, },
        {
            field: 'workspaces', headerName: 'Workspaces', flex: 3, renderCell: (params) => {
                const workspaces = params.row.workspaces;
                return <WorkspaceIcon workspaces={workspaces} scenarioId={params.row.id} />;
            }
        },
        { field: 'fy_year', headerName: 'Year', flex: 2 },
        // { field: 'stage', headerName: 'Stage', width: 100 },
        // { field: 'createdBy', headerName: 'Created By', width: 130 },
        {
            field: 'approvalStatus', headerName: 'Status', flex: 2, renderCell: (params) => {
                const status = params.row.approvalStatus;
                if (status == "APPROVED") {
                    return <Tooltip title="Approved"><VerifiedRoundedIcon /></Tooltip>
                } else if (status == "REQUESTED_APPROVAL") {
                    return <Tooltip title="Pending approval"><HourglassBottomRoundedIcon /></Tooltip>
                } else {
                    return <Tooltip title="In progress"><EditNoteIcon /></Tooltip >
                }
            }
        },
        {
            field: 'details', headerName: 'Who?', flex: 2, renderCell: (params) => {
                const row = params.row;
                return <IconContextMenu Icon={PersonIcon} Content={
                    <Grid sx={{ p: 2 }}>
                        {getWhoColumns(row)}
                    </Grid>
                }></IconContextMenu>
            }
        },
        {
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            align: "center",
            renderCell: (params) => (
                <Tooltip title="Delete Scenario">
                    <DeleteIcon
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleRemoveScenarioClick(params);
                        }}
                        sx={{
                            color: "lightgray",
                            "&:hover": {
                                color: "black",
                                cursor: "pointer"
                            },
                        }}
                    />
                </Tooltip>
            ),
        },
    ];

    const navigate = useNavigate();


    const [scenarios, setScenarios] = useState([]);

    const [snackNotif, setSnackNotif] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState("");
    const [error, setError] = useState(false);
    const [activeScenario, setActiveScenario] = useState({});
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [accessDenied, setAccessDenied] = useState(false);

    const handleRemoveScenarioClick = (params) => {
        setActiveScenario(params.row);
        setIsDialogOpen("removeScenario");
    };

    const handleCreateScenarioClick = () => {
        setIsDialogOpen("addScenario");
        // navigate('/workspace');
    }

    const handleDialogClose = () => {
        setIsDialogOpen("");
        setError(false);
    };

    const handleAddClick = (scenario) => {
        setScenarios([...scenarios, scenario]);
        setIsDialogOpen("");
        setError(false);
        setSnackNotif("addScenario");
        navigate('/scenarios/' + scenario.id);
    }

    const loadScenarios = () => {
        setAccessDenied(false);
        setError(null);
        const done = getScenarios(data => {
            setScenarios(data);
            setLoading(false);
        }, (err) => {
            setError(true);
            if (err['error'] === 403) {
                setAccessDenied(true);
            }
            setLoading(false);
        });
        if (!done) {
            loadErrorDialog({ title: "Message", message: "Please select a project", reload: false });
            setLoading(false);
            navigate('/organization');
        }
    }

    useEffect(() => {
        setLoading(true);
        if (globalOrganization) {
            loadScenarios();

            listProjects(globalOrganization["id"], (projects) => {
                setProjects(projects);
                setSelectedProject(projects.find(x => x.id === globalProject.id));
            }, err => {
                // loadErrorDialog({ title: "Error", message: "Unable to load scenarios at the moment", reload: true });
            });
        }
    }, []);

    const removeScenarioFromOrg = () => {
        setLoading(true);
        setError(null);
        deleteScenario(activeScenario.id, () => {
            setScenarios(scenarios.filter(scenario => scenario.id !== activeScenario.id));
            setLoading(false);
            handleDialogClose();
            setSnackNotif("removeScenario");
        },
            (err) => {
                setLoading(false);
                setError("Unable to remove scenario, " + err.error_message);
            });
    };

    const handleRowHover = (params) => {
        setActiveScenario(params.row);
    };

    const handleRowClick = (params) => {
        // The "params" object contains the clicked row data
        const scenarioId = params.id;
        navigate("/scenarios/" + scenarioId);
        // You can perform any additional actions based on the clicked row data here
    };


    const handleProjectChange = (e) => {
        const selectedProjectId = e.target.value;
        const project = projects.find(x => String(x.id) === String(selectedProjectId))
        updateGlobalProject(project);
        loadScenarios();
    }

    return (
        <React.Fragment>
            {globalProject &&
                <>
                    <HierarchyBreadCrumbs showOrg />
                    {loading && <LinearProgress />}
                    {globalProject &&
                        <Grid container pt={1} borderTop={1} borderColor="primary.groupBorder" alignItems={'center'}>
                            <Typography sx={{ fontSize: 14, color: "primary.sub", mt: "2px", ml: 2, mr: 1 }}>Project</Typography>
                            {selectedProject && <Select
                                size="small"
                                sx={{ color: "primary.main", border: "none", fontWeight: "bold", fontSize: 15 }}
                                inputProps={{ style: { height: 20 } }}
                                value={selectedProject.id}
                                onChange={handleProjectChange}>
                                {projects.map((project, idx) => {
                                    return <MenuItem key={idx} sx={{ fontSize: 14 }} value={project.id}>{project.name}</MenuItem>
                                })}
                            </Select>}
                        </Grid>}
                </>}
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid container item sx={{
                    border: "1px solid lightgray",
                    boxShadow: 2,
                    p: 2, mt: 1,
                    bgcolor: 'primary.background'
                }}>
                    <Grid container item sx={{ p: 1, justifyContent: 'space-between' }} >
                        <Typography fontSize={30}>
                            Scenarios
                        </Typography>
                        {!accessDenied && <Grid item>
                            <Tooltip title="Add Scenario">
                                <Fab color="primary" aria-label="add" size="small" onClick={handleCreateScenarioClick}>
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>}
                    </Grid>
                    <Grid container minHeight={500} overflow="auto" bgcolor={'white'} borderRadius={2}>
                        {loading || (scenarios && scenarios.length > 0) &&
                            <DataGrid
                                getRowHeight={() => 'auto'}
                                sx={{
                                    // disable cell selection style
                                    '.MuiDataGrid-cell:focus': {
                                        outline: 'none'
                                    },
                                    // pointer cursor on ALL rows
                                    '& .MuiDataGrid-row:hover': {
                                        cursor: 'pointer'
                                    },
                                    width: '50vw',
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                    borderRadius: 2,
                                }}
                                rows={scenarios}
                                loading={loading}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 100 },
                                    },
                                }}
                                onRowClick={handleRowClick}
                                pageSizeOptions={[50, 100]}
                                onCellClick={handleRowHover}
                            // checkboxSelection
                            />
                        }
                        {!loading && (!scenarios || scenarios.length) === 0 &&
                            <Grid container item xs={12} justifyContent="center" alignItems='center' p={4}>
                                {accessDenied ?
                                    <Typography color={'primary.greyText'}>
                                        You do not have access to this project.
                                    </Typography> :
                                    <Typography color={'primary.greyText'}>
                                        Add your first scenario by clicking on the + icon above.
                                    </Typography>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid >


                {/*/!*ADD SCENARIO DIALOG DND INPUT*!/*/}
                {/*<Dialog open={isDialogOpen === "addScenario"} onClose={handleDialogClose} maxWidth="md" fullWidth>*/}
                {/*    <CustDialogTitle onClose={handleDialogClose}>*/}
                {/*        <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: "bold" }}>*/}
                {/*            Add Scenario*/}
                {/*        </Typography>*/}
                {/*    </CustDialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*        {loading ?*/}
                {/*            <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >*/}
                {/*                <CircularProgress />*/}
                {/*            </Grid>*/}
                {/*            :*/}
                {/*            <WorkspaceSelection handleAddClick={handleAddClick} />*/}
                {/*        }*/}
                {/*    </DialogContent>*/}
                {/*</Dialog>*/}

                {/*ADD SCENARIO DIALOG NORMAL INPUT*/}
                <Dialog open={isDialogOpen === "addScenario"} onClose={handleDialogClose}>
                    <ScenarioWorkspaceSelection handleAddClick={handleAddClick} handleDialogClose={handleDialogClose}/>
                </Dialog>

                {/*ADD SCENARIO NOTIFICATION*/}
                <Snackbar open={snackNotif === "addScenario"} autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={() => setSnackNotif("")}>
                    <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                        Scenario added successfully!
                    </Alert>
                </Snackbar>


                {/*REMOVE SCENARIO DIALOG*/}
                <Dialog open={isDialogOpen === "removeScenario"} onClose={handleDialogClose}>
                    {loading ?
                        <Grid container justifyContent="center" alignItems="center" sx={{ width: 550, minHeight: 200, boxShadow: 2, borderRadius: 2, p: 4 }} >
                            <CircularProgress />
                        </Grid>
                        :
                        <Grid container item sx={{ width: 550, minHeight: 200, borderRadius: 2, p: 4 }} direction="column">
                            <Typography sx={{ color: "primary.main", mb: 1, fontSize: 20, fontWeight: "bold" }}>Remove Scenario From Project</Typography>

                            <Alert severity="warning" sx={{ mb: 2 }}>
                                Are you sure you want to delete this scenario?
                            </Alert>

                            {error && <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(false)}>{error}</Alert>}

                            <Grid item container justifyContent='flex-end'>
                                <Button variant="outlined" size="medium" onClick={handleDialogClose}>Cancel</Button>
                                <Button variant="contained" color="error" size="medium" onClick={removeScenarioFromOrg} sx={{ ml: 1 }}>Remove</Button>
                            </Grid>
                        </Grid>
                    }
                </Dialog>
                {/*REMOVE SCENARIO NOTIFICATION*/}
                <Snackbar open={snackNotif === "removeScenario"} autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={() => setSnackNotif("")}>
                    <Alert variant="filled" severity="success" onClose={() => setSnackNotif("")}>
                        Scenario removed successfully!
                    </Alert>
                </Snackbar>
            </Grid>
        </React.Fragment>
    );
}

import GrainIcon from '@mui/icons-material/Grain';
import { Box, Grid, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import React, { useContext } from 'react';
import OrganizationContext from 'state/OrganizationContext';
import ProjectContext from 'state/ProjectContext';

export default function HierachyBreadCrumbs(props) {
    const showOrg = props.showOrg;
    const showProj = props.showProjects;
    const showScenarios = props.showScenarios;
    const { globalOrganization } = useContext(OrganizationContext);
    const { globalProject } = useContext(ProjectContext);

    return (
        <Grid container item
            // xs={12}
            sx={{ pl: 2, width: 'fit-content' }}>

            <Breadcrumbs aria-label="breadcrumb" fontSize={14} my={1.5}> fontWeight="bold"
                {showOrg &&
                    <Box>
                        <Typography sx={{ fontSize: 12, mr: 1, display: "inline" }}>Org</Typography>
                        <Link
                            underline="hover"
                            sx={{ fontWeight: 'bold' }}
                            color="inherit"
                            href="/organization"
                        >
                            {/* <BusinessIcon sx={{ height: 25, postiion: "absolute", mt: 1 }} /> */}
                            {globalOrganization.name}
                        </Link></Box>
                }
                {showProj &&
                    <Box>
                        <Typography sx={{ fontSize: 12, mr: 1, display: "inline" }}>Project</Typography>
                        <Link
                            underline="hover"
                            sx={{ fontWeight: 'bold' }}
                            color="inherit"
                            href="/scenarios"
                        >
                            {/* <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
                            {globalProject?.name}
                        </Link>
                    </Box>}
                {showScenarios && <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/scenarios"
                >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Scenarios
                </Link>}
            </Breadcrumbs>
        </Grid >
    );
}
import React from "react";
import { useParams } from "react-router-dom";

export default function ResetRedirect() {
    const { email, token } = useParams();

    localStorage.setItem("reset-password", JSON.stringify({
        email: email,
        token: token
    }))

    window.location = '/reset'

    return (<React.Fragment></React.Fragment>)
}
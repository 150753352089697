import { Typography, Grid, Tooltip } from "@mui/material"
import React from "react";
import PricingGraph from '../../components/PricingGraph';
import TimelineChart from '../../components/TimelineChart';
import { currencyFormatter } from "../../utils/Utils";
import { extractMetricData } from "./Utils";


export default function MetricsGraph(props) {
    const metric = props.metric ? props.metric : props.yAxisDataKey;
    const period = props.period ? props.period : props.xAxisDataKey;

    const [data] = React.useState(extractMetricData(props.data, period, metric));

    const yFormatter = props.yFormatter ? props.yFormatter : currencyFormatter
    const variant = props.variant ? props.variant : "mini";

    const width = props.width ? props.width : variant === "mini" ? 300 : 800;
    const height = props.height ? props.height : variant === "mini" ? 80 : 400;

    const styles = {
        graphTitle: {
            textAlign: 'center',
            fontSize: 18,
            m: 0,
            mt: 1,
            pt: 1,
            pb: 0,
            fontFamily: 'Arial',
            fontVariant: 'small-caps'
        },
        titleValue: {
            textAlign: 'left',
            fontSize: 24,
            fontWeight: 'bold',
            mt: 1,
            mb: 1,
            ml: 2
        },
        box: {
            borderColor: "primary.groupBorder",
            overflow: 'hidden',
            p: 0,
            pl: 2,
            pr: 2,
            m: 2,
            boxShadow: 2,
            pb: 2,
            borderRadius: 2,
            width: width
        }
    }
    const ChartType = props.chartType === "timeline" ? TimelineChart : PricingGraph;

    const getLatestValue = () => {
        if (!data || data.length === 0) return 0;
        return yFormatter(data[data.length - 1][metric]);
    }

    return (
        <Tooltip title={variant === "mini" ? "Click to view details" : ""}>
            <Grid key={data} sx={styles.box} item>
                <div style={{ cursor: 'pointer' }} onClick={props.onClick}>
                    {props.title && <Typography sx={styles.graphTitle}>{props.title}</Typography>}
                    <Typography sx={styles.titleValue}>{getLatestValue()}</Typography>
                    {data && data.length > 0 && <ChartType
                        {...props}
                        data={data}
                        xAxisDataKey={period}
                        yAxisDataKey={metric}
                        height={height}
                        yFormatter={yFormatter}
                        width={width * 0.85}
                        hideGrid={variant === "mini"}
                        hideXLabel={variant === "mini"}
                        hideYLabel={variant === "mini"}
                        hideDot={variant === "mini"}
                        onClick={props.onClick}></ChartType>}
                    {(!data || data.length === 0) && <Grid sx={{ minWidth: width, minHeight: height }}>No data available</Grid>}
                </div>
            </Grid>
        </Tooltip>
    )
}
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CategoryIcon from '@mui/icons-material/Category';

import { Grid } from "@mui/material";
import React from "react";
import SelectorComponent from "../../components/SelectorComponent";
import { uiStyles } from "../../themes/themes";
import BasicProfileCard from './BasicProfileCard';
import ProductFeatures from './ProductFeatures';

export default function Settings(props) {
    return (<Grid sx={[uiStyles.content, { p: 1 }]}>
        <SelectorComponent hidecards="true" hidebackbutton="true"
            {...props}
            selectoptions={[
                "Company profile",
                "Product features"
            ]}
            icons={[
                AccountBoxIcon,
                CategoryIcon
            ]}
            views={[
                <BasicProfileCard />,
                <ProductFeatures />,
            ]}>
        </SelectorComponent>
    </Grid>)
}
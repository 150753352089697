import React from "react";

import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Grid, InputLabel, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LinearProgress } from "@mui/material";

export default function RichFileUpload(props) {
    const title = props.title;
    const fileTypes = Array(props.fileTypes).map(x => "." + x).join()
    const fileRef = React.createRef();
    const onSuccess = props.onSuccess;
    const onFailure = props.onFailure;

    const styles = {
        dropMessageStyle: {
            color: "primary.sub",
            width: '100%',
            border: 2,
            borderColor: "primary.main",
            borderRadius: 1,
            borderStyle: 'dashed',
            p: 1,
            minHeight: props.height ? props.height : 100,
            cursor: 'pointer'
        },
        dropZoneActive: {
            borderColor: "primary.sub",
            backgroundColor: grey[200]
        }
    }

    const [key, setKey] = React.useState(1);
    const [Icon, setIcon] = React.useState(UploadFileIcon);
    const [dropZoneStyles, setDropZoneStyles] = React.useState([styles.dropMessageStyle]);
    const [error, setError] = React.useState(null);
    const [uploading, setUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const handleCancel = (e) => {
        console.log("Cancelled");
    }

    const handleFileUpload = (file) => {
        setKey(key + 1);
        setIcon(UploadFileIcon);
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            file,
            file.name
        );

        const uploadFn = props.uploadFn;
        const datatype = props.datatype;
        if (props.onUploadStart) props.onUploadStart();
        setUploading(true);
        setUploadProgress(0);
        uploadFn(formData, datatype, (progress) => {
            setUploadProgress(progress * 100);
        }, (data) => {
            setError(null);
            if (onSuccess) onSuccess(data);
            setIcon(CloudDoneIcon);
            setKey(key + 1);
            setUploading(false);
        }, (err) => {
            if (props.fileUploadFailureMessage) setError(props.fileUploadFailureMessage(err)); else setError("Failed to upload file");
            if (onFailure) onFailure(err);
            setIcon(ErrorOutlineIcon);
            setKey(key + 1);
            setUploading(false);
        })
    }
    const handleChange = (e) => {
        const file = e.target.files[0];
        handleFileUpload(file);
    };
    const openFileUploadDialog = () => {
        setIcon(UploadFileIcon);
        fileRef.current.click();
        if (props.onReset) { setError(null); props.onReset(); }
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setDropZoneStyles([styles.dropMessageStyle]);
        if (!props.fileTypes.includes(file.type.split("/")[1])) {
            setError('Invalid file type');
            onFailure({ "error_message": "Invalid file type" });
            return;
        }
        handleFileUpload(file);
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        if (props.onReset) { setError(null); props.onReset(); }
        setDropZoneStyles([styles.dropMessageStyle, styles.dropZoneActive]);
    }
    const handleDragLeave = (e) => {
        e.preventDefault();
        if (props.onReset) { setError(null); props.onReset() };
        setDropZoneStyles([styles.dropMessageStyle]);
    }

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Grid key={key} container sx={{ backgroundColor: "white" }}>
            {title && <Grid item xs={12}>
                <InputLabel sx={{ cursor: 'inherit' }}>{title}</InputLabel>
            </Grid>}
            {uploading && uploadProgress !== 100 &&
                <Grid key={uploadProgress}
                    sx={{ width: '100%', height: props.height, p: 4, backgroundColor: "primary.background", border: 1, borderRadius: 1, borderColor: "primary.groupBorder" }}>
                    <LinearProgressWithLabel variant="determinate" progress={uploadProgress} value={uploadProgress}></LinearProgressWithLabel>
                </Grid>
            }
            {!uploading && <Grid
                key={uploading}
                item xs={12}
                onClick={openFileUploadDialog}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onBlur={handleDragLeave}
                onDragOver={e => { e.preventDefault(); handleDragEnter(e); }}
                sx={{ backgroundColor: "primary.background", p: 1, border: 1, borderRadius: 1, borderColor: "primary.groupBorder" }}>
                <Grid sx={dropZoneStyles} container justifyContent="center" alignItems="center">
                    <Grid item xs={12} align="center">
                        <Grid item>
                            <Icon sx={{ width: 30, height: 30 }}></Icon>
                        </Grid>
                    </Grid>
                    {Icon === UploadFileIcon && <Grid item align="center" xs={12} sx={{ fontSize: 11 }}>Click/Drag drop to upload file</Grid>}
                    {Icon === ErrorOutlineIcon && <Grid item align="center" xs={12} sx={{ fontSize: 11, color: "primary.warning" }}>{error}</Grid>}
                </Grid>
            </Grid>}
            <input type="file" hidden ref={fileRef} onChange={handleChange} onBlur={handleCancel} accept={fileTypes}></input>
        </Grid>
    );
}
// ErrorDialog.js
import React from 'react';
import { useErrorContext } from './ErrorDialogContext';
import { Dialog, DialogContent, Typography, Grid, DialogActions, Button } from '@mui/material';
import CustDialogTitle from '../components/CustDialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ErrorDialog() {
    const { error, clearError } = useErrorContext();

    if (!error) {
        return null;
    }

    return (
        <Dialog open={error} onClose={clearError}>
            <CustDialogTitle sx={{ fontSize: 14, fontWeight: "bold" }}>{error.title ?? "Error"}</CustDialogTitle>
            <DialogContent>
                <Grid sx={{ borderTop: 1, p: 3, pb: 0, borderColor: "primary.disabledBorder", textAlign: "center" }}>
                    <ErrorOutlineIcon color="warning" sx={{ transform: "scale(1.6)", mb: 3 }} />
                    <Typography sx={{ fontSize: 14 }}>{error.message ?? "Unknown error"}</Typography>
                </Grid>
            </DialogContent>
            <DialogActions>
                {error.reload &&
                    <Button onClick={() => window.location.reload()}>Reload</Button>
                }
                <Button onClick={clearError}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialog;

import "./App.css";

import {Alert, Grid, Snackbar, ThemeProvider} from "@mui/material";
import HeaderMenuBar from "./menubar/HeaderMenuBar";
import PricingPage from "./pages/payments/PricingPage";
import { mainTheme } from "./themes/themes";

import PublisherDesign from "pages/packaging/PublisherDesign";
import React, {useRef} from 'react';
import {
  BrowserRouter as Router, Navigate, Route, Routes, useLocation
} from 'react-router-dom';
import Insights from "./pages/analytics/Insights";
import LoginPage from "./pages/login/LoginPage";
import ResetPasswordPage from "./pages/login/ResetPasswordPage";
import ResetRedirect from "./pages/login/ResetRedirect";
import PackagingFlow from "./pages/packaging/PackagingFlow";
import FinancialMetrics from "./pages/pricing-input-data/FinancialMetrics";
import MarketSegments from "./pages/pricing-input-data/MarketSegments";
import PriceModelDefintionsPage from "./pages/scenarios/PriceModelDefinitionsPage";
import Scenario from "./pages/scenarios/Scenario";
import ScenarioListPage from "./pages/scenarios/ScenarioListPage";
import DataIntegrationsPage from "./pages/settings/DataIntegrationsPage";
import OrganizationDetails from "./pages/settings/OrganizationDetails";
import OrganizationSelection from "./pages/settings/OrganizationSelection";
import SideBar from "./pages/settings/OrgHierarchyPage";
import ProjectDetails from "./pages/settings/ProjectDetails";
import Settings from "./pages/settings/Settings";
import UserListPage from "./pages/settings/UserListPage";
import ErrorDialog from "./state/ErrorDialog";
import { ErrorProvider } from "./state/ErrorDialogContext";
import OrganizationContext from "./state/OrganizationContext";
import ProjectContext from "./state/ProjectContext";
import { getOrgFromLocalStore, getProjectFromLocalStore, getUser, setOrgInLocalStore, setProjectInLocalStore, setUser, updateParam } from "./state/state";
import mixpanel from 'mixpanel-browser';
import AppProperties from "pages/settings/AppProperties";
import ClientPlanListPage from "./pages/settings/ClientPlanListPage";

import CustomerPlanListPage from "./pages/settings/CustomerPlanListPage";
import EntitlementsFlow from "./pages/entitlements/EntitlementsFlow";
import PriceOpsPlanListPage from "./pages/settings/PriceOpsPlanListPage";
import CustomerPortal from "./pages/settings/CustomerPortal";
import PriceOpsPlansContext from "./state/PriceOpsPlansContext";
import {fetchPriceOpsPlansOfClient} from "./apis/ClientDataService";
import priceops from "priceops";
import PortalPreview from "pages/settings/PortalPreview";
// import "iframe-resizer/js/iframeResizer.contentWindow";


mixpanel.init('ceabb4e8dfe3c43a9dd5cf42637a1fe7', {
  debug: true, track_pageview: true, persistence: 'localStorage'
});

function App() {

  const isAuthenticated = () => {
    return getUser() ? true : false
  }

  const [globalOrganization, setGlobalOrganization] = React.useState(getOrgFromLocalStore());
  const [globalProject, setGlobalProject] = React.useState(getProjectFromLocalStore());
  const [headerBar, setHeaderBar] = React.useState(false);
  const [notify, setNotify ] = React.useState(false)
  const [notification, setNotification ] = React.useState("")

  const setOrgIfExists = (user) => {
    if (!globalOrganization && user) {
      if (user["organizations"]) {
        const defaultOrg = user["organizations"][0];
        updateGlobalOrganization(defaultOrg);
        setOrgInLocalStore(defaultOrg);
      }
    }
  }

  React.useEffect(() => {
    const user = getUser();
    if (user) {
      if (globalOrganization) {
        priceops.setCustomer(globalOrganization?.id, user.email);
        // console.log(priceops.getCustomer())
        updatePriceOpsPlans()
      }
      mixpanel.identify(user.id.toString())
      console.debug("MIXPANEL IDENTIFY", user.id.toString())
    }

    setOrgIfExists(user);
  }, []);


  const PrivateRoute = ({ component, publicPage, hideHeader, orgPage, analyticsPage,...rest }) => {
    const location = useLocation()?.pathname;

    if (!hideHeader) setHeaderBar(true); else setHeaderBar(false);

    if (publicPage
        || (orgPage && isAuthenticated())
    ) return component;

    if (!isAuthenticated()) {
      return <Navigate to={"/login?redirect=" + location} />;
    }
    if (!getOrgFromLocalStore()) {

      if(!orgPage){
        setNotify(true)
        setNotification("Please add an Organization first to get started!")
      }

      return <Navigate to="/organization" />;
    }


    // LIMITATION ANALYTICS PAGES
    if(!limitationsRef.current.analytics && analyticsPage){

      setNotify(true)
      setNotification(`${getOrgFromLocalStore().name} does not have access to this feature!`)

      return <Navigate to="/organization" />;
    }

    return (component === <ResetPasswordPage /> ? <SideBar /> : component);
  };

  const handleLogin = (user) => {
    setUser(user);
    mixpanel.identify(user.id.toString())
    mixpanel.track("User Logged In");
    setOrgIfExists(user);
  }

  const handleLogout = () => {
    clearInterval(intervalRef.current);
    setUser(null);
    mixpanel.track("User Logged Out");
    mixpanel.reset();
    console.log("MIXPANEL LOGOUT")
    window.location = '/login';
    priceops.onCustomerLogout();
  }

  const updateGlobalOrganization = (org) => {
    setOrgInLocalStore(org);
    setGlobalOrganization(org);

    if (org) {
      priceops.setCustomer(org?.id, getUser()?.email);
    }else{
      priceops.onCustomerLogout();
    }

    // console.log("Getting customer", priceops.getCustomer())

    // const user = getUser();
    // user["organizations"] = [org];
    // setUser(user);
  }

  const updateGlobalProject = (project) => {
    setProjectInLocalStore(project);
    setGlobalProject(project);
  }

  const updatePriceOpsPlans = () =>{
    fetchPriceOpsPlansOfClient(data => {
      priceOpsPlansRef.current = data;
      // console.log("Updated PriceOps Plans of ", globalOrganization.name, data);
      updateLimitations();
    }, err => {
      console.log(err);
    });
  }

  const priceOpsPlansRef = useRef([]);
  const counterRef= useRef(0)
  const intervalRef = useRef();
  const defaultLimitations = {
    "hide_watermark" : false,
    "stripe" : false,
    "analytics" : false,
    "add_on" : false,
    "engine" : false,
    "starter_plan" : false,
    "stripe_integration_plan" : false,
    "launch_plan" : false,
    "billing_period" : "N/A",
    "created_date" : "N/A",
  }
  const limitationsRef = useRef({...defaultLimitations})

  const updateLimitations = () => {

    // console.log(globalOrganization.name)

    if(globalOrganization.name.toLowerCase() === "priceops"){
      limitationsRef.current.hide_watermark = true
      limitationsRef.current.stripe = true
      limitationsRef.current.analytics = true
      limitationsRef.current.add_on = true
      limitationsRef.current.engine = true
      console.log("This is priceops org no limitations!")
    }
    else{
      let hasActivePlans = false
      priceOpsPlansRef.current.forEach(plan => {
        // Check only active plans
        if(['active','succeeded','past_due','trialing'].includes(plan.status)){

          if(plan.product.header_name === "Starter"){
            limitationsRef.current.starter_plan = true
            limitationsRef.current.billing_period = plan.prices[0].billing_period
            limitationsRef.current.created_date = plan.created_date
            hasActivePlans = true
          }

          if(plan.product.header_name === "Stripe Integration"){
            limitationsRef.current.stripe = true
            limitationsRef.current.stripe_integration_plan = true
          }

          if(plan.product.header_name === "Launch"){
            limitationsRef.current.hide_watermark = true
            limitationsRef.current.stripe = true
            limitationsRef.current.analytics = true
            limitationsRef.current.add_on = true
            limitationsRef.current.engine = true
            limitationsRef.current.launch_plan = true
            limitationsRef.current.billing_period = plan.prices[0].billing_period
            limitationsRef.current.created_date = plan.created_date
            hasActivePlans = true
          }
        }
      });

      if(!hasActivePlans){
        limitationsRef.current = {...defaultLimitations}
      }
    }

    // console.log(limitationsRef.current)
  }


  React.useEffect(()=>{

    limitationsRef.current = {...defaultLimitations}

    if(globalOrganization){
      // Fetch plans immediately
      updatePriceOpsPlans()

      // Start a polling call to get current org's priceops plans
      intervalRef.current = setInterval(() => {
        // counterRef.current +=1
        // console.log("Fetching Current PriceOps Plans ")
        updatePriceOpsPlans()
        // console.log(counterRef.current)
      }, 5*60*1000 );   // Checking every 5 minutes
    }

    // Cleanup function
    return () => {
      clearInterval(intervalRef.current);
    };
  },[globalOrganization])

  return (

      <Grid justifyContent="center">
        <ThemeProvider theme={mainTheme}>
          <ErrorProvider>
            <OrganizationContext.Provider value={{ globalOrganization, updateGlobalOrganization, limitationsRef }}>
              <ProjectContext.Provider value={{ globalProject, updateGlobalProject }}>
                  <Router>
                    {getUser() && headerBar && <HeaderMenuBar onLogout={handleLogout}></HeaderMenuBar>}
                    <Grid>
                      <Routes>
                        <Route path="/reset_redirect/:email/:token" element={<ResetRedirect />} />
                        <Route path="/reset" element={<ResetPasswordPage />} />
                        <Route path="/organization" element={<PrivateRoute component={<OrganizationSelection />} orgPage={true} />} />
                        <Route path="/organization-details" element={<PrivateRoute component={<OrganizationDetails />} />} />
                        {/*<Route path="/workspace" element={<PrivateRoute component={<WorkspaceSelection />} />} />*/}
                        <Route path="/users" element={<PrivateRoute component={<UserListPage />} />} />
                        <Route path="/client-plans" element={<PrivateRoute component={<ClientPlanListPage />} />} />
                        <Route path="/customer-plans" element={<PrivateRoute component={<CustomerPlanListPage />} />} />
                        {/* <Route path="/customer-portal" element={<PrivateRoute component={<CustomerPortal />} />} />
                        <Route path="/portal-preview" element={<PrivateRoute component={<PortalPreview />} publicPage={true} hideHeader={true} />} /> */}
                        {/*<Route path="/priceops-plans" element={<PrivateRoute component={<PriceOpsPlanListPage />} />} />*/}
                        <Route path="/app-settings" element={<PrivateRoute component={<AppProperties />} />} />
                        <Route path="/scenarios" element={<PrivateRoute component={<ScenarioListPage />} />} />
                        <Route path="/packaging" element={<PrivateRoute component={<PackagingFlow />} />} />
                        <Route path="/entitlements" element={<PrivateRoute component={<EntitlementsFlow />} />} />
                        <Route path="/designs/:designId" element={<PrivateRoute component={<PublisherDesign />} publicPage={true} hideHeader={true} />} />
                        {/*<Route path="/organization/:organizationId/project/:projectId" element={<ProjectDetails />} />*/}
                        <Route path="/" element={<PrivateRoute component={<OrganizationSelection />} />} />
                        <Route path="/settings" element={<PrivateRoute component={<Settings />} />} />
                        <Route path="/login" element={<PrivateRoute component={<LoginPage onSuccess={handleLogin} />} publicPage={true} hideHeader={true} />} />
                        <Route path="/marketsegments" element={<PrivateRoute component={<MarketSegments />} analyticsPage={true} />} />
                        <Route path="/pricemodels" element={<PrivateRoute component={<PriceModelDefintionsPage />} />} />
                        <Route path="/scenarios/:scenarioId" element={<PrivateRoute component={<Scenario />} />} />
                        <Route path="/scenarios/:scenarioId/:step" element={<PrivateRoute component={<Scenario />} />} />
                        <Route path="/scenarios/:scenarioId/:step/:params" element={<PrivateRoute component={<Scenario />} />} />
                        <Route path="/analytics" element={<PrivateRoute component={<Insights />} analyticsPage={true} />} />
                        <Route path="/willingnesstopay" element={<PrivateRoute component={<React.Fragment />} />} />
                        <Route path="/pricing" element={<PrivateRoute component={<PricingPage />} />} />
                        <Route path="/data" element={<PrivateRoute component={<DataIntegrationsPage />} analyticsPage={true} />} />
                        <Route path="/finance_metrics" element={<PrivateRoute component={<FinancialMetrics />} />} />
                      </Routes>
                    </Grid>
                  </Router>

                {/*NO ORG NOTIFICATION*/}
                <Snackbar open={notify} autoHideDuration={6000}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          onClose={() => setNotify(false)}>
                  <Alert variant="filled" severity="info" onClose={() => setNotify(false)}>
                    {notification}
                  </Alert>
                </Snackbar>

              </ProjectContext.Provider>
            </OrganizationContext.Provider>
            <ErrorDialog />
          </ErrorProvider>
        </ThemeProvider>
      </Grid>
  );
}

export default App;
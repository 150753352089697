import { deepOrange, grey, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#6C63FF",
      lightMain: "#d7d9ed",
      textOnMain: "white",
      sub: "#363552",
      textOnSub: "white",
      // main: "#E9F6FF",
      // sub: "white",
      text: "#000000",
      greyText: "#5E6C84", // for non essential items or labels
      hoverText: "#d6d6d6",
      border: grey[200],
      background: "#f2f2f2",
      bg2: "white",
      graphRef: "#0a2e48",
      disabledBackground: grey[300],
      disabledBorder: grey[100],
      groupBorder: grey[200],
      error: red[200],
      remove: "#ed8b8b",
      warning: deepOrange[600]
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"system-ui"',
      '"Roboto"',
      "'Inter'",
      '"Lexend Deca"',
      '"IBM Plex Sans"',
      "'Montserrat'",
      "'Chakra Petch'",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        color: "primary.text"
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        color: "primary.main"
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          height: 27,
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            padding: "4px 4px 4px 10px",
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize:12
        }
      }
    },
    MuiOutlinedInput:{
      styleOverrides:{
        root:{
          backgroundColor:'white'
        }
      }
    }
  }
});

export const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
  '#0A2E48', '#E5305E', '#CD96F6', '#4C7275', '#575E5E',
  '#9496F3', '#578797', '#F6BF9C'
];

export const uiStyles = {
  content: {
    margin: 4,
    backgroundColor: "primary.background",
    minHeight: '80vh'
  },
  icon: {
    backgroundColor: "primary.greyText",
    color: "primary.text",
    borderRadius: 20,
    marginBottom: 1,
    cursor: "pointer",
    boxShadow: 1,
    border: 1,
    width: 30,
    height: 30,
    p: 1,
    borderColor: grey[100],
    float: 'left'
  }
}
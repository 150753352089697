import React from "react";

import { Grid } from "@mui/material";
import {
  CartesianGrid,
  Label, Line,
  LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis,
  YAxis
} from "recharts";
import { mainTheme } from "../themes/themes";

export default function PricingGraph(props) {
  const data = props.data;
  const x = props.xAxisDataKey;
  const y = props.yAxisDataKey;
  const xLabel = props.xLabel;
  const yLabel = props.yLabel;
  const legend = props.legend ? props.legend : props.yLabel;
  const yFormatter = props.yFormatter ? props.yFormatter : (value) => value;
  const xFormatter = props.xFormatter ? props.xFormatter : (value) => value;
  const yReference = props.yReference ? yFormatter(props.yReference) : null;
  const xReference = props.xReference ? xFormatter(props.xReference) : null;
  const width = props.width ? props.width : 700;
  const height = props.height ? props.height : 400;
  const cursor = props.onClick ? 'pointer' : 'default';
  const hideXLabel = props.hideXLabel ? props.hideXLabel : false;
  const hideYLabel = props.hideYLabel ? props.hideYLabel : false;
  const hideGrid = props.hideGrid ? props.hideGrid : false;

  const YTickLabel = props => {
    return (
      <g>
        <rect
          x={props.viewBox.x}
          y={props.viewBox.y}
          fill="transparent"
          width={100}
          height={30}
        />
        <text x={props.viewBox.x} y={props.viewBox.y} dy={15} dx={30} fontSize={13}>
          {yReference}
        </text>
      </g>
    );
  };

  const XTickLabel = props => {
    return (
      <g>
        <rect
          x={props.viewBox.x}
          y={props.viewBox.y}
          fill="transparent"
          width={100}
          height={30}
        />
        <text x={props.viewBox.x} y={props.viewBox.y} dy={20} dx={5} fontSize={13}>
          {xFormatter(xReference)}
        </text>
      </g>
    );
  };

  const getYRange = () => {
    if (data) {
      const max = Math.max.apply(null, data.map(d => d[y] ? d[y] : 0));
      const min = Math.min.apply(null, data.map(d => d[y] ? d[y] : 0));
      const variance = (max - min) / (max === 0 ? 1 : max);
      const range = [((1 - variance) * min), ((1 + variance) * max)];
      return range;
    }
    return [0, 0];
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{ cursor: cursor }}>
      <Grid container sx={{ cursor: cursor, maxWidth: 700, pl: 2, pr: 2 }}>
        <ResponsiveContainer width="95%" height={height}>
          <LineChart
            width={width}
            height={height}
            data={data}
            margin='0 auto'
            sx={{ cursor: cursor }}
          >
            {hideYLabel && <YAxis domain={props.yRange ? props.yRange : getYRange()} tick={false} width={2}></YAxis>}
            {hideXLabel && <XAxis dataKey={x} tick={false} height={2}></XAxis>}
            {!hideGrid && <CartesianGrid strokeDasharray="2 2" />}
            {!hideXLabel && <XAxis dataKey={x} fontSize={10} dx={-10} angle={300} dy={20} height={100} tickFormatter={xFormatter}>
              <Label fontSize={13} dy={30} value={xLabel} sx={{ color: "primary.text" }}></Label>
            </XAxis>}
            {!hideYLabel && <YAxis
              domain={props.yRange ? props.yRange : getYRange()}
              fontSize={10}
              tickFormatter={yFormatter}
            >
              <Label
                dx={-25}
                fontSize={13}
                sx={{
                  textAnchor: "middle",
                  fill: "primary.text",
                  color: "primary.text",
                  marginLeft: -20,
                }}
                angle={270}
                value={yLabel}
              />
            </YAxis>}
            {xReference && <ReferenceLine
              x={props.xReference}
              stroke={mainTheme.palette.primary.graphRef}
              label={<Label content={XTickLabel} position="top" />}
            />}
            {yReference && <ReferenceLine
              y={props.yReference}
              stroke={mainTheme.palette.primary.graphRef}
              label={<Label content={YTickLabel} />}
            />}
            <Line name={legend} type="monotone" dataKey={y} stroke={mainTheme.palette.primary.main} strokeWidth={2} dot={props.hideDot !== true}>
            </Line>
            {!props.tooltipContent && <Tooltip formatter={yFormatter} />}
            {props.tooltipContent && <Tooltip content={props.tooltipContent} />}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid >
  );
}

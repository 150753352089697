import React, { useState } from "react";

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    Backdrop,
    IconButton
} from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from '@mui/icons-material/Person';
import { Dialog } from '@mui/material';
import { registerUser } from "../../apis/ClientDataService";
import OtpVerificationFlow from "./OtpVerificationForm";
import { validateEmail } from "../../utils/Utils";
import Link from "@mui/material/Link";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';

export default function RegisterFlow(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [reenterPassword, setReenterPassword] = useState("");
    const [verifyUserId, setVerifyUserId] = useState();
    const [email, setEmail] = useState(props?.email);
    const [team] = useState("default");
    const [roles] = useState([]);
    const [status, setStatus] = React.useState(null);
    const [state, setState] = React.useState(null);
    const [loading, setLoading] = React.useState(false);


    const passwordCheck = (pass) => {
        // Regular expression that matches passwords with
        // at least 8 characters, an uppercase letter, a lowercase letter, a number, and a special character
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/;
        return regex.test(pass);
    }



    function validateForm() {
        return username.length > 0 && password.length > 0 && passwordCheck(password)  && password === reenterPassword && validateEmail(email);
    }

    function handleRegister(event) {
        event.preventDefault();

        if (password !== reenterPassword) {
            setStatus("Passwords do not match!");
            setState("error");
            return;
        }

        if (!validateEmail(email)) {
            setState("error");
            setStatus("Please enter a valid email id");
            return;
        }

        const userDetails = {
            name: username.trim(),
            password: password,
            email: email.trim(),
            team: team.trim(),
            roles: roles
        }
        setLoading(true);
        setStatus(null);
        registerUser(userDetails, (data) => {
            setVerifyUserId(data["id"]);
            setLoading(false);
            setUsername("");
            setPassword("");
            setReenterPassword("");
            setEmail("");
            setStatus("You have registered successfully. Please verify your account with the OTP sent to your email " + email);
            setState("success");
            if (props?.onSuccess) props?.onSuccess(email);
        }, err => {
            setLoading(false);
            setStatus("Failed to register user, " + err.error_message);
            setState("error");
        });
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        // <form onSubmit={handleRegister} width={'100%'}>
            <Grid  container item justifyContent="center" alignItems="center" direction="column" pt={3}>
                {
                    loading && <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <Dialog open={state === "success"} disableScrollLock={true}>
                    <OtpVerificationFlow
                        verifyUserId={verifyUserId}
                        updateLoading={setLoading}
                        updateStatus={setStatus}
                        updateState={setState}
                    />
                </Dialog>

                <Grid container item direction="column" gap={1}>
                    <TextField
                        variant="outlined"
                        size={'small'}
                        margin="dense"
                        label="Name"
                        id="user-name"
                        aria-describedby="name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        variant="outlined"
                        size={'small'}
                        margin="dense"
                        label="Email"
                        id="login-id"
                        aria-describedby="email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        variant="outlined"
                        size={'small'}
                        margin="dense"
                        label="Password"
                        id="login-password"
                        aria-describedby="Login password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        autoComplete="new-password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {password &&
                        (passwordCheck(password) ?
                        <Alert severity={'success'} >
                            Contains atleast 8 characters including
                            an upper case letter, lower case letter, number and special character.
                        </Alert>
                        :
                        <Alert severity={'info'} >
                            Must contain atleast 8 characters including
                            an upper case letter, lower case letter, number and special character.
                        </Alert>
                        )
                    }

                    <TextField
                        variant="outlined"
                        size={'small'}
                        margin="dense"
                        label="Confirm Password"
                        id="re-enter-password"
                        aria-describedby="Re enter password"
                        value={reenterPassword}
                        onChange={(e) => setReenterPassword(e.target.value)}
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Grid container textAlign={'center'} justifyContent={'center'}>
                        <Typography fontSize={12} mt={1}>
                            By continuing, you agree to PriceOps' <Link
                            href="https://www.priceops.net/terms-and-conditions"
                            target="_blank"><b>Terms of Use</b></Link>.<br/> Read our <Link
                            href="https://www.priceops.net/privacy-policy"
                            target="_blank"><b>Privacy Policy</b></Link>.
                        </Typography>
                    </Grid>

                    {status &&
                        <Grid item sx={{ my: 1 }}>
                            <Alert onClose={() => setStatus(null)} severity={state}>
                                {status}
                            </Alert>
                        </Grid>
                    }

                    <Button
                        sx={{ mt: 1, py:2.7,
                            '&.Mui-disabled': {
                                color: 'white',
                                backgroundColor: 'primary.main',
                            },
                        }}
                        size="lg"
                        autoFocus
                        type="submit"
                        disabled={!validateForm() && !loading}
                        variant="contained"
                        onClick={(e) => handleRegister(e)}
                    >
                        Sign up
                    </Button>

                    <Grid container width={'100%'} gap={0.5} mt={1} justifyContent={'center'}>
                        <Typography fontSize={12}>Have an account?</Typography>
                        <Typography
                            fontSize={12}
                            fontWeight={800}
                            type="button"
                            onClick={() => props.setRegisterPage(false)}
                            sx={{ color: "primary.main", cursor: 'pointer' }}
                        >
                            Log in
                        </Typography>
                    </Grid>
                    {/*<Grid>*/}
                    {/*    <Button onClick={() => props.setRegisterPage(false)} sx={{ color: "primary.main", mt: 2 }}>Back to login</Button>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        // </form>
    );
}
